import React, { useEffect, useState } from "react";
import { Platform, TextInput, View } from "react-native";
import { useSelector } from "react-redux";
// import esiColor from "../../constants/Colors";

export default function QuentityField(props) {

    const [count, setCount] = useState(props.count);
    useEffect(() => {
        setCount(props.count);
    }, [props.count])
    const esiColor = useSelector(state => state.theme);

    return (
        Platform.OS === 'web' ?
            <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                style={{ borderWidth: 1, width: 30, borderColor: '#27b6cc', textAlign: 'center', color: esiColor.itemColor }}
                onChangeText={(value) => {
                    if (parseInt(value))
                        setCount(value);
                    else
                        setCount("");
                }}
                onBlur={(e) => {
                    props.handleOnChangeCount(e.target.value||1);
                    setCount(e.target.value||1);
                }}
                value={count}
                maxLength={2}
            />
            :
            <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                style={{ borderWidth: 1, width: 30, borderColor: '#27b6cc', textAlign: 'center', color: esiColor.itemColor }}
                onChangeText={(value) => {

                    if (parseInt(value))
                        setCount(value);
                    else
                        setCount("");


                }}
                onEndEditing={(e) => {
                    props.handleOnChangeCount(count || 1);
                    props.handleOnChangeCount(count || 1);
                    if(!count){
                        setCount(1);
                    }
                }}
                maxLength={2}

                value={String(count)}
            />

    )
}