import { getAllContentManagementStaticAction } from "../../state/actions/contentActions";
import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView, Dimensions, Image, TouchableOpacity, Linking, Platform, } from 'react-native';
import { Text, Surface, ActivityIndicator, Card } from 'react-native-paper';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import Footer from "../footer";
// import Map from "../../components/custom/MapView/MapView";

export default function AboutUs({ navigation }) {
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const dispatch = useDispatch();
    const [contentsOne, setContentsOne] = useState({});
    // console.log(contentsOne)
    // const MY_URL = 'https://www.google.com/maps/place/EasyStepIn+IT+Services+Private+Limited/@12.9560352,77.6945393,19z/data=!4m13!1m7!3m6!1s0x3bae13cc14d607cb:0x5eaf25d9f64c2215!2sEasyStepIn+IT+Services+Private+Limited!3b1!8m2!3d12.9560347!4d77.6950702!3m4!1s0x3bae13cc14d607cb:0x5eaf25d9f64c2215!8m2!3d12.9560347!4d77.6950702'
    const responseFunction = (data) => {
        setContentsOne(data)
    }
    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Name": "ContactUs",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);
    return (
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView>
                <View style={{ flexDirection: 'row', margin: 2, marginBottom: 25, marginTop: 10, alignSelf: 'center' }}>
                    <View>
                        {/* <Text style={{ fontSize: 24, marginTop: 10, marginBottom:10,fontWeight: "bold", alignContent: 'center', color: '#27b6cc', justifyContent: 'center' }}>About Us</Text> */}
                        <Image style={{ width: "85%", height: 80, flexDirection: 'row', alignSelf: 'center', }} source={require('../../assets/images/aboutus/TITLE.png')} />

                        <View style={{  alignSelf: 'center' }}>
                            <Text style={{ fontStyle: 'normal', alignSelf: 'center' }}> <h4>Meepaisa is India's leading e-commerce marketplace with over 10K+ Partners and 80 million products across 80+ categories.</h4></Text>
                        </View>

                        <Image style={{ width: 200, height: 200, alignSelf: 'center', marginTop: "2%" }} source={require('../../assets/images/aboutus/shop.png')} />
                        <View style={{  alignSelf: 'center', }}>
                            <Text style={{ alignSelf: 'center', fontStyle: 'normal', marginTop: 10 }}> <h4>Meepaisa started with the team of 15 members.</h4></Text>
                        </View>
                        <Image style={{ width: 300, height: 250, alignSelf: 'center', marginTop: "2%" }} source={require('../../assets/images/aboutus/shopf.png')} />
                        <View style={{ alignSelf: 'center' }}>
                            <Text style={{ alignSelf: 'center', fontStyle: 'normal' }}> <h4>We are now connected with all the cities.</h4></Text>
                        </View>
                        <Image style={{ width: 300, height: 250, alignSelf: 'center', marginTop: "2%" }} source={require('../../assets/images/aboutus/esilogo.png')} />
                    </View>
                </View>

                <Footer navigation={navigation} />
            </ScrollView>
        </Surface>
    );
}
