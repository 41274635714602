import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Alert, Platform } from "react-native";
import { boomiApi } from "./commonAxios";

export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const CART_DEFAULT_ADDRESS = "CART_DEFAULT_ADDRESS";


export const setDefaultAddress = (address) => {
    return function (dispatch) {
        dispatch({
            type: CART_DEFAULT_ADDRESS,
            data: address
        })
    }
}

export const getCashBackVoucher = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/Meepaisa_Coupon/meepaisa_coupon_remaining`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                responseFunction(true, successResponse.Coupon_Remaining, formData);
            } else {
                responseFunction(false, [], formData);
            }
        })
        .catch(error => {
            responseFunction(false, [], formData);
        })
};

export const setDefaultAddressOnTest = () => {
    return function (dispatch) {
        AsyncStorage.getItem('defaultAddress').then((value) => {
            dispatch({
                type: CART_DEFAULT_ADDRESS,
                data: JSON.parse(value)
            })
        })

    }
}


export const addCart = (payload, emailId, responseFunction = (data) => { }) => {
    return function (dispatch) {
        boomiApi.post(`/User_Cart/Create`, payload)
            .then(response => {
                let data = response.data;
                responseFunction(data);
                if (data.Response_Status == "Success") {
                }
                else {
                }
                dispatch(getCart({ User_Email_Id: emailId }))
            })
            .catch(error => {
            })
    }
}

export const getCart = (payload) => {
    return function (dispatch) {
        boomiApi.post(`/User_Cart/GET`, payload)
            .then(response => {
                let data = response.data;
                let cart = [];
                if (data[0]?.Cart_Get) {
                    let responseCart = data[0]?.Cart_Get ? data[0]?.Cart_Get : []
                    for (let i = 0; i < responseCart.length; i++) {
                        let item = {
                            "partnerId": responseCart[i]?.Partner_Details_Id,
                            "partnerName": responseCart[i]?.Partner_Name,
                            "partnerLogo": responseCart[i]?.Logo_Path,
                            "Business_Type": responseCart[i]?.Business_Type,
                            products: [{
                                Product: responseCart[i]?.Products.Product,
                                partnerProduct: responseCart[i]?.Products.Partner_Product,
                                partnerProductId: responseCart[i]?.Products.Partner_Product.Partner_Product_Id,
                                itemCount: responseCart[i]?.Products.Inventory.User_Requested_Quantity,
                                size: responseCart[i]?.Products.Inventory.Size,
                                addressError: "",
                                isGiftPackErrorMessage: "",
                                address: responseCart[i]?.Products.Delevery_Address,
                                User_Cart_Id: responseCart[i]?.User_Cart_Id,
                                Inventory: responseCart[i]?.Products.Inventory,
                                isGiftPackMessage: responseCart[i]?.Products?.User_Cart_Details?.Gift_Message || "",
                                isGiftPack: (responseCart[i]?.Products?.User_Cart_Details?.Gift_Message ? true : false),
                                Email_ID: payload.User_Email_Id,
                                GEO_Locations: responseCart[i]?.Products.GEO_Locations
                            }],
                            addressError: ""
                        }
                        let notExist = true;
                        for (let j = 0; j < cart.length; j++) {
                            if (cart[j].partnerId == responseCart[i]?.Partner_Details_Id) {
                                notExist = false;
                                cart[j].products.push({
                                    Product: responseCart[i]?.Products.Product,
                                    partnerProduct: responseCart[i]?.Products.Partner_Product,
                                    partnerProductId: responseCart[i]?.Products.Partner_Product.Partner_Product_Id,
                                    itemCount: responseCart[i]?.Products.Inventory.User_Requested_Quantity,
                                    size: responseCart[i]?.Products.Inventory.Size,
                                    addressError: "",
                                    isGiftPackErrorMessage: "",
                                    address: responseCart[i]?.Products.Delevery_Address,
                                    User_Cart_Id: responseCart[i]?.User_Cart_Id,
                                    Inventory: responseCart[i]?.Products.Inventory,
                                    isGiftPackMessage: responseCart[i]?.Products?.User_Cart_Details?.Gift_Message || "",
                                    isGiftPack: (responseCart[i]?.Products?.User_Cart_Details?.Gift_Message ? true : false),
                                    Email_ID: payload.User_Email_Id,
                                    GEO_Locations: responseCart[i]?.Products.GEO_Locations
                                })
                            }
                        }
                        if (notExist) {
                            cart.push(item);
                        }
                    }
                }

                dispatch({
                    type: GET_CART_ITEMS,
                    data: cart
                })
            })
            .catch(error => {
            })
    }
}


export const productAddOrRemoveAddress = (cartItem, address) => {
    return function (dispatch) {
        let payload = {
            "Cart_Id": cartItem.User_Cart_Id,
            "Is_Self_Pickup": 0,
            "Partner_Address_Id": "",
            "User_Address_Id": address?.User_Address_Id || ""
        }
        boomiApi.put(`/User_Cart/Address_Update`, payload)
            .then(response => {
                let data = response.data;
                dispatch(getCart({ User_Email_Id: cartItem.Email_ID }))
            })
            .catch(error => {
            })
    }
}

export const productAddOrRemoveAddressPartnerPage = (cartItem, address) => {
    return function (dispatch) {
        let payload = {
            "Cart_Id": cartItem.User_Cart_Id,
            "Is_Self_Pickup": 0,
            "Partner_Address_Id": "",
            "User_Address_Id": address?.User_Address_Id || ""
        }
        boomiApi.put(`/User_Cart/Address_Update`, payload)
            .then(response => {
                let data = response.data;
            })
            .catch(error => {
            })
    }
}


export const partnerAddOrRemoveAddress = (cartItem, address, toast) => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "FILTER",
            "Partner_Details_Id": cartItem.partnerProduct?.Partner_Details_Id,
            "User_Email_Id": cartItem?.Email_ID,
            "User_Address_Id": address?.User_Address_Id || ""
        }
        boomiApi.put(`/User_Cart/Update`, payload,)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Failure") {
                    toast.show({ message: data.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
                }
                dispatch(getCart({ User_Email_Id: cartItem.Email_ID }))
            })
            .catch(error => {
            })
    }
}


export const productAddOrRemoveGiftPack = (oldCart1, newItem) => {
    return function (dispatch) {
        let oldCart = JSON.parse(JSON.stringify(oldCart1));
        let partner = newItem.partnerProduct;
        let partnerExist = false;
        let productExist = false;
        for (let i = 0; i < oldCart.length; i++) {
            if (oldCart[i].partnerId === partner.Partner_Details_Id) {
                let items = oldCart[i].products;
                partnerExist = true;
                for (let j = 0; j < items.length; j++) {
                    let item = items[j];
                    if (item.partnerProductId === partner.Partner_Product_Id) {
                        item.isGiftPack = item.isGiftPack ? item.isGiftPack ? false : true : true;
                        if (!(item.isGiftPack)) {
                            item.isGiftPackMessage = "";
                            item.isGiftPackErrorMessage = "";
                        }
                        productExist = true;
                        break;
                    }
                }
                if (productExist) {
                    break;
                }
            }
        }
        dispatch({
            type: GET_CART_ITEMS,
            data: oldCart
        })
    }
}
export const productAddOrRemoveGiftPackMessage = (cartItem, message) => {
    return function (dispatch) {
        let payload = {
            "Cart_Id": cartItem.User_Cart_Id,
            "Gift_Message": message,
        }
        boomiApi.put(`/User_Cart/Address_Update`, payload)
            .then(response => {
                let data = response.data;
                dispatch(getCart({ User_Email_Id: cartItem.Email_ID }))
                if (data.Response_Status == "Success") {
                }
                else {
                }
            })
            .catch(error => {
            })
    }
}


export const addItemMultipleCart = (cartItem, count, responseFunction = (data) => { }) => {
    return function (dispatch, getState) {
        let payload = {
            "User_Cart_Id": cartItem.User_Cart_Id,
            "Quantity": count,
            "Partner_Product_Id": cartItem.partnerProduct.Partner_Product_Id
        }
        boomiApi.put(`/User_Cart/Update`, payload)
            .then(response => {
                let emailId = getState().auth.user.Email_Id
                let data = response.data;
                dispatch(getCart({ User_Email_Id: emailId }))
                responseFunction(data);
                if (data.Response_Status == "Success") {
                }
                else {
                }
            })
            .catch(error => {
            })
    }
}

export const removeItemInCart = (cartItem) => {
    return function (dispatch) {
        let payload = {
            "User_Cart_Id": cartItem.data.User_Cart_Id,
            "Move_Wish_List": cartItem.Move_Wish_List
        }
        boomiApi.post(`/User_Cart/Delete`, payload)
            .then(response => {
                let data = response.data;
                dispatch(getCart({ User_Email_Id: cartItem.data.Email_ID }))

                if (data.Response_Status == "Success") {
                }
                else {
                }
            })
            .catch(error => {
            })
    }
}

export const removePartnerInCart = (Partner_Detail_Id, emailId) => {
    return function (dispatch) {
        let payload = {
            "Partner_Detail_Id": Partner_Detail_Id,
            "User_Email_Id": emailId
        }
        boomiApi.post(`/User_Cart/Delete`, payload)
            .then(response => {
                let data = response.data;
                dispatch(getCart({ User_Email_Id: emailId }))
                if (data.Response_Status == "Success") {
                }
                else {
                }
            })
            .catch(error => {
            })
    }
}

export const removeAll = (emailId) => {
    return function (dispatch) {
        let payload = {
            "User_Email_Id": emailId
        }
        boomiApi.post(`/User_Cart/Delete`, payload)
            .then(response => {
                let data = response.data;
                dispatch(getCart({ User_Email_Id: emailId }))
                if (data.Response_Status == "Success") {
                }
                else {
                }
            })
            .catch(error => {
            })
    }
}


export const productRemoveAddressError = (oldCart1) => {
    return function (dispatch) {
        let oldCart = JSON.parse(JSON.stringify(oldCart1))
        for (let i = 0; i < oldCart.length; i++) {
            let items = oldCart[i].products;
            for (let j = 0; j < items.length; j++) {
                let item = items[j];
                item.addressError = "";
                item.isGiftPackErrorMessage = "";
            }
            oldCart[i].addressError = "";
        }
        dispatch({
            type: GET_CART_ITEMS,
            data: oldCart
        })

    }
}


export const paceOrderValidation = (oldCart1, partner, addressCheckResponse, productError, type = null) => {
    return function (dispatch) {
        let oldCart = JSON.parse(JSON.stringify(oldCart1));
        let partnerExist = false;
        let noError = true;
        for (let i = 0; i < oldCart.length; i++) {
            if (type === "all") {
                let items = oldCart[i].products;
                partnerExist = true;
                for (let j = 0; j < items.length; j++) {
                    let item = items[j];
                    if (item.address) {
                        item.addressError = "";
                    }
                    else {
                        item.addressError = "Shipping Address is required."
                        noError = false;
                    }
                    if (item.isGiftPack) {
                        if (!(item.isGiftPackMessage)) {
                            item.isGiftPackErrorMessage = "Gift Message is required.";
                            noError = false;
                        } else if (item.isGiftPackMessage.length < 5) {
                            item.isGiftPackErrorMessage = "Gift Message must be more than 15 characters.";
                            noError = false;
                        }
                        else {
                            item.isGiftPackErrorMessage = "";
                        }
                    }
                }
            } else {
                if (oldCart[i].partnerId === partner.partnerId) {
                    let items = oldCart[i].products;
                    partnerExist = true;
                    for (let j = 0; j < items.length; j++) {
                        let item = items[j];
                        if (item.address) {
                            item.addressError = "";
                        }
                        else {
                            item.addressError = "Shipping Address is required."
                            noError = false;
                        }

                        if (item.Inventory.Is_Stock_Available == "0") {
                            noError = false;
                        }

                        if (item.isGiftPack) {
                            if (!(item.isGiftPackMessage)) {
                                item.isGiftPackErrorMessage = "Gift Message is required.";
                                noError = false;
                            } else if (item.isGiftPackMessage.length < 5) {
                                item.isGiftPackErrorMessage = "Gift Message must be more than 15 characters.";
                                noError = false;
                            }
                            else {
                                item.isGiftPackErrorMessage = "";
                            }
                        }
                    }
                    break;
                }
            }
        }
        if (productError.length > 0) {
            noError = false;
        }
        addressCheckResponse(noError);
        dispatch({
            type: GET_CART_ITEMS,
            data: oldCart
        })
    }
}


// API connection data
export const giftVoucherCheck = (voucherId, giftVoucherResponse) => {
    return function (dispatch) {
        let payload = {
            Gift_Voucher_Id: voucherId
        };
        boomiApi.post(`/Gift_Voucher/Gift_Voucher_Send_OTP_To_Email_&_Mobile`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    giftVoucherResponse(true, response.data);
                }
                else {
                    giftVoucherResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                giftVoucherResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const getValidGiftVoucher = (voucherId, responseVoucher) => {
    return function (dispatch) {
        let payload = {
            "Gift_Id": voucherId
        };
        boomiApi.post(`/Gift_Voucher/User_Get`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    responseVoucher(true, response.data);
                }
                else {
                    responseVoucher(false, data.Response_Message);
                }
            })
            .catch(error => {
                responseVoucher(false, "Currently server is not working. Please try again later.");
            })
    }
}


export const couponCodeCheckAction = (code, user, partnerID, responseVoucher, index) => {
    return function (dispatch) {
        let payload = {
            "Code": code,
            "Email_Id": user,
            "Partner_Detail_Id": partnerID
        };
        boomiApi.post(`/Coupons/User_Get`, payload)
            .then(response => {
                let data = response.data;
                if (data?.Response_Success == "Success" || data?.Success_Response?.Response_Success == "Success") {
                    responseVoucher(true, response.data?.Coupons[0], index);
                }
                else {
                    responseVoucher(false, data.UI_Display_Message, index);
                }
            })
            .catch(error => {
                responseVoucher(false, "Currently server is not working. Please try again later.", index);
            })
    }
}


export const placeNewOrderAction = (request, setResponse, item) => {
    return function (dispatch) {
        boomiApi.post(`/Order/Create`, request)
            .then(response => {
                setResponse(true, response.data, item);
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const productInventoryAction = (request, setResponse, cart, data, count) => {
    return function (dispatch) {
        boomiApi.post(`/Inventory/Inventory_Stock_Availability`, request)
            .then(response => {
                setResponse(true, response.data, cart, data, count);
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.", cart, data, count);
            })
    }
}

export const placeNewOrderInventoryAction = (request, setResponse) => {
    return function (dispatch) {
        boomiApi.post(`/Inventory/Inventory_Validation_Cart`, request)
            .then(response => {
                setResponse(true, response.data);
            })
            .catch(error => {
                setResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const corpVoucherCheck = (voucherId, corpVoucherResponse) => {
    return function (dispatch) {
        let payload = {
            Corp_Coupon_ID: voucherId
        };
        boomiApi.post(`/Order/Corp_coupon_OTP_send`, payload)
            .then(response => {
                let data = response.data;
                if (data.Response_Status == "Success") {
                    corpVoucherResponse(true, response.data);
                }
                else {
                    corpVoucherResponse(false, data.UI_Display_Message);
                }
            })
            .catch(error => {
                corpVoucherResponse(false, "Currently server is not working. Please try again later.");
            })
    }
}

export const getValidCorpVoucher = (voucherId, responseVoucher) => {
    return function (dispatch) {
        let payload = {
            "Records_Filter": "FILTER",
            "Corp_Coupon_Id": voucherId
        };
        boomiApi.post(`/Order/Corp_Coupon_Get`, payload)
            .then(response => {
                let data = response.data;
                if (data.Success_Response.Response_Status == "Success") {
                    responseVoucher(true, data.Coupon[0]);
                }
                else {
                    responseVoucher(false, data.Success_Response.UI_Display_Message);
                }
            })
            .catch(error => {
                responseVoucher(false, "Currently server is not working. Please try again later.");
            })
    }
}