import React, { useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../../components/custom/product-card";
// import { getvisitedProductByIdAction } from "../../state/actions/productsActions";
import isEmpty from "../../state/validations/is-empty";
import recentlyvisitedgif from "../../assets/images/GIF/recentlyvisitedgif.gif"
import { Dimensions } from "react-native";
import { Image } from "react-native";
import { getvisitedProducts } from "../../state/actions/node-actions/home-actions";
// import esiColor from "../../constants/Colors";


export default function RecentlyVisites({ navigation, dataReload, dataErrorReload }) {
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const { height, width } = Dimensions.get('window');
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const [sEmailId, setSEmailId] = useState("");
    const [visitors, setVisitors] = useState([]);

    const [loading, setLoading] = React.useState(false);
    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setVisitors(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            visiterProductCAll();
        } else {
            await delay(dataErrorReload);
            visiterProductCAll();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const visiterProductCAll = () => {
        setLoading(true);
        dispatch(getvisitedProducts({
            Records_Filter: "FILTER",
            User_Email_Id: EmailID,

        }, { PageNo: 1, PageSize: 20, SortBy: "USER_VISIT_ID", SortOrder: "DESC" }, callBackGetData));
    }

    useEffect(() => {
        if ((EmailID != sEmailId) && EmailID) {
            setSEmailId(EmailID);
            visiterProductCAll();
        }
    }, [EmailID]);

    const handleClick = async (item) => {
        navigation('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id })
    };

    const styles = StyleSheet.create({
        content: {
            // borderRadius: 10,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            // padding: 5,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            // elevation: 10,
            marginTop: 15,
            marginBottom: 15,
            // paddingBottom:25
        },

    });

    return (
        <View>

            {isAuthenticated &&
                <View>
                    <Text style={{ textAlign: 'left', fontSize: 16, color: esiColor.LCFontColor, fontWeight: 'bold', marginBottom: 10, marginLeft: 10, marginTop: 10 }}>Recent Visits</Text>

                    <View style={styles.content}>

                        <ScrollView
                            contentContainerStyle={{ paddingVertical: 10 }}
                            horizontal
                            showsHorizontalScrollIndicator={false}>

                            {!loading ?

                                !isEmpty(visitors) ? (
                                    visitors.map((data, index) => (
                                        <View key={data, index}>
                                            <ProductCard data={data} navigate={navigation.navigate} handleClick={handleClick} ProductId={data?.Product_Id} PartnerId={data?.Partner_Details_Id} />
                                        </View>
                                    ))
                                )
                                    : (<View style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flex: 1, marginTop: 30 }}>
                                        <Text style={{ color: esiColor.NoDataFound }}>You haven't visited any Products yet. Please do visit.</Text>
                                        <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={recentlyvisitedgif} />
                                    </View>
                                    ) :
                                <ActivityIndicator color='#27b6cc' />}
                        </ScrollView>
                    </View>
                </View>
            }
        </View>
    )

}

