import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import Modal from "react-native-modal";
import { Avatar, Button, HelperText, Surface, TouchableRipple } from "react-native-paper";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { Rating } from "react-native-ratings";
import { updateUserBiddingProduct } from "../../../../state/actions/boomi-actions/bid-products-actions";
import { useSelector } from "react-redux";

export default function Review(props) {
    const { dimensions, partnerProduct, product, getProductDetails, dispatch, ...rest } = props;
    const [addReviewPop, setAddReviewPop] = useState(false);
    const [loadingForm, setloadingForm] = useState(false);

    const reviewCallBack = (status, data) => {
        setloadingForm(false);
        if (status) {
            setAddReviewPop((prop) => !prop);
            getProductDetails();
        }
    }
    const esiColor = useSelector(state => state.theme);

    return (
        <View style={{ flexDirection: "column", marginTop: 10, marginLeft: 15 }} >
            
            <View>
                {product?.User_Rating_For_Product ?
                    <View style={{ flexDirection: 'column', width: "100%" }}>
                        <View style={{ width: "100%", flexDirection: "row", margin: "auto" }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, marginVertical: 5, fontWeight: "500", color: esiColor.itemColor }}>Product Rating  :  </Text>
                            <Rating
                                type='heart'
                                tintColor={esiColor.TintColor}
                                startingValue={product?.User_Rating_For_Product || 0}
                                ratingCount={5}
                                imageSize={20}
                                style={{ paddingVertical: 5 }}
                                readonly={true}
                            />
                        </View>
                        <View style={{ width: "100%", margin: "auto" }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, marginVertical: 5, fontWeight: "500", color: esiColor.itemColor }}>Product Review  :</Text>
                            <Text style={{ fontSize: 14, marginLeft: 5, color: esiColor.itemColor }}>{product?.User_Review_For_Product}</Text>

                        </View>
                        <View style={{ width: "100%", flexDirection: "row", margin: "auto", marginTop: 10 }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, fontWeight: "500", color: esiColor.itemColor }}>Partner Rating  :  </Text>
                            <Rating
                                type='heart'
                                tintColor={esiColor.TintColor}
                                startingValue={product?.User_Rating_For_Partner || 0}
                                ratingCount={5}
                                imageSize={20}
                                style={{ paddingVertical: 5 }}
                                readonly={true}
                            />
                        </View>
                        <View style={{ width: "100%", margin: "auto" }}>
                            <Text style={{ fontSize: 16, marginLeft: 5, marginVertical: 5, fontWeight: "500", color: esiColor.itemColor }}>Partner Review  :</Text>
                            <Text style={{ fontSize: 14, marginLeft: 5, color: esiColor.itemColor }}>{product?.User_Review_For_Partner}</Text>
                        </View>
                    </View>
                    :
                    <View style={{ flexDirection: 'column', margin: 10 }}>
                        <View style={{ flex: 1, alignSelf: 'center', }}>
                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, width: 110, height: 30, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }} onPress={() => { setAddReviewPop(prop => !prop) }}>
                                <Text style={{ color: esiColor.itemButtenColor }}>ADD REVIEW</Text>
                            </TouchableOpacity>
                        </View>
                    </View>}
            </View>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={addReviewPop}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 400, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderWidth: 1, borderColor: esiColor.SBorderColor }}>
                    <Surface style={{
                        justifyContent: 'center',
                        borderRadius: 10,
                        top: Platform.OS === 'web' ? 10 : 20,
                        elevation: 0, backgroundColor: esiColor.BackgroundColor
                    }} >
                        <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                            setAddReviewPop((prop) => !prop);
                        }}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                        <Formik
                            initialValues={
                                {
                                    Product_Rating: 0,
                                    Product_Review: '',
                                    Partner_Rating: 0,
                                    Partner_Review: '',

                                }
                            }
                            validationSchema={Yup.object().shape({
                                Product_Rating: Yup.number().min(1, "Please provide Rating.").required("Please provide Rating."),
                                Partner_Rating: Yup.number().min(1, "Please provide Rating.").required("Please provide Rating."),
                                Product_Review: Yup.string().min(10, "Please provide minimum 10 characters.").required("Review is required."),
                                Partner_Review: Yup.string().min(10, "Please provide minimum 10 characters.").required("Review is required."),
                            })}
                            onSubmit={values => {
                                setloadingForm(true);
                                let formData = {
                                    "User_Products_Bidding_Id": product?.User_Products_Bidding_Id,
                                    "User_Rating_For_Partner": values.Partner_Rating,
                                    "User_Rating_For_Product": values.Product_Rating,
                                    "User_Review_For_Partner": values.Partner_Review,
                                    "User_Review_For_Product": values.Product_Review
                                }
                                dispatch(updateUserBiddingProduct(formData, reviewCallBack));
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                <View style={{
                                    flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                }}>
                                    {
                                        <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>

                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Product Rating:</Text>
                                                <Rating
                                                    type='heart'
                                                    tintColor={esiColor.TintColor}
                                                    startingValue={values.Product_Rating}
                                                    ratingCount={5}
                                                    imageSize={30}
                                                    style={{ paddingVertical: 10 }}
                                                    onFinishRating={(value) => {
                                                        setFieldValue("Product_Rating", value)
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Rating && errors.Product_Rating)}>
                                                    {touched.Product_Rating && errors.Product_Rating}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Product Review:</Text>
                                                <EsiTextAreaInput
                                                    error={Boolean(touched.Product_Review && errors.Product_Review)}
                                                    containerStyle={{ height: 100 }}
                                                    style={{ height: 100 }}
                                                    mode='outlined'
                                                    value={values.Product_Review}
                                                    textareavalueUpdate={value => {
                                                        setFieldValue("Product_Review", value)
                                                    }}
                                                    placeholder={'Please provide Product Review.'}
                                                    underlineColorAndroid={'transparent'}
                                                    theme={{
                                                        colors: {
                                                            primary: "#27b6cc"
                                                        },
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Product_Review && errors.Product_Review)}>
                                                    {touched.Product_Review && errors.Product_Review}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Partner Rating:</Text>
                                                <Rating
                                                    type='heart'
                                                    tintColor={esiColor.TintColor}
                                                    startingValue={values.Partner_Rating}
                                                    ratingCount={5}
                                                    imageSize={30}
                                                    style={{ paddingVertical: 10 }}
                                                    onFinishRating={(value) => {
                                                        setFieldValue("Partner_Rating", value)
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Partner_Rating && errors.Partner_Rating)}>
                                                    {touched.Partner_Rating && errors.Partner_Rating}
                                                </HelperText>
                                            </View>
                                            <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                                <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Partner Review:</Text>
                                                <EsiTextAreaInput
                                                    error={Boolean(touched.Partner_Review && errors.Partner_Review)}
                                                    containerStyle={{ height: 100 }}
                                                    style={{ height: 100 }}
                                                    mode='outlined'
                                                    value={values.Partner_Review}
                                                    textareavalueUpdate={value => {
                                                        setFieldValue("Partner_Review", value)
                                                    }}
                                                    placeholder={'Please provide Partner Review.'}
                                                    underlineColorAndroid={'transparent'}
                                                    theme={{
                                                        colors: {
                                                            primary: "#27b6cc"
                                                        },
                                                    }}
                                                />
                                                <HelperText type="error" visible={Boolean(touched.Partner_Review && errors.Partner_Review)}>
                                                    {touched.Partner_Review && errors.Partner_Review}
                                                </HelperText>
                                            </View>

                                            <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                <Button disabled={loadingForm} style={{ marginTop: 5, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {
                                                    handleSubmit()
                                                }}>
                                                    <Text style={{ color: esiColor.itemButtenColor }}>SUBMIT</Text>
                                                </Button>

                                            </View>
                                        </View>}
                                </View>)}
                        </Formik>
                    </Surface>

                </View>
            </Modal>
        </View>
    )
}