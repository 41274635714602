import React from 'react';
import { Dropdown } from 'react-native-element-dropdown';
import { Dimensions, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
export default function EsiSearchDropdown(props: any) {
    //currently i am trying to get this done with useState()
    const [showDropDown, setShowDropDown] = React.useState(false);
    const esiColor = useSelector(state => state.theme);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    const styles = StyleSheet.create({

        inputSearchStyle: {
            height: 40,
            fontSize: 16,
            color: esiColor.Text
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        dropdown: {
            height: 58,
            borderWidth: 1,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
            borderColor: props.style?.borderColor || esiColor.TextOuterLine,

        },
        placeholderStyle: {
            fontSize: 16,
            color: esiColor.Text
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.Text

        },
    })
    /*====================================
            Handler
    ====================================*/
    return (
        <View>
            <Dropdown
                {...props}

                // data param here is used to assign the data from the function values that will be provided.
                style={styles.dropdown}
                data={props.data}
                // search is used to get the value from the dropdown
                search={props.search}
                maxHeight={props.maxHeight}
                // valueField is used to get the value
                valueField={props.valueField}
                searchPlaceholder={props.searchPlaceholder}
                //onFocus or onBlur is used to call when the input box is clicked
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onChange={(item) => props.onUpdateValue(item)}
                // Providing styles for placeholder
                placeholderStyle={styles.placeholderStyle}
                // Providing styles for selectedText
                selectedTextStyle={styles.selectedTextStyle}
                // Providing styles for inputSearch
                inputSearchStyle={styles.inputSearchStyle}
                // Providing styles for icon
                iconStyle={styles.iconStyle}
                containerStyle={{ backgroundColor: esiColor.BackgroundColor }}
                itemContainerStyle={{ backgroundColor: esiColor.BackgroundColor }}
                activeColor={esiColor.BackgroundColor}

            />
        </View>
    );
};
