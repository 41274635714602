import 'react-native-gesture-handler';
import * as React from 'react';
import { View, Platform, TouchableOpacity, ActivityIndicator, Keyboard } from "react-native";
import { Avatar, Checkbox, Colors, HelperText, RadioButton, Searchbar, Surface, Text, TextInput, TouchableRipple } from "react-native-paper";
import * as Location from "expo-location";
import isEmpty from '../../../state/validations/is-empty';
import DraggableMap from '../map/DraggableMap/DraggableMap';
import { useSelector } from 'react-redux';
// import { getCityLocationsByFilters } from '../../../state/actions/shopbycityActions';
// import { useDispatch } from 'react-redux';
// import esiColor from '../../../constants/Colors';



function AddressLocation(props: any) {
  //props variable consists of all <List.Accordion> properties along with map data.
  const { isLocationVisible, setIsLocationVisible, filters, setFilters } = props;
  const [locationLoad, setLocationLoad] = React.useState(false);
  const [locationStock, setLocationStock] = React.useState(false);
  const [deviceLocation, setLocation] = React.useState({ latitude: filters?.latitude, longitude: filters?.longitude });
  const esiColor = useSelector(state => state.theme);

  React.useEffect(() => {
    Keyboard.dismiss();
    (async () => {
      if ((isEmpty(deviceLocation.latitude) || isEmpty(deviceLocation.longitude)) && !locationStock) {
        setLocationLoad(true);
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          setLocation({ latitude: 12.956161044024359, longitude: 77.69511468708515 });
          setLocationStock(true);
          setLocationLoad(false);
          return;
        }
        const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
        setLocation({ latitude, longitude });
      } else if ((isEmpty(deviceLocation.latitude) || isEmpty(deviceLocation.longitude))) {
        setLocation({ latitude: 12.956161044024359, longitude: 77.69511468708515 });
      }
    })();
  })
  const [manuvalZip, setManuvalZip] = React.useState("");
  const [oldManuvalZip, setOldManuvalZip] = React.useState("");


  React.useEffect(() => {

  }, [manuvalZip])

  return (
    <Surface style={{
      // alignItems: 'flex-end',
      justifyContent: 'center',
      borderRadius: 10,
      top: Platform.OS === 'web' ? 10 : 0,
      elevation: 0,
      backgroundColor: esiColor.BackgroundColor,
    }} >
      <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
        setIsLocationVisible(!isLocationVisible);
      }}>
        <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
      </TouchableRipple>
      <View>
        <View style={{ width: props.width, height: props.height }} >
          {(deviceLocation.latitude && deviceLocation.longitude) &&
            <DraggableMap latitude={deviceLocation.latitude} longitude={deviceLocation.longitude} setZipcode={(value) => { setManuvalZip(value) }} showSelectedAddress={true} setAddressWithLanLog={(address, lan, lon, zip) => {
              setFilters(address, lan, lon, zip);
            }}
            />}
        </View>
      </View>
    </Surface>
  );
}

export default AddressLocation;