
import { meeapiBoomi, meeapiCUD } from "./meeapi";

export const createBids = (
    formData = [],
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.post("/User_Products_Bidding/Create", formData)
        .then(response => {
            let data = response.data;
            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const createCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/Bidding_Products_Cart/Create", formData)
        .then(response => {
            let data = response.data;
            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const updateCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.put("/Bidding_Products_Cart/Update", formData)
        .then(response => {
            let data = response.data;
            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const deleteCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/Bidding_Products_Cart/Delete",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const placeCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.post("/User_Products_Bidding/Cart_Delete_Bidding_Products_Create",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const placeCommentToBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/Bidding_Products_Comments/Create",
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Response_Status == "Success") {
            responseFunction(true);
        } else {
            responseFunction(false);
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const updateProductPayment = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.put("/User_Products_Bidding/Payments_Update", formData)
        .then(response => {
            let data = response.data;
            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const updateUserBiddingProduct = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.put("/User_Products_Bidding/Update", formData)
        .then(response => {
            let data = response.data;
            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};