import { shareAsync } from "expo-sharing";
import React, { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Dialog, Button, IconButton, Portal, Surface, Text, Card, Divider, DataTable, List, TextInput, HelperText, Menu } from "react-native-paper";
import StepIndicator from 'react-native-step-indicator';
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersAction, getOrderdetailAction, getOrdertrackAction, getSingleOrdersAction, OrderInvoiceAction, OrderStatusUpdateAction } from "../../state/actions/orderDetailsAction";
import isEmpty from "../../state/validations/is-empty";
import * as Print from "expo-print";
import Header from "../header";
import ESIDeliveryRating from "./delireviewrating";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { Formik } from 'formik';
import * as Yup from 'yup';
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";


export default function RetailOrderDetails(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const { orderId, masterId, addressId } = props?.route?.params || "";
    const windowHeight = Dimensions.get('window').height;
    const [data, setData] = React.useState();
    const EmailID = useSelector(state => state.auth.user?.Email_Id)
    const [invoiceDetails, setDetails] = useState();
    const [orderInfo, setOrderInfo] = useState({});
    const toast = useToast();

    const handleClick = async (data) => {
        navigation.navigate('ProductDetails', { Product_Id: data?.Product_Id })
    };

    const [visible, setVisible] = React.useState(false);
    const hideDialog = () => {
        setVisible(false);
    }
    const hideDialogR = () => {
        setVisibleOne(false);
    }
    const [visibleone, setVisibleOne] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [issubmitted, setSubmit] = React.useState(false);
    const [oLoading, setOLoading] = React.useState(false);
    const [orderDetailsFilter, setOrderDetailsFilter] = React.useState("");
    useEffect(() => {
        if (isEmpty(orderId)) {
            navigation.navigate('MyOrders')
        }
        else {
            if (EmailID) {
                let formData1 = {
                    Order_Id: orderId,
                    Item_Master_Id: masterId,
                    User_Email_Id: EmailID
                }
                let formData2 = {
                    Records_Filter: "FILTER",
                    User_Address_Id: addressId
                }
                dispatch(getOrderdetailAction(formData2));
                let formDataInvoice = {
                    "Item_Master_Id": masterId,
                    "Order_Id": orderId,
                    "Order_Type": "RETAIL"

                }
                dispatch(OrderInvoiceAction(formDataInvoice, updateStatesInvoice));
                if (!oLoading && orderDetailsFilter != JSON.stringify(formData1)) {
                    setOrderInfo({});
                    setOLoading(true);
                    setOrderDetailsFilter(JSON.stringify(formData1));
                    dispatch(getSingleOrdersAction(formData1, orderDetailsResponse));
                }
            }
        }
    }, [orderId, masterId, addressId, EmailID]);

    const orderDetailsResponse = (data) => {
        setOrderInfo(data);
        setOLoading(false);
    };

    const updateStatesInvoice = (status, data) => {
        if (status) {
            setDetails(data);

        }
    };

    const statusChangeResponse = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setSubmit(false);
        if (status) {
            let formData1 = {
                Order_Id: orderId,
                Item_Master_Id: masterId,
                User_Email_Id: EmailID,
            }
            let formData2 = {
                Records_Filter: "FILTER",
                User_Address_Id: addressId
            }
            dispatch(getOrderdetailAction(formData2));
            let formDataInvoice = {
                "Item_Master_Id": masterId,
                "Order_Id": orderId,
                "Order_Type": "RETAIL"
            }
            dispatch(OrderInvoiceAction(formDataInvoice, updateStatesInvoice));
            if (!oLoading) {
                setOrderInfo({});
                setOLoading(true);
                setOrderDetailsFilter(JSON.stringify(formData1));
                dispatch(getSingleOrdersAction(formData1, orderDetailsResponse));
            }
            dispatch(getAllOrdersAction({
                Order_Id: "",
                Order_Status: "",
                Records_Filter: "FILTER",
                User_Email_Id: EmailID,
            }));
        }
    }


    const cancelhandleClick = (data) => {
        let updateData = {
            Item_Master_Id: data?.ProductDetails?.Item_Master_Id,
            Order_Id: data?.OrderDetails?.Order_Id,
            Modified_By: EmailID,
            Status: "CANCELLED",
        }
        dispatch(OrderStatusUpdateAction(updateData, data, navigation, statusChangeResponse))

    };

    const returnhandleClicks = (data) => {
        let updateData = {
            Item_Master_Id: data?.ProductDetails?.Item_Master_Id,
            Order_Id: data?.OrderDetails?.Order_Id,
            Modified_By: EmailID,
            Status: "RETURN",
        }
        dispatch(OrderStatusUpdateAction(updateData, data, navigation, statusChangeResponse))
        setVisibleOne(!visibleone)
    };


    const getCurrentDeliveryStatus = (deliveryType, ItemMasterStatus) => {
        let orderlabels = [];
        if (deliveryType == "LIVE" && ["NEW", "ACCEPTED", "PACKED", "IN-TRANSIT", "DELIVERED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Packed", "In Transit", "Delivered"];
        } else if (["REJECTED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Rejected", "Settled"];
        } else if (["CANCELLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Cancelled", "Settled"];
        }
        else if (["RETURNED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Delivered", "Returned", "Settled"];
        }
        else if (["SETTLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Settled"];
        } else {
            orderlabels = ["New", "Shipped", "In Transit", "Out For Delivery", "Delivered"];
        }
        return orderlabels
    }

    const getCurrentStatusPoission = (data, ItemMasterStatus, deliveryType) => {
        let poission = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].toUpperCase() == ItemMasterStatus) {
                poission = i;
                break;
            } else if (data[i] == "Out For Delivery" && ItemMasterStatus == "OUT-FOR-DELIVERY") {
                poission = i;
                break;
            }
            else if (data[i] == "In Transit" && ItemMasterStatus == "IN-TRANSIT") {
                poission = i;
                break;
            }
        }
        if (poission < 1) {
            if (deliveryType == "LIVE" && ["NEW", "ACCEPTED", "PACKED", "IN-TRANSIT", "DELIVERED"].includes(ItemMasterStatus)) {
                if (["ACCEPTED", "PACKED"].includes(ItemMasterStatus)) {
                    poission = 2;
                }
            } else if (["REJECTED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "REJECTED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 2;
                }
            } else if (["CANCELLED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "CANCELLED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 2;
                }
            }
            else if (["RETURNED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "DELIVERED", "CANCELLED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 3;
                }
            }
            else if (["SETTLED"].includes(ItemMasterStatus)) {
                poission = 2;
            }
            if (["SETTLED", "DELIVERED"].includes(ItemMasterStatus)) {
                poission += 1;
            }
        }
        return poission;
    }

    const isEligibileReturn = (orderDeliveryDate, returnAvailabileDays) => {
        let isReturn = false;
        if (orderDeliveryDate && returnAvailabileDays) {
            let deliveryDate = new Date(orderDeliveryDate);
            let dd1 = String(deliveryDate.getDate()).padStart(2, '0');
            let mm1 = String(deliveryDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy1 = deliveryDate.getFullYear();
            deliveryDate = yyyy1 + "-" + mm1 + "-" + dd1;

            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            today = yyyy + "-" + mm + "-" + dd;
            if ((new Date(today) - new Date(deliveryDate)) / (1000 * 60 * 60 * 24) <= Number(returnAvailabileDays)) {
                isReturn = true;
            }
        }
        return isReturn
    }


    const customStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: 'green',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'green',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'green',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'green',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'green'
    }
    const cancelStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: '#fe7013',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'red',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'red',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'red',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'red'
    }

    const getInvoice = () => {
        try {
            return `

                <html>
                <head>
            <style>
            .grid-container {
            display: grid;
            grid-template-columns: auto auto;
            }.hr.solid {
            border-top: 5px solid #27b6cc;
            }
            table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            }
            td{
            border: 1px solid #27b6cc;
            text-align: right;
            padding: 8px;
            }
            th {
            border: 1px solid #27b6cc;
            text-align: right;
            background-color: #27b6cc;
            padding: 8px;
            }
            </style>
            
                    <meta charset="utf-8">
                    <title>Invoice</title>
                    <script src="script.js"></script>
                </head>
                <body onclick="window.print()" >
                    <header>
                <center>
                        <h1 style="color:#27b6cc;">Invoice</h1>
                </center>
                        <address contenteditable>
                <div class="grid-container">
                <img src="`+ require('../../assets/images/img/logo/logo.png') + `" width="100" height="80" style="background-color: rgb(39, 182, 204);">
                <span style="text-align:right">
                <p contenteditable="false">Order Id: `+ invoiceDetails.OrderId + `</p>
                <p contenteditable="false">Order Date: `+ invoiceDetails.OrderDate + `</p>
                <p contenteditable="false">Invoice No: `+ invoiceDetails.InvoiceNo + `</p>
                <p contenteditable="false">Invoice Date:`+ invoiceDetails.InvoiceDate + `</p>
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4 contenteditable="false">Billing Address:</h4>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.Name + `</p>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.AddressDrToSt + `</p>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.City + `,` + invoiceDetails.BillingAddress.State + `,` + invoiceDetails.BillingAddress.Country + `</p>
                <p contenteditable="false">${invoiceDetails.BillingAddress.PhoneNumber}</p>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.email + `</p>
                </span>
                <span style="text-align:right">
                <h4 contenteditable="false">Shipping Address:</h4>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.Name + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.AddressDrToSt + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.City + `,` + invoiceDetails.ShippingAddress.State + `,` + invoiceDetails.ShippingAddress.Country + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.PhoneNumber + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.email + `</p>
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4 contenteditable="false">Sold By:</h4>
                <p contenteditable="false">`+ invoiceDetails.Partner.Name + `</p>
                <p contenteditable="false">`+ invoiceDetails.Partner.AddressDrToSt + `</p>
                <p contenteditable="false">`+ invoiceDetails.Partner.City + `,` + invoiceDetails.Partner.State + `,` + invoiceDetails.Partner.Country + `</p>
                <p contenteditable="false">`+ invoiceDetails.Partner.Name + `</p>
                </span>
                <span style="text-align:right">
                <p contenteditable="false">PAN NO: `+ invoiceDetails.Partner.Pan + `</p>
                <p contenteditable="false">GST: `+ invoiceDetails.Partner.GST + `</p>
                </span>
                </div>
                <hr class="solid">
                <table>
            <tr>
                <th contenteditable="false" style="text-align: center;" >SI No</th>
                <th contenteditable="false" style="width:50%;text-align: center;">Description</th>
                <th contenteditable="false" style="text-align: center;" >Rate</th>
                `+ (invoiceDetails.product.Discount ? `<th style="text-align: center;" >Discount</th>` : ``) + `
                <th contenteditable="false" style="text-align: center;" >Amount</th> 
                <th contenteditable="false" style="text-align: center;" >QTY</th>
                <th contenteditable="false" style="width:10%;text-align: center;" >Total</th>
            </tr>
            <tr contenteditable="false"> 
            <td style="text-align: center;" >1</td>
            <td contenteditable="false" style="width:50%;text-align: center;" >`+ invoiceDetails.product.Name + `</td>
            <td contenteditable="false" style="text-align: center;" >`+ invoiceDetails.product.Product_Price + `</td>
            `+ (invoiceDetails.product.Discount ? `<td style="text-align: center;" >` + invoiceDetails.product.Discount + `</td>` : ``) + `
            <td contenteditable="false" style="text-align: center;" >`+ invoiceDetails.product?.Product_Selling_Price + `</td>
            <td contenteditable="false" style="text-align: center;" >`+ invoiceDetails.product.Qty + `</td>
            <td contenteditable="false" style="text-align: center;" >`+ invoiceDetails.product.GrandTotal + `</td>
        </tr>
        ` + (invoiceDetails.product.ShippingCharges ? `
        <tr contenteditable="false">
        `+ (invoiceDetails.product.Discount ? `<td></td>` : ``) + `
        <td></td>
        <td></td>
        <td></td>
        <td contenteditable="false" style="text-align: center;" colspan="2" > Shipping Charges </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.ShippingCharges + ` </td>
        </tr>`: ``) + (invoiceDetails.product.IGST ? `
        <tr contenteditable="false">
        `+ (invoiceDetails.product.Discount ? `<td></td>` : ``) + `
        <td></td>
        <td></td>
        <td></td>
        <td contenteditable="false" style="text-align: center;"> IGST  </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.IGSTPercentage + `% </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.IGST + ` </td>
        </tr>`: ``)
                + (invoiceDetails.product.SGST ? `
        <tr contenteditable="false">
        `+ (invoiceDetails.product.Discount ? `<td></td>` : ``) + `
        <td></td>
        <td></td>
        <td></td>
        <td contenteditable="false" style="text-align: center;"> SGST  </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.SGSTPercentage + `% </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.SGST + ` </td>
        </tr>`: ``) +
                (invoiceDetails.product.CGST ? `
        <tr contenteditable="false">
        `+ (invoiceDetails.product.Discount ? `<td></td>` : ``) + `
        <td></td>
        <td></td>
        <td></td>
        <td contenteditable="false" style="text-align: center;"> CGST  </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.CGSTPercentage + `% </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.CGST + ` </td>
        </tr>`: ``) +
                (invoiceDetails.product.VAT ? `
        <tr contenteditable="false">
        `+ (invoiceDetails.product.Discount ? `<td></td>` : ``) + `
        <td></td>
        <td></td>
        <td></td>
        <td contenteditable="false" style="text-align: center;"> VAT  </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.VATPercentage + `% </td>
        <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.product.VAT + ` </td>
        </tr>`: ``) +
                `
    <tr contenteditable="false">
    `+ (invoiceDetails.product.Discount ? `<td></td>` : ``) + `
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>

    <tr>
        <td></td>
        <td>  </td>
        `+ (invoiceDetails.product.Discount ? `<td></td>` : ``) + `
        <td style="text-align: center; font-weight: bold;" colspan="3">
        Final Amount
        </td>
        <td contenteditable="false" style="text-align: center; font-weight: bold;"> `+ invoiceDetails.product.Final_Amount + ` </td>
    
    </tr>

    <tr>
        <td contenteditable="false" style="text-align: left; font-weight: bold;" colspan="`+ (invoiceDetails.product.Discount ? 7 : 6) + `">
        Amount in words:${invoiceDetails.product.AmountInWords}
        </td>
    </tr>
            
        </table>
                        </address>
                        </header>
                    <aside>
                        <div contenteditable>
                            <p contenteditable="false">Note: This is computer generated Invoice and signature is not required.</p>
                        </div>
                    </aside>
                </body>
            </html>
`;
        } catch (error) {
        }

    }

    const printToFile = async () => {
        const html = getInvoice();
        if (Platform.OS == 'web') {
            const pW = window.open('', '', 'height=500, width=500')
            pW.document.write(html);
            pW.document.close();
        }
        else {
            try {
                const { uri } = await Print.printToFileAsync({ html });
                await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
            } catch (error) {
            }
        }
    }
    const [expanded, setExpanded] = React.useState(true);
    const handlePress = () => setExpanded(!expanded);
    return (

        <Surface style={{ backgroundColor: esiColor.BackgroundColor, flex: 1 }}>
            <Header {...props} />
            {oLoading ? <ActivityIndicator color='#27b6cc' /> :
                <ScrollView>

                    <IconButton icon="keyboard-backspace" color={"#27b6cc"} onPress={() => navigation.navigate('MyOrders')}>
                    </IconButton>

                    <Portal>
                        <Dialog visible={visible} onDismiss={hideDialog} style={{
                            maxWidth: 500, minWidth: 300, height: 350, borderRadius: 10,
                            marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor,
                            shadowColor: esiColor.brandShadowColor, borderWidth: 0.5
                        }}>

                            <Dialog.ScrollArea>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 1, alignItems: "flex-end" }}>
                                        <IconButton style={{ marginTop: 1, marginRight: -10 }}
                                            icon="close"
                                            size={20}
                                            color={esiColor.itemColor}
                                            onPress={() => setVisible(!visible)}
                                        />
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 1 }} >
                                        <Text style={{ color: "#27b6cc", fontSize: 20, textAlign: "center", marginTop: -20, marginBottom: 20 }}>Order Cancellation Form</Text>
                                    </View>
                                </View>
                                <ScrollView >
                                    <Dialog.Content>
                                        {(() => {

                                            let reasonTypes = [
                                                { label: "Select Reason Type", value: "" },
                                                { label: "By Mistakenly Places Order", value: "By Mistakenly Places Order" },
                                                { label: "Bought from other Partner", value: "Bought from other Partner" },
                                                { label: "Delivery Time is too long", value: "Delivery Time is too long" },
                                                { label: "Other Reason", value: "Other Reason" }];

                                            return (
                                                <Formik
                                                    initialValues={
                                                        {
                                                            Cancel_Return_User_Reason_Type: '',
                                                            Cancel_Return_User_Comment: ''
                                                        }
                                                    }
                                                    validationSchema={Yup.object().shape({
                                                        Cancel_Return_User_Reason_Type: Yup.string()
                                                            .required('Reason is required.')
                                                    })}
                                                    onSubmit={values => {
                                                        let updateData = {
                                                            Item_Master_Id: data?.ProductDetails?.Item_Master_Id,
                                                            Order_Id: data?.OrderDetails?.Order_Id,
                                                            Modified_By: EmailID,
                                                            Status: "CANCELLED",
                                                            Cancel_Return_User_Reason_Type: values.Cancel_Return_User_Reason_Type,
                                                            Cancel_Return_User_Comment: values.Cancel_Return_User_Comment
                                                        }
                                                        dispatch(OrderStatusUpdateAction(updateData, data, navigation, statusChangeResponse))
                                                        setSubmit(!issubmitted);
                                                        setVisible(!visible);
                                                    }}
                                                >
                                                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                                                        <View style={{
                                                            flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <View style={{ margin: "auto", width: "100%" }}>
                                                                <EsiSearchDropdown
                                                                    style={[{
                                                                        maxHeight: 70,
                                                                        minHeight: 58,
                                                                        paddingHorizontal: 8,
                                                                        width: "100%",
                                                                        borderColor: esiColor.SBorderColor,
                                                                        borderWidth: 1,
                                                                        borderRadius: 5,
                                                                        paddingHorizontal: 8,
                                                                        activeColor: esiColor.itemColor,
                                                                        iconColor: esiColor.itemColor,
                                                                        backgroundColor: esiColor.BackgroundColor,
                                                                    }, { borderColor: Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) ? '#b00020' : esiColor.SBorderColor }]}
                                                                    data={reasonTypes}
                                                                    placeholderStyle={{
                                                                        fontSize: 16, color: esiColor.BackgroundColor
                                                                    }}
                                                                    selectedTextStyle={{
                                                                        fontSize: 16,
                                                                        // maxHeight: 70,
                                                                        // minHeight: 40,
                                                                        width: "100%",
                                                                        color: esiColor.SIconColor

                                                                    }}
                                                                    inputSearchStyle={{
                                                                        height: 40,
                                                                        fontSize: 16
                                                                    }}
                                                                    iconStyle={{
                                                                        width: 20,
                                                                        height: 20
                                                                    }}
                                                                    labelField="label"
                                                                    valueField="value"
                                                                    maxHeight={180}

                                                                    search
                                                                    placeholder='Select Reason'
                                                                    defaultValue={""}
                                                                    className="address"
                                                                    onUpdateValue={(item: any) => {
                                                                        setFieldValue('Cancel_Return_User_Reason_Type', item.value);
                                                                    }}
                                                                    value={values.Cancel_Return_User_Reason_Type}
                                                                />
                                                                <HelperText style={{}} type="error" visible={Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type)}>
                                                                    {touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type}
                                                                </HelperText>
                                                            </View>
                                                            <View style={{ margin: "auto", width: "100%" }}>

                                                                <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                                    label='Comment'
                                                                    // mode='outlined'
                                                                    onChangeText={handleChange('Cancel_Return_User_Comment')}
                                                                    onBlur={handleBlur('Cancel_Return_User_Comment')}
                                                                    value={values.Cancel_Return_User_Comment}
                                                                    multiline={true}
                                                                    numberOfLines={4}
                                                                    // style={{ backgroundColor: esiColor.BackgroundColor, }}
                                                                    theme={{ colors: { text: esiColor.Text, placeholder: esiColor.SPHTextColor } }}
                                                                    underlineColor={esiColor.itemColor}
                                                                    mode='outlined'
                                                                    outlineColor={esiColor.itemColor}
                                                                    activeOutlineColor={esiColor.itemColor}
                                                                    style={{ maxHeight: 100, minWidth: "100%", marginHorizontal: "auto", backgroundColor: esiColor.BackgroundColor, }}
                                                                />
                                                                <HelperText style={{}} type="error" visible={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}>
                                                                    {touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment}
                                                                </HelperText>
                                                            </View>
                                                            <View style={{ margin: "auto" }}>
                                                                <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                                                </Button>
                                                            </View>
                                                        </View>
                                                    )}
                                                </Formik>
                                            )
                                        })()}

                                    </Dialog.Content>
                                </ScrollView>
                            </Dialog.ScrollArea>
                        </Dialog>
                        <Dialog visible={visibleone} onDismiss={hideDialogR} style={{
                            maxWidth: 500, minWidth: 300, height: 350, borderRadius: 10,
                            marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor,
                            borderColor: esiColor.SBorderColor, borderWidth: 0.5
                        }}>
                            <Dialog.ScrollArea>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 1, alignItems: "flex-end" }}>
                                        <IconButton style={{ marginTop: 1, marginRight: -10 }}
                                            icon="close"
                                            size={20}
                                            color={esiColor.itemColor}
                                            onPress={() => setVisibleOne(!visibleone)}
                                        />
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 1 }} >
                                        <Text style={{ color: "#27b6cc", fontSize: 20, textAlign: "center", marginTop: -20, marginBottom: 20 }}>Order Return Form</Text>
                                    </View>
                                </View>
                                <ScrollView >
                                    <Dialog.Content>
                                        {(() => {

                                            let reasonTypes = [
                                                { label: "Select Reason Type", value: "" },
                                                { label: "Product Damaged", value: "Product Damaged" },
                                                { label: "Product Quality", value: "Product Quality" },
                                                { label: "Product Size", value: "Product Size" },
                                                { label: "Product Fitting is not good", value: "Product Fitting is not good" },
                                                { label: "Other Reason", value: "Other Reason" }
                                            ];

                                            return (
                                                <Formik
                                                    initialValues={
                                                        {
                                                            Cancel_Return_User_Reason_Type: '',
                                                            Cancel_Return_User_Comment: ''
                                                        }
                                                    }
                                                    validationSchema={Yup.object().shape({
                                                        Cancel_Return_User_Reason_Type: Yup.string()
                                                            .required('Reason is required.'),
                                                        Cancel_Return_User_Comment: Yup.string().min(20, "Minimum 20 characters are required.")
                                                            .required('Comment is required.'),
                                                    })}
                                                    onSubmit={values => {
                                                        let updateData = {
                                                            Item_Master_Id: data?.ProductDetails?.Item_Master_Id,
                                                            Order_Id: data?.OrderDetails?.Order_Id,
                                                            Modified_By: EmailID,
                                                            Status: "RETURN",
                                                            Cancel_Return_User_Reason_Type: values.Cancel_Return_User_Reason_Type,
                                                            Cancel_Return_User_Comment: values.Cancel_Return_User_Comment
                                                        }
                                                        dispatch(OrderStatusUpdateAction(updateData, data, navigation, statusChangeResponse))
                                                        setVisibleOne(!visibleone);
                                                        setSubmit(!issubmitted);
                                                    }}
                                                >
                                                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                                                        <View style={{
                                                            flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <View style={{ margin: "auto", width: "100%" }}>
                                                                <EsiSearchDropdown
                                                                    style={[{
                                                                        maxHeight: 70,
                                                                        minHeight: 58,
                                                                        paddingHorizontal: 8,
                                                                        width: "100%",
                                                                        borderColor: esiColor.SBorderColor,
                                                                        borderWidth: 1,
                                                                        borderRadius: 5,
                                                                        paddingHorizontal: 8,
                                                                        activeColor: esiColor.itemColor,
                                                                        iconColor: esiColor.itemColor,
                                                                        backgroundColor: esiColor.BackgroundColor,
                                                                    }, { borderColor: Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type) ? '#b00020' : '#27b6cc' }]}
                                                                    data={reasonTypes}
                                                                    placeholderStyle={{
                                                                        fontSize: 16, color: esiColor.BackgroundColor
                                                                    }}
                                                                    selectedTextStyle={{
                                                                        fontSize: 16,
                                                                        // maxHeight: 70,
                                                                        // minHeight: 40,
                                                                        width: "100%",
                                                                        color: esiColor.SIconColor

                                                                    }}
                                                                    inputSearchStyle={{
                                                                        height: 40,
                                                                        fontSize: 16
                                                                    }}
                                                                    iconStyle={{
                                                                        width: 20,
                                                                        height: 20
                                                                    }}
                                                                    labelField="label"
                                                                    valueField="value"
                                                                    maxHeight={180}

                                                                    search
                                                                    placeholder='Select Reason'
                                                                    defaultValue={""}
                                                                    className="address"
                                                                    onUpdateValue={(item: any) => {
                                                                        setFieldValue('Cancel_Return_User_Reason_Type', item.value);
                                                                    }}
                                                                    value={values.Cancel_Return_User_Reason_Type}
                                                                />
                                                                <HelperText style={{}} type="error" visible={Boolean(touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type)}>
                                                                    {touched.Cancel_Return_User_Reason_Type && errors.Cancel_Return_User_Reason_Type}
                                                                </HelperText>
                                                            </View>
                                                            <View style={{ margin: "auto", width: "100%" }}>

                                                                <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                                    label='Comment'
                                                                    // mode='outlined'
                                                                    onChangeText={handleChange('Cancel_Return_User_Comment')}
                                                                    onBlur={handleBlur('Cancel_Return_User_Comment')}
                                                                    value={values.Cancel_Return_User_Comment}
                                                                    multiline={true}
                                                                    numberOfLines={4}
                                                                    theme={{ colors: { text: esiColor.Text, placeholder: esiColor.SPHTextColor } }}
                                                                    underlineColor={esiColor.itemColor}
                                                                    mode='outlined'
                                                                    outlineColor={esiColor.itemColor}
                                                                    activeOutlineColor={esiColor.itemColor}
                                                                    style={{ maxHeight: 100, minWidth: "100%", marginHorizontal: "auto", backgroundColor: esiColor.BackgroundColor, }}
                                                                    error={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}
                                                                />
                                                                <HelperText style={{}} type="error" visible={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}>
                                                                    {touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment}
                                                                </HelperText>
                                                            </View>
                                                            <View style={{ margin: "auto" }}>
                                                                <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                                                </Button>
                                                            </View>
                                                        </View>
                                                    )}
                                                </Formik>
                                            )
                                        })()}

                                    </Dialog.Content>
                                </ScrollView>
                            </Dialog.ScrollArea>
                        </Dialog>
                    </Portal>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <View style={{ flex: 3, flexDirection: 'column', alignContent: 'center', marginLeft: 50, }}>
                            <View style={{
                                marginHorizontal: 1, padding: 1,
                                backgroundColor: esiColor.BackgroundColor, marginTop: 15,
                                flexDirection: 'column',
                                alignItems: 'center',
                                maxWidth: 300,
                                maxHeight: 350,
                                minHeight: 300,
                                borderRadius: 10,
                                shadowColor: esiColor.brandShadowColor,
                                shadowOpacity: 7, shadowRadius: 7
                            }}
                            >
                                <View style={{ flex: 1, alignSelf: 'center' }} >
                                    <TouchableOpacity onPress={() => handleClick(orderInfo?.ProductDetails)}>
                                        <Image source={{ uri: orderInfo?.ProductDetails?.Product_Image + `?w=80&h=100` }} style={{ height: 100, marginTop: 15, width: 80, resizeMode: 'contain' }}></Image>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flex: 2, flexDirection: 'column', marginTop: 25, alignSelf: 'center' }}  >
                                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.brandFontColor }}>{orderInfo?.ProductDetails?.Product_Name}</Text>
                                    <Text style={{ color: esiColor.DescColor }}>Order ID : {orderInfo?.OrderDetails?.Order_Id}</Text>
                                    <Text style={{ color: esiColor.DescColor }}>Order Product ID : {orderInfo?.ProductDetails?.Item_Master_Id}</Text>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ color: esiColor.DescColor }}>Quantity : {orderInfo?.ProductDetails?.Quantity}</Text>
                                    </View>
                                    <Text style={{ color: esiColor.DescColor }}>Order Date : {orderInfo?.ProductDetails?.Order_Date}</Text>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ color: esiColor.DescColor }}>Partner Name :</Text><Text style={{ color: esiColor.SIconColor, fontWeight: 'bold' }}>{orderInfo?.ProductDetails?.Partner_Name}</Text>
                                    </View>
                                    {((["DELIVERED"].includes(orderInfo?.ProductDetails?.Line_Item_Status)) && orderInfo?.ProductDetails?.Is_Item_Retutn_Flag != "0" && isEligibileReturn(orderInfo?.ProductDetails?.Order_Item_Delivered_Date, orderInfo?.ProductDetails?.Max_Days_To_Return)) &&
                                        <View style={{ flexDirection: 'row', margin: 5, }}>
                                            <TouchableOpacity
                                                style={{
                                                    backgroundColor: esiColor.globalButtonColor, width: 80, borderRadius: 5, alignItems: "center", padding: 5, opacity: issubmitted ? 0.2 : "",
                                                }}
                                                onPress={() => { setData(orderInfo); setVisibleOne(!visibleone) }}
                                            >
                                                <Text style={{ color: esiColor.itemButtenColor }}> RETURN </Text>
                                            </TouchableOpacity>

                                        </View>
                                    }
                                    {(!(["CANCELLED", "DELIVERED", "RETURN", "SETTLED"].includes(orderInfo?.ProductDetails?.Line_Item_Status)) && orderInfo?.ProductDetails?.Is_Item_Cancel_Flag != "0") &&
                                        <View style={{ flexDirection: 'row', margin: 5, }}>
                                            <TouchableOpacity
                                                style={{
                                                    backgroundColor: esiColor.globalButtonColor, width: 80, borderRadius: 5, alignItems: "center", padding: 5, opacity: issubmitted ? 0.2 : "",

                                                }}
                                                onPress={() => { setData(orderInfo); setVisible(!visible) }}
                                            >
                                                <Text style={{ color: esiColor.itemButtenColor }}> CANCEL </Text>
                                            </TouchableOpacity>
                                        </View>}
                                </View>
                            </View>
                        </View>
                        <View style={{ flex: 5, flexDirection: 'column' }}>

                            {!(["DELIVERED", "SETTLED"].includes(orderInfo?.ProductDetails?.Line_Item_Status)) &&
                                <View style={{ marginVertical: 5, flexDirection: "column" }} >
                                    <Text style={{ textAlign: "center" }} >
                                        {orderInfo?.ProductDetails?.Expected_Delivery_Date_Time}
                                    </Text>
                                    <Text style={{ textAlign: "center" }} >
                                        {orderInfo?.ProductDetails?.Delivery_Message}
                                    </Text>
                                </View>
                            }
                            {(orderInfo?.ProductDetails?.Delivery_Person_PhoneNumber && !(["RETURN", "DELIVERED", "CANCELLED", "SETTLED"].includes(orderInfo?.ProductDetails?.Line_Item_Status))) &&
                                <View
                                    style={{
                                        marginHorizontal: 1, padding: 1, margin: 'auto',
                                        backgroundColor: esiColor.brandCardBackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7,
                                        marginTop: 10, flexDirection: 'column',
                                        // elevation: 10,
                                        // borderColor: 'grey',
                                        width: 350,
                                        height: 100,
                                        // borderWidth: 0.5,
                                        borderRadius: 10, marginBottom: 15,

                                    }}
                                >
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.DescColor, marginLeft: 3, marginTop: 1 }}>Delivery Person </Text>
                                    <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {
                                        if (orderInfo?.ProductDetails?.Line_Item_Status == "IN-TRANSIT" && orderInfo?.ProductDetails?.Delivery_Type == "LIVE") {
                                            navigation.navigate("OrderTracking", { ID: orderInfo?.ProductDetails?.Item_Master_Id });
                                        }
                                    }} >

                                        <View style={{ flex: 1, alignContent: 'center', alignItems: "center", marginLeft: 10 }} >
                                            <Image source={{ uri: orderInfo?.ProductDetails?.Delivery_Person_Image + `?w=75&h=75` }} style={{ height: 75, width: 75, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.ProductDetails?.Delivery_Person_Name}</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.ProductDetails?.Delivery_Person_PhoneNumber}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            }

                            {(orderInfo?.ProductDetails?.Return_Shipping_Person_Mobile && (["INITIATED"].includes(orderInfo?.ProductDetails?.Return_Order_Shipping_Status))) &&
                                <View style={{
                                    marginHorizontal: 1, padding: 1, margin: 'auto', backgroundColor: 'white',
                                    marginTop: (Platform.OS === 'web' ? 15 : 0), flexDirection: 'column',
                                    elevation: 10,
                                    borderColor: 'grey',
                                    borderWidth: 0.5,
                                    borderRadius: 10,
                                }}
                                >
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: '#27b6cc', marginLeft: 3, marginTop: 1 }}>Return Pickup Person </Text>

                                    <View style={{ flex: 1, alignContent: 'center', alignItems: "center", marginLeft: 10 }} >
                                        <Image source={{ uri: orderInfo?.ProductDetails?.Return_Shipping_Person_Image }} style={{ height: 50, width: 50, resizeMode: 'contain' }}></Image>
                                    </View>
                                    <View style={{ flex: 2, flexDirection: 'column' }}  >
                                        <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold' }}>{orderInfo?.ProductDetails?.Return_Shipping_Person_Name}</Text>
                                        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{orderInfo?.ProductDetails?.Return_Shipping_Person_Mobile}</Text>
                                    </View>
                                    {/* </TouchableOpacity> */}
                                </View>
                            }
                            {/* </View> */}
                            <View style={{ marginRight: 50, alignSelf: 'flex-end', width: '100%' }}>
                                {getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status).length > 0 &&
                                    <View style={{ marginBottom: 30 }}>
                                        <StepIndicator
                                            customStyles={orderInfo?.ProductDetails?.Line_Item_Status === "CANCELLED" ? cancelStyles : customStyles}
                                            labels={getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status)}
                                            currentPosition={getCurrentStatusPoission(getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status),
                                                orderInfo?.ProductDetails?.Line_Item_Status, orderInfo?.ProductDetails?.Delivery_Type)}
                                            stepCount={getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.ProductDetails?.Line_Item_Status).length}
                                        />
                                    </View>
                                }
                                <View style={{ flexDirection: 'row' }}>

                                    {orderInfo &&
                                        <View style={{ flex: 4, flexDirection: 'column', marginLeft: 10 }}  >
                                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Delivery Address </Text>
                                            <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.Name},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>D-No : {orderInfo?.UserDeleveryAddress?.Door_No},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.Street}, {orderInfo?.UserDeleveryAddress?.Landmark},</Text>

                                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.City},{orderInfo?.UserDeleveryAddress?.State},{orderInfo?.UserDeleveryAddress?.Country},{orderInfo?.UserDeleveryAddress?.City}-{orderInfo?.UserDeleveryAddress?.Pin},</Text>
                                            {/* <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.City}-{orderInfo?.UserDeleveryAddress?.Pin},</Text> */}
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor, marginBottom: 10 }}>Phone number : {orderInfo?.UserDeleveryAddress?.PhoneNumber}.  </Text>
                                        </View>
                                    }

                                    <View style={{ flex: 4, flexDirection: 'column', marginLeft: 10, marginBottom: 20, }}  >
                                        <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Billing Address </Text>

                                        <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: esiColor.DescColor }}>{orderInfo?.UserBillingAddressDetails?.Name},</Text>
                                        <Text style={{ fontSize: 13, color: esiColor.DescColor }}>D-No : {orderInfo?.UserBillingAddressDetails?.Door_No},{orderInfo?.UserBillingAddressDetails?.Street}, {orderInfo?.UserBillingAddressDetails?.Landmark},</Text>
                                        <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserBillingAddressDetails?.City},{orderInfo?.UserBillingAddressDetails?.State},{orderInfo?.UserBillingAddressDetails?.Country},{orderInfo?.UserBillingAddressDetails?.City}-{orderInfo?.UserBillingAddressDetails?.Pin},</Text>
                                        {/* <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserBillingAddressDetails?.City}-{orderInfo?.UserBillingAddressDetails?.Pin},</Text> */}
                                        <Text style={{ fontSize: 13, color: esiColor.DescColor, marginBottom: 10 }}>Phone number : {orderInfo?.UserBillingAddressDetails?.PhoneNumber}.  </Text>
                                    </View>
                                </View>
                                {(masterId && orderId && (orderInfo?.ProductDetails?.Line_Item_Status == "DELIVERED" || orderInfo?.ProductDetails?.Line_Item_Status == "RETURNED")) &&
                                    <View style={{ marginVertical: 5 }} >
                                        <ESIDeliveryRating
                                            orderid={orderId}
                                            lineitemid={masterId}
                                            ordStatus={orderInfo?.ProductDetails?.Line_Item_Status}
                                        />
                                    </View>
                                }
                                <View style={{
                                    marginVertical: 5, shadowColor: esiColor.brandShadowColor,

                                }} >
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 15, marginLeft: 15, marginTop: 15 }}>Payment Summary</Text>

                                    <View style={{ flex: 5, flexDirection: 'column', marginLeft: 10, marginBottom: 20, shadowColor: "#fff", shadowOpacity: 7, shadowRadius: 7, justifyContent: 'space-evenly' }}  >
                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 2, marginLeft: 15 }} >
                                                <Text style={{ fontSize: 14, alignSelf: "flex-start", color: esiColor.DescColor, fontWeight: 'bold' }}>Price</Text>
                                            </View>
                                            <View style={{ flex: 2 }} >
                                                <Text style={{ fontSize: 14, alignSelf: "center", color: esiColor.DescColor, fontWeight: 'bold' }}>Quantity</Text>
                                            </View>
                                            <View style={{ flex: 2 }} >
                                                <Text style={{ fontSize: 14, alignSelf: "center", color: esiColor.DescColor, fontWeight: 'bold' }}> Total</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 2, marginLeft: 15 }} >
                                                <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>{orderInfo?.ItemPaymentSummary?.Product_Price}</Text>
                                            </View>
                                            <View style={{ flex: 2 }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.DescColor }}>{orderInfo?.ItemPaymentSummary?.Quantity}</Text>
                                            </View>
                                            <View style={{ flex: 2 }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.DescColor }}>{(Number(orderInfo?.ItemPaymentSummary?.Product_Price) * Number(orderInfo?.ItemPaymentSummary?.Quantity)) ? Number(orderInfo?.ItemPaymentSummary?.Product_Price) * Number(orderInfo?.ItemPaymentSummary?.Quantity) : "0.00"}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 2, marginLeft: 15 }} >
                                                <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Tax Amount</Text>
                                            </View>
                                            <View style={{ flex: 2 }}>
                                                <Text style={{ fontSize: 10, alignSelf: "center", color: esiColor.DescColor }}>IGST : {orderInfo?.ItemPaymentSummary?.IGSTPercentage}%</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.DescColor }}>{orderInfo?.ItemPaymentSummary?.Total_Tax}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4, marginLeft: 15 }} >
                                                <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Coupon Discount</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.DescColor }}>{orderInfo?.ItemPaymentSummary?.Partner_Coupon_Amount}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4, marginLeft: 15 }} >
                                                <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Gift Charges</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.DescColor }}>{orderInfo?.ItemPaymentSummary?.Gift_Charges}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4, marginLeft: 15 }} >
                                                <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.DescColor }}>Delivery Charges</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.DescColor }}>{orderInfo?.ItemPaymentSummary?.Delivery_Charges}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4, marginLeft: 15 }} >
                                                <Text style={{ fontSize: 13, alignSelf: "flex-start", fontWeight: "bold", color: esiColor.DescColor }}>Total Gross Price</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, alignSelf: "center", fontWeight: "bold", color: esiColor.DescColor }}>{orderInfo?.ItemPaymentSummary?.Total_Gross_Price}</Text>
                                            </View>
                                        </View>
                                        {!(["CANCELLED", "RETURN"].includes(orderInfo?.ProductDetails?.Line_Item_Status)) &&
                                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignSelf: "center", padding: 5, marginTop: 5, marginBottom: 15, width: 125 }} onPress={printToFile}>
                                                <View >
                                                    <Text style={{ color: esiColor.itemButtenColor }} >Download Invoice</Text>
                                                </View>
                                            </TouchableOpacity>
                                        }

                                    </View>
                                </View>

                            </View>
                        </View>
                    </View>

                </ScrollView >
            }

        </Surface >
    )
}

// const styles = StyleSheet.create({
//     separator: {
//         height: 1,
//         backgroundColor: "#CCCCCC",
//     },
//     text: {

//     },
//     button: {
//         alignItems: "center",
//         backgroundColor: "#27b6cc",
//         padding: 10
//     },
//     invoiceNoContainer: {
//         flexDirection: 'row',
//         // marginTop: 36,
//         justifyContent: 'flex-end'
//     },
//     invoiceDateContainer: {
//         flexDirection: 'row',
//         justifyContent: 'flex-end'
//     },
//     invoiceDate: {
//         fontSize: 12,
//     },
//     page: {
//         fontSize: 11,
//         paddingTop: 30,
//         paddingLeft: 60,
//         paddingRight: 60,
//         lineHeight: 1.5,
//         flexDirection: 'column',
//     },


//     tableContainer: {
//         flexDirection: 'row',
//         // flexWrap: 'wrap',
//         marginTop: 24,
//         borderWidth: 1,
//         borderColor: '#bff0fd',
//     },

//     headerContainer: {
//         marginTop: -10
//     },
//     container: {
//         borderBottomColor: '#bff0fd',
//         backgroundColor: '#bff0fd',
//         borderBottomWidth: 1,
//         // fontStyle: 'bold',
//         flexGrow: 1,

//     },
//     titleContainer: {
//         marginTop: "auto",
//         textAlign: 'center',
//         // width: "100%",
//         fontSize: 10,
//     },
// });