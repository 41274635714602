import {
  ActionType, getDataAsync,

} from '../actions/authActions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform, StyleSheet } from 'react-native';
const storeData = async (value) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem('user', jsonValue)
  } catch (e) {
    // saving error
  }
}

const initialState = {
  user: {},
  error: '',
  isAuthenticated: null,
  sessionExpiredTime: 60 * 55,
  sessionExpiredDate: new Date().setSeconds(new Date().getSeconds() + (60 * 55)),
  isLoading: true,
}
export default function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case ActionType.USER_SIGNIN:
      return { isLoading: false, user: JSON.parse(action.data), error: '', isAuthenticated: action.data ? true : false }
    case ActionType.USER_REQUEST:
      return { ...state, error: '', isLoading: true, isAuthenticated: false }
    case ActionType.USER_SUCCESS:
      if (action.login) {
        // storeData(action.data);
        return { ...state, isLoading: false, user: action.data, error: '', isAuthenticated: true }
      } else {
        if (state.isAuthenticated) {
          // storeData(action.data);
          return { ...state, isLoading: false, user: action.data, error: '', isAuthenticated: true }
        } else {
          return { ...state }
        }
      }
    case ActionType.USER_FAIL:
      if (action.login) {
        return { ...state, isLoading: false, user: {}, error: action.data, isAuthenticated: false }
      } else {
        return { ...state }
      }
    case ActionType.USER_SIGNUP_REQUEST:
      return { ...state, error: '' }
    case ActionType.USER_SIGNUP_SUCCESS:
      return { ...state, error: '' }
    case ActionType.USER_SIGNUP_FAIL:
      return { ...state, error: action.data }
    case ActionType.USER_LOGOUT:
      return { ...state, isAuthenticated: false, user: {} };
    case ActionType.USER_SESSION_REFRESH_DATE:
      return { ...state, sessionExpiredDate: action.expireDate, sessionExpiredTime: action?.expireTime || state.sessionExpiredTime };
    default:
      return state;
  }
}