import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerCart from "./PartnerCart";
import Header from "../header";
import { ActivityIndicator, Surface, Text } from 'react-native-paper';

import { Image, View, StyleSheet, ScrollView, Platform, TouchableOpacity, Dimensions, RefreshControl } from "react-native";
import { getUserSettings, getWalletByUser } from "../../state/actions/walletActions";
import { GetAllAddressAction, getAllCartAddressAction } from "../../state/actions/addressActions";
import isEmpty from "../../state/validations/is-empty";

import { getCart, productRemoveAddressError, paceOrderValidation } from "../../state/actions/cartActionsNew";


export default function Cart(props: any) {
  const dispatch = useDispatch();
  const { navigation, ...rest } = props;
  // const history = useHistory();
  const esiColor = useSelector(state => state.theme);

  let cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.auth.user);
  const [address, setAddress] = React.useState([]);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [wallet, setWallet] = React.useState(null);

  const addresslist = (address: any) => {
    let addresslist = []
    if (address) {
      if (address[0]) {
        for (let i = 0; i < address.length; i++) {
          addresslist.push({
            label: address[i].Address_Type + " " + "," + " " + address[i].Door_Number + " " + "," + " " + address[i].Landmark + " " + "," + " " + address[i].Street + " " + "," + " " + address[i].City + " " + "," + " " + address[i].State + " " + "," + " " + address[i].Zip,
            value: JSON.stringify(
              {
                "User_Address_Id": address[i].User_Address_Id,
                "Shipping_Email_Id": address[i].Shipping_Email_Id,
                "User_Email_Id": address[i].User_Email_Id,
                "Mobile": address[i].Mobile,
                "Address_Type": address[i].Address_Type,
                "Door_Number": address[i].Door_Number,
                "Street": address[i].Street,
                "City": address[i].City,
                "State": address[i].State,
                "Country": address[i].Country,
                "Zip": address[i].Zip,
                "Landmark": address[i].Landmark,
                "Name": address[i].Name,
                "Location": address[i].Location,
                "Alternate_Mobile": address[i].Alternate_Mobile
              }
            )
          })
        }
      }
    }
    addresslist.push({
      label: "Add New Address",
      value: "Add New Address"
    })
    return addresslist
  }
  const updateStates = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data ? data : []));
    setAddress(addresslist(add));
  };

  const updateStatesWallet = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data));
    setWallet(add?.Wallet[0]);
  };
  // setAddress
  const getAddress = () => {
    let data = {
      User_Email_Id: user.Email_Id,
      Records_Filter: "FILTER",
    };
    dispatch(getAllCartAddressAction(updateStates, data));
  }

  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isLoading = useSelector(state => state.auth.isLoading);

  const addresses = useSelector(state => state.address.addresses.all);

  useEffect(() => {
    updateStates(addresses, false);
  }, [addresses]);

  const [partnerDeliveryCharges, setPartnerDeliveryCharges] = React.useState(50);
  const [fgPartnerOrderRequiredAmount, setFGPartnerOrderRequiredAmount] = React.useState('[]');
  const handileNavigation = () => {
    dispatch(getUserSettings("CART_DELIVERY_CHARGE_BALANCER", setFGPartnerOrderRequiredAmount, false));
    dispatch(getUserSettings("FG_Delivery_Amount", setPartnerDeliveryCharges));
    if (isAuthenticated) {
      dispatch(getCart(
        {
          "Records_Filter": "FILTER",
          "User_Email_Id": user.Email_Id
        }
      ));
      getAddress();
      // let formData = {
      //   Records_Filter: "FILTER",
      //   User_Email_Id: user.Email_Id,
      // };
      // dispatch(GetAllAddressAction(formData))
      dispatch(productRemoveAddressError(cart));
      let data = {
        User_Id: user.Email_Id,
        Wallet_Id: "",
      };
      dispatch(getWalletByUser(updateStatesWallet, data));
    } else {
      if (!isLoading)
        navigation.navigate('Home');
    }
  };

  useEffect(() => {
    handileNavigation();
  }, [isAuthenticated, props.navigation, props.route]);

  const [orderPlaced, setOrderPlaced] = useState(false);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false);
  };

  const [callCount, setCallCount] = React.useState([]);

  const addressCheckResponse = (noError) => {

    if (noError) {
      navigation.navigate('CartSummary', { cart: 'all' });

    }
  };

  const [productError, setProductError] = useState([]);

  const handleSubmit = () => {
    // cart.map(item=>{
    dispatch(paceOrderValidation(cart, [], addressCheckResponse, productError, "all"));
    // })
  };
  const { height, width } = Dimensions.get('window');

  return (
    // <View style={{ flex: 1 }}>
    <Surface style={{ flex: 1, flexDirection: 'column', backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => {
              dispatch(getCart(
                {
                  "Records_Filter": "FILTER",
                  "User_Email_Id": user.Email_Id
                }
              ));
            }}
          />
        }
      >
        <View style={{ justifyContent: 'flex-start', marginLeft: 15, marginTop: 15, marginBottom: 15 }}>
          <Text style={{ fontWeight: 'bold', fontSize: 20, color: esiColor.brandFontColor }}>Cart</Text>
        </View>

        {orderPlaced ? <ActivityIndicator color={esiColor.SIconColor} /> :
          (!isEmpty(cart) && isAuthenticated) ? (
            <>
              <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', width: width, alignItems: 'center', justifyContent: 'space-evenly', marginLeft: 50 }} >

                {cart.map((item: any, index: any) => (
                  <PartnerCart key={item?.partnerId} pindex={index} cart={cart} item={item} address={address} setOpenAddressPopup={setOpenAddressPopup}
                    expanded={expanded}
                    handleChange={handleChange}
                    wallet={wallet}
                    navigation={navigation}
                    partnerDeliveryCharges={partnerDeliveryCharges}
                    fgPartnerOrderRequiredAmount={fgPartnerOrderRequiredAmount}
                  />
                ))}
              </View>
              <View style={{ flexDirection: 'row', width: width, alignItems: 'center', justifyContent: 'space-evenly', backgroundColor: esiColor.BackgroundColor }} >
                {(!isEmpty(cart) && cart.length > 1) &&
                  <View style={{
                    flexDirection: "row",
                    backgroundColor: esiColor.BackgroundColor,
                    paddingTop: 5, marginBottom: 15
                  }}>
                    <TouchableOpacity
                      style={{
                        // alignItems: "center",
                        backgroundColor: esiColor.globalButtonColor,
                        padding: 10,
                        margin: 'auto',
                        borderRadius: 8,
                        // flexDirection: "row", justifyContent: 'center'
                      }}
                      onPress={() => { handleSubmit(); setExpanded(!expanded); }}
                    >
                      <Text style={{ color: esiColor.itemButtenColor }} >Place All Orders</Text>
                    </TouchableOpacity>
                  </View>}
              </View>
            </>
          ) : (
            // <View >
            <View style={{ justifyContent: 'center', alignItems: 'center', }}>
              {/* <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={cartgif} /> */}
              {/* <Image style={{ width: 130, height: 130 }} source={{ uri: "https://i.imgur.com/dCdflKN.png" }} /> */}
              <Image style={{ width: 175, height: 175 }} source={require('../../assets/images/Cart_empty.png')} />

              <Text style={{ fontWeight: 'bold', fontSize: 30, margin: 5, color: esiColor.NoDataFound }}> Your Cart is Empty.</Text>
              <Text style={{ fontSize: 15, fontWeight: 'bold', margin: 5, color: esiColor.Text }}>Add products to make an order.</Text>
              <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 10, padding: 10, margin: 5 }} onPress={() => navigation.navigate('Home')}>
                <Text style={{ fontWeight: 'bold', color: esiColor.itemButtenColor }}>Shop Now</Text>
              </TouchableOpacity>
            </View>
          )
        }
      </ScrollView>
    </Surface >
  );
}
