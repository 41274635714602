import React from "react";
import { Text, Surface, Card } from 'react-native-paper';
import { View, ScrollView, Dimensions, Image, ImageBackground } from 'react-native';
import Header from "../header";
import Footer from "../footer";
const { height, width } = Dimensions.get('window');
export default function HowCashbackWorks(props) {

    const { navigation, ...rest } = props;
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    return (
        <View style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Surface style={{ flex: 1 }}>

                    <View style={{ margin: "auto", alignContent: "center", justifyContent: "center" }}>
                        <ImageBackground source={require('../../assets/images/howcashbackworks1/how_cashback_works_im1.png')} resizeMode="contain" style={{ width: 1200, height: 800 }} />

                    </View>
                    {/* <Video navigation={navigation} /> */}
                    <View style={{ margin: "auto", alignContent: "center", justifyContent: "center" }}>
                        <ImageBackground source={require('../../assets/images/howcashbackworks1/how_cashback_works_im2.png')} resizeMode="contain" style={{ width: 1200, height: 1200 }} />
                    </View>

                    <View style={{ margin: "auto", alignContent: "center", justifyContent: "center",  marginTop: -75 }}>
                        <ImageBackground source={require('../../assets/images/howcashbackworks1/how_cashback_works_im3.png')} resizeMode="contain" style={{ width: width, height: 1000, }} />
                    </View>

                </Surface>

                <View style={{marginTop : -75}}>
                    <Footer navigation={navigation} />
                </View>
            </ScrollView>


        </View>
    );
}