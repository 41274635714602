import { FontAwesome, Ionicons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, FlatList, StyleSheet, Image, Platform, TouchableOpacity, SafeAreaView, Text, View, Linking, ScrollView, RefreshControl } from "react-native";
import Modal from 'react-native-modal';
import { Avatar, Button, Surface, TouchableRipple } from "react-native-paper";
import Feather from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from "react-redux";
import { actionTypes, apiActions } from "../../state/actions";
import { getProductSizeActionWishList, getvisitedProductByIdAction } from "../../state/actions/productsActions";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import { addCart } from "../../state/actions/cartActionsNew";
import { TouchableOpacity as TouchableOpacityG } from 'react-native-gesture-handler';
import wishlistgif from "../../assets/images/GIF/wishlistgif.gif"
import { useToast } from "../../self_modules/react-native-paper-toast/src";


const WishList = (props) => {
    const { navigation } = props;
    const { height, width } = Dimensions.get('window');
    const dimensions = Dimensions.get('window');

    const EmailID = useSelector(state => state.auth.user?.Email_Id)
    const esiColor = useSelector(state => state.theme);
    const [wish_list, setWishList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sub_total, setSubTotal] = useState(0);
    const [delete_id, setDeleteId] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isEmpty(EmailID)) {
            getWishList();
        }
        else {
            setTimeout(() => {
                if (isEmpty(EmailID)) {
                    navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [props.navigation, props.route, EmailID]);

    const getWishList = async () => {
        if (EmailID) {
            setLoading(true);
            setSubTotal(0);
            setWishList([]);
            setDeleteId("");
            let filter = {
                "User_Email_Id": EmailID
            }

            apiActions.get_my_wish_list(filter)
                .then(async res => {
                    let wish_data = res.Product_Details;
                    if (!isEmpty(wish_data)) {
                        setWishList(wish_data);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }

    const removeItem = (wish_id) => {

        let data = {
            Wish_Id: wish_id
        }
        setLoading(true);
        apiActions.delete_wish_list(data).then((res) => {
            if (res.Response_Status === "Success") {
                getWishList();
                dispatch(getvisitedProductByIdAction(EmailID));
            } else {
                dropDownAlertFun('info', 'Failed', 'Unable to delete, Please try again.');
            }

        }).finally(() => {
            setLoading(false);
        })

    }
    const checkOut = async () => {
        if (wish_list.length == 0) {
            // Toast.show('No items', Toast.LONG);
        } else {
            let diff = 0;
            var wishList = wish_list?.map((item) => {
                if (item?.checked == true) {
                    dispatch({ type: actionTypes.CART_ADD, product: item, Partner_Details_Id: item.Partner_Details_Id })
                    let data = {
                        Wish_Id: item.Wish_Id
                    }
                    apiActions.delete_wish_list(data)
                    apiActions.get_wish_list()
                        .then(async res => {
                        })
                        .catch(err => {
                        })
                        .finally(() => {
                            setModalVisible(false)
                        })
                    diff += parseFloat(item?.Partner_Selling_Price)
                    return null;
                }
                return item;
            })
            setSubTotal(sub_total - diff)
            wishList = wishList.filter(item => item != null);
            setWishList(wishList);
        }
    }
    const [modalVisible, setModalVisible] = useState(false);

    // Tost message code.
    const toast = useToast();

    // Tost message code.
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const [loadCartSize, setLoadCartSize] = useState(false);
    const [cartProduct, setCartProduct] = useState({});
    const [selectedCart, setSelectedCart] = useState({});
    const [cartModelOpen, setCartModelOpen] = useState(false);
    let cart = useSelector((state) => state.cart.cart);

    const apiResponse = (status, response, wishlistId, message) => {
        setLoadCartSize(false);
        if (status) {
            if (isEmpty(response)) {
                dropDownAlertFun('info', 'Failed', 'Out of stock.');
            } else {
                setCartProduct({ sizes: response, walletId: wishlistId });
                setCartModelOpen(true);
            }
        } else {

        }
    }

    const selectItem = (ischeck, wish_id) => {

        var wishList = wish_list?.map((item, index) => {
            if (item.Wish_Id == wish_id) {
                if (ischeck == true) {
                    setSubTotal(sub_total + parseFloat(item?.Partner_Selling_Price))
                } else {
                    setSubTotal(sub_total - parseFloat(item?.Partner_Selling_Price))
                }
                item.checked = ischeck;
            }
            return item;
        })
        setWishList(wishList);
    }
    const RenderItem = ({ item }) => {

        return (
            // <View>
            <View style={{
                borderRadius: 8, padding: 2, backgroundColor: esiColor.CBColor,
                shadowColor: esiColor.brandShadowColor,
                // shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 7,
                shadowRadius: 7,
                margin: 10,
                // elevation: 10,
                height: 150, width: 490,
                maxWidth: 500, alignSelf: 'flex-start'

            }}>
                <View style={{ flexDirection: 'row', paddingVertical: 5 }}>
                    {/* <View style={{ flex: 1, justifyContent: 'flex-start', alignSelf: 'center', marginLeft: 10 }}>

                    </View> */}
                    <View style={{ flex: 3, flexDirection: 'column', alignItems: 'center', marginTop: 25, marginBottom: 25 }}>

                        <View style={{ flex: 1 }}>
                            <Image source={{ uri: item?.Products.Product_Image + `?w=70&h=70` }} style={{ height: 70, width: 70 }}></Image>
                        </View>
                    </View>
                    <View style={{ flex: 5, flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Text style={{ fontSize: 15, marginVertical: 5, color: esiColor.itemColor }}>Brand : {item?.Products.Brand_Name}</Text>
                        <Text style={{ color: esiColor.brandFontColor }}>{item?.Products.Product_Name?.length > 50 ? item?.Products.Product_Name.slice(0, 50) + '...' : item?.Products.Product_Name}</Text>
                        <View style={{ flexDirection: 'row', marginVertical: 5 }}>
                            <Text style={{ color: esiColor.itemColor, textDecorationLine: 'line-through', fontSize: 12 }}> ₹{item?.Partner_Product?.Original_Price}</Text>
                            <Text style={{ color: esiColor.itemColor }}>    ₹{parseFloat(item?.Partner_Product?.Partner_Selling_Price).toFixed(2)}</Text>
                        </View>
                        <Text style={{ color: esiColor.itemColor, marginBottom: 5 }}>{item?.Products.Product_Short_Description?.length > 50 ? item?.Products.Product_Short_Description?.slice(0, 50) + '...' : item?.Products.Product_Short_Description}</Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', alignSelf: 'center', margin: 25, marginTop: 100, justifyContent: 'space-between' }}>
                        {/* <View style={{ marginVertical: 5, alignItems: 'center' }}>
                            <Image source={{ uri: item?.Partner_Product.Logo_Path }} style={{ height: 30, width: 50, borderRadius: 10 }}></Image>
                        </View> */}
                        <TouchableOpacity onPress={() => {
                            setDeleteId(item?.wishlistId)
                            setModalVisible(true)
                        }}>
                            <FontAwesome name="trash-o" size={20} color="red"></FontAwesome>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {
                            if (item?.Partner_Product?.Partner_Type === "Physical") {
                                setLoadCartSize(true);
                                dispatch(getProductSizeActionWishList(item.Products.Product_Id, item.Partner_Product.Partner_Details_Id, item.wishlistId, apiResponse));
                            } else {
                                Linking.openURL(item?.Partner_Product.Web_Redirection_Url).catch((err) =>
                                    console.error('An error occurred', err),
                                );
                            }
                        }}>
                            <FontAwesome color={"#27b6cc"} name={item?.Partner_Product?.Partner_Type === "Physical" ? "cart-plus" : "external-link"} size={20} />
                        </TouchableOpacity>
                    </View>

                </View>
                <Modal
                    onBackButtonPress={() => setModalVisible(false)}
                    isVisible={modalVisible}
                    onBackdropPress={() => setModalVisible(false)}
                >
                    <View style={{
                        flex: 1, maxWidth: 500, minWidth: 350, minHeight: 250, maxHeight: 250, borderRadius: 10,
                        backgroundColor: esiColor.BackgroundColor, alignSelf: 'center', shadowColor: esiColor.brandShadowColor,
                        shadowOpacity: 7, shadowRadius: 7
                    }}>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={{ fontSize: 18, color: esiColor.DescColor }}>Are you sure to delete this product?</Text>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginHorizontal: 20 }}>
                            <TouchableOpacity onPress={() => {
                                removeItem(delete_id);
                                setModalVisible(false);
                            }} style={{
                                backgroundColor: esiColor.globalButtonColor, flex: 1, alignItems: 'center',
                                borderBottomLeftRadius: 10, borderTopLeftRadius: 10, padding: 5, shadowColor: esiColor.brandShadowColor,
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.2,
                                shadowRadius: 10,
                                elevation: 10, marginRight: 5
                            }}>
                                <Text style={{ color: esiColor.itemButtenColor, fontSize: 16 }}>Yes</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setModalVisible(false)}
                                style={{
                                    backgroundColor: esiColor.globalButtonColor, flex: 1, alignItems: 'center',
                                    borderBottomRightRadius: 10, borderTopRightRadius: 10, padding: 5, shadowColor: esiColor.brandShadowColor,
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.2,
                                    shadowRadius: 10,
                                    elevation: 10,
                                }}>
                                <Text style={{ fontSize: 16, color: esiColor.itemButtenColor }}>Cancel</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Modal>
            </View>
            // </View>
        )
    }

    const onRefresh = () => {
        getWishList();
    };


    return (
        // <View style={{ flex: 1 }}>
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ alignItems: 'flex-start', marginBottom: 10, marginTop: 15, marginLeft: 15 }}>
                <Text style={{ fontSize: 22, color: esiColor.brandFontColor, fontWeight: 'bold' }}>Wish List</Text>
            </View>

            {(loading || loadCartSize) ? <ActivityIndicator color={esiColor.SIconColor} />
                : <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={onRefresh}
                        />
                    }
                >{
                        wish_list.length > 0 ?
                            <View style={{ flex: 1, justifyContent: 'flex-start' }}>
                                <FlatList
                                    numColumns={3}
                                    data={wish_list}
                                    renderItem={RenderItem}
                                    keyExtractor={(item, index) => index}
                                />
                            </View> :
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

                                {/* <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={wishlistgif} /> */}
                                <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={require('../../assets/images/wishlist_empty.png')} />

                                <Text style={{ fontSize: 20, marginVertical: 20, color: esiColor.NoDataFound }}> No favourites are added yet.</Text>
                                {loading ? <ActivityIndicator color={esiColor.SIconColor} size="small" />
                                    : wish_list.length > 0 ?
                                        <View style={{ flex: 1 }}>
                                            <FlatList
                                                data={wish_list}
                                                renderItem={RenderItem}
                                                keyExtractor={(item, index) => index}
                                            />
                                        </View> :
                                        <TouchableOpacity onPress={() => getWishList()} ><Text style={{ color: esiColor.NoDataFound }}>Please click here, to reload the page.</Text></TouchableOpacity>}

                            </View>}
                </ScrollView>
            }
            {/* Size Selection Modal */}
            <Modal animationIn={"slideInDown"}
                style={{ alignItems: 'center' }} isVisible={cartModelOpen}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 200, maxHeight: 250, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7 }}>

                    <View style={{
                        alignItems: 'flex-end'
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setCartModelOpen(false); }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={30} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ textAlign: "center", color: esiColor.DescColor }}>
                            Select Size
                        </Text>

                        <View style={{ textAlign: "center", justifyContent: "space-evenly", flexDirection: 'row' }}>
                            {cartProduct.sizes &&
                                cartProduct.sizes.map((size, index) => (
                                    <View key={index}>
                                        <TouchableOpacity
                                            key={index}
                                            onPress={() => { setSelectedCart({ size: size.Product_Size, quantity: size.Product_Available_Stock }); }}
                                        >
                                            <View style={selectedCart.size === size.Product_Size ?
                                                {
                                                    width: 50,
                                                    height: 50,
                                                    backgroundColor: 'rgb(42, 175, 82)',
                                                    // cursor: pointer,
                                                    borderRadius: 50,
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    borderColor: '#c4c4da',
                                                    borderWidth: 1,
                                                    position: 'relative',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: 'white'

                                                }
                                                : {
                                                    width: 50,
                                                    marginTop: 20,
                                                    marginBottom: 20,
                                                    // height: auto;
                                                    height: 50,
                                                    backgroundColor: 'white',
                                                    // cursor: pointer;
                                                    borderRadius: 50,
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    borderColor: '#c4c4da',
                                                    borderWidth: 1,
                                                    position: 'relative',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',

                                                }} >
                                                <Text icon="format-size">{size?.Product_Size ? size?.Product_Size : ""}</Text>
                                            </View>

                                        </TouchableOpacity>
                                        {
                                            size.Product_Available_Stock ?
                                                <Text >
                                                    {parseInt(size.Product_Available_Stock) < 11 ?
                                                        <Text style={styles.ribbon}>{size.Product_Available_Stock} Left</Text>
                                                        : null}
                                                </Text> : null
                                        }
                                    </View>
                                )

                                )}
                        </View>

                        <View>
                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, width: 200, alignSelf: 'center' }} accessible={selectedCart?.size && selectedCart?.quantity ? true : false} >
                                <Button
                                    onPress={() => {
                                        let data = {};
                                        try {
                                            data = wish_list.filter((item) => item.wishlistId === cartProduct.walletId)[0];

                                        } catch (error) {

                                        }
                                        if (!isEmpty(data)) {

                                            let item = { partner: data.Partner_Product, product: { ...(data.Products), ...(data.Partner_Product) }, size: selectedCart.size, quantity: selectedCart.quantity }
                                            setCartModelOpen(false);

                                            let payload = {
                                                "Partner_Details_Id": data.Partner_Product.Partner_Details_Id,
                                                "Partner_Product_Id": data.Partner_Product.Partner_Product_Id,
                                                "Quantity": "1",
                                                "Size": selectedCart.size,
                                                "User_Email_Id": EmailID
                                            }

                                            dispatch(addCart(payload, EmailID));

                                            removeItem(cartProduct.walletId);
                                        }

                                    }}
                                    variant="contained"
                                    color="blue"
                                    type="submit"
                                    disabled={selectedCart?.size && selectedCart?.quantity ? false : true}
                                    style={{ color: selectedCart?.size && selectedCart?.quantity ? "" : "blue" }}
                                >
                                    <Text style={{ color: esiColor.itemButtenColor }}>Proceed</Text>
                                </Button>
                            </TouchableOpacity>
                            {/* <button  onClick={cartData} className="sizebutton" >Proceed</button> */}
                        </View>
                    </View>
                </View>
            </Modal >
        </Surface >

    );
}

export default WishList;

