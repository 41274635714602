import React from 'react';
import { Text, View, Image, Platform, Dimensions, ImageBackground } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Surface } from 'react-native-paper';
import { useSelector } from 'react-redux';


const EsiCityCard = (props: any) => {
  const esiColor = useSelector(state => state.theme);

  // item is brand object , using this object item to display info.
  const { item, navigate, navigation, State_Name, name, ...rest } = props
  // const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  // const halfdimensions = dimensions;
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  return (
    <Surface style={{
      borderRadius: 10, margin: 5,
      backgroundColor: esiColor.CBColor,
      marginBottom: 15, flexDirection: 'row',
      shadowColor: esiColor.brandShadowColor,
      shadowRadius: 5,
      alignItems: 'flex-start',
      height: 120,
      width: 300,
    }}>
      <View style={{ flex: 2, justifyContent: 'center', marginLeft: 5, marginTop: 10 }} >
        <ImageBackground style={{ backgroundColor: esiColor.ImgBck, height: 90, width: 90, borderRadius: '100%', }}>
          <Image source={item?.Image_Path ? item?.Image_Path : require('../../assets/images/custom-components/flag.png' + `?w=90&h=90`)}
            style={{ height: 90, width: 90, resizeMode: 'contain', borderRadius: '100%', borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
          </Image>
        </ImageBackground>
      </View>

      <View style={{ flex: 5, flexDirection: 'column', marginTop: 35 }}  >
        {/* <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.Text, }}>{item.Country_Name}</Text> */}
        <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.Text, margin: 'auto', marginLeft: (Platform.OS === 'web' ? null : 50), }}>{item.Name}</Text>
        <Text style={{ fontSize: 14, margin: 'auto', color: esiColor.itemColor, marginLeft: (Platform.OS === 'web' ? null : 50), }}>
          {item?.State_Name}</Text>
      </View>
    </Surface>
  );
};

export default EsiCityCard;

