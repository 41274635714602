import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AddAddressAction, GetAllAddressAction } from "../../state/actions/addressActions";
import { Card, TextInput, Text, Button, HelperText, Surface, Checkbox, IconButton, RadioButton } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, KeyboardAvoidingView, ActivityIndicator, Dimensions, TouchableOpacity, } from 'react-native';
import Header from '../header';
import EsiCity from '../../components/custom/country-state-city/city';
import EsiState from '../../components/custom/country-state-city/state';
import isEmpty from '../../state/validations/is-empty';
import { CountryCode } from '../../../utils/countrycode';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import AddressLocation from '../../components/custom/location/address-location';
import Modal from "react-native-modal";
import ESITextInput from '../../components/custom-fields/textInput';
// import esiColor from '../../constants/Colors';
// import PhoneInput from 'react-native-phone-number-input';
// import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
// import EsiCountry from '../../components/custom/country-state-city/country';


const AddresstypeOption = [
  {
    value: 'Home',
    label: 'Home'
  },
  {
    value: 'Business',
    label: 'Business'
  },
  {
    value: 'Office',
    label: 'Office'
  }
];

const countryOption = [
  {
    value: 'IN',
    label: 'India'
  }
  // ,
  // {
  //   value: 'US',
  //   label: 'United States'
  // },
  // {
  //   value: 'GB',
  //   label: 'United Kingdom'
  // },
  // {
  //   value: 'SG',
  //   label: 'Singapore'
  // },
  // {
  //   value: 'PH',
  //   label: 'Phillipines'
  // },
  // {
  //   value: 'MY',
  //   label: 'Malaysia'
  // },
  // {
  //   value: 'AU',
  //   label: 'Australia'
  // },
  // {
  //   value: 'ID',
  //   label: 'Indonesia'
  // },
  // {
  //   value: 'VN',
  //   label: 'Vietnam'
  // },
  // {
  //   value: 'NP',
  //   label: 'Nepal'
  // },
  // {
  //   value: 'SA',
  //   label: 'Saudi Arabia'
  // }
];
const windowHeight = Dimensions.get('window').height;

const AddAddress = (props: any) => {

  //toast for popup after successful API calls.
  const { navigation, valueUpdate, route, ...rest } = props
  const dispatch = useDispatch();
  const toast = useToast();
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  const esiColor = useSelector(state => state.theme);

  const EmailID = useSelector(state => state.auth.user.Email_Id);
  const isSubmited = useSelector(state => state.address.addressCreate.isLoading);

  const Mobile = useSelector(state => state.auth.user?.Mobile || "");


  //Dropdowns
  const [typeDropDown, setTypeDropDown] = useState("");
  const [countryDropDown, setCountryDropDown] = useState(false);
  const [stateDropDown, setStateDropDown] = useState(false);
  const [cityDropDown, setCityDropDown] = useState(false);

  //mobile
  const phoneInput = useRef(null);
  const phoneInput1 = useRef(null);
  const phoneRegExp = /^([+]\d{2}[ ])?\d{10}$/

  /*====================================
          Handler
  ====================================*/

  useEffect(() => {
    setSeconds(2);
  }, [EmailID]);

  const [seconds, setSeconds] = React.useState(-1);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);
      if (seconds === 1) {
        if (isEmpty(EmailID)) {
          navigation.navigate("Home");
        }
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const values = {
    Address_Type: "Home",
    Alternate_Mobile: "",
    City: "",
    Country: countryOption[0].value,
    Created_By: EmailID,
    Door_No: "",
    Is_Active: "1",
    Is_Billing: "",
    Is_Default: "",
    Is_Shipping: "",
    Land_Mark: "",
    Location: "",
    Mobile: Mobile,
    Name: "",
    Shipping_Email_Id: EmailID,
    State: "",
    Street: "",
    User_Email_Id: EmailID,
    Zip: "",
    Latitude: "",
    Longitude: "",
  }

  let resetFormikForm;

  const navigationStatus = () => {
    let formData = {
      Records_Filter: "FILTER",
      User_Email_Id: EmailID,
    };
    if (resetFormikForm) {
      resetFormikForm();
    }
    dispatch(GetAllAddressAction(formData));
    navigation.goBack();
  }

  const Form_Validation = Yup.object().shape({
    Name: Yup.string().required('Name is required.'),
    // Shipping_Email_Id: Yup.string().required('Shipping Email ID is required.'),
    User_Email_Id: Yup.string().required('User Email ID is required.'),
    Address_Type: Yup.string().required('Address Type is required.'),
    Country: Yup.string().required('Country is required.'),
    State: Yup.string().required('State is required.'),
    City: Yup.string().required('City is required.'),
    Zip: Yup.string()
      .matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Please provide valid Pin Code.')
      .required('Pin Code is required.'),
    Land_Mark: Yup.string().required('Land Mark is required.'),
    Street: Yup.string().required('Street is required.'),
    Location: Yup.string().required('Location is required.'),
    Door_No: Yup.string().required('Door No is required.'),
    Mobile: Yup.string().required("Mobile Number is required.").test("phone-required", "Mobile Number is required.", (value: any) => {
      if (value) {
        if (!("+" + phoneInput.current?.getCallingCode() == value)) {
          return true;
        }
      }
      return false;

    })
      .test("phone-validation", "Please provide valid Mobile Number.", (value: any) => {
        let mobileValue = (value || "").split(" ");
        if (mobileValue[0] === "+91") {
          if (/^[0-9]\d*$/.test(mobileValue[1])) {
            if (mobileValue[1]?.length === 10) {
              return true;
            }
          }
        } else {
          if (/^[0-9]\d*$/.test(mobileValue[1])) {
            return true;
          }
        }
        return false
      }),

    Alternate_Mobile: Yup.string()
      .when('Mobile', (Mobile, schema, Alternate_Mobile) => {
        let dataExist = false;
        if (Alternate_Mobile.value) {
          let splitValues = (Alternate_Mobile.value).split(" ");
          dataExist = splitValues.length > 1;
          if (dataExist) {
            dataExist = splitValues[1].length > 0 ? true : false;
          }
        }
        return dataExist ? schema.notOneOf([Yup.ref('Mobile'), null], 'Alternate Number must be different.').test("phone1-validation", "Please provide valid Alternate Number.", (value: any) => {
          let mobileValue = (value || "").split(" ");
          if (mobileValue[0] === "+91") {
            if (/^[0-9]\d*$/.test(mobileValue[1])) {
              if (mobileValue[1]?.length === 10) {
                return true;
              } else if (mobileValue[1]?.length <= 0) {
                return true;
              }
            }
          } else {
            if (/^[0-9]\d*$/.test(mobileValue[1])) {
              return true;
            }
          }
          return false
        }) : schema
      }),
  })

  const onSubmit = (values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
    let submitTest = true;
    setSubmitting(false);
    resetFormikForm = resetForm;
    if (submitTest) {
      let formValues = JSON.parse(JSON.stringify(values));
      if (values.Is_Active) {
        formValues.Is_Active = 1;
      } else {
        formValues.Is_Active = 0;
      }
      if (values.Is_Default) {
        formValues.Is_Default = 1;
      } else {
        formValues.Is_Default = 0;
      }
      if (values.Is_Billing) {
        formValues.Is_Billing = 1;
      } else {
        formValues.Is_Billing = 0;
      }
      if (values.Is_Shipping) {
        formValues.Is_Shipping = 1;
      } else {
        formValues.Is_Shipping = 0;
      }
      dispatch(AddAddressAction(formValues, navigationStatus, EmailID, toast));
    }
  }

  const getCountryCode = (value) => {
    let dailCode = "91";
    if (value) {
      if (value.split('+').length > 0) {
        let pSplit = value.split('+');
        let count = pSplit.length > 1 ? 1 : 0;
        if (pSplit[count].split(' ').length > 0) {
          dailCode = value.split('+')[count].split(' ')[0];
        }
      }
    }
    return CountryCode.find((item) => item.dial_code == dailCode)?.code;
  }

  const getCurrentMobileNumber = (value) => {
    let dailNumber = value;
    if (value) {
      if (value.split(' ').length > 1) {
        dailNumber = value.split(' ')[1];
      }
    }
    return dailNumber;
  }

  const [isLocationVisible, setIsLocationVisible] = React.useState(false);


  const combinationCode = () => {
    return (
      // <View style={{ flex: 1 }}>
      <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
        <>
          <Header {...props} />
          <ScrollView>

            <Formik initialValues={{ ...values }} enableReinitialize={true} onSubmit={onSubmit} validationSchema={Form_Validation}>
              {(props) => (
                <View style={{ flexDirection: "column", width: 800, alignSelf: 'center' }}>

                  {/* <Card style={styles.cardCircle}> */}
                  {/* <Card.Content> */}
                  <View style={{ flexDirection: 'row' }}>
                    <IconButton
                      icon="arrow-left"
                      // size={30}
                      color={"#27B6CC"}
                      // style={{ marginTop: 10 }}
                      onPress={() => {
                        if (route?.params?.page === "cart-summary") {
                          navigation.navigate('CartSummary');
                        } else if (route?.params?.page === "cart") {
                          navigation.navigate('Cart');
                        } else {
                          navigation.navigate('UserAddresses');
                        }
                      }} />
                    <Text style={{ fontSize: 20, fontWeight: "bold", marginTop: 12, color: esiColor.brandFontColor }}>
                      Add Address
                    </Text>
                  </View>

                  <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', marginTop: -10 }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.TextBC }}
                        selectionColor={esiColor.TextSelection}
                        theme={{
                          colors: {
                            primary: esiColor.TextHighlight,
                            text: esiColor.Text,
                            placeholder: esiColor.TextPlaceholder
                          }
                        }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        mode='outlined'
                        label='Name'
                        name="Name"
                        value={props.values.Name}
                        onChangeText={props.handleChange('Name')}
                        error={Boolean(props.touched.Name && props.errors.Name)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Name && props.errors.Name)}>
                        <ErrorMessage name="Name" />
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.TextBC }}
                        selectionColor={esiColor.TextSelection}
                        theme={{
                          colors: {
                            primary: esiColor.TextHighlight,
                            text: esiColor.Text,
                            placeholder: esiColor.TextPlaceholder
                          }
                        }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        mode='outlined'
                        label='Door No'
                        name="Door_No"
                        value={props.values.Door_No}
                        onChangeText={props.handleChange('Door_No')}
                        error={Boolean(props.touched.Door_No && props.errors.Door_No)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Door_No && props.errors.Door_No)}>
                        <ErrorMessage name="Door_No" />
                      </HelperText>
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.TextBC }}
                        selectionColor={esiColor.TextSelection}
                        theme={{
                          colors: {
                            primary: esiColor.TextHighlight,
                            text: esiColor.Text,
                            placeholder: esiColor.TextPlaceholder
                          }
                        }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        mode='outlined'

                        label='Street'
                        // mode='outlined'
                        name="Street"
                        value={props.values.Street}
                        onChangeText={props.handleChange('Street')}
                        error={Boolean(props.touched.Street && props.errors.Street)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Street && props.errors.Street)}>
                        <ErrorMessage name="Street" />
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.TextBC }}
                        selectionColor={esiColor.TextSelection}
                        theme={{
                          colors: {
                            primary: esiColor.TextHighlight,
                            text: esiColor.Text,
                            placeholder: esiColor.TextPlaceholder
                          }
                        }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        mode='outlined'

                        label='Land Mark'
                        // mode='outlined'
                        name="Land_Mark"
                        value={props.values.Land_Mark}
                        onChangeText={props.handleChange('Land_Mark')}
                        error={Boolean(props.touched.Land_Mark && props.errors.Land_Mark)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Land_Mark && props.errors.Land_Mark)}>
                        <ErrorMessage name="Land_Mark" />
                      </HelperText>
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, }}>
                      <TouchableOpacity onPress={() => { setIsLocationVisible(!isLocationVisible); }}>
                        <TextInput
                          style={{ backgroundColor: esiColor.TextBC }}
                          selectionColor={esiColor.TextSelection}
                          theme={{
                            colors: {
                              primary: esiColor.TextHighlight,
                              text: esiColor.Text,
                              placeholder: esiColor.TextPlaceholder
                            }
                          }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          mode='outlined'

                          label='Location'
                          name="Location"
                          // disabled={true}
                          value={props.values.Location}
                          // onTouchStart={() => {
                          //   setIsLocationVisible(!isLocationVisible);
                          // }}
                          error={Boolean(props.touched.Location && props.errors.Location)}
                          right={<TextInput.Icon icon={!(isEmpty(props.values.Latitude) || isEmpty(props.values.Longitude)) ? "map-marker-check-outline" : "map-marker-plus-outline"} onPress={() => {
                            setIsLocationVisible(!isLocationVisible);
                          }} color={!(isEmpty(props.values.Latitude) || isEmpty(props.values.Longitude)) ? "green" : "#27b6cc"} />}
                        />
                      </TouchableOpacity>
                      <HelperText type="error" visible={Boolean(props.touched.Location && props.errors.Location)}>
                        <ErrorMessage name="Location" />
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      {/* <Text style={{ textAlign: "left", color: esiColor.itemColor }}>Mobile Number</Text> */}
                      <ESITextInput
                        style={{ width: "100%" }}
                        label="Mobile"
                        mode='outlined'
                        onChangeText={(value) => {
                          props.setFieldValue("Mobile", "+91 " + value)
                        }}
                        value={props.values.Mobile?.split(" ")[1]}
                        left={<TextInput.Affix textStyle={{ color: esiColor.itemColor }} text="+91" />}
                        error={Boolean(props.touched.Mobile && props.errors.Mobile)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Mobile && props.errors.Mobile)}>
                        <ErrorMessage name="Mobile" />
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1 }}>
                      {/* <Text style={{ textAlign: "left", color: esiColor.itemColor }}>Alternate Mobile</Text> */}
                      <ESITextInput
                        style={{ width: "100%" }}
                        label="Alternate Mobile"
                        mode='outlined'
                        onChangeText={(value) => {
                          props.setFieldValue("Alternate_Mobile", "+91 " + value)
                        }}
                        value={props.values.Alternate_Mobile?.split(" ")[1]}
                        left={<TextInput.Affix textStyle={{ color: esiColor.itemColor }} text="+91" />}
                        error={Boolean(props.touched.Alternate_Mobile && props.errors.Alternate_Mobile)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Alternate_Mobile && props.errors.Alternate_Mobile)}>
                        <ErrorMessage name="Alternate_Mobile" />
                      </HelperText>
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                      <Text style={[styles.dropdownlabel, Boolean(props.touched.State && props.errors.State) && { color: '#b00020' }]}>
                        State
                      </Text>
                      <EsiState
                        style={[Boolean(props.touched.State && props.errors.State) && { borderColor: '#b00020' }]}
                        labelField="name"
                        valueField="isoCode"
                        value={props.values.State}
                        name="State"
                        search
                        searchPlaceholder={'Select State'}
                        CountryCode={props.values.Country}
                        onUpdateValue={item => {
                          props.setFieldValue('State', item.isoCode)
                          setStateDropDown(false);
                        }}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.State && props.errors.State)}>
                        <ErrorMessage name="State" />
                      </HelperText>
                    </View>

                    <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                      <Text style={[styles.dropdownlabel, Boolean(props.touched.City && props.errors.City) && { color: '#b00020' }]}>
                        City
                      </Text>

                      <EsiCity
                        style={[Boolean(props.touched.City && props.errors.City) && { borderColor: '#b00020' }]}
                        labelField="name"
                        valueField="name"
                        CountryCode={props.values.Country}
                        StateCode={props.values.State}
                        value={props.values.City}
                        name="City"
                        search
                        searchPlaceholder={'Select City'}
                        onUpdateValue={item => {
                          props.setFieldValue('City', item.name)
                          setCityDropDown(false);
                        }}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.City && props.errors.City)}>
                        <ErrorMessage name="City" />
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.TextBC }}
                        selectionColor={esiColor.TextSelection}
                        theme={{
                          colors: {
                            primary: esiColor.TextHighlight,
                            text: esiColor.Text,
                            placeholder: esiColor.TextPlaceholder
                          }
                        }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        mode='outlined'

                        label='Shipping Email ID'
                        // mode='outlined'
                        name="Shipping_Email_Id"
                        type="email"
                        value={props.values.Shipping_Email_Id}
                        onChangeText={props.handleChange('Shipping_Email_Id')}
                        error={Boolean(props.touched.Shipping_Email_Id && props.errors.Shipping_Email_Id)}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Shipping_Email_Id && props.errors.Shipping_Email_Id)}>
                        <ErrorMessage name="Shipping_Email_Id" />
                      </HelperText>
                    </View>
                    <View style={{ flexGrow: 1, width: 200, marginRight: '0.5%', flexShrink: 1 }}>
                      <TextInput
                        style={{ backgroundColor: esiColor.TextBC }}
                        selectionColor={esiColor.TextSelection}
                        theme={{
                          colors: {
                            primary: esiColor.TextHighlight,
                            text: esiColor.Text,
                            placeholder: esiColor.TextPlaceholder
                          }
                        }}
                        outlineColor={esiColor.TextOuterLine}
                        underlineColor={esiColor.TextUnderline}
                        mode='outlined'
                        label='Pin Code'
                        name="Zip"
                        value={props.values.Zip}
                        onChangeText={props.handleChange('Zip')}
                        error={Boolean(props.touched.Zip && props.errors.Zip)}
                        maxLength={6}
                      />
                      <HelperText type="error" visible={Boolean(props.touched.Zip && props.errors.Zip)}>
                        <ErrorMessage name="Zip" />
                      </HelperText>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", paddingBottom: 15 }}>
                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                      <Checkbox.Android
                        name="Is_Default"
                        color={esiColor.itemColor}
                        uncheckedColor={esiColor.SBorderColor}
                        status={props.values.Is_Default ? 'checked' : 'unchecked'}
                        onPress={() => {
                          props.setFieldValue("Is_Default", !props.values.Is_Default);
                        }}
                      />
                      <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Default</Text>
                    </View>

                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                      <Checkbox.Android
                        color={esiColor.itemColor}
                        uncheckedColor={esiColor.SBorderColor}
                        name="Is_Billing"
                        status={props.values.Is_Billing ? 'checked' : 'unchecked'}
                        onPress={() => {
                          props.setFieldValue("Is_Billing", !props.values.Is_Billing);
                        }}
                      />
                      <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Billing</Text>
                    </View>

                    <View style={{ flexShrink: 1, flexDirection: "row" }} >
                      <Checkbox.Android
                        color={esiColor.itemColor}
                        name="Is_Shipping"
                        uncheckedColor={esiColor.SBorderColor}
                        status={props.values.Is_Shipping ? 'checked' : 'unchecked'}
                        onPress={() => {
                          props.setFieldValue("Is_Shipping", !props.values.Is_Shipping);
                        }}
                      />
                      <Text style={{ marginTop: 10, color: esiColor.DescColor }}>Is Shipping</Text>
                    </View>

                  </View>

                  <View style={{ justifyContent: 'space-evenly' }}>

                    <RadioButton.Group onValueChange={newValue => { props.setFieldValue('Address_Type', newValue) }} value={props.values.Address_Type}>
                      <View style={{ flexShrink: 1, flexDirection: "row", justifyContent: 'space-between' }}>
                        {AddresstypeOption.map((aType, index) => {
                          return (
                            <View style={{ flex: 5, flexDirection: "row", alignItems: 'center' }} key={"radio" + index} >
                              <RadioButton.Android color={esiColor.DescColor}
                                uncheckedColor={esiColor.SBorderColor} value={aType.value} />
                              <Text style={{ color: esiColor.DescColor }}>{aType.label}</Text>
                            </View>
                          )
                        })}
                      </View>
                    </RadioButton.Group>
                  </View>
                  <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", marginBottom: 20 }}>
                    {isSubmited ? <ActivityIndicator color={"#27B6CC"} /> :
                      <Button disabled={isSubmited} style={{ marginTop: "3%", marginBottom: 10, backgroundColor: esiColor.globalButtonColor, }} color={esiColor.itemColor} mode="contained" onPress={props.handleSubmit}>
                        <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                      </Button>}
                  </View>
                  {/* </View> */}


                  {/* </Card.Content> */}
                  {/* </Card> */}

                  <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={isLocationVisible}>
                    <View style={{ flex: 1, maxWidth: 500, minWidth: 500, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderWidth: 0.5, borderColor: esiColor.SBorderColor }}>
                      <AddressLocation isLocationVisible={isLocationVisible} setIsLocationVisible={setIsLocationVisible} filters={{
                        Latitude: props.values.Latitude,
                        Longitude: props.values.Longitude
                      }}
                        setFilters={(address, lan, lon, zip) => {
                          props.setFieldValue("Location", address);
                          props.setFieldValue("Latitude", lan);
                          props.setFieldValue("Longitude", lon);
                          props.setFieldValue("Zip", zip);
                          setIsLocationVisible(!isLocationVisible);
                        }}
                        Width={450}
                        height={450}
                      />
                    </View>
                  </Modal>

                </View>
              )}
            </Formik>
          </ScrollView>

        </>

      </Surface>

    )
  }

  const styles = StyleSheet.create({
    cardCircle: {
      width: (Platform.OS === 'web') ? "75%" : "98%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2%",

    },

    checkbox: {
      alignSelf: "center",
    },
    label: {
      marginBottom: 30,
    },


    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.DescColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: esiColor.SBorderColor,
      borderWidth: 1,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,

      // color: "white"

    },
    placeholderStyle: {
      fontSize: 16,
      // backgroundColor: esiColor.BackgroundColor,
      color: esiColor.BackgroundColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },

    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    datePickerStyle: {
      width: 358,
      height: 60,
      marginTop: 6,
    },

  });

  return (
    <Surface style={{ flex: 1 }}>
      {Platform.OS === "ios" ?
        <KeyboardAvoidingView behavior='padding'>
          {combinationCode()}
        </KeyboardAvoidingView> : combinationCode()}
    </Surface>
  );
};



export default AddAddress;
