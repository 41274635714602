import React, { useState, useRef, useEffect } from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  Animated,
  Easing,
  Image,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Badge, IconButton } from 'react-native-paper';
import { blue } from '@material-ui/core/colors';
import isEmpty from '../../../state/validations/is-empty';
import { useSelector } from 'react-redux';
// import esiColor from '../../../constants/Colors';

const CartAccordionListItem = (props) => {

  const [open, setOpen] = useState(false);
  const esiColor = useSelector(state => state.theme);

  useEffect(() => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 1,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: false
    }).start();
    setOpen(true);
  }, [
    props.expanded
  ])
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(50);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: false
      }).start();
    }
    setOpen(!open);
  };

  const styles = StyleSheet.create({
    bodyBackground: {
      backgroundColor: esiColor.BackgroundColor,
      overflow: 'hidden',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      // marginTop: '-1rem',
      // marginBottom: '-1rem',
      // paddingLeft: '1.5rem',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: '#1a1a1a',
      backgroundColor: esiColor.BackgroundColor,
      flex: 1
    },
    bodyContainer: {
      // marginTop: '-2rem',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      backgroundColor: esiColor.BackgroundColor
    },
    item: {
      margin: 16,
    },
    button: {
      // opacity: 0.6,
      color: "#e6e6e6"
    },
    badge: {
      position: 'absolute',
      top: 10,
      right: 10,
      // top:10,
      backgroundColor: "#27b6cc"
    },
  });

  return (
    <>
      <TouchableWithoutFeedback key={props?.item.partnerId} onPress={() => toggleListItem()}>
        <View style={styles.titleContainer}>
          <View style={{
            display: "flex",
            flexDirection: "row",
            flex: 5
          }}>
            <Image
              source={{ uri: props?.item.partnerLogo }}
              style={{ width: 40, height: 40, borderRadius: 8 }}
            />
            <Text style={{ marginTop: 2, marginLeft: 10, fontWeight: 'bold', color: esiColor.brandFontColor }}>{props?.item.partnerName}</Text>
          </View>
          <View style={[styles.item, { flex: 2 }]}>
            <IconButton icon="cart" size={30} color={"#e6e6e6"} style={styles.button} />
            <Badge style={styles.badge}>
              {props?.item?.products?.length}
            </Badge>
          </View>
          <View style={{
            display: "flex",
            flexDirection: "row",
            flex: 3
          }}>
            <Text style={{ fontWeight: 'bold', flexWrap: "wrap", color: esiColor.DescColor }}>₹ {props.getPartnerOrderFinalPrice(props?.item)}</Text>
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
              <MaterialIcons name="keyboard-arrow-down" style={{ fontWeight: 'bold' }} size={20} color="#27b6cc" />
            </Animated.View>
          </View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          style={styles.bodyContainer}
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }>
          {props.children && props.children}
        </View>
      </Animated.View>
    </>
  );
};
export default CartAccordionListItem;


