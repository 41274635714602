import React, { useRef, useState } from "react";
import { Card, Surface, Text } from "react-native-paper";
import { Image, Platform, StyleSheet, TouchableOpacity, View, ScrollView, Dimensions, ImageBackground } from "react-native";
import ImageView from 'react-native-image-view';
// import esiColor from "../../constants/Colors";
// const window = Dimensions.get("window");
// const screen = Dimensions.get("screen");
const { height, width } = Dimensions.get('window');
import { useSelector } from "react-redux";

export default function ImageCarousel(props: any) {
    // image is a Product images object as we use this object to display the info front-end
    const { image, dimensions } = props
    const esiColor = useSelector(state => state.theme);

    //For image scroll
    const [active, setactive] = useState(0);
    const el = useRef(null);
    const change = ({ nativeEvent }: { nativeEvent: any }) => {
        const slide = Math.ceil(nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width)
        if (slide !== active) {
            setactive(slide);
        }
    }

    // const click = ({ nativeEvent }: { nativeEvent: any }) => {
    //     const slides = Math.ceil(nativeEvent.contentOffset.y / nativeEvent.layoutMeasurement.width)
    //     if (slides !== active) {
    //         setactive(slides);
    //     }
    // }

    return (

        <View style={{
            position: 'relative', height: 400, maxHeight: 400, marginRight: 15, marginLeft: 15,
            width: (width > height) ? height : width, alignItems: 'center'
        }}>
            <ScrollView
                ref={el}
                horizontal
                onScroll={change}
                showsHorizontalScrollIndicator={false}
                automaticallyAdjustContentInsets={false}
                style={{
                    margin: "auto", height: (height < width) ? width : height,
                    width: (width > height) ? height : width, marginRight: 15, marginLeft: 15,
                    shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7
                }}>
                {image.map((image: any, index: any) => (

                    <ImageBackground style={{
                        height: 400, maxHeight: 400,
                        backgroundColor: esiColor.ImgBck,
                        width: (width > height) ? height : width, borderRadius: 10,
                    }}>
                        <Image
                            source={{ uri: image.imageuri }}
                            style={{
                                height: 400, maxHeight: 400,
                                width: (width > height) ? height : width, resizeMode: 'contain',
                                borderRadius: 10, shadowRadius: 2
                            }}>
                        </Image>
                    </ImageBackground>
                ))
                }
            </ScrollView>

            <View style={{ flexDirection: 'row', position: 'absolute', bottom: 0, alignSelf: 'center' }}>
                {image.map((image: any, index: any) => (
                    // <TouchableOpacity
                    //     onPress={click}
                    // >
                    <Text key={index} style={index == active ? styles.pagingActiveText : styles.pagingText}>⬤</Text>
                    //  </TouchableOpacity>

                )
                )}
            </View>
        </View>
    )

}


const styles = StyleSheet.create({
    pagingText: { color: '#888', margin: 3 },
    pagingActiveText: { color: '#27b6cc', margin: 3 },
})