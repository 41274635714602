import * as React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import EsiCheckboxTree from '../components/custom/checkbox-tree';

import { Text, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import Header from './header';

export default function NotFoundScreen(props) {
  const esiColor = useSelector(state => state.theme);

  return (
    <>
      <Header {...props} />
      <View style={styles.container}>
        <Image style={{ height: '85%', width: '75%' }} source={require('../assets/images/404_Screen.png')} />
        <Text style={{ color: esiColor.NoDataFound, fontWeight: 'bold', fontSize: 20 }}>Oops! Page not found!</Text>

        {/* <TouchableOpacity onPress={() => navigation.replace('Root')} >
     <Text style={{color:'#27b6cc',}}>Go to home screen!</Text>
   </TouchableOpacity> */}
        {/* <EsiCheckboxTree /> */}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // padding: 20,
  }
});
