import React, { useState } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Card, TextInput, Text, Button, HelperText, Surface, IconButton } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView } from 'react-native';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import Header from '../header';
import { AddTicketsAction, uploadAttachment } from '../../state/actions/ticketActions';
import FileUpload from '../../components/custom/file-upload/upload';
import isEmpty from '../../state/validations/is-empty';
import { useToast } from '../../self_modules/react-native-paper-toast/src';
import ESITextInput from '../../components/custom-fields/textInput';


const IssuerelatedOption = [
  {
    value: 'Order',
    label: 'Order'
  },
  {
    value: 'Payment',
    label: 'Payment'
  },
  {
    value: 'Delivery',
    label: 'Delivery'
  },
  {
    value: 'Product',
    label: 'Product'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

const TicketstatusOption = [
  {
    value: 'New',
    label: 'New'
  },
  {
    value: 'In Progress',
    label: 'In Progress'
  },
  {
    value: 'Hold',
    label: 'Hold'
  },
  {
    value: 'Closed',
    label: 'Closed'
  },

];
function AddTicket(props: any) {
  const { navigation, valueUpdate, ...rest } = props
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const toast = useToast();
  //here iterating Partners name and Partners ID values 
  const [reload, setReload] = useState(false);
  const EmailID = useSelector(state => state.auth.user.Email_Id);

  React.useEffect(() => {
    if (isEmpty(EmailID)) {
      setTimeout(() => {
        if (isEmpty(EmailID)) {
          navigation.navigate("Ticket");
        }
      }, 1000)
    }
  }, [EmailID]);



  const [typeDropDown, settypeDropDown] = useState("");
  const [statusDropDown, setStatusDropDown] = useState("");

  const isSubmited = useSelector(state => state.ticket.TicketCreate.isLoading);

  // img upload
  const [file, setFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [uploadFile, setUploadFile] = useState(false);

  const callBackUploadFile = (data: any) => {
    if (data) {
      setFileUrl(data.File_URL);
    }
    setUploadFile(false);
  };

  const handleFile = async (result: any) => {

    if (result) {
      let call = result.uri

      let fileData = {
        fileName: result.name.split(".")[0],
        ext: result.name.split(".")[1],
        ticketId: "T-0000002",
      };

      const imageData = await fetchFileFromUri(call);
      setFile(result.name);
      dispatch(uploadAttachment(imageData, fileData, callBackUploadFile));
    };
  }
  const fetchFileFromUri = async (uri: any) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };
  const handleRemoveFile = e => {
    setFileUrl('');
    setFile('');
  };


  const styles = StyleSheet.create({
    cardCircle: {
      width: 750,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 20,
      borderRadius: 6,
      borderWidth: 0.01,
      borderColor: esiColor.SBorderColor,
      backgroundColor: esiColor.CBColor
    },

    files: {
      backgroundColor: esiColor.RTBC,
    },

    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.Text,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,
    },
    dropdown: {
      height: 58,
      borderColor: esiColor.SBorderColor,
      borderWidth: 1,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,

    },
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.BackgroundColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    datePickerStyle: {
      width: 358,
      height: 60,
      marginTop: 6,
    },
    input: {
      backgroundColor: esiColor.BackgroundColor,
      width: 700,

    },

  });

  /*====================================
          Handler
  ====================================*/

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <IconButton
        icon="arrow-left"
        size={27}
        color={esiColor.SIconColor}
        onPress={() => navigation.navigate('Ticket')} />
      <ScrollView>
        <Formik initialValues={{
          Attachment: fileUrl,
          Created_By: EmailID,
          Ticket_Description: "",
          // Ticket_Status: "",
          Ticket_Subject: "",
          Type: "",
        }}
          //field validations
          validationSchema={Yup.object().shape({
            Type: Yup.string().required('Type  is required.'),
            Ticket_Subject: Yup.string().required('Ticket Subject is required.'),
            Ticket_Description: Yup.string().required('Ticket Description  is required.'),
            // Ticket_Status: Yup.string().required('Ticket Status is required.'),
          })}
          onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
            let submitTest = true;
            setSubmitting(false);
            const errors = {};

            values.Attachment = fileUrl;
            if (!values.Attachment) {
              submitTest = false;
              errors.Attachment = "Attachment is required.";
            }
            setErrors(errors);
            if (submitTest) {
              let formValues = JSON.parse(JSON.stringify(values));
              dispatch(AddTicketsAction(formValues, navigation, toast, resetForm, EmailID));

            }
          }
          }
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Card style={styles.cardCircle}>
              <Card.Content>
                <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                  Add Support Ticket
                </Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: "center" }}>
                  <View style={{ flexGrow: 1, maxWidth: 700, marginRight: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                    <ESITextInput
                      style={{ backgroundColor: esiColor.BackgroundColor }}
                      label='Ticket Subject'
                      mode='outlined'
                      name="Ticket_Subject"
                      value={values.Ticket_Subject}
                      onChangeText={handleChange('Ticket_Subject')}
                      error={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}
                    />
                    <HelperText type="error" visible={Boolean(touched.Ticket_Subject && errors.Ticket_Subject)}>
                      <ErrorMessage name="Ticket_Subject" />
                    </HelperText>
                  </View>
                </View>

                <View style={{ flexGrow: 1, maxWidth: 700, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <ESITextInput
                    multiline
                    numberOfLines={4}
                    mode='outlined'
                    placeholder="Write a description...."
                    onChangeText={handleChange('Ticket_Description')}
                    value={values.Ticket_Description}
                    label='Description'
                  // style={styles.input}

                  />
                  <HelperText type="error" visible={Boolean(touched.Ticket_Description && errors.Ticket_Description)}>
                    <ErrorMessage name="Ticket_Description" />
                  </HelperText>
                </View>


                <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <View style={{ flexGrow: 1, maxWidth: 700, marginRight: '0.5%', flexShrink: 1, paddingTop: 5 }}>
                    <Text style={[styles.dropdownlabel, Boolean(touched.Type && errors.Type) && { color: '#b00020' }]}>
                      Type
                    </Text>
                    <EsiSearchDropdown
                      style={[styles.dropdown, Boolean(touched.Type && errors.Type) && { borderColor: '#b00020' }]}
                      placeholderStyle={styles.placeholderStyle}

                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      dropdownPosition="bottom"
                      placeholder={!typeDropDown ? '-- Please Select --' : ''}
                      data={IssuerelatedOption}
                      maxHeight={180}
                      labelField="label"
                      valueField="value"
                      value={values.Type}
                      onUpdateValue={item => {
                        setFieldValue('Type', item.value)
                        settypeDropDown(false);
                      }}
                    />
                    <HelperText type="error" visible={Boolean(touched.Type && errors.Type)}>
                      <ErrorMessage name="Type" />
                    </HelperText>

                  </View>
                  {/* <View style={{ flexGrow: 1, width: 200, marginLeft: '0.5%', flexShrink: 1, paddingTop: 20 }}>
                    <Text style={[styles.dropdownlabel, Boolean(touched.Ticket_Status && errors.Ticket_Status) && { color: '#b00020' }]}>
                      Ticket Status
                    </Text>
                    <EsiSearchDropdown
                      style={[styles.dropdown, Boolean(touched.Ticket_Status && errors.Ticket_Status) && { borderColor: '#b00020' }]}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      dropdownPosition="bottom"
                      placeholder={!statusDropDown ? '-- Please Select --' : ''}
                      data={TicketstatusOption}
                      maxHeight={180}
                      labelField="label"
                      valueField="value"
                      value={values.Ticket_Status}
                      onUpdateValue={item => {
                        setFieldValue('Ticket_Status', item.value)
                        setStatusDropDown(false);
                      }}
                    />
                    <HelperText type="error" visible={Boolean(touched.Ticket_Status && errors.Ticket_Status)}>
                      <ErrorMessage name="Ticket_Status" />
                    </HelperText>
                  </View> */}
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                  <View style={{ flexShrink: 1, width: 250, paddingTop: 20 }} >
                    <FileUpload style={{ borderRadius: 6, borderWidth: 1, borderColor: '#D6D6D7' }} filestyle={styles.files} handleRemoveFile={handleRemoveFile} file={file} uploadFile={handleFile} />
                    <HelperText type="error" visible={!fileUrl && touched.Attachment && errors.Attachment}>
                      {!fileUrl && touched.Attachment && errors.Attachment}
                    </HelperText>
                  </View>
                </View>
                <View style={{ width: 130, marginLeft: "auto", marginRight: "auto", }}>
                  <Button disabled={isSubmited || uploadFile} style={{ marginTop: "5%", backgroundColor: esiColor.globalButtonColor, }} mode="contained" onPress={handleSubmit}>
                    <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                  </Button>
                </View>

              </Card.Content>
            </Card>
          )}
        </Formik>

      </ScrollView>
    </Surface>
  );
};



export default AddTicket
