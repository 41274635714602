import React, { FC, ReactElement, useRef, useState } from 'react';
import {
    FlatList,
    StyleSheet,
    Text,
    TouchableOpacity,
    Modal,
    View,
    Platform,
} from 'react-native';
import esiColor from '../../constants/Colors';

interface Props {
    label: string;
    data: Array<{ label: string; value: string }>;
    onSelect: (item: { label: string; value: string }) => void;
    selected: string
}

const EsiDropdown: FC<Props> = ({ label, data, onSelect, selected }) => {
    const DropdownButton = useRef();
    const [visible, setVisible] = useState(false);
    const [dropdownTop, setDropdownTop] = useState(0);
    const [dropdownLeft, setDropdownLeft] = useState(0);

    const toggleDropdown = (): void => {
        visible ? setVisible(false) : openDropdown();
    };

    const openDropdown = (): void => {
        DropdownButton.current.measure((_fx: number, _fy: number, _w: number, h: number, _px: number, py: number) => {
            let dHeight = Platform.OS == "android" ? py : py + h;
            setDropdownTop(dHeight);
            setDropdownLeft(_fx)
        });
        setVisible(true);
    };

    const onItemPress = (item: any): void => {
        onSelect(item);
        setVisible(false);
    };

    const renderItem = ({ item }: any): ReactElement<any, any> => (
        <TouchableOpacity style={styles.item} onPress={() => onItemPress(item)}>
            <Text>{item.label}</Text>
        </TouchableOpacity>
    );

    const renderDropdown = (): ReactElement<any, any> => {
        return (
            <Modal visible={visible} transparent animationType="none">
                <TouchableOpacity
                    style={styles.overlay}
                    onPress={() => setVisible(false)}
                >
                    <View style={[styles.dropdown, { top: dropdownTop, flexShrink: 1 }]}>
                        <FlatList
                            data={data}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => index.toString()}
                        />
                    </View>
                </TouchableOpacity>
            </Modal>
        );
    };

    return (
        <TouchableOpacity
            ref={DropdownButton}
            style={styles.button}
            onPress={toggleDropdown}
        >
            {renderDropdown()}
            <Text numberOfLines={1} style={[styles.buttonText]}>
                {(!!selected && selected) || label}
            </Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 12, textTransform: "none", fontWeight: "bold",
        paddingHorizontal: 10,
        borderRadius: 4
    },
    buttonText: {
        color: "#27b6cc",
        fontWeight: "bold"
    },
    dropdown: {
        position: 'relative',
        backgroundColor: '#fff',
        shadowColor: '#000000',
        shadowRadius: 4,
        shadowOffset: { height: 4, width: 0 },
        shadowOpacity: 0.5,
        // marginRight:25,
        alignSelf: "center"
        // marginHorizontal:"5%"
    },
    overlay: {
        width: '100%',
        height: '100%'
    },
    item: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderBottomWidth: 1,
    },
});

export default EsiDropdown;