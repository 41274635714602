import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Searchbar, Text, Surface, Checkbox } from 'react-native-paper';
import { View, ScrollView, RefreshControl, TouchableOpacity, Platform } from 'react-native';
import Header from "../header";
import EsiBrandCard from "../../components/custom/brand-card";
import isEmpty from "../../state/validations/is-empty";
import { getBrandsAction } from "../../state/actions/node-actions/home-actions";
import Pagination from "../../components/custom/pagination/pagination";
import { EvilIcons } from "@expo/vector-icons";


export default function AllBrands(props: any) {
  const { item, navigation, ...rest } = props;
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const data = props?.route?.params;
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = React.useState({
    "Records_Filter": "FILTER",
    "Brand_Status": "Active",
    "Search_Brand_Name": "",
    "Top_Brands": data?.top == "true" ? 1 : ""
  });
  const [pageOld, setPageOld] = React.useState(false);
  const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 20, SortBy: "BRAND_ID", SortOrder: "DESC" });

  const callBackGetData = async (status, data, pagination) => {
    goToTop();

    setIsLoading(false);
    if (status) {
      setBrands(data);
    }
    setPageOld(true);
    if (pagination) {
      setPagination(pagination);
    } else {
      setPagination({ PageNo: 1, PageSize: 20, SortBy: "BRAND_ID", SortOrder: "DESC" });
    }
  }

  useEffect(() => {
    if (pageOld) {
      setPageOld(false);
    } else {
      brandsCall();
    }
  }, [pagination])
  const [testFilters, setTestFilters] = useState({});
  useEffect(() => {
    if (JSON.stringify(testFilters) != JSON.stringify(filters)) {
      setTestFilters(filters);
      brandsCall();
    }
  }, [filters]);
  // search and set search is used for the search functionality
  const [loading, setIsLoading] = React.useState(false);
  const brandsCall = () => {
    setIsLoading(true);
    dispatch(getBrandsAction(filters,
      pagination,
      callBackGetData
    ));
  }

  //Returns a function to scroll the page up or down by any amount, in pixels. Supports scrolling.
  // let scroll;
  const goToTop = () => {
    try {
      scroll.scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }
  const [searchBarOpen, setSearchBarOpen] = React.useState(false);

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor}} >
      <Header {...props} />

      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 3 }} >
          <TouchableOpacity style={{ marginLeft: 10, marginTop: 10, flexDirection: "row" }}
            onPress={() => {
              setPageOld(true);
              setPagination({ PageNo: 1, PageSize: 20, SortBy: "BRAND_ID", SortOrder: "DESC" });
              setFilters({
                ...filters,
                Top_Brands: filters.Top_Brands == "" ? 1 : ""
              });
              if (data?.top || data?.top == false || data?.top == true) {
                navigation.setParams({
                  top: filters.Top_Brands == "" ? true : false,
                });
              }
            }}
          >
            <Checkbox
              color={esiColor.SIconColor}
              uncheckedColor={esiColor.SBorderColor}
              status={filters.Top_Brands == 1 ? "checked" : "unchecked"}
            />

            <Text style={{
              marginTop: 10,
              fontWeight: 'bold',
              color: esiColor.Text
            }}>Top Brands</Text>
          </TouchableOpacity>
        </View>
        <View style={{ flex: 5, justifyContent: 'flex-end' }}>

          {searchBarOpen || filters.Search_Brand_Name.length > 0 ?
            <Searchbar style={{
              margin: 10, color: esiColor.DescColor, maxHeight: 35, borderColor: esiColor.SBorderColor, borderWidth: 1,
              maxWidth: 350, backgroundColor: esiColor.BackgroundColor
            }}
              inputStyle={{ fontSize: 12, }}
              value={filters.Search_Brand_Name}
              autoFocus
              onChangeText={(value) => {
                setPageOld(true);
                setPagination({ PageNo: 1, PageSize: 20, SortBy: "BRAND_ID", SortOrder: "DESC" });
                setFilters({
                  ...filters,
                  Search_Brand_Name: value
                });
              }}
              onBlur={() => {
                setSearchBarOpen(false);
              }}
              placeholderTextColor= {esiColor.SPHTextColor}
              multiline={false}
              theme={{ colors: { text: esiColor.DescColor } }}

              placeholder="Search"
              icon={() => <EvilIcons
                name="search"
                size={20}
                color={esiColor.SIconColor}
                style={{ borderColor: esiColor.DescColor }}
              // style={{marginHorizontal:-15}}
              />}
            />
            :
            <EvilIcons
              name="search"
              size={30}

              style={{ alignSelf: "flex-end", marginRight: 5, marginTop: 10, color: esiColor.SIconColor }}
              onPress={() => { setSearchBarOpen(true) }}
            />
          }
        </View>
      </View>

      <ScrollView showsVerticalScrollIndicator={false} ref={(c) => { scroll = c }}
        refreshControl={
          <RefreshControl
            refreshing={loading}
          />
        }
      >
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', margin: 'auto', backgroundColor: esiColor.BackgroundColor }}>
          {
            isEmpty(brands) ? (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 25 }}>
                <Text style={{ color: esiColor.NoDataFound }}>No Brands found!</Text>
              </View>
            ) : (
              brands.map((item, index) => (
                <Fragment key={index}>
                  <EsiBrandCard item={item} navigation={navigation} />
                </Fragment>
              ))
            )
          }
        </View>
        <View style={{ marginBottom: 20 }}>
          <Pagination pagination={pagination} setPagination={setPagination} />
        </View>

      </ScrollView>

    </Surface>
  );
}
