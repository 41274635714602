import React, { useEffect, useState } from "react";
import { View } from "react-native";
import Textarea from 'react-native-textarea';
import { Card, Checkbox, HelperText, Surface, TextInput as TextInputP } from "react-native-paper";

export default function GiftMessageField(props) {

    const [giftValue, setGigtValue] = useState(props.value);
    useEffect(() => {
        setGigtValue(props.value);
    }, [props.value])

    return (
        <View style={{ width: "100%", margin: "auto" }}>
            <TextInputP
                // selectionColor='rgba(0, 0, 0, 0.5)'
                style={{ width: "100%" }}
                label="Gift Message"
                mode='outlined'
                onChangeText={(value) => {
                    setGigtValue(value);
                }}
                value={giftValue}
                onBlur={() => {
                    props.onChangeText(giftValue);
                }
                }
                error={props.error ? giftValue.length >= 15 ? false : true : false}
            />
            <HelperText type="error" visible={props.error ? giftValue.length >= 15 ? false : true : false}>
                {props.errorMessage}
            </HelperText>
        </View>
    )
}