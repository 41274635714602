import React, { useState } from 'react';
import EsiSearchDropdown from '../searchable-dropdown';
import axios from 'axios';
import config from '../../../state/actions/config';
import { StyleSheet } from 'react-native';
// import esiColor from '../../../constants/Colors';
import { useSelector } from 'react-redux';
import { nodeGetApi } from '../../../state/actions/commonAxios';

const EsiState = (props: any) => {
    // props variable consist all react text field properties along with valueUpdate function.
    // valueUpdate function will return onChange value of field.

    // valueUpdate function is a custom property as it dont have in react native textfield properties.
    // CountryCode value isrequired.
    const esiColor = useSelector(state => state.theme);

    const { CountryCode, ...rest } = props
    const [state, setState] = useState([]);
    React.useEffect(() => {
        if (CountryCode) {
            getStates(CountryCode)
        }
    }, [CountryCode])

    const getStates = (CountryCode: string) => {
        nodeGetApi.post(`/Products/country-by-state`, { "CountryCode": CountryCode }, {
            headers: config.nodeUserHeadersCommon
        })
            .then(response => {
                setState(response.data);
            })
            .catch(error => {
            })
    }

    const styles = StyleSheet.create({

        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        iconStyle: {
            width: 20,
            height: 20,
        },
        dropdown: {
            height: 58,
            borderColor: esiColor.SBorderColor,
            borderWidth: 1,
            borderRadius: 5,
            paddingHorizontal: 8,
            activeColor: esiColor.itemColor,
            iconColor: esiColor.itemColor,
            backgroundColor: esiColor.BackgroundColor,
    
            // color: "white"
    
        },
        placeholderStyle: {
            fontSize: 16,
            // backgroundColor: esiColor.BackgroundColor,
            color: esiColor.BackgroundColor
        },
        selectedTextStyle: {
            fontSize: 16,
            color: esiColor.SIconColor
        },
    })

    return (
        <EsiSearchDropdown
            {...props}
            // data param here is used to assign the data from the function values that will be provided.
            data={state}
            style={styles.dropdown}
            // Providing styles for placeholder
            // placeholderStyle={styles.placeholderStyle}
            // Providing styles for selectedText
            // selectedTextStyle={styles.selectedTextStyle}
            // Providing styles for inputSearch
            // inputSearchStyle={styles.inputSearchStyle}
            placeholderStyle={styles.placeholderStyle}
            selectedTextStyle={styles.selectedTextStyle}
            inputSearchStyle={styles.inputSearchStyle}
            // Providing styles for icon
            iconStyle={styles.iconStyle}
            // valueField is used to get the value
            valueField={props.valueField}
            // labelField is used to get the label
            labelField={props.labelField}
            // search is used to get the value from the dropdown
            search={props.search}
            searchPlaceholder={props.searchPlaceholder}
            maxHeight={180}
            dropdownPosition="auto"
        />

    );
};

export default EsiState;
