import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Card, TextInput, Text, Button, HelperText, Surface, TouchableRipple, } from 'react-native-paper';
import { StyleSheet, View, Platform, ScrollView, Dimensions, ActivityIndicator } from 'react-native';
import { editProfileAction } from "../../state/actions/profileActions";
import { UpdateUPIAction } from "../../state/actions/userupiActions";
import moment from "moment";
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import EsiDatePicker from '../../components/custom/date/date';
import PasswordInput from '../../components/custom/PasswordInput';
import { CountryCode } from '../../../utils/countrycode';
import { useToast } from '../../self_modules/react-native-paper-toast/src';


//Gender Type are used to store the data used for dropdowns
const genderType = [
  {
    value: '--Please Select--',
    label: '--Please Select--'
  },
  {
    value: 'Male',
    label: 'Male '
  },
  {
    value: 'Female',
    label: 'Female'
  },

];

const IdproofType = [
  {
    value: '',
    label: '--Please Select--'
  },
  {
    value: 'Aadhaar Card',
    label: 'Aadhaar Card '
  },
  {
    value: 'PAN Card',
    label: 'PAN Card'
  },
  {
    value: 'Driving License',
    label: 'Driving License'
  },

];

const Account = (props) => {
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  //navigation is used for navigation between screens
  const { className, navigation, profiletemp, callgetuser, setScreenType, loading, setLoading, ...rest } = props
  // user contains the data from API's using state call
  const user = (useSelector((state) => state.auth.user));
  const toast = useToast();
  const [profile, setProfile] = React.useState(profiletemp);
  //isSubmitted contains the data from Update API using state call
  const isSubmited = useSelector(state => state.userupi.upiUpdate.isLoading);

  //userupis contains the data from  GET API using state call
  const userupis = useSelector(state => state.userupi.userupis.all);

  //Dropdown
  const [typeDropDown, setTypeDropDown] = useState("");
  const [idproofDropDown, setIdproofDropDown] = useState("");
  //mobile
  const phoneInput = useRef(null);
  //dimensions
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

  const updateStates = (data, status) => {
    setLoading(false);
    if (status) {
      toast.show({ message: 'Profile updated successfully.', type: 'info', duration: 3000, position: 'top' });
      callgetuser();
    }
    else {
      toast.show({ message: data, type: 'info', duration: 3000, position: 'top' });
    }
  };

  const valuesFormik = (profiletemp: any, userupis: any) => {
    return {
      Dob: profiletemp?.Dob ? profiletemp.Dob : "",
      Facebook_Login_Id: profiletemp ? profiletemp.Facebook_Login_Id : "",
      First_Name: profiletemp ? profiletemp.First_Name : "",
      Gender: profiletemp ? profiletemp.Gender : "",
      Google_Login_Id: profiletemp ? profiletemp.Google_Login_Id : "",
      Id_Proof_Number: profiletemp?.Id_Proof_Number ? profiletemp.Id_Proof_Number : "",
      Id_Proof_Type: profiletemp ? profiletemp.Id_Proof_Type : "",
      Last_Name: profiletemp ? profiletemp.Last_Name : "",
      Mobile: profiletemp ? profiletemp.Mobile : "",
      Email_Id: user.Email_Id,
      User_Details_Id: profiletemp ? profiletemp.User_Details_Id : "",
      Paypal_Id: userupis?.Paypal_Id ? userupis?.Paypal_Id : "",
      Upi_Id: userupis?.Upi_Id ? userupis?.Upi_Id : "",
      User_Upi_Id: userupis?.User_Upi_Id ? userupis?.User_Upi_Id : "",
    }
  }

  //Form Validations
  const Form_Validation = Yup.object().shape({
    First_Name: yup
      .string()
      .required("First Name is required."),
    Last_Name: yup
      .string()
      .required("Last Name is required."),
    Mobile: Yup.string()
      .test("phone-required", "Mobile Number is required.", (value: any) => {
        if (value) {
          if (!("+" + phoneInput.current?.getCallingCode() == value)) {
            return true;
          }
        }
        return false;

      })
      .test("phone-validation", "Please provide valid Mobile Number.", (value: any) => {
        let mobileValue = (value || "").split(" ");
        if (mobileValue[0] === "+91") {
          if (/^[0-9]\d*$/.test(mobileValue[1])) {
            if (mobileValue[1]?.length === 10) {
              return true;
            }
          }
        } else {
          if (/^[0-9]\d*$/.test(mobileValue[1])) {
            return true;
          }
        }
        return false
      })
      .required('Mobile Number is required.'),
    Dob: yup.date().max(moment().subtract(18, "years"), "Date Of Birth must be before " + moment().subtract(18, "years").toLocaleString().split(" ").slice(1, -2).join("/") + ".").
      min(moment().subtract(100, "years"), "Date Of Birth must be after " + moment().subtract(100, "years").toLocaleString().split(" ").slice(1, -2).join("/") + ".").required("Date Of Birth is required."),

    Gender: yup.string().required("Gender is required."),
    // Id_Proof_Type: yup.string().required("ID Proof Type is required."),
    Id_Proof_Number: yup.string().when('Id_Proof_Type', (Id_Proof_Type, schema) => {
      if (Id_Proof_Type === "Aadhaar Card") {
        return schema.min(12, "Aadhaar Card Number is not valid.").max(12, "Aadhaar Card Number is not valid.").matches(/^[0-9]*$/, "Aadhaar Card Number is not valid.").required("Aadhaar Card Number is required.");
      } else if (Id_Proof_Type === "PAN Card") {
        return schema.matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "PAN Card Number is not valid.").required("PAN Card Number is required.");
      } else if (Id_Proof_Type === "Driving License") {
        return schema.matches(/^([a-zA-Z]){2}([0-9]){14}$/, "Driving License Number is not valid.").required("Driving License Number is required.");
      }
    }),
    Paypal_Id: yup.string(),
    Upi_Id: Yup.string().matches(/^[\w.-]+@[\w.-]+$/, " UPI ID is not valid."),

  })


  const onSubmit = (values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
    let submitTest = true;
    setSubmitting(false);
    const errors = {};
    setErrors(errors);
    if (submitTest) {
      setLoading(true);
      let formValues1 = JSON.parse(JSON.stringify(values));
      dispatch(editProfileAction(updateStates, formValues1));
      let formValues = JSON.parse(JSON.stringify(values));
      dispatch(UpdateUPIAction(formValues, user.Email_Id));
    }
  }

  // const getCountryCode = (value) => {
  //   let dailCode = "91";
  //   if (value) {
  //     if (value.split('+').length > 0) {
  //       let pSplit = value.split('+');
  //       let count = pSplit.length > 1 ? 1 : 0;
  //       if (pSplit[count].split(' ').length > 0) {
  //         dailCode = value.split('+')[count].split(' ')[0];
  //       }
  //     }
  //   }
  //   return CountryCode.find((item) => item.dial_code == dailCode)?.code;
  // }

  // const getCurrentMobileNumber = (value) => {
  //   let dailNumber = value;
  //   if (value) {
  //     if (value.split(' ').length > 1) {
  //       dailNumber = value.split(' ')[1];
  //     }
  //   }
  //   return dailNumber;
  // }

  const styles = StyleSheet.create({

    itemContainer: {
      justifyContent: 'flex-end',
      flex: 1
    },
    dropdownlabel: {
      position: 'absolute',
      backgroundColor: esiColor.BackgroundColor,
      color: esiColor.SIconColor,
      left: 22,
      top: 10,
      zIndex: 999,
      paddingHorizontal: 8,
      fontSize: 14,

    },
    dropdown: {
      height: 58,
      borderColor: esiColor.SBorderColor,
      borderWidth: 1,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,

    },
    placeholderStyle: {
      fontSize: 16,
      // backgroundColor: esiColor.BackgroundColor,
      color: esiColor.BackgroundColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
      // backgroundColor: esiColor.BackgroundColor
    },
    // cardCircle2: {
    //   width: "95%",
    //   height: 320,
    //   margin: "1%",
    //   borderWidth: 0.5,
    //   marginTop: '5%',
    // },
    // CardCircle: {
    //   width: (window.width >= 500) ? "100%" : "95%",
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    //   //margin:'auto',
    //   marginTop: (window.width >= 500) ? "1%" : "2%",
    //   borderRadius: 6,
    //   borderWidth: 0.1,
    //   borderColor: '#D6D6D7',
    // },

  });

  return (
    // <View>
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>

      <ScrollView>
        {profiletemp &&
          <Formik initialValues={valuesFormik(profiletemp, userupis)} enableReinitialize={true} onSubmit={onSubmit} validationSchema={Form_Validation}>
            {(props) => (
              <View style={{ flexDirection: "column", justifyContent: "flex-start", flexWrap: "wrap" }}>

                <Card style={{
                  width: dimensions <= 500 ? "98%" : "75%", marginLeft: "auto", marginRight: "auto",
                  marginTop: "2%", marginBottom: "2%", borderRadius: 6, borderWidth: 0.01, shadowRadius: 2,
                  backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor
                }}>
                  <Card.Content>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}>
                        Profile
                      </Text>
                      <TouchableRipple
                        onPress={() => setScreenType("password")}
                      >
                        <Text style={{ fontSize: 14, textDecorationLine: 'underline', color: esiColor.itemColor, marginTop: 3 }}>Change Password</Text>
                      </TouchableRipple>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        {/*  First Name Input Field  with Validation */}
                        <TextInput
                          label='First Name'

                          style={{ backgroundColor: esiColor.TextBC }}
                          selectionColor={esiColor.TextSelection}
                          theme={{
                            colors: {
                              primary: esiColor.TextHighlight,
                              text: esiColor.Text,
                              placeholder: esiColor.TextPlaceholder
                            }
                          }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          mode='outlined'
                          name="First_Name"

                          value={props.values.First_Name}
                          onChangeText={props.handleChange('First_Name')}
                          error={Boolean(props.touched.First_Name && props.errors.First_Name)}
                        // maxLength={6}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.First_Name && props.errors.First_Name)}>
                          <ErrorMessage name="First_Name" />
                        </HelperText>
                      </View>
                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        {/*  Last Name Input Field  with Validation */}
                        <TextInput
                          style={{ backgroundColor: esiColor.TextBC }}
                          selectionColor={esiColor.TextSelection}
                          theme={{
                            colors: {
                              primary: esiColor.TextHighlight,
                              text: esiColor.Text,
                              placeholder: esiColor.TextPlaceholder
                            }
                          }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          label='Last Name'
                          mode='outlined'

                          name="Last_Name"
                          value={props.values.Last_Name}
                          onChangeText={props.handleChange('Last_Name')}
                          error={Boolean(props.touched.Last_Name && props.errors.Last_Name)}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.Last_Name && props.errors.Last_Name)}>
                          <ErrorMessage name="Last_Name" />
                        </HelperText>
                      </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 325, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        {/*  Gender Input Field  with Validation */}
                        <Text style={[styles.dropdownlabel, Boolean(props.touched.Gender && props.errors.Gender) && { color: '#b00020' }]}>
                          Gender
                        </Text>
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(props.touched.Gender && props.errors.Gender) && { color: esiColor.itemColor }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          outlineColor={"#27b6cc"}

                          dropdownPosition="bottom"
                          placeholder={!typeDropDown ? '-- Please Select --' : ''}
                          data={genderType}
                          maxHeight={180}
                          labelField="label"
                          valueField="value"
                          value={props.values.Gender}
                          onUpdateValue={item => {
                            props.setFieldValue('Gender', item.value)
                            setTypeDropDown(false);
                          }}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.Gender && props.errors.Gender)}>
                          <ErrorMessage name="Gender" />
                        </HelperText>
                      </View>

                      <View style={{ width: 325, flexShrink: 1, flexGrow: 1, paddingTop: 5 }}>
                        {/*  DOB Input Field  with Validation */}
                        <EsiDatePicker
                          error={Boolean(props.touched.Dob && props.errors.Dob)}
                          style={{ width: "100%", }}
                          name={'Dob'}
                          label='Date Of Birth'
                          value={props.values.Dob}
                          valueUpdate={item => {
                            props.setFieldValue('Dob', item)
                          }}
                        />
                        <HelperText style={{ marginTop: 15 }} type="error" visible={Boolean(props.touched.Dob && props.errors.Dob)}>
                          <ErrorMessage name="Dob" />
                        </HelperText>
                      </View>

                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      <View style={{ flexGrow: 1, width: 230, marginRight: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                        {/*  ID Proof Type Input Field  with Validation */}
                        <Text style={[styles.dropdownlabel, Boolean(props.touched.Id_Proof_Type && props.errors.Id_Proof_Type) && { color: '#b00020' }]}>
                          ID Proof Type
                        </Text>
                        <EsiSearchDropdown
                          style={[styles.dropdown, Boolean(props.touched.Id_Proof_Type && props.errors.Id_Proof_Type) && { borderColor: '#b00020' }]}
                          placeholderStyle={styles.placeholderStyle}
                          selectedTextStyle={styles.selectedTextStyle}
                          inputSearchStyle={styles.inputSearchStyle}
                          iconStyle={styles.iconStyle}
                          dropdownPosition="bottom"
                          placeholder={!idproofDropDown ? '-- Please Select --' : ''}
                          data={IdproofType}
                          maxHeight={180}
                          labelField="label"
                          valueField="value"
                          value={props.values.Id_Proof_Type}
                          onUpdateValue={item => {
                            props.setFieldValue('Id_Proof_Type', item.value);
                            props.setFieldValue('Id_Proof_Number', "");
                            setIdproofDropDown(false);
                          }}
                        />
                        <HelperText type="error" visible={Boolean(props.touched.Id_Proof_Type && props.errors.Id_Proof_Type)}>
                          <ErrorMessage name="Id_Proof_Type" />
                        </HelperText>
                      </View>

                      <View style={{ flexGrow: 1, width: 230, marginLeft: '0.5%', flexShrink: 1, paddingTop: 10 }}>
                        {/*  ID Proof Number Input Field  with Validation */}
                        <PasswordInput
                          style={{ backgroundColor: esiColor.TextBC }}
                          selectionColor={esiColor.TextSelection}
                          theme={{
                            colors: {
                              primary: esiColor.TextHighlight,
                              text: esiColor.Text,
                              placeholder: esiColor.TextPlaceholder
                            }
                          }}
                          outlineColor={esiColor.TextOuterLine}
                          underlineColor={esiColor.TextUnderline}
                          mode='outlined'
                          name="Id_Proof_Number"

                          disabled={props.values.Id_Proof_Type != "" ? false : true}
                          // label={(props.values.Id_Proof_Type === "Aadhaar Card" ? "Aadhaar Card" : props.values.Id_Proof_Type === "PAN Card" ? "PAN Card" : props.values.Id_Proof_Type === "Driving License" ? "Driving License" : "")}
                          label={props.values.Id_Proof_Type ? props.values.Id_Proof_Type + " Number" : "ID Proof Number"}
                          value={props.values.Id_Proof_Number}
                          onChangeText={props.handleChange('Id_Proof_Number')}
                          maxLength={(props.values.Id_Proof_Type === "Aadhaar Card" ? 12 : props.values.Id_Proof_Type === "Driving License" ? 16 : props.values.Id_Proof_Type === "PAN Card" ? 10 : 0)}
                          visible={Boolean(props.touched.Id_Proof_Number && props.errors.Id_Proof_Number)}
                        />
                      </View>
                    </View>
                   
                    <View><Text style={{ fontSize: 14, fontWeight: "bold", color: esiColor.brandFontColor }}>
                      Bank Details:
                    </Text></View>

                    {userupis &&
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                        <View style={{ flexGrow: 1, width: 100, marginLeft: '0.5%', flexShrink: 1, paddingTop: 15 }}>
                          {/*  UPI ID Input Field  with Validation */}
                          <PasswordInput
                            style={{ backgroundColor: esiColor.TextBC }}
                            selectionColor={esiColor.TextSelection}
                            theme={{
                              colors: {
                                primary: esiColor.TextHighlight,
                                text: esiColor.Text,
                                placeholder: esiColor.TextPlaceholder
                              }
                            }}
                            outlineColor={esiColor.TextOuterLine}
                            underlineColor={esiColor.TextUnderline}
                            label='UPI ID'
                            mode='outlined'
                            name="Upi_Id"
                            value={props.values.Upi_Id}
                            onChangeText={props.handleChange('Upi_Id')}
                            error={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}
                            visible={Boolean(props.touched.Upi_Id && props.errors.Upi_Id)}
                          />
                        </View>
                      </View>
                    }

                    <View style={{ width: 130, marginLeft: "auto", marginRight: "auto" }}>
                      {loading ? <ActivityIndicator color={esiColor.SIconColor} /> :
                        <Button style={{ marginVertical: 5, backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={props.handleSubmit}>
                          <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                        </Button>}
                    </View>

                  </Card.Content>
                </Card>
              </View>

            )
            }
          </Formik >
        }
      </ScrollView >
    </Surface>

  );
}

Account.propTypes = {
  profiletemp: PropTypes.object,
  callgetuser: PropTypes.func
};



export default Account;
