import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, ScrollView, Platform, Dimensions, ImageBackground } from 'react-native';
import { Provider, Card, Button, Text, Surface, Modal, ActivityIndicator } from 'react-native-paper';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { getAllContentManagementStaticAction } from '../../state/actions/contentActions';
import Footer from '../footer';


export default function BecomeOurPartner({ navigation }) {
    const dispatch = useDispatch();
    const [contentsOne, setContentsOne] = useState({});
    const responseFunction = (data) => {
        setContentsOne(data)
    }
    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Name": "Become Our Partner",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);

    const [pos, setPos] = React.useState(0);

    return (
        <View style={{ flex: 1 }}>
            <Surface style={{ flex: 1 }}>
                <Header navigation={navigation} />
                <Text style={{ fontSize: 24, marginTop: 15, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>Become Our Partner</Text>

                <ScrollView onScroll={(e) => setPos(e.nativeEvent.contentOffset.y)}>

                    <View style={{
                        flex: 1,
                        marginHorizontal: 2, borderRadius: 25, padding: 5,
                        margin: 'auto', backgroundColor: 'white', flexDirection: 'column',
                        shadowColor: '#000',
                        marginTop: 15,
                        paddingTop: 10,
                        alignSelf: 'center',
                        minWidth: 750,
                        maxWidth: 750
                    }}>
                        <View>
                            {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                                <RenderHTML
                                    source={{ html: contentsOne?.Description }} /> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                    <Text>No Data found!</Text></View>) :
                                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                    <ActivityIndicator animating={true} color='#27B6CC' style={{ marginTop: 25 }} />
                                </View>}
                        </View>
                    </View>
                    <View style={{ margin: "auto", alignContent: "center", justifyContent: "center", marginTop: -100 }}>
                        <ImageBackground source={require('../../assets/images/BecomeOurPartner.png')} resizeMode="contain" style={{ width: 800, height: 800 }} />
                    </View>
                    <View style={{ display: !pos ? "none" : "flex" }}><Footer navigation={navigation} /></View>

                </ScrollView>
            </Surface>
        </View>
    );


}