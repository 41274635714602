import React, { useEffect, useMemo, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl, ActivityIndicator, Alert } from "react-native";
import { Avatar, Button, HelperText, IconButton, Surface, Text, TextInput, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import { useRoute } from "@react-navigation/native";
import { FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import { Formik } from "formik";
import * as Yup from 'yup';
import Modal from "react-native-modal";
import { deleteallFromCart, deleteServiceCartBids, getBiddingservicesCart, placeServiceCartBids, updateServiceCartBids } from "../../../state/actions/boomi-actions/bid-services-action";
import { Dropdown } from "react-native-element-dropdown";
import EsiDatePicker from "../../../components/custom/date/date";
import ESITextInput from "../../../components/custom-fields/textInput";
import EsiTextAreaInput from "../../../components/custom/TextAreaInput/textareainput";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown1";


export default function CartOfServiceBids(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions;
    const esiColor = useSelector(state => state.theme);

    const [loading, setIsLoading] = React.useState(false);
    const [uiMessage, setUIMessage] = React.useState("");
    const [myBids, setMyBids] = React.useState([]);

    const [servicetsubTypes, setServicesubtyps] = React.useState([]);
    const [experyDate, setExperyDate] = useState();
    const [serviceHours, setserviceHours] = useState();
    const [serviceDays, setserviceDays] = useState();
    const [selectserviceType, setSelectserviceTypes] = useState({});

    const dispatch = useDispatch();

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setMyBids(data.Service_Cart);
            setUIMessage(data.UI_Display_Message);
        }
    }

    const getMyCartBids = () => {
        dispatch(getBiddingservicesCart({
            "Bidding_Service_Cart_Id": "",
            User_Email_Id: logedInEmail
        },
            callBackGetData));
        setIsLoading(true);
    }

    const route = useRoute();

    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            getMyCartBids();
        } else {
            navigation.navigate("Home");
        }
    }, [logedInEmail, route]);

    const deleteCartAction = (item) => {
        setIsLoading(true);
        dispatch(deleteServiceCartBids({ Bidding_Service_Cart_Id: item?.Bidding_Service_Cart_Id }, deleteCallbackResponse));

    }

    const placeCartAction = () => {
        setIsLoading(true);
        dispatch(deleteallFromCart({ User_Email_Id: logedInEmail }, deleteall));
    }

    const deleteall = (status: boolean) => {
        if (status) {
            setIsLoading(false);
            getMyCartBids();
            navigation.navigate("BidServices");
        } else {
            setIsLoading(false);
        }
    }
    const deleteCallbackResponse = (status: boolean) => {
        setIsLoading(false);
        if (status) {
            getMyCartBids();
        }
    }

    const editCartAction = (item) => {
        setAddCartItem(item);
        const subtypes = item?.Service_Sub_Types;
        setExperyDate(item?.Bidding_Expiry_Date)
        setserviceDays(item?.Service_Request_Total_Time_In_Days)
        setserviceHours(item?.Service_Request_Total_Time_In_Hours)
        if (subtypes != undefined) {
            const subtypeArray = subtypes.split(",");
            const keyValueArray = [];
            subtypeArray.map((name: String, index: number) => {
                keyValueArray.push({ label: name, value: name })
                if (index == 0) {
                    setSelectserviceTypes({ label: name, value: name })
                }
            })

            setServicesubtyps(keyValueArray)
        }
        else
            setServicesubtyps([])
        setAddCartPop(true);
    }

    const [addCartPop, setAddCartPop] = React.useState(false);
    const [addCartItem, setAddCartItem] = React.useState({});
    const [loadingForm, setloadingForm] = React.useState(false);
    const [formResults, setFormResults] = React.useState(false);
    const formCallbackResponse = (status: boolean) => {
        setloadingForm(false);
        if (status) {
            setFormResults(true);
            getMyCartBids();
        }
    }
    let nDate = new Date();
    nDate.setDate(nDate.getDate() + 2);
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);
    const datediff = (first, second) => {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    const daysAndHoursText = (data: any) => {
        let text = "";
        if (data.Service_Request_Total_Time_In_Days) {
            text = text + "For " + data.Service_Request_Total_Time_In_Days + " Days"
        }
        if (data.Service_Request_Total_Time_In_Hours) {
            text = text + ", " + data.Service_Request_Total_Time_In_Hours + "hours required"
        }
        return text;
    }

    const styles = StyleSheet.create({
        text: {
            fontSize: 14,
            fontFamily: 'system-ui',
            color: esiColor.itemColor
        },
        text_medium: {
            fontSize: 14,
            marginTop: 5,
            marginBottom: 5,
            fontFamily: 'system-ui',
            // fontWeight: "600",
            color: esiColor.itemColor
        }

    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ marginRight: 10, marginLeft: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    <IconButton
                        icon="arrow-left"
                        // size={30}
                        color={esiColor.SIconColor}
                        // style={{ marginVertical: -10, flex: 1, marginLeft: 0 }}
                        onPress={() => {
                            if (navigation.canGoBack())
                                navigation.goBack();
                            else navigation.navigate("BidServices")

                        }} />
                    {/* <View> */}
                    <Text style={{ fontSize: 20, fontWeight: "400", alignSelf: "center", color: esiColor.brandFontColor }}>
                        Services Cart
                    </Text>
                    {/* </View> */}
                </View>

                <ScrollView style={{ height: height - ((height / 100) * (Platform.OS === 'web' ? 20 : 30)) }}

                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={() => {
                                getMyCartBids();
                            }}
                        />
                    }

                >
                    {
                        <View>
                            {myBids && myBids.length > 0 ? (Array.isArray(myBids) ? myBids : []).map((data, index) => (
                                <View key={index} style={{
                                    shadowColor: esiColor.brandShadowColor,
                                    // shadowOffset: { width: -2, height: 4 },
                                    shadowOpacity: 5,
                                    shadowRadius: 7,
                                    marginHorizontal: 2, borderRadius: 10, padding: 2,
                                    backgroundColor: esiColor.CBColor,
                                    marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                    alignSelf: 'center',
                                    elevation: 5,
                                    width: 650,
                                    marginBottom: 15,
                                    // justifyContent: 'space-between',
                                    maxHeight: height > 250 ? 250 : 90,
                                    minHeight: 100,
                                }}>
                                    <View style={{ flex: 3, alignContent: 'center', marginLeft: 10, marginTop: 10, marginBottom: 10 }} >
                                        <Image source={{ cache: "force-cache", uri: data?.Service_Image + `?w=80&h=85` }} style={{ height: 85, width: 80, resizeMode: 'contain' }}></Image>
                                    </View>
                                    <View style={{ flex: 6, flexDirection: 'column', marginTop: 5 }}  >
                                        <Text style={{ fontSize: 16, paddingTop: 5, fontFamily: 'system-ui', color: esiColor.DescColor }}>{data?.Service_Type}</Text>
                                        <Text style={styles.text_medium}>  {daysAndHoursText(data)}</Text>
                                        <Text style={styles.text} numberOfLines={1} ellipsizeMode={"tail"}> {data?.Description}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-around", alignSelf: "center" }}>
                                        <TouchableOpacity style={{ marginBottom: 20 }} onPress={(e) => { editCartAction(data); }} >
                                            <MaterialIcons name="edit" size={22} color={esiColor.SIconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ marginVertical: 5 }} onPress={(e) => {
                                            deleteCartAction(data);
                                        }}
                                        >
                                            <MaterialIcons name="delete" size={22} color="#ee4d2d" />
                                        </TouchableOpacity>


                                    </View>
                                </View>
                            )) :

                                <View style={{ alignItems: "center", marginTop: 10 }}>
                                    {loading ? <ActivityIndicator color={esiColor.esiMainColor} /> :
                                        <View >
                                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, height: 500 }}>
                                                <Image style={{ width: 130, height: 130 }} source={require('../../../assets/images/Cart_empty.png')} />
                                                <Text style={{ fontSize: 30, margin: 5, color: esiColor.NoDataFound }}> Your Cart is Empty.</Text>
                                                <Text style={{ fontSize: 15, fontWeight: 'bold', margin: 5, color: esiColor.Text }}>Add Services to create a bidding service.</Text>
                                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 10, padding: 10, margin: 5 }} onPress={() => navigation.navigate('BidServices')}><Text style={{ fontWeight: 'bold', color: esiColor.itemButtenColor }}>Bid Now</Text></TouchableOpacity>

                                            </View>
                                        </View>
                                    }

                                </View>}

                        </View>
                    }
                    {(myBids && myBids.length > 0) ? <View style={{ minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", marginBottom: 20, }}>
                        <Button style={{ backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={placeCartAction}>
                            <Text style={{ color: esiColor.itemButtenColor }}>Ask Quotation</Text>
                        </Button>

                    </View> : <></>}
                </ScrollView>

                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={addCartPop}>
                    <ScrollView showsHorizontalScrollIndicator={false} style={{ flex: 1, maxWidth: 500, minWidth: 400, minHeight: 550, maxHeight: 560, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                        <Surface style={{
                            justifyContent: 'center',
                            top: Platform.OS === 'web' ? 10 : 20,
                            elevation: 0, backgroundColor: esiColor.BackgroundColor,

                        }} >
                            {/* <View style={{ flexDirection: 'row' }}>
                                <Text style={{
                                    fontSize: 18,
                                    flex: 1,
                                    alignContent: 'center',
                                    marginLeft: 15,
                                    fontWeight: 'bold'
                                }}>{addCartItem.Bidding_Service_Id}</Text> */}
                            <TouchableRipple style={{ width: 50, alignSelf: "flex-end" }} onPress={() => {
                                setAddCartPop((prop) => !prop);
                                setloadingForm(false);
                                setFormResults(false);
                            }}>
                                <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            {/* </View> */}

                            <Formik
                                initialValues={
                                    {
                                        User_Email_Id: logedInEmail,
                                        Bidding_Expiry_Date: addCartItem?.Bidding_Expiry_Date,
                                        Bidding_Service_Cart_Id: addCartItem?.Bidding_Service_Cart_Id,
                                        Description: addCartItem?.Description,
                                        Pincode: addCartItem.Pincode,
                                        Bidding_Service_Start_Date: addCartItem?.Bidding_Service_Start_Date,
                                        Service_Sub_Type: addCartItem?.Selected_Sub_Type,
                                        Service_Request_Total_Time_In_Hour: addCartItem?.Service_Request_Total_Time_In_Hours,
                                        Service_Request_Total_Time_In_Days: addCartItem?.Service_Request_Total_Time_In_Days

                                    }
                                }
                                validationSchema={Yup.object().shape({
                                    Bidding_Expiry_Date: Yup.string().required('Expiry Date of service is required.').test("date-of-bidding", "Service Date should be more then " + dos + ".", (value: any) => {
                                        if (value) {
                                            let date1 = new Date(value).getTime();
                                            let date2 = new Date(dos).getTime();
                                            if (datediff(date2, date1) > -1) {
                                                return true;
                                            }
                                        }
                                        return false;

                                    }),
                                    Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                                    Service_Sub_Type: Yup.string().required("Service Sub Type is required."),

                                    Service_Request_Total_Time_In_Days: Yup.string().when('Service_Request_Total_Time_In_Hour', (Hour, schema) => {
                                        if (Hour > 0) {
                                            return schema;
                                        } else {
                                            return schema.required('Required Days/Hours is required.');
                                        }
                                    }),
                                    Bidding_Service_Start_Date: Yup.string().required('Start Date of service is required.').test("service start date", "start Date should be more then expiry date", (value: any) => {

                                        if (value && experyDate) {
                                            let date1 = new Date(value).getTime();
                                            let date2 = new Date(experyDate).getTime();
                                            if (date1 > date2) {
                                                return true;
                                            }
                                        }
                                        return false;
                                    })
                                })}
                                onSubmit={values => {

                                    setloadingForm(true);
                                    dispatch(updateServiceCartBids(values, formCallbackResponse));
                                }}
                            >
                                {({ handleSubmit, setFieldValue, handleBlur, handleChange, values, errors, touched }) => (
                                    <View style={{
                                        flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                    }}>
                                        {loadingForm ?
                                            <ActivityIndicator color={esiColor.esiMainColor} />
                                            :
                                            formResults ?
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                                    <Image source={require('../../../assets/images/GIF/cart-success.gif')}
                                                        style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <Text style={{ textAlign: "center", fontWeight: "bold", color: esiColor.itemColor }} >
                                                            {"Successfully  updated to cart."}
                                                        </Text>
                                                    </View>
                                                </View>
                                                :
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: 10, justifyContent: "center", alignItems: 'center' }}>
                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Requried days*"
                                                            mode='outlined'
                                                            onChangeText={(e) => {
                                                                let value = e.replace(/\D/g, '')
                                                                setFieldValue("Service_Request_Total_Time_In_Days", value)
                                                            }}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Service_Request_Total_Time_In_Days}
                                                            error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}>
                                                            {touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Requried Hours*"
                                                            mode='outlined'
                                                            onChangeText={(e) => {
                                                                let value = e.replace(/\D/g, '')
                                                                setFieldValue("Service_Request_Total_Time_In_Hour", value)
                                                            }}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Service_Request_Total_Time_In_Hour}
                                                            error={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days)}>
                                                            {touched.Service_Request_Total_Time_In_Days && errors.Service_Request_Total_Time_In_Days}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                                                        <EsiAutocompleteDropdown

                                                            key={"size" + values.Service_Sub_Type}
                                                            loading={false}
                                                            data={(addCartItem?.Service_Sub_Types)?.split(",").map((type) => ({ id: type, title: type }))}
                                                            label={"Select sub type"}
                                                            onSelect={(item) => {
                                                                if (item?.id)
                                                                    setFieldValue("Service_Sub_Type", item.id)
                                                            }}
                                                            onClear={() => {
                                                            }}
                                                            getSuggestions={(q) => {
                                                                // apiCall(q);
                                                            }}
                                                            selected={{ id: values.Service_Sub_Type, title: values.Service_Sub_Type }}

                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Service_Sub_Type && errors.Service_Sub_Type)}>
                                                            {touched.Service_Sub_Type && errors.Service_Sub_Type}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                                                        <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Description:</Text>
                                                        <EsiTextAreaInput
                                                            error={Boolean(touched.Description && errors.Description)}
                                                            containerStyle={{ height: 170 }}
                                                            style={{ height: 170 }}
                                                            mode='outlined'
                                                            value={values.Description}
                                                            textareavalueUpdate={value => {
                                                                setFieldValue("Description", value)
                                                            }}
                                                            placeholder={'Please provide additional information'}
                                                            underlineColorAndroid={'transparent'}
                                                            theme={{
                                                                colors: {
                                                                    primary: "#27b6cc"
                                                                },
                                                            }}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Description && errors.Description)}>
                                                            {touched.Description && errors.Description}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <EsiDatePicker style={{ margin: "auto" }}
                                                            name={'Bidding_Expiry_Date'}
                                                            label='Bidding Expiry Date'
                                                            value={values.Bidding_Expiry_Date}
                                                            variant=''
                                                            valueUpdate={item => {
                                                                setExperyDate(item)
                                                                setFieldValue("Bidding_Expiry_Date", item);
                                                            }}
                                                            error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                                                            {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <EsiDatePicker style={{ margin: "auto" }}
                                                            name={'Bidding_Service_Start_Date'}
                                                            label='Bidding Service Start Date'
                                                            value={values.Bidding_Service_Start_Date}
                                                            variant='standard'
                                                            valueUpdate={item => {
                                                                setFieldValue("Bidding_Service_Start_Date", item);
                                                            }}
                                                            error={Boolean(touched.Bidding_Service_Start_Date && errors.Bidding_Service_Start_Date)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Bidding_Service_Start_Date && errors.Bidding_Service_Start_Date)}>
                                                            {touched.Bidding_Service_Start_Date && errors.Bidding_Service_Start_Date}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Pincode*"
                                                            mode='outlined'
                                                            onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Pincode}
                                                            error={Boolean(touched.Pincode && errors.Pincode)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                            {touched.Pincode && errors.Pincode}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                        <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {
                                                            handleSubmit()
                                                        }}>
                                                            <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                                                        </Button>
                                                    </View>
                                                </View>}
                                    </View>)}
                            </Formik>
                        </Surface>

                    </ScrollView>
                </Modal>
            </View>
        </Surface>
    )
}


