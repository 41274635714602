import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View, ScrollView, RefreshControl, FlatList, Dimensions, Platform, ActivityIndicator } from "react-native";
import { Button, IconButton, Menu, Searchbar, Surface, Text } from "react-native-paper";

import { useDispatch } from "react-redux";

import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import ProductCard from "../../components/custom/product-card";
import Pagination from "../../components/custom/pagination/pagination";
import { Drawer } from 'react-native-drawer-layout';
import { getProductsAction } from "../../state/actions/node-actions/home-actions";
import EsiDropdown from "../../components/custom/EsiDropDown";
import { PartnerFilters } from "./DropDownFilters/PartnerFilters";
import { BrandFilters } from "./DropDownFilters/BrandFilters";
import { CategoryFilters } from "./DropDownFilters/CategoryFilters";
import { ExtraCashBackFilter } from "./DropDownFilters/ExtraCashBackFilter";
import { PriceFilter } from "./DropDownFilters/PriceFilter";
import { DiscountFilters } from "./DropDownFilters/DiscountFilters";
import { PopularFilters } from "./DropDownFilters/PopularFilters";


export default function NewProducts(props) {
    const [open, setOpen] = React.useState(false);
    const { navigation } = props;
    const dispatch = useDispatch();
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);
    const [pageOld, setPageOld] = React.useState(false);
    const callBackGetData = async (status, data, pagination) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            setPageOld(true);
            if (pagination) {
                setPagination(pagination);
            } else {
                setPagination({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
            }
        }
    }

    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });

    const [testFill, setTestFill] = React.useState({});
    const [wishdata, setWishData] = React.useState(false);

    const [search, setSearch] = React.useState("");
    const [openBusinessType, setOpenBusinessType] = React.useState(false);
    const [businessType, setBusinessType] = React.useState("");
    const data = props?.route?.params;

    useEffect(() => {
        if (data?.ptype) {
            setBusinessType(data?.ptype)
        }
        else {
            setBusinessType("")
        }
        if (data?.scid) {
            setFilters(props => ({ ...props, subCategoryId: data?.scid }))
        }
        setSearch("");
    }, [data])

    let sampleFilter = {
        partnerId: "P-D-0000201",
        brandId: "B-0000868",
        categoryId: "C-0000319",
        subCategoryId: "C-S-0000228"
    }
    const [filters, setFilters] = useState({
        extra: false,
        price: "",
        discount: "",
        popular: []
    });

    useEffect(() => {
        let formData = {
            Records_Filter: "FILTER",
            Status: "Active",
            condition: true
        }
        if (!isEmpty(filters?.brandId)) {
            formData.Brand_Id = [filters?.brandId]
        }
        if (!isEmpty(filters?.partnerId)) {
            formData.Partner_Details_Id = [filters?.partnerId]
        }

        if (!isEmpty(filters?.categoryId)) {
            formData.Category_Id = [filters?.categoryId]
        }
        if (!isEmpty(filters?.subCategoryId)) {
            formData.Sub_Category_Id = [filters?.subCategoryId]
        }

        if (filters.popular.indexOf("Is_Top_Product") >= 0) {
            formData.Is_Top_Product = '1';
        }
        if (filters.popular.indexOf("Is_Popular") >= 0) {
            formData.Is_Popular = '1';
        }
        if (filters.popular.indexOf("Is_Verified") >= 0) {
            formData.Is_Verified = '1';
        }
        if (filters.popular.indexOf("Whats_Hot") >= 0) {
            formData.Whats_Hot = '1';
        }
        if (filters.popular.indexOf("Is_Upcoming") >= 0) {
            formData.Is_Upcoming = '1';
        }

        const discountFilter = filters.discount.split("-");
        if (discountFilter.length == 2) {
            formData.Min_Discount = discountFilter[0];
            formData.Max_Discount = discountFilter[1];
        }

        const priceFilter = filters.price.split("-");
        if (priceFilter.length >= 1) {
            if (priceFilter[0])
                formData.Min_Price = priceFilter[0]
        }
        if (priceFilter.length == 2) {
            formData.Max_Price = priceFilter[1]
        }

        if (filters.extra) {
            formData.Extra_Cashback = '1';
        }

        formData.Search = search;
        formData.Business_Type = businessType;
        if (!(JSON.stringify(testFill) === JSON.stringify(formData))) {
            setTestFill(formData);
            setPageOld(false);
            setLoading(true);
            dispatch(getProductsAction(formData, pagination, callBackGetData));
        }

    }, [search, businessType, filters]);
    const scroll = useRef(null);
    const goToTop = () => {
        scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
    }
    const handleClick = async (item) => {
        navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata })
    };

    const productShow = useMemo(() => {
        let numColumCount = (dimensions.width / 250).toFixed() == 1 ? 2 : (dimensions.width / 250).toFixed();

        return <FlatList
            // horizontal={true}
            key={dimensions.width}
            columnWrapperStyle={{ justifyContent: "space-around" }}
            numColumns={numColumCount}
            data={allproducts}
            renderItem={({ item, index, separators }) => (
                <ProductCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />
            )}
        />
    }, [allproducts, dimensions])

    const drawerContent = useCallback(() => {
        return (
            <ScrollView
                showsHorizontalScrollIndicator={false}
                style={{ marginVertical: 10 }} >
                <View >
                    <PartnerFilters filters={filters} setFilters={setFilters} />
                </View>
                <View style={{ marginTop: 5 }} >
                    <BrandFilters filters={filters} setFilters={setFilters} />
                </View>
                <View style={{ marginTop: 5 }} >
                    <CategoryFilters filters={filters} setFilters={setFilters} />
                </View>
                <View style={{ marginTop: 5 }} >
                    <PopularFilters filters={filters} setFilters={setFilters} />
                </View>
                <View style={{ marginTop: 5 }} >
                    <ExtraCashBackFilter filters={filters} setFilters={setFilters} />
                </View>
                <View style={{ marginTop: 5 }} >
                    <DiscountFilters filters={filters} setFilters={setFilters} />
                </View>
                <View style={{ marginTop: 5 }} >
                    <PriceFilter filters={filters} setFilters={setFilters} />
                </View>
            </ScrollView >
        )
    }, [open, filters])


    return (
        <Surface style={{ flex: 1 }}>
            <Header {...props} />

            <Drawer
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                drawerStyle={{}}
                renderDrawerContent={drawerContent}
                drawerPosition={"right"}
            // drawerType={"slide"}
            >
                <View>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        {navigation.canGoBack() &&
                            <IconButton
                                icon="arrow-left"
                                size={27}
                                color={"#27B6CC"}
                                style={{ marginLeft: 1 }}
                                onPress={() => {
                                    navigation.goBack()
                                }} />
                        }
                        <Searchbar style={{ marginHorizontal: 10, borderColor: "#CACFD2", minWidth: "40%", maxWidth: "40%", borderWidth: 0.5, maxHeight: 30, marginTop: 10 }}
                            inputStyle={{ fontSize: 12, marginLeft: -20 }}
                            value={search}
                            onChangeText={(value) => {
                                setSearch(value);
                            }}
                            placeholderTextColor='black'
                            multiline={false}
                            placeholder="Search"
                        />
                        {Platform.OS == "web" ? <EsiDropdown label="All" data={[{ label: "All", value: "" }, { label: "FOOD", value: "FOOD" }, { label: "GROCERY", value: "GROCERY" }, { label: "RETAIL", value: "RETAIL" }]}
                            onSelect={(item) => {
                                setBusinessType(item.value);
                            }}
                            selected={businessType}
                        /> :
                            <Menu
                                visible={openBusinessType}
                                onDismiss={() => setOpenBusinessType(!openBusinessType)}
                                anchor={<Button mode="text" style={{ marginTop: 10 }} labelStyle={{ fontSize: 12, textTransform: "capitalize", fontWeight: "bold" }} icon={"store-edit-outline"} color="#27b6cc" onPress={() => setOpenBusinessType(!openBusinessType)}>{businessType == "" ? "All" : businessType.charAt(0).toUpperCase() + businessType.substring(1).toLowerCase()}</Button>}
                            >
                                <Menu.Item onPress={() => {
                                    setOpenBusinessType(!openBusinessType);
                                    setBusinessType("");
                                }} title="All" />

                                <Menu.Item onPress={() => {
                                    setBusinessType("FOOD");
                                    setOpenBusinessType(!openBusinessType);
                                }} title={"Food"} />
                                <Menu.Item onPress={() => {
                                    setBusinessType("GROCERY");
                                    setOpenBusinessType(!openBusinessType);
                                }} title={"Grocery"} />
                                <Menu.Item onPress={() => {
                                    setBusinessType("RETAIL");
                                    setOpenBusinessType(!openBusinessType);
                                }} title={"Retail"} />

                            </Menu>
                        }

                        <IconButton
                            color={'#27b6cc'}
                            icon="filter"
                            size={24}
                            style={{ marginTop: -45, alignSelf: 'flex-end' }}
                            onPress={() => setOpen((prevOpen) => !prevOpen)}
                        />

                    </View>

                </View>
                <ScrollView ref={scroll}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                        />
                    }
                >
                    {
                        <View style={{ alignItems: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'space-around' }}>
                            {isEmpty(allproducts) ? (loading ? <ActivityIndicator /> :
                                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                                    <Text>No Products found!</Text>
                                </View>
                            ) : (
                                productShow
                            )
                            }
                        </View>}
                    <View style={{ marginBottom: 5 }}>
                        <Pagination pagination={pagination} setPagination={setPagination} />
                    </View>
                </ScrollView>
            </Drawer>
        </Surface>
    )
}