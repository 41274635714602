import React, { useEffect, useState } from "react";
import { ActivityIndicator, Card, Surface, Text, IconButton } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import { Image, StyleSheet, View, ScrollView, Platform, Dimensions, ImageBackground } from "react-native";
import { getFilterCategoriesAction } from "../../state/actions/categoriesActions";
// import isEmpty from "../../state/validations/is-empty";
import Header from "../header";


export default function AllCategory(props) {
  // api using redux
  const { navigation } = props;
  const dispatch = useDispatch();
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);

  const halfdimentions = dimensions / 6;
  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const [mainCategory, setMainCategory] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState("");

  const [loading, setLoading] = useState(false);

  const categeyCallback = (data) => {
    setLoading(false);
    if (!(data?.Is_Data_Exist === "0")) {
      setMainCategory(data.Category || []);
    } else {
      setMainCategory([]);
    }
  }
  let type = props?.route?.params?.type
  useEffect(() => {

    onRefresh();

  }, [props?.route?.params?.Store_Type_Name]);

  const onRefresh = () => {
    let formData = {};
    if (type == "store") {
      formData = {
        "Store_Type_Name": props?.route?.params?.Store_Type_Name,
        "Records_Filter": "FILTER"
      }
    } else {
      formData = {
        "Records_Filter": "ALL"
      }
    }
    setLoading(true);
    dispatch(getFilterCategoriesAction(formData, categeyCallback));
  };

  const styles = StyleSheet.create({
    Card: {
      flex: 1,
      borderRadius: 10,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      position: 'relative',
      backgroundColor: esiColor.BackgroundColor
    },
  });


  return (
    <Surface style={{
      flex: 1, position: Platform.OS === 'web' ? 'absolute' : null,
      top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null,
      right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null, backgroundColor: esiColor.BackgroundColor
    }}>
      <Header {...props} />
      <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'center', marginTop: 15, marginBottom: 15 }}>
        <Text style={{ fontSize: 16, fontWeight: "bold", alignSelf: 'flex-start', color: esiColor.brandFontColor, marginLeft: 50 }}>All Categories</Text>
      </View>
      <IconButton
        icon="arrow-left"
        size={25}
        color={esiColor.SIconColor}
        style={{ marginTop: -42 }}
        onPress={() => navigation.navigate('Home')}
      />

      <ScrollView showsVerticalScrollIndicator={false}>

        {loading ?
          <ActivityIndicator color={esiColor.SIconColor} animating={loading} style={{ marginTop: 20 }} />
          :
          <View style={styles.Card}>
            {mainCategory.length === 0 ?
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Text style={{ color: esiColor.NoDataFound }}>No categories available to display</Text>
              </View> :
              mainCategory.map((item, index) => {
                return (
                  <View key={index} style={{ padding: 3, marginLeft: 5, marginRight: 5, marginBottom: 50, maxWidth: (halfdimentions), width: (halfdimentions) }}>
                    <Card style={{
                      height: 150, maxHeight: 150, margin: 1,
                      shadowColor: esiColor.brandShadowColor, shadowRadius: 7,
                    }} onPress={(e) => navigation.navigate('SubCategory', { category_id: item?.Category_Id })} >
                      <View>
                        <ImageBackground style={{ height: 150, maxHeight: 150, backgroundColor: esiColor.ImgBck }}>

                          <Image source={{ uri: item?.Category_Image_Mobile + `?h=125` }}
                            style={{
                              height: 125, maxHeight: 125,
                              shadowColor: esiColor.brandShadowColor, shadowRadius: 10, marginTop: 10, marginLeft: 10, marginRight: 10
                            }}
                          />
                        </ImageBackground>
                      </View>
                      <Text style={{ margin: 2, flex: 1, color: esiColor.itemColor, fontSize: 14, fontWeight: 'bold', textAlign: 'center', paddingVertical: 4, marginTop: 10, marginBottom: 10 }}>{item?.Category_Name}</Text>
                    </Card>
                  </View>
                )
              })
            }
          </View>
        }
      </ScrollView>

    </Surface>
  );
}
