import { combineReducers } from "redux";
import addressReducer from "./addressReducer";
import authReducer from "./authReducer";
import brandsReducer from "./brandsReducer";
import categoriesReducer from "./categoriesReducer";
import contentReducer from "./contentReducer";
import couponsReducer from "./couponsReducer";
import dealsReducer from "./dealReducer";
import faqReducer from "./faqReducer";
import filterServiceReducer from "./filterServiceReducer";
import newseventsReducer from "./newsandeventsReducer";
import partnersReducer from "./partnerReducer";
import productsReducer from "./productsReducer";
import referReducer from "./referReducer";
import bannersReducer from "./bannerReducer";
import cashbackReducer from "./missingcashbackReducer";
import userupiReducer from "./userupiReducer";
import walletReducer from "./walletReducer";
import shopbycityReducer from "./shopbycityReducer";
import giftReducer from "./giftReducer";
import addCartReducer from "./addCartReducer";
import reviewsandratingsReducer from "./reviewsandratingsReducer";
import shopbycityFilterReducer from "./shopbycityFilterReducer";
import globalSearchReducer from "./globalSearchReducer";
import dashboardReducer from "./dashboardReducer";
import transactionReducer from "./transactionReducer";
import ordersReducer from "./orderReducer";
import orderdetailsReducer from "./orderDetailsReducers";
import searchGlobal from "./searchGlobal";
import ticketReducer from "./ticketReducer";
import financialTransactionReducer from "./financialTransactionReducer";

import { persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import pushnotificationReducer from "./pushnotificationReducer";
import storecategoriesReducer from "./storetypecategoriesReducer";
import ridesReducer from "./rideReducer";
import serviceReducer from "./servicesReducer";
import eventsReducer from "./eventReducer";
import themeReducer from "./themeReducer";
import farmersReducer from "./farmersReducer";
const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['auth']
};


const reducers = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  allCoupons: couponsReducer,
  allCategories: categoriesReducer,
  allPartners: partnersReducer,
  allDeals: dealsReducer,
  allBrands: brandsReducer,
  allProducts: productsReducer,
  refer: referReducer,
  filters: filterServiceReducer,
  cityFilters: shopbycityFilterReducer,
  address: addressReducer,
  allFAQs: faqReducer,
  allNewsEvents: newseventsReducer,
  allContents: contentReducer,
  allBanners: bannersReducer,
  allCashbacks: cashbackReducer,
  userupi: userupiReducer,
  wallet: walletReducer,
  allCities: shopbycityReducer,
  BuyGift: giftReducer,
  reviewandrating: reviewsandratingsReducer,
  cart: addCartReducer,
  recentSearch: globalSearchReducer,
  dashboard: dashboardReducer,
  alltransactions: transactionReducer,
  allOrders: ordersReducer,
  orderDetail: orderdetailsReducer,
  gSearch: searchGlobal,
  ticket: ticketReducer,
  pushnotifications: pushnotificationReducer,
  userfinancialTransactions: financialTransactionReducer,
  storecategories: storecategoriesReducer,
  rides: ridesReducer,
  services: serviceReducer,
  events:eventsReducer,
  theme:themeReducer,
  allFarmers: farmersReducer


})

export default reducers;
export type RootState = ReturnType<typeof reducers>