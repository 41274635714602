import React, { Fragment, useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Surface, Text, } from 'react-native-paper';
import { View, TouchableOpacity, Platform, Image, StyleSheet, ScrollView, FlatList, ImageBackground } from 'react-native';
import { getFarmersAction } from "../../state/actions/farmerActions";
import isEmpty from "../../state/validations/is-empty";


export default function MeeFarmers(props) {
    const { navigation, dataReload, dataErrorReload, ...rest } = props
    const farmers = useSelector(state => state.allFarmers.farmers.all);
    //API dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFarmersAction());
    }, []);

    const handleClick = async (data) => {
        navigation('FarmerDetails', { Partner_Details_Id: data.Partner_Details_Id })
    }

    const esiColor = useSelector(state => state.theme);

    const styles = StyleSheet.create({
        content: {
            shadowColor: esiColor.brandShadowColor,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            // elevation: 10,
            borderRadius: 10,
            height: 200,
            alignSelf: 'center',
            alignItems: 'center',
            alignContent: 'center',
            marginBottom: 30,
            marginLeft: 15,
            marginRight: 15
        },
        item: {
            borderRadius: 5,
            marginRight: 10,
            padding: 5,
            // flex: 1,
            maxHeight: 100,
            minHeight: 100,
            borderWidth: 1,
            borderColor: esiColor.BorderColor,
        }

    });

    return (farmers?.length > 0 &&
        <View style={styles.content}>
            <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', marginLeft: 2, marginTop: 10 }}> Farm Finds</Text>
            </View>

            {isEmpty(farmers) ? (
                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                    <Text style={{ color: esiColor.NoDataFound }}>No farmers found!</Text>
                </View>
            ) : (<FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={farmers.slice(0, 10)}
                renderItem={({ item, index, separators }) => (
                    <View key={index} style={{ marginTop: 20, margin: 10 }}>

                        <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                            <ImageBackground style={{ backgroundColor: esiColor.ImgBck, height: 80, width: 100 }}>
                                <Image source={{ cache: "force-cache", uri: item?.Logo_Path + `?w=100&h=75` }}
                                    style={{ height: 75, flex: 1, alignSelf: "center", resizeMode: 'contain', width: 100 }}></Image>
                            </ImageBackground>
                        </TouchableOpacity>

                        <View style={{ alignItems: 'stretch', marginTop: 10, paddingLeft: 10, height: 40 }}>
                            <Text style={{ textAlign: 'center', color: esiColor.itemColor, fontSize: 14 }}>{item?.Name.slice(0, 15)}...</Text>
                        </View>
                    </View>
                )}
            />
            )
            }
            <TouchableOpacity onPress={() => navigation('Farmers')} style={{ position: 'absolute', right: 10, alignSelf: 'flex-end' }}>
                <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold', marginTop: 10 }}>View All</Text>
            </TouchableOpacity>
        </View>

    )
}
