import { initializeApp } from "firebase/app";
import { getMessaging,onMessage,getToken , onBackgroundMessage} from "firebase/messaging";
import * as Sentry from 'sentry-expo';
const firebaseConfig = {
  apiKey: "AIzaSyDN5iBPnmDX_dOifNcerIrO17mKXRcTUA4",
    authDomain: "meepaisa-fff60.firebaseapp.com",
    projectId: "meepaisa-fff60",
    storageBucket: "meepaisa-fff60.appspot.com",
    messagingSenderId: "267435852832",
    appId: "1:267435852832:web:364e17c0c18a440de46845",
    measurementId: "G-Y69JDCJGEL"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

let pushToken;
export const getToken_firebase = async (setTokenFound) => {
    try {
    const currentToken = await getToken(messaging, { vapidKey: 'BHC3kZr8sYmGhR38BIdDwaQJ1ZhsdHd072AqrYLQ8Wsxlp7lTFSVBvuBjgyMoUOtUpRh5TFTT7kpECdIrU4aL1I' });
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      // Sentry.captureMessage(currentToken,"info")
      setTokenFound(true);
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});