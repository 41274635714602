import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, ScrollView, Platform, Dimensions } from 'react-native';
import { Provider, Card, Button, Text, Surface, Modal, ActivityIndicator } from 'react-native-paper';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { getAllContentManagementStaticAction } from '../../state/actions/contentActions';
import Footer from '../footer';
import esiColor from '../../constants/Colors';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function AllContent(props) {
    const { navigation } = props;
    // const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const dispatch = useDispatch();
    const [contentsOne, setContentsOne] = useState({});
    const responseFunction = (data) => {
        setContentsOne(data)
    }
    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Name": "Privacy&Cookie Policy",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);
    const [pos, setPos] = React.useState(0);

    return (
        <Surface style={{ flex: 1, backgroundColor: '#fff' }}>
            <Header {...props} />
            <Text style={{ fontSize: 24, marginTop: 5, fontWeight: "bold", textAlign: 'center', color: esiColor.brandFontColor, justifyContent: 'center', }}>Privacy Policy</Text>
            <ScrollView onScroll={(e) => setPos(e.nativeEvent.contentOffset.y)}>

                <View style={{
                    flex: 1,
                    marginHorizontal: 2, padding: 5, margin: 'auto',
                    flexDirection: 'column',
                    marginTop: 10,
                    marginBottom: 20,
                    paddingTop: 10,
                    minWidth: 800,
                    maxWidth: 800,
                    alignSelf: 'center'

                }}>
                    {/* <View> */}
                    {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                        <RenderHTML
                            // tagsStyles={{
                            //     span: { color: esiColor.DescColor }
                            // }}
                            source={{ html: contentsOne?.Description }} /> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                            <Text style={{ color: "#fff" }}> No Data found!</Text></View>) :
                        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                            <ActivityIndicator color='#27b6cc' animating={true} style={{ marginTop: 25 }} />
                        </View>}
                    {/* </View> */}
                </View>
                <View style={{ display: !pos ? "none" : "flex" }}><Footer navigation={navigation} /></View>

            </ScrollView>
        </Surface>
    );


}
