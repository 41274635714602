import React, { useState, useEffect } from "react";
import { Card, Text } from "react-native-paper";
import { Image, TouchableOpacity, View, Dimensions, ImageBackground, ActivityIndicator } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../state/validations/is-empty";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { getSingleCartItemCheck } from "../../state/actions/node-actions/home-actions";
import QuentityField from "./QuentityField";
import { addItemMultipleCart } from "../../state/actions/cartActionsNew";

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
export default function PartnerProductCard(props: any) {
    // handle click event is used to go to next page with IDs
    // data is a Product object as we use this object to display the info front-end
    const { data, onLikeClicked, wishPartners, sizes, PartnerDetails, login, cartAddedSuccess, setCartAddedSuccess, ...rest } = props;
    const dispatch = useDispatch();
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    useEffect(() => {
        checkCart();
    }, [EmailID, data])
    useEffect(() => {
        checkCart();
    }, [cartAddedSuccess])

    const [cart, setCart] = useState({ User_Cart_Id: 0, Quantity: 0 });

    const checkCartResponse = (status, data) => {
        if (status) {
            setCart(data);
        }
    }

    const checkCartUpdateResponse = (data) => {
        checkCart();
    }

    const checkCart = () => {
        if (EmailID && data?.Partner_Product_Id) {
            let filter = {
                "Partner_Product_ID": data?.Partner_Product_Id,
                "User_Email_Id": EmailID,
            }
            dispatch(getSingleCartItemCheck(filter, {}, checkCartResponse))
        }
    }

    const toast = useToast();
    const esiColor = useSelector(state => state.theme);


    return (
        <Card style={{
            margin: 5, borderRadius: 2, width: 175, paddingVertical: 5, borderColor: "#CACFD2", borderWidth: 0.5,
            backgroundColor: esiColor.CBColor,
            shadowColor: esiColor.brandShadowColor, shadowRadius: 5,
        }}>
            <View style={{ flexDirection: "row" }} >
                <TouchableWithoutFeedback style={{ flex: 2 }} onPress={() => {
                    onLikeClicked(wishPartners.filter((text) => text === data?.Partner_Details_Id).length > 0 ? true : false, data?.Partner_Details_Id)
                }}>
                    <FontAwesome name={wishPartners.filter((text) => text === data?.Partner_Details_Id).length > 0 ? 'heart' : 'heart-o'} style={{ marginLeft: 5 }} color='red' size={20}></FontAwesome>

                </TouchableWithoutFeedback>
                <View style={{ flexDirection: 'row', flex: 2 }}>
                    <View style={{ flexDirection: 'column', flex: 1 }}>
                        {data?.Partner_Type != "Online" &&
                            <Text style={{ padding: 2, fontSize: 10, color: esiColor.DescColor, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, marginBottom: 8 }}>{data.Is_Stock_Available == "1" && data.Is_Product_Available == "1" && data.Is_Open == "1" && data.Partner_Type === "Physical" ? <Text style={{ color: "green" }}>In Stock</Text> : <Text style={{ color: "red" }}> {data.Is_Open == "1" ? "Out of Stock" : "Store Closed"}</Text>}</Text>
                        }
                    </View>
                    <View style={{ flexDirection: 'column', flex: 1 }}>
                        <Text style={{ backgroundColor: esiColor.globalButtonColor, padding: 2, fontSize: 14, color: esiColor.itemButtenColor, borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>{data?.Partner_Discount_Type == 'A' && data?.Partner_Discount + "/-"}{(data?.Partner_Discount_Type == 'P' || data?.Partner_Discount_Type == 'percentage' || data?.Partner_Discount_Type == 'Percentage') && data?.Partner_Discount + "%"} Off</Text>
                    </View>
                </View>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 2 }}>
                <ImageBackground style={{ height: 100, width: "100%", backgroundColor: esiColor.ImgBck }}>

                    <Image source={{ cache: "force-cache", uri: data?.Logo_Path }} style={{ height: 70, flex: 1, resizeMode: 'contain', width: "100%" }} />
                </ImageBackground>
            </View>

            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Text style={{ paddingVertical: 3, color: esiColor.itemColor, fontSize: 15, fontWeight: 'bold' }}> {data?.Partner_Name} </Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Image source={(data?.Esi_Cashback_Type != 'R') ? require("../../assets/images/cashback.gif") : require("../../assets/images/cashback.gif")} style={{ height: 30, width: "40%", resizeMode: 'contain' }} />
                <Text style={{ color: esiColor.itemColor, fontWeight: "bold", marginTop: 5 }} >  {data?.Cashback_Label?.toLowerCase()} {data?.Esi_Cashback}{(data?.Esi_Cashback_Type == 'P' || data?.Esi_Cashback_Type == 'percentage' || data?.Esi_Cashback_Type == 'Percentage') ? '%' : (data?.Esi_Cashback_Type != 'R') ? '/-' : "*"}</Text>
            </View>
            <View style={{ width: '100%', marginTop: 2, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <Text style={{ paddingVertical: 3, color: esiColor.itemColor, fontSize: 14, fontWeight: 'bold' }}>₹{parseFloat(data?.Partner_Selling_Price)?.toFixed(2)}     <Text style={{ fontSize: 14, textDecorationLine: 'line-through', color: esiColor.amountStrikeColor }}>₹{(data?.Product_Price)}</Text>
                </Text>
            </View>

            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                {cart.User_Cart_Id ?
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity disabled={cart.Quantity <= 1 ? true : false} onPress={(e) => dispatch(addItemMultipleCart({
                            User_Cart_Id: cart.User_Cart_Id,
                            partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                        }, Number(cart.Quantity) - 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: '#cccccc' }}>
                            <MaterialIcons name="remove" size={22} color="#cccccc" />
                        </TouchableOpacity>
                        {
                            <QuentityField count={cart.Quantity} handleOnChangeCount={(count) => {
                                dispatch(addItemMultipleCart({
                                    User_Cart_Id: cart.User_Cart_Id,
                                    partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                                }, count, checkCartUpdateResponse))
                            }} />
                        }
                        <TouchableOpacity onPress={(e) => dispatch(addItemMultipleCart({
                            User_Cart_Id: cart.User_Cart_Id,
                            partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                        }, Number(cart.Quantity) + 1, checkCartUpdateResponse))} style={{ borderWidth: 1, borderColor: '#cccccc' }}>
                            <MaterialIcons name="add" size={22} color="#cccccc" />
                        </TouchableOpacity>
                    </View> :

                    <TouchableOpacity disabled={data.Partner_Type === "Physical" && (data.Is_Open != "1" || data.Is_Stock_Available != "1" || data.Is_Product_Available != "1") ? true : false} onPress={(e) => { login(data) }}  >
                        <View style={{
                            flexDirection: "row", backgroundColor: esiColor.globalButtonColor, borderRadius: 8, paddingVertical: 5, paddingHorizontal: 20, marginBottom: 4,
                        }}>
                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: "bold" }}>{data.Partner_Type === "Online" ? "Grab Deal" : "Add to Cart"} </Text>
                        </View>
                    </TouchableOpacity>
                }

            </View>
        </Card>
    );
}
