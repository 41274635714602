import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Surface, Colors, TextInput, TouchableRipple, Avatar, IconButton } from 'react-native-paper';
import { View, Dimensions, Text, TouchableOpacity, Platform, TouchableHighlight, ActivityIndicator } from 'react-native';
import Header from "../header";
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import ESITextInput from "../../components/custom-fields/textInput";
import { TouchableOpacity as TouchableOpacityIos } from "react-native-gesture-handler";
import AddressLocation from "../../components/custom/location/address-location";
import RidesLocation from "../../components/custom/location/rides-location";
import isEmpty from "../../state/validations/is-empty";
import TrackingMap from "../../components/custom/map/TrackingMap/TrackingMap";
import { addRide, getMyRiders, getRiders } from "../../state/actions/ridesAction";
import EsiDatePicker from "../../components/custom/date/date";
import EsiTimePicker from "../../components/custom/date/time";
import { CheckBox } from "react-native-elements";

export default function Rides(props: any) {
    const { navigation, ...rest } = props;
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const EmailID = useSelector(state => state.auth.user.Email_Id);
    const esiColor = useSelector(state => state.theme);

    useEffect(() => {
        if (!isAuthenticated) {
            setTimeout(() => {
                if (isEmpty(EmailID)) {
                    navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [isAuthenticated]);

    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    const dispatch = useDispatch();

    // dimensions are used for the width and height of card 
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    // let scroll;
    // const goToTop = () => {
    //     scroll.scrollTo({ x: 0, y: 0, animated: true });
    // }

    // distance callculate functions
    const [distance, setDistance] = React.useState('');

    const [origon, setOrigon] = useState({});
    const [distination, setDistination] = useState({});

    const [openMap, setOpenMap] = useState(false);
    const [openMapFor, setOpenMapFor] = useState("");
    const [vehicals, setVehicals] = useState([]);
    const [vehicalsLoading, setVehicalsLoading] = useState(false);

    const responseOfGetRiders = (status, data) => {
        setVehicalsLoading(false);
        if (status) {
            setVehicals(data);
        }
    }

    // Schedule Call
    let nDate = new Date();
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());

    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [selectData, setSelectData] = useState({});
    const [isSchedule, setIsSchedule] = useState(false);
    const [schedule, setSchedule] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);
    const [scheduleTime, setScheduleTime] = useState((nDate.getHours() < 10 ? "0" + nDate.getHours() : nDate.getHours()) + ":" + (nDate.getMinutes() < 10 ? "0" + nDate.getMinutes() : nDate.getMinutes()) + ":" + (nDate.getSeconds() < 10 ? "0" + nDate.getSeconds() : nDate.getSeconds()));

    const getSeletedVehicalDetails = (data) => {
        setAddRideError("");
        setSelectData(data);
        setIsConfirmScreen(true);
    }


    const [addRideError, setAddRideError] = useState("");
    const [addRideLoading, setAddRideLoading] = useState(false);
    const addRideResponse = (status, data) => {

        setAddRideLoading(false);
        if (status) {
            setIsConfirmScreen(false);
            toast.show({ message: "Successfully created Ride.", type: 'info', duration: 3000, position: 'top' });
            dispatch(getMyRiders({
                "Records_Filter": "FILTER",
                "User_Email_Id": EmailID
            }))
            navigation.navigate("MyRides");

        } else {
            setAddRideError("Unable to create Ride, Please try after some time.");
        }

    }

    const [duplicateCall, setDuplicateCall] = useState("{}");


    useEffect(() => {
        if (distance && JSON.stringify({ Longitude: origon?.Latitude, Longitude: origon?.Longitude, distance: distance }) != duplicateCall) {
            setDuplicateCall(JSON.stringify({ Longitude: origon?.Latitude, Longitude: origon?.Longitude, distance: distance }))
            setVehicals([]);
            setVehicalsLoading(true);
            dispatch(getRiders({
                Address: origon?.address,
                Distance_In_Kms: distance,
                Drop_Latitude: distination?.Latitude,
                Drop_Location: distination?.value,
                Drop_Longitude: distination?.Longitude,
                Pickup_Latitude: origon?.Latitude,
                Pickup_Location: origon?.value,
                Pickup_Longitude: origon?.Longitude
            }, responseOfGetRiders));
        }
    }, [distance, origon])

    const pickUpTextBox = () => {
        return (<ESITextInput
            // selectionColor='rgba(0, 0, 0, 0.5)'
            style={{ width: 400 }}
            label="Pickup"
            mode='outlined'
            value={origon?.value ? origon?.value?.slice(0, 35) + "..." : ""}
            // disabled
            editable={false}
            // multiline={true}
            right={<TextInput.Icon color={origon?.value ? esiColor.SIconColor : "#61e6fa"} name={origon?.value ? "map-marker-check-outline" : "map-marker-outline"} />}
        />)
    }

    const dropTextBox = () => {
        return (<ESITextInput
            // selectionColor='rgba(0, 0, 0, 0.5)'
            style={{ width: 400 }}
            label="Drop"
            mode='outlined'
            // outlineColor = "#27b6cc"
            color="#fff"
            shadowColor="#fff"
            shadowRadius="7"
            value={distination?.value ? distination?.value?.slice(0, 35) + "..." : ""}
            editable={false}
            // multiline={true}
            numberOfLines={1}
            right={<TextInput.Icon color={distination?.value ? esiColor.SIconColor : "#61e6fa"} name={distination?.value ? "map-marker-check-outline" : "map-marker-outline"} />}
        />)
    }
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ alignSelf: 'flex-start', marginTop: 10, flexDirection: 'row', marginLeft: 15 }}>
                {navigation.canGoBack() &&
                    <IconButton
                        icon="arrow-left"
                        // size={30}
                        color={esiColor.SIconColor}
                        style={{ marginTop: -5 }}
                        onPress={() => {
                            navigation.goBack();
                        }} />}
                <Text style={{ color: esiColor.brandFontColor, fontWeight: 'bold', fontSize: 16 }}>Book your Ride</Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 10, paddingHorizontal: 15 }}>
                {Platform.OS == "android" ? <TouchableOpacity style={{ minWidth: "100%", paddingHorizontal: 15 }} onPress={() => {
                    setOpenMapFor("pickup");
                    setOpenMap(true);
                }} >
                    {pickUpTextBox()}
                </TouchableOpacity> :
                    <TouchableOpacityIos style={{ minWidth: "100%" }} onPress={() => {
                        setOpenMapFor("pickup");
                        setOpenMap(true);
                    }} >
                        {pickUpTextBox()}
                    </TouchableOpacityIos>

                }
            </View>
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5, paddingHorizontal: 15, marginBottom: 10 }}>
                {Platform.OS == "android" ?
                    <TouchableOpacity style={{ minWidth: "100%", paddingHorizontal: 15, backgroundColor: esiColor.BackgroundColor }} onPress={() => {
                        setOpenMapFor("drop");
                        setOpenMap(true);
                    }} >
                        {dropTextBox()}
                    </TouchableOpacity> : <TouchableOpacityIos style={{ minWidth: "100%", backgroundColor: esiColor.BackgroundColor }} onPress={() => {
                        setOpenMapFor("drop");
                        setOpenMap(true);
                    }} >
                        {dropTextBox()}
                    </TouchableOpacityIos>}

            </View>

            {
                !isEmpty(origon) && !isEmpty(distination) &&

                <TrackingMap origin={{ latitude: origon.Latitude, longitude: origon.Longitude }}
                    destination={{ latitude: distination.Latitude, longitude: distination.Longitude }}
                    isRide={true} setDistance={setDistance} navigation={props.navigation}
                    vehicals={vehicals}
                    vehicalsLoading={vehicalsLoading}
                    confirmAction={getSeletedVehicalDetails}
                />
            }

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openMap}>
                <View style={{ flex: 1, maxWidth: 450, minWidth: 400, minHeight: 500, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <RidesLocation isLocationVisible={openMap} filters={openMapFor == "pickup" ? origon : distination} setIsLocationVisible={setOpenMap}
                        setFilters={(address, lan, lon, zip, cityAddress) => {
                            if (openMapFor == "pickup") {
                                setOrigon({ Latitude: lan, Longitude: lon, value: address, address: cityAddress });
                            } else {
                                setDistination({ Latitude: lan, Longitude: lon, value: address, address: cityAddress });
                            }
                            setOpenMap(false);
                        }}

                        Width={450}
                        height={450}

                    />
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }}
                isVisible={isConfirmScreen}
            >
                <View style={{
                    flex: 1, maxWidth: 450, minWidth: 400, minHeight: 250, maxHeight: 300, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5
                }}>
                    {addRideLoading ? <ActivityIndicator color={esiColor.SIconColor} /> :
                        <View >
                            <View style={{ flexDirection: "column" }} >
                                <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, backgroundColor: "" }} onPress={() => {
                                    setIsConfirmScreen(!isConfirmScreen);
                                }}>
                                    <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                                </TouchableRipple>
                                <View style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: 10
                                }}>
                                    <CheckBox
                                        containerStyle={{ borderWidth: 0, backgroundColor: esiColor.BackgroundColor }}
                                        title='Schedule Ride'
                                        // iconType='material'
                                        // checkedIcon='clear'
                                        // uncheckedIcon='add'
                                        checkedColor={esiColor.SIconColor}
                                        checked={isSchedule}
                                        onPress={() => setIsSchedule(!isSchedule)}
                                    />
                                </View>
                                {isSchedule &&
                                    <>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "center"
                                        }}>
                                            <EsiDatePicker
                                                style={{ minWidth: 200, margin: "auto" }}
                                                name={'DOB'}
                                                label='Date'
                                                value={schedule}
                                                valueUpdate={item => {
                                                    setSchedule(item);
                                                }}
                                            />
                                        </View>
                                        <View style={{
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            marginTop: 10
                                        }}>
                                            <EsiTimePicker
                                                style={{ minWidth: 200, margin: "auto" }}
                                                name={'Time'}
                                                label='Time'
                                                value={scheduleTime}
                                                valueUpdate={item => {
                                                    setScheduleTime(item);
                                                }}
                                            />
                                        </View>
                                    </>}
                                {!isEmpty(addRideError) &&
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "center"
                                    }}>
                                        <Text style={{ color: "red" }} >
                                            {addRideError}
                                        </Text>
                                    </View>
                                }
                            </View>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginVertical: 5,
                                    justifyContent: "center",
                                    width: 450
                                }}
                            >
                                <TouchableHighlight
                                    style={{
                                        paddingHorizontal: 20,
                                        paddingVertical: 10,
                                        backgroundColor: esiColor.globalButtonColor,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                    }}
                                    onPress={() => {
                                        let noErrors = true;
                                        if (isSchedule) {
                                            if (!schedule) {
                                                noErrors = false;
                                                setAddRideError("Please select Schedule Date.");
                                            } else if (!scheduleTime) {
                                                noErrors = false;
                                                setAddRideError("Please select Schedule Time.");
                                            } else {
                                                var hours = (new Date(schedule + " " + scheduleTime) - new Date()) / 36e5;
                                                if (!(hours >= 3)) {
                                                    noErrors = false;
                                                    setAddRideError("For Schedule Ride need at least 3hr time.");
                                                }
                                            }
                                        }
                                        if (noErrors) {
                                            setAddRideLoading(true);
                                            setAddRideError("");
                                            dispatch(addRide(
                                                {
                                                    "User_Rides": {
                                                        "Distance_In_Kms": distance,
                                                        "Drop_Latitude": distination?.Latitude,
                                                        "Drop_Location": distination?.value,
                                                        "Drop_Longitude": distination?.Longitude,
                                                        "Is_Schedule_Ride": isSchedule ? 1 : 0,
                                                        "Pickup_Latitude": origon?.Latitude,
                                                        "Pickup_Location": origon?.value,
                                                        "Pickup_Longitude": origon?.Longitude,
                                                        "Schedule_Date_Time": isSchedule ? schedule + " " + scheduleTime : "",
                                                        "User_Email_Id": EmailID,
                                                        "Comfort_Type": selectData.Vehicle_Comfort_Type,
                                                        "Address": origon?.address
                                                    }
                                                }, addRideResponse
                                            ))
                                        }
                                    }}
                                    underlayColor='#fff'>
                                    <Text style={{
                                        color: esiColor.itemButtenColor,
                                        textAlign: 'center'
                                    }}>Start Ride</Text>
                                </TouchableHighlight>
                            </View>
                        </View>

                    }
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
        </Surface>

    );
}
