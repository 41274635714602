// api.js
import axios from 'axios';
// import * as SecureStore from 'expo-secure-store';
import dConfig from './config';
import { Platform } from 'react-native';
import CryptoJS from 'crypto-js';

const boomiApi = axios.create({
    baseURL: dConfig.url,
    headers: dConfig.headersCommon,
    timeout: 10000, // Adjust as needed
});

// Add a request interceptor
boomiApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            if (Platform.OS != 'web') {
                const token = JSON.parse(await SecureStore.getItemAsync('Token'));
                if (token) {
                    config.headers["Bearer-Token"] = `Bearer ${token.accessToken}`;
                }
            } else {
                const encryptedToken = localStorage.getItem('Token');
                const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
                const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (decryptedToken) {
                    config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
                }
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);


const boomiDelApi = axios.create({
    baseURL: dConfig.delurl,
    headers: dConfig.headersCommon,
    timeout: 10000, // Adjust as needed
});

// Add a request interceptor
boomiDelApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            if (Platform.OS != 'web') {
                const token = JSON.parse(await SecureStore.getItemAsync('Token'));
                if (token) {
                    config.headers["Bearer-Token"] = `Bearer ${token.accessToken}`;
                }
            } else {
                const encryptedToken = localStorage.getItem('Token');
                const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
                const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (decryptedToken) {
                    config.headers["Bearer-Token"] = `Bearer ${decryptedToken.accessToken}`;
                }
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);


const nodeCudApi = axios.create({
    baseURL: dConfig.nodeCUDUrl,
    // timeout: 10000, // Adjust as needed
});

// Add a request interceptor
nodeCudApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            if (Platform.OS != 'web') {
                const token = JSON.parse(await SecureStore.getItemAsync('Token'));
                if (token) {
                    config.headers.Authorization = `Bearer ${token.accessToken}`;
                }
            } else {
                const encryptedToken = localStorage.getItem('Token');
                const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
                const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (decryptedToken) {
                    config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
                }
            }
        } catch (error) {

        }
        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const nodeGetApi = axios.create({
    baseURL: dConfig.nodeUserUrl,
    timeout: 10000, // Adjust as needed
});

// Add a request interceptor
nodeGetApi.interceptors.request.use(
    async (config) => {
        // Modify the request config here, such as adding headers
        try {
            if (Platform.OS != 'web') {
                const token = JSON.parse(await SecureStore.getItemAsync('Token'));
                if (token) {
                    config.headers.Authorization = `Bearer ${token.accessToken}`;
                }
            } else {
                const encryptedToken = localStorage.getItem('Token');
                const bytes = CryptoJS.AES.decrypt(encryptedToken, dConfig.cryptoKay);
                const decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (decryptedToken) {
                    config.headers.Authorization = `Bearer ${decryptedToken.accessToken}`;
                }
            }
        } catch (error) {

        }

        return config;
    },
    (error) => {
        // Handle errors
        return Promise.reject(error);
    }
);

const urlGenarator = (url: any, pagination: any) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
export { boomiApi, boomiDelApi, nodeCudApi, nodeGetApi, urlGenarator }
