import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View, ScrollView, RefreshControl, FlatList, Dimensions, Platform, ActivityIndicator, Image, TouchableOpacity } from "react-native";
import { Avatar, Badge, Button, DefaultTheme, HelperText, IconButton, Menu, Searchbar, Surface, Text, TouchableRipple } from "react-native-paper";

import { useDispatch, useSelector } from "react-redux";

import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import Pagination from "../../../components/custom/pagination/pagination";
import { Drawer } from 'react-native-drawer-layout';
import { availabileBiddingProducts, getBiddingProductsCart } from "../../../state/actions/node-actions/bid-products-actions";
import { CategoryFilters } from "./CategoryFilters";
import { FontAwesome5, Ionicons, MaterialIcons } from "@expo/vector-icons";
import Modal from "react-native-modal";
import SignIN from "../../auth/signin";
import { useToast } from "../../../self_modules/react-native-paper-toast/src";
import { Formik } from "formik";
import * as Yup from 'yup';
import ESITextInput from "../../../components/custom-fields/textInput";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown1";
import EsiTextAreaInput from "../../../components/custom/TextAreaInput/textareainput";
import { createBids, createCartBids } from "../../../state/actions/boomi-actions/bid-products-actions";
import * as Location from "expo-location";
import api from "../../../state/actions/api";
import EsiDatePicker from "../../../components/custom/date/date";
import noProdImg from "../../../assets/images/GIF/noProdFoundImg.png";


export default function NewBidProducts(props) {
    const [open, setOpen] = React.useState(false);
    const esiColor = useSelector(state => state.theme);
    const { navigation } = props;
    const dispatch = useDispatch();
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);
    const [pageOld, setPageOld] = React.useState(false);
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);

    const callBackGetData = async (status, data, pagination) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            setPageOld(true);
            if (pagination) {
                setPagination(pagination);
            } else {
                setPagination({ PageNo: 1, PageSize: 50, SortBy: "BIDDING_PRODUCT_ID", SortOrder: "DESC" });
            }
        }
    }

    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "BIDDING_PRODUCT_ID", SortOrder: "DESC" });

    const [testFill, setTestFill] = React.useState({});

    const [search, setSearch] = React.useState("");
    const [pincode, setPincode] = React.useState("");
    const data = props?.route?.params;

    useEffect(() => {
        setSearch("");
    }, [data])

    useEffect(() => {
        (async () => {
            if (Platform.OS === 'android' && !Constants.isDevice) {
                alert(
                    'Oops, this will not work on Snack in an Android emulator. Try it on your device!'
                );
                return;
            }
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                // Alert.alert('Location Service not enabled', 'Please enable your location services to continue', [{ text: 'OK' }], { cancelable: false });
                return;
            }

            const { coords: { latitude, longitude } } = await Location.getCurrentPositionAsync();
            api.get(`https://reverse-geocode.meepaisa.com/reverse?format=json&lat=${latitude}&lon=${longitude}`).then(async (response) => {
                let info = response?.data?.address?.postcode;
                if (info)
                    setPincode(info)

            }).catch(async (reason) => {
            });
        })();
    }, [])

    const [filters, setFilters] = useState([]);
    const [cartCount, setCartCount] = useState(0);

    const [testPagination, setTestPagination] = React.useState(pagination);


    const callProducts = () => {
        let formData = {
            Status: "Active"
        }

        if (filters.length > 0)
            formData.Categorys = filters.map((item) => item.id);
        formData.Search = search;
        formData.EmailId = logedInEmail || "";
        if (!(JSON.stringify(testFill) === JSON.stringify(formData)) || !(JSON.stringify(testPagination) === JSON.stringify(pagination))) {
            setTestPagination(pagination);
            setTestFill(formData);
            setPageOld(false);
            setLoading(true);
            dispatch(availabileBiddingProducts(formData, pagination, callBackGetData));
        }
        if (logedInEmail)
            dispatch(getBiddingProductsCart({
                "getCount": true,
                "User_Email_ID": logedInEmail
            }, (status, data) => setCartCount(data?.Counts || 0)));
    }

    useEffect(() => {
        callProducts();
    }, [search, logedInEmail, filters, pagination]);

    const scroll = useRef(null);
    const goToTop = () => {
        scroll.current?.scrollTo({ x: 0, y: 0, animated: true });
    }


    const [isModalVisible, setModalVisible] = React.useState(false);
    const toast = useToast();

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
        if (status) {

        }
    };


    const [addCartPop, setAddCartPop] = React.useState(false);
    const [addCartItem, setAddCartItem] = React.useState({});
    const addProductToBid = (data: any) => {
        if (logedInEmail) {
            setAddCartPop((prop) => !prop);
            setFormResults(false);
            setAddCartItem(data);
        } else {
            setModalVisible(!isModalVisible);
        }
    }
    const productShow = useMemo(() => {
        let numColumCount = Number((dimensions.width / 250).toFixed() == "1" ? 2 : (dimensions.width / 250).toFixed());

        return <FlatList
            // horizontal={true}
            key={dimensions.width}
            columnWrapperStyle={{ justifyContent: "space-around" }}
            numColumns={numColumCount}
            data={allproducts}
            renderItem={({ item, index, separators }) => (
                <View key={"bid" + index} style={[{
                    margin: 5, backgroundColor: esiColor.CBColor,
                    borderRadius: 8, width: (dimensions.width / Number(numColumCount)) - 10, maxWidth: 230, paddingVertical: 2

                }, Platform.OS != "android" ? {
                    shadowColor: esiColor.brandShadowColor,
                    shadowOpacity: 5,
                    shadowRadius: 7,
                    elevation: 20
                } : {
                    elevation: 20,
                    overflow: "hidden"
                }]}>
                    <View style={{ flexDirection: "row", justifyContent: "center" }}>
                        <Image style={{ resizeMode: 'contain', width: (dimensions.width / Number(numColumCount)) - 20, maxWidth: 210, height: ((dimensions.width / Number(numColumCount)) - 20) * .7, maxHeight: 200, borderRadius: 8, backgroundColor: "#efefef" }}
                            source={{ cache: "force-cache", uri: item?.Image + `?w=210&h=200` }}></Image>
                    </View>
                    <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 5 }}>
                        <Text style={{ flexWrap: "wrap", textAlign: "center", color: esiColor.itemColor, fontWeight: 500 }} >{item.Product_Name.slice(0, 50) + (item.Product_Name.length > 50 ? "..." : "")}</Text>
                    </View>

                    <View style={{ flexDirection: "row", alignSelf: "center", marginBottom: 5, backgroundColor: esiColor.globalButtonColor, width: 125, borderRadius: 7 }}>
                        <Button icon="cart-plus" uppercase={false} color={esiColor.itemButtenColor} onPress={() => {
                            addProductToBid(item);
                        }}>
                            <Text style={{ color: esiColor.itemButtenColor }}>Add to Bid</Text>
                        </Button>
                    </View>
                </View>
            )}
        />
    }, [allproducts, dimensions])

    const drawerContent = useCallback(() => {
        return (
            <ScrollView
                showsHorizontalScrollIndicator={false}
                style={{ marginVertical: 10, backgroundColor: esiColor.BackgroundColor }} >
                <View style={{ marginTop: 5 }} >
                    <CategoryFilters filters={filters} setFilters={setFilters} />
                </View>
            </ScrollView >
        )
    }, [open, filters])

    const [loadingForm, setloadingForm] = React.useState(false);
    const [formResults, setFormResults] = React.useState(false);
    const [formikSubmitType, setFormikSubmitType] = React.useState(0);
    let nDate = new Date();
    nDate.setDate(nDate.getDate() + 2);
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);


    const formCallbackResponse = (status: boolean) => {
        setloadingForm(false);
        if (status) {
            setFormResults(true);
            callProducts();
        }
    }

    const canGoBackCallback = useMemo(() => {
        return navigation.canGoBack();
    }, [navigation])


    const datediff = (first, second) => {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />

            <Drawer
                open={open}
                style={{ backgroundColor: esiColor.BackgroundColor }}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                drawerStyle={{}}
                renderDrawerContent={drawerContent}
                drawerPosition={"left"}
            >
                <View>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                        {canGoBackCallback &&
                            <IconButton
                                icon="arrow-left"
                                size={27}
                                color={esiColor.SIconColor}
                                style={{ marginLeft: 1 }}
                                onPress={() => {
                                    navigation.goBack();
                                }} />
                        }
                        <View style={{ alignItems: "center" }}>

                            {(cartCount > 0 && logedInEmail) &&
                                <TouchableOpacity onPress={() => {
                                    navigation.navigate("CartBidProducts");
                                }} style={{ borderRadius: 8, alignSelf: "center", padding: 5, marginTop: 15, }} >
                                    <Ionicons name="cart" size={30} color={esiColor.globalButtonColor} />
                                    <Badge style={{ position: "absolute", right: 0, top: -7, color: esiColor.esiMainColor, backgroundColor: esiColor.ImgBck }} >{cartCount}</Badge>
                                </TouchableOpacity>}
                        </View>
                        <Searchbar style={{ marginHorizontal: 10, borderColor: esiColor.SBorderColor, backgroundColor: esiColor.BackgroundColor, width: '25%', borderWidth: 0.5, maxHeight: 30, marginTop: 10, left: 0 }}
                            inputStyle={{ fontSize: 12, marginLeft: -20 }}
                            value={search}
                            onChangeText={(value) => {
                                setSearch(value);
                            }}
                            iconColor={esiColor.SIconColor}
                            placeholderTextColor={esiColor.SPHTextColor}
                            theme={{ colors: { text: esiColor.SBTextColor } }}
                            multiline={false}
                            placeholder="Search"
                        />

                        <IconButton
                            color={esiColor.SIconColor}
                            icon="filter"
                            size={24}
                            style={{ marginTop: -45, alignSelf: 'flex-end' }}
                            onPress={() => setOpen((prevOpen) => !prevOpen)}
                        />

                    </View>

                </View>
                <ScrollView ref={scroll}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                        />
                    }
                >
                    <View style={{ alignItems: 'center', flex: 1, flexDirection: 'row', flexWrap: 'wrap', position: 'relative', justifyContent: 'space-around' }}>
                        {isEmpty(allproducts) ? (loading ? <ActivityIndicator /> :
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                                <Image style={{ resizeMode: 'contain', width: 400, height: 250, alignSelf: 'center' }} source={noProdImg} />

                                <Text style={{ color: esiColor.NoDataFound }}>No Products found!</Text>
                            </View>
                        ) : (
                            productShow
                        )
                        }
                    </View>

                    <View style={{ marginBottom: 5 }}>
                        <Pagination pagination={pagination} setPagination={setPagination} />
                    </View>

                </ScrollView>

            </Drawer>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={addCartPop}>
                <ScrollView style={{ flex: 1, maxWidth: 500, minWidth: 400, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                    <Surface style={{
                        justifyContent: 'center',
                        borderRadius: 10,
                        top: Platform.OS === 'web' ? 10 : 20,
                        elevation: 0, backgroundColor: esiColor.BackgroundColor,
                    }} >
                        <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                            setAddCartPop((prop) => !prop);
                            setFormResults(false);
                        }}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                        <Formik
                            initialValues={
                                {
                                    Bidding_Product_Id: addCartItem.Bidding_Product_Id,
                                    Quantity: '',
                                    Measurement_Type: '',
                                    User_Email_Id: logedInEmail,
                                    Description: '',
                                    Pincode: pincode || '',
                                    Bidding_Expiry_Date: "",
                                }
                            }
                            validationSchema={Yup.object().shape({
                                Quantity: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required."),
                                Measurement_Type: Yup.string().required("Size is required."),
                                Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                                Bidding_Expiry_Date: Yup.string().required('Date of service is required.').test("date-of-bidding", "Service Date should be more then " + dos + ".", (value: any) => {
                                    if (value) {
                                        let date1 = new Date(value).getTime();
                                        let date2 = new Date(dos).getTime();
                                        if (datediff(date2, date1) > -1) {
                                            return true;
                                        }
                                    }
                                    return false;

                                })

                            })}
                            onSubmit={values => {
                                setloadingForm(true);
                                if (formikSubmitType == 0)
                                    dispatch(createBids([values], formCallbackResponse));
                                else
                                    dispatch(createCartBids(values, formCallbackResponse));
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                <View style={{
                                    flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                }}>
                                    {loadingForm ?
                                        <ActivityIndicator color={esiColor.esiMainColor} />
                                        :
                                        formResults ?
                                            <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                                <Image source={require('../../../assets/images/GIF/cart-success.gif')}
                                                    style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                                                <View style={{ width: "90%", margin: "auto" }}>
                                                    <Text style={{ textAlign: "center", fontWeight: "bold", color: esiColor.itemColor }} >
                                                        {formikSubmitType == 0 ? "Successfully placed your bid." : "Successfully added your item to cart."}
                                                    </Text>
                                                </View>
                                            </View>
                                            :
                                            <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>

                                                <View style={{ width: "90%", margin: "auto" }}>
                                                    <ESITextInput
                                                        style={{ width: "100%" }}
                                                        label="Quantity*"
                                                        mode='outlined'
                                                        onChangeText={handleChange("Quantity")}
                                                        onBlur={
                                                            handleBlur
                                                        }
                                                        value={values.Quantity}
                                                        error={Boolean(touched.Quantity && errors.Quantity)}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Quantity && errors.Quantity)}>
                                                        {touched.Quantity && errors.Quantity}
                                                    </HelperText>
                                                </View>
                                                <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                                                    <EsiAutocompleteDropdown

                                                        key={"size" + values.Measurement_Type}
                                                        loading={false}
                                                        data={addCartItem?.Measurement_Type?.split(",").map((type) => ({ id: type, title: type }))}
                                                        label={"Select Size"}
                                                        onSelect={(item) => {
                                                            if (item?.id)
                                                                setFieldValue("Measurement_Type", item.id)
                                                        }}
                                                        onClear={() => {
                                                        }}
                                                        getSuggestions={(q) => {
                                                            // apiCall(q);
                                                        }}
                                                        selected={{ id: values.Measurement_Type, title: values.Measurement_Type }}

                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Measurement_Type && errors.Measurement_Type)}>
                                                        {touched.Measurement_Type && errors.Measurement_Type}
                                                    </HelperText>
                                                </View>
                                                <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                                                    <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Description:</Text>
                                                    <EsiTextAreaInput
                                                        error={Boolean(touched.Description && errors.Description)}
                                                        containerStyle={{ height: 170 }}
                                                        style={{ height: 170 }}
                                                        mode='outlined'
                                                        value={values.Description}
                                                        textareavalueUpdate={value => {
                                                            setFieldValue("Description", value)
                                                        }}
                                                        placeholder={'Please provide additional information'}
                                                        underlineColorAndroid={'transparent'}
                                                        theme={{
                                                            colors: {
                                                                primary: "#27b6cc"
                                                            },
                                                        }}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Description && errors.Description)}>
                                                        {touched.Description && errors.Description}
                                                    </HelperText>
                                                </View>
                                                <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                    <ESITextInput
                                                        style={{ width: "100%" }}
                                                        label="Pincode*"
                                                        mode='outlined'
                                                        onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                        onBlur={
                                                            handleBlur
                                                        }
                                                        value={values.Pincode}
                                                        error={Boolean(touched.Pincode && errors.Pincode)}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                        {touched.Pincode && errors.Pincode}
                                                    </HelperText>
                                                </View>
                                                <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                    <EsiDatePicker
                                                        style={{ margin: "auto" }}
                                                        name={'Bidding_Expiry_Date'}
                                                        label='Bidding Expiry Date'
                                                        value={values.Bidding_Expiry_Date}
                                                        valueUpdate={item => {
                                                            setFieldValue("Bidding_Expiry_Date", item);
                                                        }}
                                                        error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                    />
                                                    <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                                                        {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                    </HelperText>
                                                </View>
                                                <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                    <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {
                                                        setFormikSubmitType(0);
                                                        handleSubmit()
                                                    }}>
                                                        <Text style={{ color: esiColor.itemButtenColor }}> Ask Quotation</Text>
                                                    </Button>
                                                    <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} mode="outlined" onPress={() => {
                                                        setFormikSubmitType(1);
                                                        handleSubmit()
                                                    }}>
                                                        <FontAwesome5 name={"cart-plus"} size={20} color={esiColor.itemButtenColor} />
                                                    </Button>
                                                </View>
                                            </View>}
                                </View>)}
                        </Formik>
                    </Surface>

                </ScrollView>
            </Modal>

        </Surface>
    )
}