import React from 'react';
import { View, Text } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import { Button, Card, IconButton } from 'react-native-paper';
import { useToast } from '../../../self_modules/react-native-paper-toast/src';

const FileUpload = (props: any) => {
    const toast = useToast();
    //props consists of style props along with uploadPic function and custom props like image.

    // uploadPic function will return onchange value of perticular upload image responce. 

    // pickImage is a function for selecting images from mobile or web using ImagePicker plugin and will get uploaded image URL.
    const pickFile = async () => {
        // No permissions request is necessary for launching the file library
        let result = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: false,
            type: ['text/*', 'image/*', 'application/pdf']
        })
        // if file is not cancelled after upload then  responce will passing to the uploadFile
        if (!result.cancelled) {
            let valid = false;
            if (result.mimeType.split("/")[0] === "text" || result.mimeType.split("/")[0] === "image") {
                valid = true;
            } else if (result.mimeType === "application/pdf") {
                valid = true;
            }
            if (valid) {
                props.uploadFile(result);
            }else{
                toast.show({ message: 'Please upload valid image/file.', type: 'info', duration: 3000, position: 'top' });
            }
        }
    };
    return (

        <Card {...props}>

            {/* This is condition if file is uploaded we are providing Remove file function, If not will display file uplaod option.  */}
            {props.file ?
                <Card.Content>

                    <View
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    ><View style={{ flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                            <View style={{ flexShrink: 1, minWidth: 200 }}>
                                <Text>{props.file}</Text>
                            </View>
                            <View style={{ flexShrink: 1 }}>
                                <IconButton
                                    icon="delete"
                                    color={"blue"}
                                    // size={20}
                                    onPress={props.handleRemoveFile}
                                />
                            </View>
                        </View>
                    </View>
                </Card.Content>
                : <Button style={{ fontSize: 10 }} uppercase={false} color={'#27b6cc'} title="Choose Photo" onPress={pickFile} >Choose File</Button>}

        </Card >
    );
};

export default FileUpload;