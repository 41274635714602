import React, { useEffect } from "react";
import { ActivityIndicator, Button, Surface, Text } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import { View, ScrollView } from "react-native";
import RenderHTML from "react-native-render-html";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
// import { List } from 'react-native-paper';
import { getAllFaqAction } from "../../state/actions/faqActions";
import Accordian from "../../components/accordian";
// import Footer from "../footer";

const AllFaq = (props) => {
    const { navigation } = props;
    const dispatch = useDispatch();
    const [pos, setPos] = React.useState(0);

    let faq = useSelector((state) => state.allFAQs.faqs.all);
    const loading = useSelector(state => state.allFAQs.faqs.isLoading);
    useEffect(() => {
        if (isEmpty(faq)) {
            dispatch(getAllFaqAction());
        }
    }, [faq]);
    return (
        // <View style={{ flex: 1 }}>
            <Surface style={{ flex: 1 }}>
                <Header navigation={navigation} />
                <Text style={{ fontSize: 20, marginTop: 20, marginBottom: 20, fontWeight: "bold", textAlign: 'center', color: "#27b6cc", justifyContent: 'center' }}>FAQ's</Text>
                <ScrollView onScroll={(e) => setPos(e.nativeEvent.contentOffset.y)}>
                    {!isEmpty(faq) ? faq.map((data: any, index: any) => (
                        <View key={index} style={{ flexWrap: "wrap", alignSelf: 'center', marginBottom: 2, width:800, justifyContent:'center' }} >
                            <Accordian data={data} >
                                <View
                                    style={{
                                        borderBottomColor: '#9ce5f0',
                                        borderBottomWidth: 1,
                                        marginBottom: 5,
                                      
                                    }}
                                />
                                <RenderHTML
                                    source={{ html: data?.Answer }} />
                            </Accordian>
                        </View>)
                    ) :
                        <ActivityIndicator animating={loading ? true : false} color='#27b6cc' style={{ marginTop: 20 }} />
                    }

                </ScrollView>
            </Surface>
        //     {/* {pos >= 0 && <View style={!faq ? { display: "none" } : { display: "flex" }} ><Footer navigation={navigation} /></View>} */}

        // {/* </View> */}
    );
}


export default AllFaq;