import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderHTML from "react-native-render-html";
import Modal from "react-native-modal";
import { Avatar, Card, Text, TextInput, Provider, Surface, TouchableRipple, IconButton, Colors } from "react-native-paper";
import { View, TouchableOpacity, Image, useWindowDimensions, ScrollView, StyleSheet, Platform, Dimensions } from "react-native";
import { Rating } from "react-native-ratings";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import SignIN from "../auth/signin";
import { Ionicons } from "@expo/vector-icons";
import { addBrandReviewAction, editBrandReviewAction, getBrandByIdAction, getUserReviewsbyIdAction } from "../../state/actions/brandsActions";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
const { height, width } = Dimensions.get('window');


// api
export default function BrandDetails(props) {
    const { navigation } = props;
    const toast = useToast();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const [visible, setVisible] = useState(10);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [rating, setRating] = useState(0);
    //comment param is used here to take the value from the Comment text box
    const [comment, setComment] = useState("")
    const [disabled, setDisabled] = useState("")
    const brandId = props?.route?.params?.Brand_Id;
    let brands = useSelector((state) => state.allBrands.brand.brand);
    const esiColor = useSelector(state => state.theme);
    let brandReviews = useSelector((state) => state.allBrands.brandReviews.all);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.auth?.user?.Email_Id);
    //reviewId is used to get the Particular Review ID dynamically
    const [reviewId, setData] = useState()
    //setCommentError param is used here to display validation error message
    const [commenterror, setCommentError] = useState("")
    const [error, setError] = useState("")
    //setRatingError param is used here to display validation error message
    const [ratingerror, setRatingError] = useState("")

    //For add review
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isReviewModalVisible, setReviewModalVisible] = React.useState(false);

    //For edit review
    const [setEditModalVisible] = React.useState(false);
    const [isEditReviewModalVisible, setEditReviewModalVisible] = React.useState(false);
    const BrandReviews = () => {
        let existcomment;
        let UserData = [];
        if (Array.isArray(brandReviews)) {
            if (brandReviews[0]) {
                existcomment = brandReviews.filter(review => review.User_Email_Id === user).length
                UserData = brandReviews.filter(review => review.User_Email_Id === user ? review : "")
            }
        }
        return [existcomment, UserData]
    }
    const [commentvalidation, uservalidation] = BrandReviews();
    //displaying Signin pop up
    const signInModalResponse = () => {
        setModalVisible(!isModalVisible);
    };
    //For Add review
    const toggleModal = () => {
        if (isAuthenticated) {
            setReviewModalVisible(!isReviewModalVisible);
        }
        else {
            setModalVisible(true);
        }

    };
    //For edit review
    const editToggleModal = () => {
        if (isAuthenticated) {
            setEditReviewModalVisible(!isEditReviewModalVisible);
        }
        else {
            setEditModalVisible(true);
        }

    };

    const addReviewResponse = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setReviewModalVisible(!isReviewModalVisible);
        setComment("");
        setRating("");
    }


    const addReviewModal = () => {

        if (comment !== "" && rating > 0) {
            setCommentError("");
            setRatingError("");
            let formData = {
                Brand_Id: brandId,
                Brand_Name: brands.Brand_Name,
                Rating: rating,
                Comments: comment,
                User_Email_Id: user,
                Status: "Active"
            }
            let formValues = JSON.parse(JSON.stringify(formData));
            dispatch(addBrandReviewAction(formValues, brandId, addReviewResponse));
        } else {
            if (isEmpty(comment)) {
                setCommentError("Review is required.")
            }
            if (isEmpty(rating)) {
                setRatingError("Rating is required.")
            }
        }

    }

    const editReviewResponse = (status, message) => {
        toast.show({ message: message, type: 'info', duration: 3000, position: 'top' });
        setEditReviewModalVisible(!isEditReviewModalVisible);
        setComment("");
        setRating("");
    }


    const editReviewModal = () => {
        if (comment !== "" && rating > 0) {
            setCommentError("");
            setRatingError("");
            let editData = {
                Product_Id: brandId,
                Brand_Review_Id: reviewId,
                Brand_Name: brands.Brand_Name,
                Rating: rating,
                Comments: comment,
                User_Email_Id: user,
                Status: "Active"
            }
            let formValues = JSON.parse(JSON.stringify(editData));
            dispatch(editBrandReviewAction(formValues, brandId, editReviewResponse));
        }
        else {
            if (isEmpty(comment)) {
                setCommentError("Review is required.")
            }
            if (isEmpty(rating)) {
                setRatingError("Rating is required.")
            }
        }

    }
    // Comment validations
    const handleOnChange = (value) => {
        if (commentvalidation > 0) {
            setError("You have already added review.")
        }
        if (value === "") {
            setError("")
        }
    }
    // Edit Onchanges
    const editOnChange = (value) => {
        if (value === "") {
            setCommentError("Review is required.")
        } else {
            setCommentError("")
        }
    }
    const [id, setID] = useState();
    const [branddata, setBrandData] = useState();
    const callBackGetBrandData = (brands) => {
        if (brands) {
            setBrandData(brands);

            setID(brandId)
        }
    };
    useEffect(() => {
        onRefresh();
    }, [brandId]);

    const onRefresh = () => {
        dispatch(getBrandByIdAction(brandId, callBackGetBrandData));
        dispatch(getUserReviewsbyIdAction(brandId));
    };
    // Tost message code.
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    const styles = StyleSheet.create({

        container: {
            paddingLeft: 12,
            paddingRight: 15,
            paddingVertical: 10,
            flexDirection: 'row',
            alignItems: 'flex-start',
            borderRadius: 2
        },
        content: {
            marginLeft: 16,
            flex: 1,
            flexDirection: 'column', justifyContent: 'center', margin: "auto"

        },
        contentHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 6,
            borderRadius: 2,
            backgroundColor: esiColor.BackgroundColor,
        },

        image: {
            width: 45,
            height: 45,
            borderRadius: 20,
        },
        name: {
            fontSize: 16,
            fontWeight: "bold",
            color: esiColor.brandFontColor
        },
        containerm: {
            paddingLeft: 19,
            paddingRight: 16,
            paddingVertical: 12,
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginLeft: 1,
            borderRadius: 2,
            borderBottomWidth: 0.1, borderColor: esiColor.SBorderColor
        },
        centerElement: {
            justifyContent: 'center',
            alignItems: 'center'
        },
        squreStyle: {
            width: "100%",
            height: 75,
            borderRadius: 15,
            backgroundColor: esiColor.BackgroundColor,
            zIndex: 1,
        },
        arcStyle: {
            width: "20%",
            height: 70,
            position: "absolute",
            bottom: -25,
            left: "40%",
            borderRadius: 35,
            backgroundColor: esiColor.BackgroundColor,
            transform: [{ scaleX: 5 }, { scaleY: 1 }],
        },

    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => navigation.navigate('Brands')}>
                <Ionicons name="arrow-back" size={25} color={esiColor.SIconColor} style={{ marginTop: 10 }} />
            </TouchableOpacity>
            <ScrollView style={{ marginTop: 20 }}>
                <Card style={{ margin: "auto", marginBottom: 20, width: (width > height) ? height : width, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 5, marginTop: 10 }}>
                    <View style={{ marginVertical: 140, marginTop: 10 }}>
                        <View style={styles.squreStyle}>
                            <View style={{ flexDirection: 'column', justifyContent: 'center', margin: "auto", }}>
                                <Text style={{ fontSize: 20, textTransform: 'uppercase', margin: "auto", color: esiColor.itemColor, marginTop: 5, marginBottom: 10 }}>{branddata?.Brand_Name}</Text>
                                <Image source={{ uri: branddata?.Brand_Image + `?w=100&h=100` }}
                                    style={{
                                        height: 100, width: 100, resizeMode: 'center', margin: "auto", borderRadius: 400 / 2,
                                        overflow: "hidden", borderWidth: 1, borderColor: esiColor.SBorderColor, marginTop: 20
                                    }}></Image>

                                <Rating
                                    readonly={true}
                                    rating={branddata?.Brand_Esi_Rating ? parseInt(branddata?.Brand_Esi_Rating) : 0}
                                    type='heart'
                                    tintColor={esiColor.TintColor}
                                    startingValue={5}
                                    ratingCount={5}
                                    imageSize={20}
                                    style={{ paddingVertical: 5 }}
                                    minValue={1}
                                    onFinishRating={(rating) => ratingCompleted(rating)}
                                    name="rating"
                                />
                            </View>
                        </View>
                        <View style={styles.arcStyle} />
                    </View>

                    <View style={{ marginLeft: '3%', marginRight: '3%', marginTop: '3%', flex: 1, backgroundColor: esiColor.RTBC }}>
                        {branddata?.Brand_Description &&

                            <RenderHTML
                                source={{ html: branddata?.Brand_Description }} />
                        }
                    </View>

                    <View style={{ fontSize: 20, width: 100, alignSelf: 'center', marginTop: 15 }}>
                        <TouchableOpacity style={[styles.centerElement, { backgroundColor: commentvalidation > 0 ? esiColor.globalButtonColor : esiColor.globalButtonColor, width: 100, height: 30, borderRadius: 5, marginBottom: 30 }]}
                            onPress={() => toggleModal()} disabled={commentvalidation > 0 ? true : false}>
                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Add Review</Text>
                        </TouchableOpacity>

                    </View>
                    <View style={styles.container}>
                        <Image style={styles.image} source={require("../../assets/images/img/logo/MeepaisaLogo.png")} />
                        <View style={styles.content}>
                            <View style={styles.contentHeader}>
                                <Text style={styles.name}>meepaisa Review</Text>
                                <View style={{ marginTop: -8, backgroundColor: esiColor.BackgroundColor }}>
                                    <Rating
                                        rating={branddata?.Esi_Ratting ? (parseInt(branddata?.Esi_Ratting)) : (0)}
                                        numberOfStars={5}
                                        ratingCount={5}
                                        imageSize={20}
                                        style={{ paddingVertical: 10 }}
                                        readonly={true}
                                        type='heart'
                                        tintColor={esiColor.TintColor}
                                        onFinishRating={(rating) => ratingCompleted(rating)} />
                                </View>
                            </View>
                            <View style={{ backgroundColor: esiColor.RTBC, flexWrap: "wrap", flex: 1 }}>
                                {branddata?.Brand_Esi_Reviews &&
                                    <RenderHTML tagsStyles={{
                                        span: { color: esiColor.DescColor }
                                    }}
                                        source={{ html: branddata?.Brand_Esi_Reviews }} />
                                }
                            </View>
                        </View>

                    </View>
                    <View >
                        {!isEmpty(brandReviews) && (
                            brandReviews.Is_Data_Exist === "0" || isEmpty(brandReviews) ? (
                                <View style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', flex: 1, marginBottom: 20 }}>
                                    <Text style={{ color: esiColor.NoDataFound }}>No Brand Reviews found!</Text>
                                </View>
                            ) : typeof brandReviews === "object" && (
                                <>
                                    {brandReviews.slice(0, visible).map((data, index) => (
                                        <View key={index}>
                                            <View style={styles.containerm}>
                                                <TouchableOpacity>
                                                    <Image style={styles.image} source={{ uri: data.Profile_Pic_Path }} />
                                                </TouchableOpacity>
                                                <View style={styles.content}>
                                                    <View style={styles.contentHeader}>
                                                        <Text style={styles.name}>{data.Full_Name}</Text>
                                                        <View style={{}}>
                                                            {user && user === data?.User_Email_Id ? (
                                                                <IconButton icon="pencil" color={esiColor.itemColor} size={20} onPress={() => { editToggleModal(); setRating(parseInt(data.Rating)); setComment(data.Comments); setData(data.Brand_Review_Id); }} />
                                                            ) : (null)}
                                                        </View>
                                                        <View style={{ marginTop: -8 }}>
                                                            <Rating
                                                                readonly={true}
                                                                type='heart'
                                                                tintColor={esiColor.TintColor}
                                                                startingValue={data?.Rating}
                                                                ratingCount={5}
                                                                imageSize={20}
                                                                onFinishRating={(rating) => ratingCompleted(rating)}
                                                                style={{ paddingVertical: 10 }}
                                                            />
                                                        </View>
                                                    </View>
                                                    <View style={{ backgroundColor: esiColor.RTBC, flexWrap: "wrap", flex: 1 }}>
                                                        {data.Comments &&

                                                            <RenderHTML tagsStyles={{
                                                                span: { color: esiColor.DescColor }
                                                            }}
                                                                source={{ html: data?.Comments }} />
                                                        }
                                                    </View>
                                                </View>
                                            </View>

                                        </View>
                                    ))}
                                    <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                                        {brandReviews?.length > visible &&
                                            <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: esiColor.GBFColor }} onPress={() => setVisible(visible + 10)}>Show More ▼</Text>
                                        }
                                    </View>
                                </>
                            ))}
                    </View>
                </Card>

            </ScrollView>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7 }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            {/* For Add Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isReviewModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7 }}>
                    <View style={{
                        alignItems: 'flex-end',
                    }}>
                        <TouchableRipple style={{ width: 30 }} onPress={() => setReviewModalVisible(false)}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={30} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <TextInput
                                style={{ backgroundColor: esiColor.BackgroundColor, maxHeight: 150, minWidth: 250, maxWidth: 250, }}
                                theme={{ colors: { text: esiColor.Text, placeholder: esiColor.itemColor } }}
                                outlineColor={esiColor.itemColor}
                                activeOutlineColor={esiColor.itemColor}
                                placeholderTextColor={esiColor.SPHTextColor}
                                underlineColor={esiColor.itemColor}
                                label='Add Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); handleOnChange(Text); setCommentError("") }}
                                multiline={true}
                                numberOfLines={4}
                                value={comment}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <Rating
                                type='heart'
                                tintColor={esiColor.TintColor}
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value); setRatingError("") }}
                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red" }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }
                            <View style={{ width: 100 }}>
                                <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5, marginTop: 10 }]} onPress={() => addReviewModal()}>
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            {/* For Edit Review */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isEditReviewModalVisible}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 300,
                    borderRadius: 10, backgroundColor: esiColor.BackgroundColor,
                    shadowColor: esiColor.brandShadowColor, shadowRadius: 7, shadowOpacity: 7
                }}>
                    <View style={{
                        alignItems: 'flex-end',
                    }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setEditReviewModalVisible(false), setComment(''), setRating(0) }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={30} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View style={{ margin: '4%' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <TextInput
                                style={{ backgroundColor: esiColor.BackgroundColor, maxHeight: 150, minWidth: 250, maxWidth: 250, }}
                                theme={{ colors: { text: esiColor.Text, placeholder: esiColor.itemColor } }}
                                outlineColor={esiColor.itemColor}
                                activeOutlineColor={esiColor.itemColor}
                                placeholderTextColor={esiColor.SPHTextColor}
                                underlineColor={esiColor.itemColor}
                                label='Edit Review'
                                mode='outlined'
                                onChangeText={(Text) => { setComment(Text); editOnChange(Text); setCommentError("") }}
                                multiline={true}
                                numberOfLines={4}
                                value={comment}
                            />
                        </View>
                        <Text style={{ color: 'red', textAlign: "center" }}>{commenterror}</Text>

                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }}>
                            <Rating
                                type='heart'
                                ratingBackgroundColor={esiColor.BackgroundColor}
                                tintColor={esiColor.TintColor}
                                startingValue={rating}
                                ratingCount={5}
                                imageSize={30}
                                style={{ paddingVertical: 10 }}
                                onFinishRating={(value) => { setRating(value); setRatingError("") }}
                            />
                            {!rating || rating === 0 ? (
                                <Text style={{ color: "red", fontSize: 16 }}>
                                    Please select the Rating.
                                </Text>
                            ) : (null)
                            }
                            <View style={{ width: 100 }}>
                                <TouchableOpacity style={[styles.centerElement, { backgroundColor: esiColor.globalButtonColor, width: 100, height: 25, borderRadius: 5, marginTop: 10 }]}
                                    onPress={() => editReviewModal()} >
                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </Surface>
    );
}

