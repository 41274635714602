import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl } from "react-native";
// import { ScrollView } from "react-native-gesture-handler";
import { Searchbar, Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import ordergif from "../../assets/images/GIF/noServicesImg.png";
import { getMyServices } from "../../state/actions/servicesAction";


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
export default function MyServices(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const [dimensions, setDimensions] = useState({ window, screen });
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions({ window, screen });
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions.window;
    const windowWidth = dimensions.window.width;
    const windowHeight = dimensions.window.height;
    const esiColor = useSelector(state => state.theme);

    const [showDropDown, setShowDropDown] = React.useState(false);
    const [values, setValues] = React.useState()
    const [page, setPage] = React.useState(0);

    const [ordersFilter, setOrdersFilter] = useState({ select: "", search: "" });

    let myServices = useSelector((state) => state.services.all);

    const dispatch = useDispatch();
    const filterOptions = [
        {
            value: 'All',
            label: 'All'
        }

    ];
    const [oldSearch, setOldSearch] = React.useState(0);
    const checkOrdersForTable = () => {
        if (ordersFilter.search) {
            try {
                if (ordersFilter.select === "All") {
                    return myServices.filter(x => {
                        let colummns = ["User_Service_Request_Status", "User_Service_Request_Id", "Service_Type", "Service_Required_Date", "Service_Price"];
                        for (let i = 0; i < colummns.length; i++) {
                            let mainString = typeof x[colummns[i]] == "string" ?
                                (colummns[i] === "User_Service_Request_Status" ?
                                    x[colummns[i]]?.split("-").map((value => value)).join(" ")
                                    : x[colummns[i]]).toLowerCase()
                                : x[colummns[i]];


                            const subString = typeof x[colummns[i]] === "string" ? ordersFilter.search.toLowerCase() : ordersFilter.search;
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(ordersFilter.search.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString == ordersFilter.search) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                }
            } finally {
                if (page > 0) {
                    if (oldSearch != ordersFilter.search.length) {
                        setOldSearch(ordersFilter.search.length);
                        setPage(0);
                    }
                }
                else if (oldSearch != ordersFilter.search.length) {
                    setOldSearch(ordersFilter.search.length);
                    setPage(0);
                }
            }
        }
        else {
            return myServices
        }
    }
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Product_Color_Variant_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };
    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
                return -1;
            }
            if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
                return 1;
            }
            return 0;
        } catch (error) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    }
    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleChange = itemValue => {
        setOrdersFilter({ ...ordersFilter, select: itemValue })
    };
    const handleSearch = itemValue => {
        setOrdersFilter({ ...ordersFilter, search: itemValue })
    };
    const data = stableSort(
        checkOrdersForTable(), getComparator(order, orderBy))
    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            setOrdersFilter({ ...ordersFilter, search: "" });
            setOrdersFilter({ ...ordersFilter, select: 'All' })
            dispatch(getMyServices({
                "Records_Filter": "FILTER",
                "Email_Id": logedInEmail
            }))
        } else {
            navigation.navigate("home");
        }
    }, [logedInEmail]);

    const styles = StyleSheet.create({
        
        text: {
            fontSize: 14,
            fontFamily: 'system-ui',
            color: esiColor.DescColor
        }
    });
    
    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />

            <View style={{ flexDirection: "row", marginTop: 15, marginBottom: 15, justifyContent: 'space-evenly' }}>

                <View style={{ flex: 5, marginLeft: 15 }}>
                    <Searchbar
                        style={{
                            marginHorizontal: 10, borderColor: esiColor.SBorderColor, borderWidth: 1, maxWidth: 200, marginRight: 10,
                            backgroundColor: esiColor.BackgroundColor, height: 30,
                        }}
                        placeholder="Search"
                        iconColor={esiColor.SIconColor}
                        theme={{ colors: { text: esiColor.SBTextColor, fontSize: 5 } }}
                        placeholderTextColor={esiColor.SPHTextColor}
                        onChangeText={(value) => handleSearch(value)}
                        value={ordersFilter.search}
                    />
                </View>
            
                <TouchableOpacity style={{
                    backgroundColor: esiColor.globalButtonColor, borderRadius: 7,
                    paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10,
                    maxWidth: 150, height: 30, justifyContent: 'center', alignItems: 'center'
                }} onPress={(e) => {
                    navigation.navigate("Services");
                }}>
                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>New Service</Text>
                </TouchableOpacity>

            </View>
            <ScrollView showsVerticalScrollIndicator={false} style={{ height: windowHeight - ((windowHeight / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={() => {
                            dispatch(getMyServices({
                                "Records_Filter": "FILTER",
                                "Email_Id": logedInEmail
                            }))
                        }}
                    />
                }
            >
                <View>
                    {data.length <= 0 ?
                        <View style={{ alignItems: "center", marginTop: 10 }}>

                            <View>
                                <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image>
                                <Text style={{ textAlign: "center", color: esiColor.NoDataFound, marginTop: 15 }}>Oops! No Services found...</Text>
                            </View>

                        </View>
                        :
                        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap', width: width, justifyContent: 'space-evenly' }} >

                            {data.map((data, index) => (
                                <TouchableOpacity key={index} onPress={() => {
                                    navigation.navigate('ServiceDetails', { serviceId: data.User_Service_Request_Id });
                                }}>
                                    <View key={index} style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        shadowOffset: { width: 2, height: 2 },
                                        shadowOpacity: 7,
                                        shadowRadius: 7,
                                        // marginHorizontal: 2,
                                        borderRadius: 10,
                                        backgroundColor: esiColor.CBColor,
                                        marginTop: 15, flexDirection: 'row',
                                        marginBottom: 20,
                                        alignItems: 'flex-start',
                                        // alignContent: 'center',
                                        // flex: 1,
                                        width: width / 4,
                                        // marginLeft: 15, marginRight: 15,
                                        height: 125,
                                        margin: 15,
                                    }}>
                                        <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                            <Image source={{ uri: data.Service_Image + `?w=80&h=100` }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{data?.Service_Type}</Text>
                                            <Text style={styles.text}>Service ID : {data.User_Service_Request_Id}</Text>
                                            <Text style={styles.text}>Service Status : <Text style={{ color: data.User_Service_Request_Status === "NEW" ? esiColor.Text : data.User_Service_Request_Status === "COMPLETED" ? esiColor.GBFColor : data.User_Service_Request_Status === "CANCELLED" ? "red" : data.User_Service_Request_Status === "RETURN" ? "orange" : esiColor.itemColor }}>
                                                {data.User_Service_Request_Status?.split("-").map((value => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()))}
                                            </Text>
                                            </Text>
                                            <Text style={styles.text}>Date Of Service: {data?.Service_Required_Date}</Text>
                                            <Text style={styles.text}>Service Amount : {data?.Service_Price}</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>

                            ))}
                        </View>
                    }
                </View>
            </ScrollView>
        </Surface>

    )
}
