//  import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme, createNavigationContainerRef } from '@react-navigation/native';
import * as React from 'react';
import { ColorSchemeName, TouchableOpacity, View, Dimensions, Platform } from 'react-native';

import AllCategory from '../screens/all-category/AllCategory';
import AllCoupons from '../screens/all-coupons/AllCoupons';
import AllDeals from '../screens/Deals/AllDeals';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import AllPartners from '../screens/Partners/AllPartners';
import SubCategory from '../screens/SubCategory/subcategories';
import LinkingConfiguration from './LinkingConfiguration';
import AllBrands from '../screens/brands/Allbrands';
import { EsiCityDrawerStackParamList, EsiDrawerStackParamList, RootStackParamList } from '../../types';
import AllProducts from '../screens/products/allProducts';
// import ProductDetails from '../screens/products/ProductDetails';
import { createDrawerNavigator } from '@react-navigation/drawer';
import EsiDrawer from '../screens/Drawer';
import UserRefer from '../screens/refer and earn/refer';
import Earn from '../screens/refer and earn/earn';
import AllFilters from '../screens/Drawer/all';
import AllPages from '../screens/LeftDrawer/leftContent';
import Useraddresses from '../screens/useraddresses/Useraddresses';
import MissingCachback from '../screens/missing-cashback/Missing-Cashback-get';
import AddAddress from '../screens/useraddresses/AddAddress';
import EditAddress from '../screens/useraddresses/EditAddress';
import BrandDetails from '../screens/brand-details/BrandDetails';
import PartnerDetails from '../screens/partner-details/PartnerDetails';
import NewsAndEvents from '../screens/AllNewsAndEvents/newsandevents';
import AllContent from '../screens/CMS/PrivacyPolicy';
import TermsAndConditions from '../screens/CMS/TermsandConditions';
import ContactUs from '../screens/CMS/ContactUs';
import Homee from '../screens/home/Home';
//  import StoreDetails from '../screens/store-details/StoreDetails';
import AddMissingCashback from '../screens/missing-cashback/Add-Missing-Cashback';
import ProfileDetails from '../screens/user-profile/ProfileDetails';
import Withdrawals from '../screens/userwallet/Withdrawals';
import WalletHistory from '../screens/userwallet/WithdrawHistory';
//  import { Text } from 'react-native-paper';
import { useSelector } from 'react-redux';
import BuyGift from '../screens/GiftCard/buy-gifts/BuyGifts';
import GiftCard from '../screens/GiftCard/SendGifts';
import AllCities from '../screens/all-cities/allCities';
// import AllCombination from '../screens/all-city-combinations/AllCityCombination';
import HowCashbackWorks from '../screens/CMS/HowCashbackWorks';
import WishList from '../screens/Mywishlist/wishlist';
import ReviewsAndRatings from '../screens/reviews-and-ratings/ReviewsandRatings';
import Cart from '../screens/cart/Cart';
import EsiCityDrawer from '../screens/CityDrawer';
import AllShopbycityFilters from '../screens/CityDrawer/allshopbycity';
import SearchProduct from '../screens/SearchProduct/globalSearch'
import AllCombinations from '../screens/all-Combinations/AllCombination';
import Dashboard from '../screens/Dashboard/dashboard';
import Notification from '../screens/notification/Notification';
import MyOrders from '../screens/myOrders/orders';
import MyOrderDetails from '../screens/orderDetails/orderDetails';
import CartSummary from '../screens/cart/CartSummary';
import AllFaq from '../screens/AllFAQ/FAQ';
import Ticket from '../screens/Ticket/ticket-get';
import AddTicket from '../screens/Ticket/Add-Ticket';
import Comments from '../screens/Ticket/comments';
import SignUPPassword from '../screens/auth/signup-set-password';
import ActivateAccount from '../screens/auth/activate-account';
import MarketingTerms from '../screens/CMS/MarketingTerms';
//  import AllFinancialTransaction from '../screens/Financial-Transactions/all-financial-transactions';
//  import AllTransaction from '../screens/transaction-history/myordertransaction';
import DeliveryTracking from '../screens/orderDetails/deliveryTracking';
import ShopbyStoreType from '../screens/shop-by-store-types/ShopbyStoreTypes';
import PartnerPrivacypolicy from '../screens/CMS/partners/PrivacyPolicy';
import PartnerTermsAndConditions from '../screens/CMS/partners/TermsandConditions';
import Rides from '../screens/Rides/Rides';
import MyRides from '../screens/Rides/MyRides';
import RideDetails from '../screens/Rides/RideDetails/RideDetails';

import MyServices from '../screens/Services/MyServices';
import Services from '../screens/Services/Services';
import ServiceDetails from '../screens/Services/ServiceDetails/ServiceDetails';

import BecomeOurPartner from '../screens/CMS/BecomeOurPartner';
import AntiSpam from '../screens/CMS/AntiSpamPolicy';
import Returns_Cancellations from '../screens/CMS/Returns&Cancellations';
import AboutUs from '../screens/CMS/AboutUs';
import Advertise_withus from '../screens/CMS/Advertise';
import PartnerProducts from '../screens/partner-details/PartnerProducts';
import NewProducts from '../screens/products/NewProducts';
import ProductDetailsNew from '../screens/products/ProductDetailsNew';

// for Bidding...
import MyBidProducts from '../screens/bidding/products/ListOfBids';
import NewBidProducts from '../screens/bidding/products/NewBiddingProducts';
import CartOfBids from '../screens/bidding/products/CartOfBids';
import BidProductDetails from '../screens/bidding/products/BidProductDetails/ProductDetails';
import BidPartnerProductDetails from '../screens/bidding/products/BidProductDetails/PartnerProductDetails';
import MyBidRides from '../screens/bidding/rides/ListOfBids';
import CartOfRideBids from '../screens/bidding/rides/CartOfBids';
import NewBidRide from '../screens/bidding/rides/NewRideBidding';
import BidRideDetails from '../screens/bidding/rides/BidRideDetails/RideDetails';
import BidPartnerRideDetails from '../screens/bidding/rides/BidRideDetails/PartnerRideDetails';
import NewBiddingServices from '../screens/bidding/services/NewBiddingServices';
import CartOfServiceBids from '../screens/bidding/services/CartOfServiceBids';
import MyBidServices from '../screens/bidding/services/ListOfBids';
import BidServiceDetails from '../screens/bidding/services/BidServiceDetails/ServiceDetails';
import BidPartnerServiceDetails from '../screens/bidding/services/BidServiceDetails/PartnerServiceDetails';
import MFHProducts from '../screens/MFH/mfhProducts';
import MFHProductDetails from '../screens/MFH/MFHProductDetails';
import allFarmers from '../screens/Farmers/allFarmers';
import FarmerDetails from '../screens/farmer-details/FarmerDetails';
import FarmerProducts from '../screens/farmer-details/FarmerProducts';

const { height, width } = Dimensions.get('window');

export const navigationRef = createNavigationContainerRef();

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <BottomNavigator />
    </NavigationContainer>
  );
}

function MyTabBar(props) {

  const { navigation, ...rest } = props;
  //  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
    <View>


    </View >
  );
}
const Tab = createBottomTabNavigator();
function BottomNavigator() {
  return (
    <Tab.Navigator screenOptions={{ tabBarHideOnKeyboard: true }} tabBar={props => <MyTabBar {...props} />}>
      <Tab.Screen name="HomeMain" component={RootNavigator}
        options={{ headerShown: false }}
      />
    </Tab.Navigator>
  );
}

const HomeStack = createDrawerNavigator<EsiDrawerStackParamList>();

function AllNavigator() {
  return (
    <HomeStack.Navigator screenOptions={{
      drawerPosition: "right"
    }} drawerContent={(props) => <EsiDrawer {...props} />}>
      <HomeStack.Screen name="All" component={AllFilters} options={{ headerShown: false }} />
    </HomeStack.Navigator>

  );

}
const HomeCityStack = createDrawerNavigator<EsiCityDrawerStackParamList>();
function AllCityNavigator() {
  return (
    <HomeCityStack.Navigator screenOptions={{
      drawerPosition: "right"
    }} drawerContent={(props) => <EsiCityDrawer {...props} />}>
      <HomeCityStack.Screen name="AllCity" component={AllShopbycityFilters} options={{ headerShown: false }} />
    </HomeCityStack.Navigator>
  );
}


/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createDrawerNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator backBehavior="history" drawerContent={(props) => <AllPages {...props} />}>
      <Stack.Screen name="Home" component={Homee} options={{ headerShown: false }} />
      <Stack.Screen name="Main" component={AllNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="MainCity" component={AllCityNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Coupons" component={AllCoupons} options={{ headerShown: false }} />
      <Stack.Screen name="Category" component={AllCategory} options={{ headerShown: false }} />
      <Stack.Screen name="SubCategory" component={SubCategory} options={{ headerShown: false }} />
      <Stack.Screen name="Partners" component={AllPartners} options={{ headerShown: false }} />
      <Stack.Screen name="deals" component={AllDeals} options={{ headerShown: false }} />
      <Stack.Screen name="NewProducts" component={NewProducts} options={{ headerShown: false }} />
      <Stack.Screen name="Brands" component={AllBrands} options={{ headerShown: false }} />
      <Stack.Screen name="Products" component={AllProducts} options={{ headerShown: false }} />
      {/* <Stack.Screen name="ProductDetails" component={ProductDetails} options={{ headerShown: false }} /> */}
      <Stack.Screen name="ProductDetails" component={ProductDetailsNew} options={{ headerShown: false }} />

      <Stack.Screen name="refer" component={UserRefer} options={{ headerShown: false }} />
      <Stack.Screen name="earn" component={Earn} options={{ headerShown: false }} />
      <Stack.Screen name="MissingCashback" component={MissingCachback} options={{ headerShown: false }} />
      <Stack.Screen name="UserAddresses" component={Useraddresses} options={{ headerShown: false }} />
      <Stack.Screen name="AddAddress" component={AddAddress} options={{ headerShown: false }} />
      <Stack.Screen name="EditAddress" component={EditAddress} options={{ headerShown: false }} />
      <Stack.Screen name="BrandDetails" component={BrandDetails} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerDetails" component={PartnerDetails} options={{ headerShown: false }} />
      <Stack.Screen name="faq" component={AllFaq} options={{ headerShown: false }} />
      <Stack.Screen name="NewsAndEvents" component={NewsAndEvents} options={{ headerShown: false }} />
      <Stack.Screen name="PrivacyPolicy" component={AllContent} options={{ headerShown: false }} />
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} options={{ headerShown: false }} />
      <Stack.Screen name="MarketingTerms" component={MarketingTerms} options={{ headerShown: false }} />
      <Stack.Screen name="ContactUs" component={ContactUs} options={{ headerShown: false }} />
      <Stack.Screen name="Homee" component={Homee} options={{ headerShown: false }} />
      {/* <Stack.Screen name="store-details" component={StoreDetails} options={{ headerShown: false }} /> */}
      <Stack.Screen name="AddMissingCashback" component={AddMissingCashback} options={{ headerShown: false }} />
      <Stack.Screen name="ProfileDetails" component={ProfileDetails} options={{ headerShown: false }} />
      <Stack.Screen name="Withdrawals" component={Withdrawals} options={{ headerShown: false }} />
      <Stack.Screen name="WalletHistory" component={WalletHistory} options={{ headerShown: false }} />
      <Stack.Screen name="BuyGift" component={BuyGift} options={{ headerShown: false }} />
      <Stack.Screen name="GiftCard" component={GiftCard} options={{ headerShown: false }} />
      <Stack.Screen name="allCities" component={AllCities} options={{ headerShown: false }} />
      {/* <Stack.Screen name="AllCitycombinations" component={AllCombination} options={{ headerShown: false }} /> */}
      <Stack.Screen name="HowCashbackWorks" component={HowCashbackWorks} options={{ headerShown: false }} />
      <Stack.Screen name="WishList" component={WishList} options={{ headerShown: false }} />
      <Stack.Screen name="ReviewsAndRatings" component={ReviewsAndRatings} options={{ headerShown: false }} />
      <Stack.Screen name="Cart" component={Cart} options={{ headerShown: false }} />
      <Stack.Screen name="CartSummary" component={CartSummary} options={{ headerShown: false }} />
      <Stack.Screen name="searchproduct" component={SearchProduct} options={{ headerShown: false }} />
      <Stack.Screen name="SearchResults" component={AllCombinations} options={{ headerShown: false }} />
      <Stack.Screen name="Dashboard" component={Dashboard} options={{ headerShown: false }} />
      <Stack.Screen name="Notification" component={Notification} options={{ headerShown: false }} />
      {/* <Stack.Screen name="Transactions" component={AllTransaction} options={{ headerShown: false }} /> */}
      <Stack.Screen name="MyOrders" component={MyOrders} options={{ headerShown: false }} />
      <Stack.Screen name="OrderDetails" component={MyOrderDetails} options={{ headerShown: false }} />
      <Stack.Screen name="OrderTracking" component={DeliveryTracking} options={{ headerShown: false }} />
      <Stack.Screen name="Ticket" component={Ticket} options={{ headerShown: false }} />
      <Stack.Screen name="AddTicket" component={AddTicket} options={{ headerShown: false }} />
      <Stack.Screen name="Comments" component={Comments} options={{ headerShown: false }} />
      <Stack.Screen name="SetPassword" component={SignUPPassword} options={{ headerShown: false }} />
      <Stack.Screen name="ActivateAccount" component={ActivateAccount} options={{ headerShown: false }} />
      {/* <Stack.Screen name="FinancialTransactions" component={AllFinancialTransaction} options={{ headerShown: false }} /> */}
      <Stack.Screen name="ShopbyStoreType" component={ShopbyStoreType} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerPrivacyPolicy" component={PartnerPrivacypolicy} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerTermsAndConditions" component={PartnerTermsAndConditions} options={{ headerShown: false }} />
      <Stack.Screen name="Rides" component={Rides} options={{ headerShown: false }} />
      <Stack.Screen name="MyRides" component={MyRides} options={{ headerShown: false }} />
      <Stack.Screen name="RideDetails" component={RideDetails} options={{ headerShown: false }} />

      <Stack.Screen name="Services" component={Services} options={{ headerShown: false }} />
      <Stack.Screen name="MyServices" component={MyServices} options={{ headerShown: false }} />
      <Stack.Screen name="ServiceDetails" component={ServiceDetails} options={{ headerShown: false }} />

      {/* for bidding */}
      <Stack.Screen name="BidProducts" component={MyBidProducts} options={{ headerShown: false }} />
      <Stack.Screen name="CartBidProducts" component={CartOfBids} options={{ headerShown: false }} />
      <Stack.Screen name="NewBidProducts" component={NewBidProducts} options={{ headerShown: false }} />
      <Stack.Screen name="BidProductDetails" component={BidProductDetails} options={{ headerShown: false }} />
      <Stack.Screen name="BidPartnerProductDetails" component={BidPartnerProductDetails} options={{ headerShown: false }} />

      <Stack.Screen name="BidRides" component={MyBidRides} options={{ headerShown: false }} />
      <Stack.Screen name="CartBidRides" component={CartOfRideBids} options={{ headerShown: false }} />
      <Stack.Screen name="NewBidRides" component={NewBidRide} options={{ headerShown: false }} />
      <Stack.Screen name="BidRideDetails" component={BidRideDetails} options={{ headerShown: false }} />
      <Stack.Screen name="BidPartnerRideDetails" component={BidPartnerRideDetails} options={{ headerShown: false }} />

      <Stack.Screen name="BidServices" component={MyBidServices} options={{ headerShown: false }} />
      <Stack.Screen name="NewBidServices" component={NewBiddingServices} options={{ headerShown: false }} />
      <Stack.Screen name="CartBidServices" component={CartOfServiceBids} options={{ headerShown: false }} />
      <Stack.Screen name="BidServiceDetails" component={BidServiceDetails} options={{ headerShown: false }} />
      <Stack.Screen name="BidPartnerServiceDetails" component={BidPartnerServiceDetails} options={{ headerShown: false }} />

      <Stack.Screen name="BecomeourPartner" component={BecomeOurPartner} options={{ headerShown: false }} />
      <Stack.Screen name="AntiSpamPolicy" component={AntiSpam} options={{ headerShown: false }} />
      <Stack.Screen name="ReturnsCancellations" component={Returns_Cancellations} options={{ headerShown: false }} />
      <Stack.Screen name="AboutUs" component={AboutUs} options={{ headerShown: false }} />
      <Stack.Screen name="Advertise" component={Advertise_withus} options={{ headerShown: false }} />
      <Stack.Screen name="PartnerProducts" component={PartnerProducts} options={{ headerShown: false }} />
      <Stack.Screen name="MFH" component={MFHProducts} options={{ headerShown: false }} />
      <Stack.Screen name="MFHDetails" component={MFHProductDetails} options={{ headerShown: false }} />
      {/* for farmer module dev */}
      <Stack.Screen name="Farmers" component={allFarmers} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerDetails" component={FarmerDetails} options={{ headerShown: false }} />
      <Stack.Screen name="FarmerProducts" component={FarmerProducts} options={{ headerShown: false }} />

      <Stack.Group >
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}