import React, { useEffect, useMemo, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl, ActivityIndicator, FlatList } from "react-native";
import { Avatar, Button, Checkbox, HelperText, IconButton, Surface, Text, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import { useRoute } from "@react-navigation/native";
import { FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
// import esiColor from "../../../constants/Colors";
import ESITextInput from "../../../components/custom-fields/textInput";
import EsiTextAreaInput from "../../../components/custom/TextAreaInput/textareainput";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown1";
import { Formik } from "formik";
import * as Yup from 'yup';
import Modal from "react-native-modal";
import { useToast } from '../../../self_modules/react-native-paper-toast/src';
import { deleteCartBids, placeCartBids, updateCartBids } from "../../../state/actions/boomi-actions/bid-ride-actions";
import EsiDatePicker from "../../../components/custom/date/date";
import { getBiddingRidesCart } from "../../../state/actions/node-actions/bid-rides-actions";


export default function CartOfRideBids(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions;
    const esiColor = useSelector(state => state.theme);


    const [loading, setIsLoading] = React.useState(false);
    const [isSingle, setIsSingle] = React.useState(false);
    const [myBids, setMyBids] = React.useState([]);

    const toast = useToast();

    const dispatch = useDispatch();

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setMyBids(data);
        }
    }

    const getMyCartBids = () => {
        dispatch(getBiddingRidesCart({ User_Email_ID: logedInEmail },
            callBackGetData));
        setIsLoading(true);
    }

    const route = useRoute();

    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            getMyCartBids();
        } else {
            navigation.navigate("Home");
        }
    }, [logedInEmail, route]);

    const deleteCartAction = (item) => {
        setIsLoading(true);
        dispatch(deleteCartBids({ Bidding_Ride_Cart_Id: item?.Bidding_Ride_Cart_Id }, deleteCallbackResponse));

    }

    const placeCartAction = () => {
        setIsLoading(true);
        dispatch(placeCartBids({ User_Email_Id: logedInEmail }, placeCallbackResponse));
    }

    const placeCallbackResponse = (status: boolean) => {
        setIsLoading(false);
        if (status) {
            getMyCartBids();
            navigation.navigate("BidRides");
        }
    }

    const deleteCallbackResponse = (status: boolean) => {
        setIsLoading(false);
        if (status) {
            getMyCartBids();
            navigation.navigate("BidRides");
            toast.show({ message: 'Bidding Ride Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        } else {
            toast.show({ message: 'We are facing some issues on user ride. We will get back to you shortly. Please try again in some time', type: 'info', duration: 3000, position: 'top' });
        }
    }

    const editCartAction = (item) => {
        setAddCartItem(item);
        setAddCartPop(true);
    }

    const [addCartPop, setAddCartPop] = React.useState(false);
    const [addCartItem, setAddCartItem] = React.useState({});
    const [loadingForm, setloadingForm] = React.useState(false);
    const [formResults, setFormResults] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const formCallbackResponse = (status: boolean, erorMessage: string) => {
        setloadingForm(false);
        if (status) {
            setFormResults(true);
            getMyCartBids();
        } else {
            setErrorMessage(erorMessage);
        }
    }
    let nDate = new Date();
    nDate.setDate(nDate.getDate() + 2);
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);
    const datediff = (first, second) => {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    const styles = StyleSheet.create({

        containerstyle: {
            maxWidth: 200,
        },
        text: {
            fontSize: 14,
            fontFamily: 'system-ui',
            color: esiColor.itemColor
        }
    });


    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10 }}>
                <View style={{ flexDirection: 'row' }}>
                    <IconButton
                        icon="arrow-left"
                        // size={30}
                        color={esiColor.SIconColor}
                        // style={{ marginVertical: -10, flex: 1, marginLeft: 0 }}
                        onPress={() => {
                            if (navigation.canGoBack())
                                navigation.goBack();
                            else navigation.navigate("BidRides")

                        }} />
                    {/* <View style={{ flex: 11 }}> */}
                    <Text style={{ fontSize: 25, fontWeight: 'bold', alignSelf: "center", color: esiColor.brandFontColor }}>
                        Rides Cart
                    </Text>
                    {/* </View> */}
                </View>
                <ScrollView style={{ height: height - ((height / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={() => {
                                getMyCartBids();
                            }}
                        />
                    }
                >
                    {myBids.length <= 0 ?
                        <View style={{ alignItems: "center", marginTop: 10 }}>

                            <View >
                                <View style={{ justifyContent: 'center', alignItems: 'center', }}>
                                    <Image style={{ width: 130, height: 130 }} source={require('../../../assets/images/Cart_empty.png')}  />
                                    <Text style={{ fontWeight: 'bold', fontSize: 30, margin: 5, color: esiColor.NoDataFound }}> Your Cart is Empty.</Text>
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', margin: 5, color: esiColor.Text }}>Add Vehicles to create a bidding ride service.</Text>
                                    <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 10, padding: 10, margin: 5 }} onPress={() => navigation.navigate('NewBidRides')}><Text style={{ fontWeight: 'bold', color: esiColor.itemButtenColor }}>Bid Now</Text></TouchableOpacity>

                                </View>
                            </View>

                        </View>
                        :
                        <View>
                            <FlatList
                                numColumns={3}
                                data={myBids || []}
                                renderItem={({ item }) => (
                                    <View style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        // shadowOffset: { width: -2, height: 4 },
                                        shadowOpacity: 5,
                                        shadowRadius: 7,
                                        marginHorizontal: 2, borderRadius: 10, padding: 2,
                                        backgroundColor: esiColor.CBColor,
                                        marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                        minWidth: (Platform.OS === 'web' ? '30%' : '95%'),
                                        marginLeft: 15, marginRight: 15, marginBottom: 15,
                                        maxHeight: height > 250 ? 250 : 90,
                                        minHeight: 100,
                                    }}>
                                        <View style={{ flex: 3, alignContent: 'center', marginLeft: 10 }} >
                                            <Image source={{ cache: "force-cache", uri: item?.Image + `?w=80&h=100` }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 6, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.DescColor }}>{item?.Vehicle_Type + " - " + item?.Vehicle_Comfort_Type + " - "}
                                                <MaterialCommunityIcons name="seat-passenger" size={18} color={esiColor.DescColor} /> {item?.Seating_Capacity}</Text>
                                            {item?.Brand_Name &&
                                                <Text style={{ color: esiColor.DescColor, fontSize: 16 }}>Brand: {item?.Brand_Name} </Text>
                                            }
                                            <Text style={styles.text}>Quantity: {item?.Quantity}</Text>
                                            <Text style={styles.text}>Description : {item?.Description}</Text>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-around", alignSelf: "center" }}>
                                            <TouchableOpacity style={{ marginVertical: 6 }} onPress={(e) => { editCartAction(item); }} >
                                                <FontAwesome5 name="edit" size={22} color={esiColor.SIconColor} />
                                            </TouchableOpacity>
                                            <TouchableOpacity style={{ marginVertical: 6 }} onPress={(e) => { deleteCartAction(item) }} >
                                                <Ionicons name="md-trash" size={22} color="#ee4d2d" />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                keyExtractor={(item, index) => index}
                            />
                            {myBids.length > 1 &&
                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>


                                    <TouchableOpacity
                                        onPress={() => {
                                            setIsSingle(state => !state)
                                        }}
                                    >
                                        <View style={{
                                            flexDirection: "row",
                                        }}>
                                            <Checkbox.Android
                                                status={isSingle ? 'checked' : 'unchecked'}
                                                uncheckedColor={esiColor.SBorderColor}
                                            />
                                            <Text style={{
                                                marginTop: 10,
                                                color: esiColor.itemColor
                                            }}>{"Is single request"}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            }
                            {(isSingle && myBids.length > 1) ?
                                <Formik
                                    initialValues={
                                        {
                                            User_Email_Id: logedInEmail,
                                            Description: "",
                                            Pincode: '',
                                            Bidding_Expiry_Date: '',
                                            No_Of_Days: "",
                                            Distance_In_KMS: "",
                                            Ride_Start_Date: ""
                                        }
                                    }
                                    validationSchema={Yup.object().shape({
                                        No_Of_Days: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required.").typeError('Input must be number.'),
                                        Distance_In_KMS: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required.").typeError('Input must be number.'),
                                        Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                                        Bidding_Expiry_Date: Yup.string().required('Date of service is required.').test("date-of-bidding", "Service Date should be more then " + dos + ".", (value: any) => {
                                            if (value) {
                                                let date1 = new Date(value).getTime();
                                                let date2 = new Date(dos).getTime();
                                                if (datediff(date2, date1) > -1) {
                                                    return true;
                                                }
                                            }
                                            return false;

                                        }),
                                        Ride_Start_Date: Yup.string().required('Ride Start Date is required.')
                                    })}
                                    onSubmit={(values, actions) => {
                                        let date1 = new Date(values.Bidding_Expiry_Date).getTime();
                                        let date2 = new Date(values.Ride_Start_Date).getTime();
                                        if (datediff(date2, date1) > -1) {
                                            actions.setFieldError("Ride_Start_Date", "Ride Date should be after Bidding Expiry Date");
                                        } else {
                                            setloadingForm(true);
                                            dispatch(placeCartBids(
                                                {
                                                    "User_Email_Id": values.User_Email_Id,
                                                    "IsMultiple": 1,
                                                    "Bidding_Expiry_Date": values.Bidding_Expiry_Date,
                                                    "Ride_Start_Date": values.Ride_Start_Date,
                                                    "Pincode": values.Pincode,
                                                    "Description": values.Description,
                                                    "No_Of_Days": values.No_Of_Days,
                                                    "No_Of_Kms": values.Distance_In_KMS
                                                }
                                                , placeCallbackResponse));
                                        }

                                    }}
                                >
                                    {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                        <View style={{
                                            flexDirection: "row", justifyContent: "center",
                                        }}>
                                            {loadingForm ?
                                                <ActivityIndicator color={esiColor.esiMainColor} />
                                                :
                                                formResults ?
                                                    <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                                        <Image source={require('../../../assets/images/GIF/cart-success.gif')}
                                                            style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                                                        <View style={{ width: "90%", margin: "auto" }}>
                                                            <Text style={{ textAlign: "center", fontWeight: "bold", color: esiColor.itemColor }} >Successfully updated cart item.
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    :


                                                    <View style={{ flex: 1, flexDirection: "column", marginHorizontal: 10, justifyContent: "center", marginBottom: 20, marginTop: 10, width: '50%' }}>
                                                        <View style={{ width: "30%", minHeight: 70, margin: "auto" }}>

                                                            <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Description:</Text>
                                                            <EsiTextAreaInput
                                                                error={Boolean(touched.Description && errors.Description)}
                                                                containerStyle={{ height: 170, }}
                                                                style={{ height: 170 }}
                                                                mode='outlined'
                                                                value={values.Description}
                                                                textareavalueUpdate={value => {
                                                                    setFieldValue("Description", value)
                                                                }}
                                                                placeholder={'Please provide additional information'}
                                                                underlineColorAndroid={'transparent'}
                                                                theme={{
                                                                    colors: {
                                                                        primary: "#27b6cc"
                                                                    },
                                                                }}
                                                            />
                                                        </View>
                                                        <View style={{ width: "25%", margin: "auto", marginTop: 5 }}>
                                                            <ESITextInput
                                                                style={{ width: "100%" }}
                                                                label="Pincode*"
                                                                mode='outlined'
                                                                onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={values.Pincode}
                                                                error={Boolean(touched.Pincode && errors.Pincode)}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                                {touched.Pincode && errors.Pincode}
                                                            </HelperText>
                                                        </View>
                                                        <View style={{ width: "25%", margin: "auto", marginTop: 5 }}>
                                                            <EsiDatePicker
                                                                style={{ margin: "auto" }}
                                                                name={'Bidding_Expiry_Date'}
                                                                label='Bidding Expiry Date'
                                                                value={values.Bidding_Expiry_Date}
                                                                valueUpdate={item => {
                                                                    setFieldValue("Bidding_Expiry_Date", item);
                                                                }}
                                                                error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                                                                {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                            </HelperText>
                                                        </View>

                                                        <View style={{ width: "25%", margin: "auto", marginTop: 5 }}>
                                                            <EsiDatePicker
                                                                style={{ margin: "auto" }}
                                                                name={'Ride_Start_Date'}
                                                                label='Ride Start Date'
                                                                value={values.Ride_Start_Date}
                                                                valueUpdate={item => {
                                                                    setFieldValue("Ride_Start_Date", item);
                                                                }}
                                                                error={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}>
                                                                {touched.Ride_Start_Date && errors.Ride_Start_Date}
                                                            </HelperText>
                                                        </View>

                                                        <View style={{ width: "25%", margin: "auto" }}>
                                                            <ESITextInput
                                                                style={{ width: "100%" }}
                                                                label="Ride Days *"
                                                                mode='outlined'
                                                                onChangeText={handleChange("No_Of_Days")}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={values.No_Of_Days}
                                                                error={Boolean(touched.No_Of_Days && errors.No_Of_Days)}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.No_Of_Days && errors.No_Of_Days)}>
                                                                {touched.No_Of_Days && errors.No_Of_Days}
                                                            </HelperText>
                                                        </View>

                                                        <View style={{ width: "25%", margin: "auto" }}>
                                                            <ESITextInput
                                                                style={{ width: "100%" }}
                                                                label="Ride Distance*"
                                                                mode='outlined'
                                                                onChangeText={handleChange("Distance_In_KMS")}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                value={values.Distance_In_KMS}
                                                                error={Boolean(touched.Distance_In_KMS && errors.Distance_In_KMS)}
                                                            />
                                                            <HelperText type="error" visible={Boolean(touched.Distance_In_KMS && errors.Distance_In_KMS)}>
                                                                {touched.Distance_In_KMS && errors.Distance_In_KMS}
                                                            </HelperText>
                                                        </View>
                                                        {errorMessage?.length > 0 &&
                                                            <View style={{ width: "90%", margin: "auto" }}>
                                                                <HelperText type="error" >
                                                                    {errorMessage}
                                                                </HelperText>
                                                            </View>
                                                        }
                                                        <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                            <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {

                                                                handleSubmit()
                                                            }}>
                                                                <Text style={{ color: esiColor.itemButtenColor }}> Ask Quotation</Text>
                                                            </Button>

                                                        </View>
                                                    </View>
                                            }
                                        </View>)}
                                </Formik> :
                                <View style={{ margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                                    <Button style={{ marginTop: 5, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={placeCartAction}>
                                        <Text style={{ color: esiColor.itemButtenColor }}>Ask Quotation</Text>
                                    </Button>
                                </View>}
                        </View>
                    }
                </ScrollView>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={addCartPop}>
                    <ScrollView showsHorizontalScrollIndicator={false} style={{ flex: 1, maxWidth: 500, minWidth: 400, minHeight: 550, maxHeight: 560, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                        <Surface style={{
                            justifyContent: 'center',
                            borderRadius: 10,
                            top: Platform.OS === 'web' ? 10 : 20,
                            elevation: 0, backgroundColor: esiColor.BackgroundColor,
                        }} >
                            <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                                setAddCartPop((prop) => !prop);
                                setFormResults(false);
                            }}>
                                <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            <Formik
                                key={addCartItem?.Bidding_Ride_Cart_Id}
                                initialValues={
                                    {
                                        Bidding_Ride_Cart_Id: addCartItem?.Bidding_Ride_Cart_Id,
                                        Quantity: addCartItem?.Quantity,
                                        Modified_By: logedInEmail,
                                        Description: addCartItem?.Description || "",
                                        Pincode: addCartItem?.Pincode || '',
                                        Bidding_Expiry_Date: addCartItem?.Bidding_Expiry_Date || '',
                                        No_Of_Days: addCartItem?.No_Of_Days,
                                        Distance_In_KMS: addCartItem?.Distance_Kms,
                                        Ride_Start_Date: addCartItem?.Ride_Start_Date
                                    }
                                }
                                validationSchema={Yup.object().shape({
                                    Quantity: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required.").typeError('Input must be number.'),
                                    No_Of_Days: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required.").typeError('Input must be number.'),
                                    Distance_In_KMS: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required.").typeError('Input must be number.'),
                                    Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                                    Bidding_Expiry_Date: Yup.string().required('Date of service is required.').test("date-of-bidding", "Service Date should be more then " + dos + ".", (value: any) => {
                                        if (value) {
                                            let date1 = new Date(value).getTime();
                                            let date2 = new Date(dos).getTime();
                                            if (datediff(date2, date1) > -1) {
                                                return true;
                                            }
                                        }
                                        return false;

                                    }),
                                    Ride_Start_Date: Yup.string().required('Ride Start Date is required.')
                                })}
                                onSubmit={(values, actions) => {
                                    let date1 = new Date(values.Bidding_Expiry_Date).getTime();
                                    let date2 = new Date(values.Ride_Start_Date).getTime();
                                    if (datediff(date2, date1) > -1) {
                                        actions.setFieldError("Ride_Start_Date", "Ride Date should be after Bidding Expiry Date");
                                    } else {
                                        setloadingForm(true);
                                        dispatch(updateCartBids(values, formCallbackResponse));
                                    }
                                }}
                            >
                                {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                    <View style={{
                                        flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                    }}>
                                        {loadingForm ?
                                            <ActivityIndicator color={esiColor.esiMainColor} />
                                            :
                                            formResults ?
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                                    <Image source={require('../../../assets/images/GIF/cart-success.gif')}
                                                        style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <Text style={{ textAlign: "center", fontWeight: "bold", color: esiColor.itemColor }} >Successfully updated cart item.
                                                        </Text>
                                                    </View>
                                                </View>
                                                :

                                                // <ScrollView style={{ minHeight: 500, maxHeight: 530 }} >
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center", }}>

                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Quantity*"
                                                            mode='outlined'
                                                            onChangeText={handleChange("Quantity")}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Quantity}
                                                            error={Boolean(touched.Quantity && errors.Quantity)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Quantity && errors.Quantity)}>
                                                            {touched.Quantity && errors.Quantity}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                                                        <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.DescColor }}>Description:</Text>
                                                        <EsiTextAreaInput
                                                            error={Boolean(touched.Description && errors.Description)}
                                                            containerStyle={{ height: 170 }}
                                                            style={{ height: 170 }}
                                                            mode='outlined'
                                                            value={values.Description}
                                                            textareavalueUpdate={value => {
                                                                setFieldValue("Description", value)
                                                            }}
                                                            placeholder={'Please provide additional information'}
                                                            underlineColorAndroid={'transparent'}
                                                            theme={{
                                                                colors: {
                                                                    primary: "#27b6cc"
                                                                },
                                                            }}
                                                        />
                                                    </View>
                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Pincode*"
                                                            mode='outlined'
                                                            onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Pincode}
                                                            error={Boolean(touched.Pincode && errors.Pincode)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                            {touched.Pincode && errors.Pincode}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <EsiDatePicker
                                                            style={{ margin: "auto" }}
                                                            name={'Bidding_Expiry_Date'}
                                                            label='Bidding Expiry Date'
                                                            value={values.Bidding_Expiry_Date}
                                                            valueUpdate={item => {
                                                                setFieldValue("Bidding_Expiry_Date", item);
                                                            }}
                                                            error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                                                            {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <EsiDatePicker
                                                            style={{ margin: "auto" }}
                                                            name={'Ride_Start_Date'}
                                                            label='Ride Start Date'
                                                            value={values.Ride_Start_Date}
                                                            valueUpdate={item => {
                                                                setFieldValue("Ride_Start_Date", item);
                                                            }}
                                                            error={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Ride_Start_Date && errors.Ride_Start_Date)}>
                                                            {touched.Ride_Start_Date && errors.Ride_Start_Date}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Ride Days *"
                                                            mode='outlined'
                                                            onChangeText={handleChange("No_Of_Days")}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.No_Of_Days}
                                                            error={Boolean(touched.No_Of_Days && errors.No_Of_Days)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.No_Of_Days && errors.No_Of_Days)}>
                                                            {touched.No_Of_Days && errors.No_Of_Days}
                                                        </HelperText>
                                                    </View>

                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Ride Distance*"
                                                            mode='outlined'
                                                            onChangeText={handleChange("Distance_In_KMS")}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Distance_In_KMS}
                                                            error={Boolean(touched.Distance_In_KMS && errors.Distance_In_KMS)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Distance_In_KMS && errors.Distance_In_KMS)}>
                                                            {touched.Distance_In_KMS && errors.Distance_In_KMS}
                                                        </HelperText>
                                                    </View>
                                                    {errorMessage?.length > 0 &&
                                                        <View style={{ width: "90%", margin: "auto" }}>
                                                            <HelperText type="error" >
                                                                {errorMessage}
                                                            </HelperText>
                                                        </View>
                                                    }
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                        <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {

                                                            handleSubmit()
                                                        }}>
                                                            <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                                                        </Button>

                                                    </View>
                                                </View>
                                            // </ScrollView>
                                        }
                                    </View>)}
                            </Formik>
                        </Surface>

                    </ScrollView>
                </Modal>
            </View>
        </Surface>
    )
}
