import React from 'react';
import { Text, TouchableOpacity, View, Image, Platform, Dimensions } from 'react-native';
import { Rating } from 'react-native-ratings';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Surface } from 'react-native-paper';
import { useSelector } from 'react-redux';
import RenderHtml from 'react-native-render-html';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const EsiBrandCard = (props: any) => {
    // item is brand object , using this object item to display info.
    const { item, navigate, navigation, ...rest } = props
    const regex = /(<([^>]+)>)/ig;
    const Description = (item?.Brand_Description || "").replace(regex, '');
    const sampleData = {
        Brand_Description: "<p>Vivo was launched as a Xiaomi brand back in 2018, swiftly gaining popularity thanks to its first smartphone - the Poco F1 - which offered top of the line.</p>\n",
        Brand_Esi_Rating: "2",
        Brand_Esi_Reviews: "<p>Lifestyle is a very good international brand.It is famous for mobile.Vivo is the main design.</p>\n",
        Brand_Id: "B-0000125",
        Brand_Image: "http://api.easystepin.com:8092/uploads/ESICB/Brand/20210831/Brand_20210831134445_Sample.jpg",
        Brand_Is_Popular: "0",
        Brand_Link: "https://www.vivo.in/",
        Brand_Name: "vivo",
        Brand_Status: "Active",
        Brand_Todays_Hot: "1",
        Coupon_Count: "3",
        Created_By: "himabindu.padagandla@easystepin.com",
        Created_Date: "2021-04-05",
        Deal_Count: "5",
        Is_Top_Brand: "1",
        Modified_By: "sangepu.vishnu@easystepin.com",
        Modified_Date: "2021-12-06",
        Product_Count: "7",
    }
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const halfdimentions = dimensions / 4;
    const esiColor = useSelector(state => state.theme);

    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    return (

        <Surface style={{
            marginHorizontal: 2, borderRadius: 10,
            padding: 2, backgroundColor: esiColor.CBColor,
            flexDirection: 'row',
            shadowColor: esiColor.brandShadowColor, borderColor: esiColor.CBColor, borderWidth: 0.5,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            alignItems: 'flex-start',
            marginRight: 9, marginLeft: 9,
            marginBottom: 9, marginTop: 9,
            elevation: 10,
            height: 165,
            maxWidth: Platform.OS === "web" ? 340 : (halfdimentions),
            width: Platform.OS === 'web' ? dimensions <= 1200 ? 700 : "80%" : "80%" && (halfdimentions),
        }}>


            <View style={{ flex: 2, alignContent: 'center', marginLeft: 10, marginRight: 10 }} >
                <TouchableOpacity onPress={() => {
                    // navigate function is used to redirect to Brand Details.
                    navigation.navigate('BrandDetails', { Brand_Id: item.Brand_Id });
                }}>
                    <Image source={{ uri: item?.Brand_Image  + `?w=90&h=100` }} style={{ height: 100, marginTop: 10, width: 90, resizeMode: 'contain' }}></Image>
                </TouchableOpacity>
            </View>

            <View style={{ flex: 5, flexDirection: 'column' }}  >
                <Text style={{ fontSize: 18, color: esiColor.itemColor, marginTop: 10 }}>{item.Brand_Name}</Text>
                <View style={{ height: 50, marginTop: 20 }}>

                    <RenderHtml source={{ html: `<span style="color: ${esiColor.Text}">${Description?.substring(0, 50)}</span>` }} />
                    {/* <Text style={{ color: esiColor.DescColor, marginBottom: 20 }}>{Description?.substring(0, 50)}...</Text> */}

                </View>
                <View style={{ flexDirection: 'row', zIndex: 100, backgroundColor: esiColor.BackgroundColor }}>
                    <Rating
                        readonly={true}
                        type='heart'
                        tintColor={esiColor.TintColor}
                        startingValue={item?.Brand_Esi_Rating}
                        ratingCount={5}
                        imageSize={15}
                        style={{ paddingVertical: 5 }}
                    />
                </View>
                <View style={{ flexDirection: 'row', zIndex: 100 }}>
                    <View style={{ marginRight: 10 }}>
                        <TouchableOpacity disabled={item?.Deal_Count == '0'} onPress={() => {
                            // navigate function is used to redirect to particular deal Details.
                            props.navigation.navigate('Main', { screen: 'All', params: { id: item.Brand_Id, type: 'brand', page: 'deals', filter: 'all' } });
                        }}>
                            <Text style={{ color: EStyleSheet.value(item?.Deal_Count == '0' ? 'red' : esiColor.GBFColor), }}>{item?.Deal_Count} {item?.Deal_Count == '1' || item?.Deal_Count == '0' ? 'Deal' : 'Deals'}</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginRight: 10 }}>
                        <TouchableOpacity disabled={item?.Coupon_Count == '0'} onPress={() => {
                            // navigate function is used to redirect to particular coupon Details.
                            props.navigation.navigate('Main', { screen: 'All', params: { id: item.Brand_Id, type: 'brand', page: 'coupons', filter: 'all' } });
                        }}>
                            <Text style={{ color: EStyleSheet.value(item?.Coupon_Count == '0' ? 'red' : esiColor.GBFColor), }}>{item?.Coupon_Count} {item?.Coupon_Count == '1' || item?.Coupon_Count == '0' ? 'Coupon' : 'Coupons'}</Text>
                        </TouchableOpacity>
                    </View>
                    <View>
                        <TouchableOpacity disabled={item?.Product_Count == '0'} onPress={() => {
                            // navigate function is used to redirect to particular product Details.
                            props.navigation.navigate('Main', { screen: 'All', params: { id: item.Brand_Id, type: 'brand', page: 'products', filter: 'all' } });
                        }}>
                            <Text style={{ color: EStyleSheet.value(item?.Product_Count == '0' ? 'red' : esiColor.GBFColor), }}>{item?.Product_Count} {item?.Product_Count == '1' || item?.Product_Count == '0' ? 'Product' : 'Products'}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

        </Surface>

    );
};

export default EsiBrandCard;