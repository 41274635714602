import { shareAsync } from "expo-sharing";
import React, { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Image, Platform, RefreshControl, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Dialog, Button, IconButton, Portal, Surface, Text, Card, Divider, DataTable, List, TextInput, HelperText, Menu, Colors, RadioButton, TouchableRipple, Avatar, Checkbox } from "react-native-paper";
import StepIndicator from 'react-native-step-indicator';
import { useDispatch, useSelector } from "react-redux";
import * as Print from "expo-print";
import ESIDeliveryRating from "./delireviewrating";
import { useToast } from "../../../self_modules/react-native-paper-toast/src";
import { getSingleRideDetails, RideInvoiceAction, rideRiviewUpdateAction, rideUpdateAction } from "../../../state/actions/ridesAction";
import Header from "../../header";
import isEmpty from "../../../state/validations/is-empty";
import Modal from "react-native-modal";
import useRazorpay from "react-razorpay";
import razorpayPay from "../../../hooks/razorpay";
import { getWalletByUser, getUserSettings } from "../../../state/actions/walletActions";
import DeliveryTracking from "./deliveryTracking";
import { Formik } from 'formik';
import * as Yup from 'yup';

export default function RideDetails(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const { rideId } = props?.route?.params || "";
    const windowHeight = Dimensions.get('window').height;
    const EmailID = useSelector(state => state.auth.user?.Email_Id)
    const [invoiceDetails, setDetails] = useState();
    const [orderInfo, setOrderInfo] = useState({});
    const toast = useToast();
    const esiColor = useSelector(state => state.theme);

    const [oLoading, setOLoading] = React.useState(false);
    const [orderDetailsFilter, setOrderDetailsFilter] = React.useState("");
    useEffect(() => {
        if (isEmpty(rideId)) {
            navigation.navigate('MyRides');
        }
        else {
            if (EmailID) {
                let formData1 = {
                    User_Ride_Id: rideId
                }
                if (!oLoading && orderDetailsFilter != JSON.stringify(formData1)) {
                    setOrderInfo({});
                    setOLoading(true);
                    setOrderDetailsFilter(JSON.stringify(formData1));
                    callPageRequiredApis();
                }
            } else {
                navigation.navigate('Home');
            }
        }
    }, [props?.route?.params, oLoading]);

    const [maxWalletUsage, setMaxWalletUsage] = useState(50);


    const callPageRequiredApis = () => {
        let formData1 = {
            User_Ride_Id: rideId
        }
        let formDataInvoice = {
            "User_Ride_Id": rideId,
        }
        dispatch(RideInvoiceAction(formDataInvoice, updateStatesInvoice));
        dispatch(getSingleRideDetails(formData1, orderDetailsResponse));
        let data = {
            User_Id: user.Email_Id,
            Wallet_Id: "",
        };
        dispatch(getWalletByUser(updateStatesWallet, data));
        dispatch(getUserSettings("ORDER_MAX_WALLET_USAGE_P", setMaxWalletUsage));

    };



    const orderDetailsResponse = (status, data) => {
        if (status) {
            setOrderInfo(data);
        } else {
            navigation.navigate("MyRides");
        }
        setOLoading(false);
    };

    const updateStatesInvoice = (status, data) => {
        if (status) {
            setDetails(data);

        }
    };


    const getCurrentDeliveryStatus = (ItemMasterStatus) => {
        let orderlabels = [];
        if (["NEW", "ACCEPTED", "ON-THE-WAY", "COMPLETED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Accepted", "On the way", "Completed"];
        } else if (["REJECTED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Rejected"];
        } else if (["CANCELLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Cancelled"];
        } else {
            orderlabels = ["New", "Accepted", "On the way", "Completed"];
        }
        return orderlabels
    }

    const getCurrentStatusPoission = (data, ItemMasterStatus) => {
        let poission = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].toUpperCase() == ItemMasterStatus) {
                poission = i;
                break;
            }
            else if (data[i] == "On the way" && ItemMasterStatus == "ON-THE-WAY") {
                poission = i;
                break;
            }
        }
        return poission;
    }
    const customStyles = {
        stepIndicatorSize: 25,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: 'green',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'green',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'green',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'green',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'green'
    }
    const cancelStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: '#fe7013',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'red',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'red',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'red',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'red'
    }

    const getInvoice = () => {

        try {
            return `
        <html>
        <head>
            <style>
            .grid-container {
            display: grid;
            grid-template-columns: auto auto;
            }.hr.solid {
            border-top: 5px solid #27b6cc;
            }
            table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            }
            td{
            border: 1px solid #27b6cc;
            padding: 8px;
            }
            th {
            border: 1px solid #27b6cc;
            background-color: #27b6cc;
            padding: 8px;
            }
            </style>
            
                    <meta charset="utf-8">
                    <title>Invoice</title>
                    <script src="script.js"></script>
                </head>
                <body onclick="window.print()" >
                <header>
                <center>
                        <h1 style="color:#27b6cc;">Invoice</h1>
                </center>
                <div class="grid-container">
                <img src="`+ require('../../../assets/images/img/logo/logo.png') + `" width="100" height="80" style="background-color: rgb(39, 182, 204);">
                <span style="text-align:right">
                <p>Ride Date: `+ invoiceDetails?.RideDate + `</p>
                
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4>Journey Details:</h4>
                <p>  Pick-Up :`+ invoiceDetails?.Ride[0]?.Pickup_Location + `</p>
                <p> Drop :`+ invoiceDetails?.Ride[0]?.Drop_Location + `</p>
                </span>
               
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4>Vehicle details:</h4>
                <p> Driver : `+ invoiceDetails?.Delivery_Person?.Name + `</p>
                <p> Number `+ invoiceDetails?.Delivery_Person?.Vehical_Number + `</p>
                <p> Distance : `+ invoiceDetails?.Ride[0]?.Distance + ` kilometers </p>
                <p> Journy Time : `+ invoiceDetails?.Ride[0]?.JournyTime + `</p>
                </span>
               
                </div>
                <hr class="solid">
                <table>
                <tr>
                <th colspan="7">
                Payment details
                </th></tr>
                    <tr>
                    <td colspan="7">
                    <span>
                        Ride Value 
                    </span>
                    <span style="float: right;">
                        `+ invoiceDetails?.Ride_Totals?.Amount_Before_Discount + `
                    </span>
                    </td>
                    </tr>
                    <tr>
                    <td  colspan="7" >
                    <span>
                         Discount 
                    </span>
                    <span style="float: right;">
                        -`+ invoiceDetails?.Ride_Totals?.Discount + ` 
                    </span>
                    </td>
                    </tr>
                    `


                +
                (invoiceDetails?.Ride_Totals?.IGST ? `
                    <tr>
                    <td colspan="7"> <span> IGST </span> 
                    
                    <span style="float: center;">
                    `+ invoiceDetails?.Ride_Totals?.IGSTPercentage + `%
                </span>
                    <span style="float: right;">
                    `+ invoiceDetails?.Ride_Totals?.IGST + ` 
                </span>
                    </td>
                    </tr>`: ``)
                +

                (invoiceDetails?.Ride_Totals?.Toll_Fare ? ` <tr>
                <td  colspan="7" >
                <span>
                Toll Fare 
                </span>
                <span style="float: right;">
                    `+ invoiceDetails?.Ride_Totals?.Toll_Fare + ` 
                </span>
                </td>
                </tr>`: "") +
                (invoiceDetails?.Ride_Totals?.Inter_State_Fare ? ` <tr>
                <td  colspan="7" >
                <span>
                Inter State Fare 
                </span>
                <span style="float: right;">
                    `+ invoiceDetails?.Ride_Totals?.Inter_State_Fare + ` 
                </span>
                </td>
                </tr>`: "")
                +

                `<tr>
                    <td  colspan="7"></td>
                   
                    </tr>

                    <tr>
                    <td  colspan="7">
                    <span style="font-weight: bold;">
                    Final Amount
                    </span>
                    <span style="float: right; font-weight: bold;">
                    `+ invoiceDetails?.Ride_Totals?.Final_Amount + `
                    </span>
                    </td>
                    </tr>

                    <tr>
                        <td style="text-align: left; font-weight: bold;" colspan="7">
                        Amount in words:${invoiceDetails?.Ride_Totals?.AmountInWords}
                        </td>
                    </tr>
            
                </table>
            </header>
            <aside>
                <div contenteditable>
                    <p>Note: This is computer generated Invoice and signature is not required.</p>
                </div>
            </aside>
        </body>
        </html>`;
        } catch (error) {
        }

    }

    const printToFile = async () => {
        const html = getInvoice();
        if (Platform.OS == 'web') {
            const pW = window.open('', '', 'height=500, width=500')
            pW.document.write(html);
            pW.document.close();
        }
        else {
            try {
                const { uri } = await Print.printToFileAsync({ html });
                await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
            } catch (error) {
            }
        }
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [rideTip, setRideTip] = React.useState(0);
    const [showRidePayment, setShowRidePayment] = React.useState(false);
    const [ridePaymentLoading, setRidePaymentLoading] = React.useState(false);
    const user = useSelector((state) => state.auth.user);
    const [wallet, setWallet] = React.useState(null);
    const updateStatesWallet = (data: any, loading: any) => {
        let add = JSON.parse(JSON.stringify(data));
        setWallet(add?.Wallet[0]);
    };

    const [useWallet, setUseWallet] = useState(false);
    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }

    const getWalletDiscountPrice = (amount, wallet) => {
        let response = 0;
        if (amount >= wallet) {
            response = wallet;
        } else {
            response = amount;
        }
        return response
    }

    const paymentUpdateResponse = (status, response) => {
        setShowRidePayment(false);
        setRidePaymentLoading(false);
        if (status) {
            toast.show({ message: 'Raid payment done successfully.', type: 'info', duration: 3000, position: 'top' });
            callPageRequiredApis();
        } else {
            toast.show({ message: 'Please try after some time.', type: 'info', duration: 3000, position: 'top' });
        }
    }

    const statusUpdateResponse = (status, response) => {
        setSubmit(!issubmitted);
        if (status) {
            toast.show({ message: 'Raid successfully cancelled.', type: 'info', duration: 3000, position: 'top' });
            callPageRequiredApis();
        } else {
            toast.show({ message: 'Please try after some time.', type: 'info', duration: 3000, position: 'top' });
        }
    }

    const [rideTrackingShow, setRideTrackingShow] = React.useState(false);

    const [visible, setVisible] = React.useState(false);
    const [issubmitted, setSubmit] = React.useState(false);
    const hideDialog = () => {
        setVisible(false);
    }


    return (
        <Surface style={{ backgroundColor: esiColor.BackgroundColor, flex: 1 }}>
            <Header {...props} />

            {oLoading ? <ActivityIndicator color={esiColor.SIconColor} /> :
                <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={oLoading}
                            onRefresh={callPageRequiredApis}
                        />
                    }
                >
                    <View style={{ marginVertical: -10 }}>
                        <IconButton icon="keyboard-backspace" color={esiColor.SIconColor} onPress={() => navigation.navigate('MyRides')}></IconButton>
                    </View>

                    <Portal>
                        <Dialog visible={visible} onDismiss={hideDialog} style={{
                            maxWidth: 500, minWidth: 300, height: 250, borderRadius: 10,
                            marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5
                        }}>

                            <Dialog.ScrollArea>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 1, alignItems: "flex-end" }}>
                                        <IconButton style={{ marginTop: 1, marginRight: -10 }}
                                            icon="close"
                                            size={20}
                                            color={esiColor.itemColor}
                                            onPress={() => setVisible(!visible)}
                                        />
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 1 }} >
                                        <Text style={{ color: esiColor.GBFColor, fontSize: 20, textAlign: "center", marginTop: -20, marginBottom: 20 }}>Ride Cancellation Form</Text>
                                    </View>
                                </View>
                                <ScrollView >
                                    <Dialog.Content>
                                        {(() => {

                                            return (
                                                <Formik
                                                    initialValues={
                                                        {
                                                            Cancel_Return_User_Comment: ''
                                                        }
                                                    }
                                                    validationSchema={Yup.object().shape({
                                                        Cancel_Return_User_Comment: Yup.string()
                                                            .required('Comment is required.')
                                                    })}
                                                    onSubmit={values => {
                                                        let updateData = {
                                                            User_Ride_Id: rideId,
                                                            Ride_Status: "CANCELLED",
                                                            User_Cancel_Reason: values.Cancel_Return_User_Comment
                                                        }
                                                        dispatch(rideUpdateAction(updateData, statusUpdateResponse));
                                                        setSubmit(!issubmitted);
                                                        setVisible(!visible);
                                                    }}
                                                >
                                                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }) => (
                                                        <View style={{
                                                            flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>

                                                            <View style={{ margin: "auto", width: "100%" }}>

                                                                <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
                                                                    label='Comment'
                                                                    // mode='outlined'
                                                                    onChangeText={handleChange('Cancel_Return_User_Comment')}
                                                                    onBlur={handleBlur('Cancel_Return_User_Comment')}
                                                                    value={values.Cancel_Return_User_Comment}
                                                                    multiline={true}
                                                                    numberOfLines={4}
                                                                    theme={{ colors: { text: esiColor.Text, placeholder: esiColor.SPHTextColor } }}
                                                                    underlineColor={esiColor.itemColor}
                                                                    mode='outlined'
                                                                    outlineColor={esiColor.itemColor}
                                                                    activeOutlineColor={esiColor.itemColor}
                                                                    style={{ maxHeight: 100, minWidth: "100%", marginHorizontal: "auto", backgroundColor: esiColor.BackgroundColor, }}
                                                                />
                                                                <HelperText style={{}} type="error" visible={Boolean(touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment)}>
                                                                    {touched.Cancel_Return_User_Comment && errors.Cancel_Return_User_Comment}
                                                                </HelperText>
                                                            </View>
                                                            <View style={{ margin: "auto", marginTop: 15 }}>
                                                                <Button style={{ backgroundColor: esiColor.globalButtonColor }} mode="contained" onPress={handleSubmit}>
                                                                    <Text style={{ color: esiColor.itemButtenColor }}>Submit</Text>
                                                                </Button>
                                                            </View>
                                                        </View>
                                                    )}
                                                </Formik>
                                            )
                                        })()}

                                    </Dialog.Content>
                                </ScrollView>
                            </Dialog.ScrollArea>
                        </Dialog>
                    </Portal>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>

                        <View style={{ flex: 2, flexDirection: 'column' }}>
                            {orderInfo.Rides?.map((product) => {

                                return (
                                    <View key={product.Ride_Id}
                                        style={{
                                            shadowOpacity: 7,
                                            shadowRadius: 7,
                                            shadowColor: esiColor.brandShadowColor,
                                            margin: 'auto', backgroundColor: esiColor.CBColor,
                                            marginHorizontal: 1, padding: 1, margin: 15,
                                            marginTop: (Platform.OS === 'web' ? 15 : 0), flexDirection: 'row',
                                            alignItems: 'flex-start',
                                            // elevation: 10,
                                            maxWidth: 400,
                                            maxHeight: 200,
                                            minHeight: 150,
                                            borderRadius: 10,
                                            marginLeft: 20
                                        }}
                                    >
                                        <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                            <Image source={{ cache: "force-cache", uri: product?.Comfort_Type_Image + `?w=80&h=100` }} style={{ height: 100, marginTop: 15, width: 80, resizeMode: 'contain' }}></Image>

                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{product?.Comfort_Type}</Text>
                                            <Text style={{ color: esiColor.itemColor }}>Ride ID : {product?.Ride_Id}</Text>

                                            <Text style={{ color: esiColor.itemColor }}>Pickup  : {product?.Pickup_Location.split(",")[0]}</Text>
                                            <Text style={{ color: esiColor.itemColor }}>Drop : {product?.Drop_Location.split(",")[0]}</Text>
                                            <Text style={{ color: esiColor.itemColor }}>KM : {product?.Distance_In_Kms}</Text>
                                            <Text style={{ color: esiColor.itemColor }}>Price : {orderInfo?.Rides_Details?.Ride_Total_Amount}</Text>
                                            {!(["CANCELLED", "COMPLETED"].includes(orderInfo?.Rides_Details?.Ride_Status)) &&
                                                <View style={{ flexDirection: 'row', margin: 5, }}>
                                                    <TouchableOpacity
                                                        style={{
                                                            backgroundColor: 'gray', width: 80, borderRadius: 5, alignItems: "center", padding: 5, opacity: issubmitted ? 0.2 : "",

                                                        }}
                                                        onPress={() => { setVisible(!visible) }}
                                                    >
                                                        <Text style={{ color: esiColor.itemButtenColor }}> CANCEL </Text>
                                                    </TouchableOpacity>
                                                </View>}
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                        <View style={{ flex: 3, flexDirection: 'column', marginRight: 25 }}>

                            {getCurrentDeliveryStatus(orderInfo?.Rides_Details?.Ride_Status).length > 0 &&
                                < View style={{ marginTop: 5 }}>
                                    <StepIndicator
                                        customStyles={orderInfo?.Rides_Details?.Ride_Status === "CANCELLED" ? cancelStyles : customStyles}
                                        labels={getCurrentDeliveryStatus(orderInfo?.Rides_Details?.Ride_Status)}
                                        currentPosition={getCurrentStatusPoission(getCurrentDeliveryStatus(orderInfo?.Rides_Details?.Ride_Status), orderInfo?.Rides_Details?.Ride_Status)}
                                        stepCount={getCurrentDeliveryStatus(orderInfo?.Rides_Details?.Ride_Status).length}
                                    />
                                </View>
                            }

                            {(["COMPLETED"].includes(orderInfo?.Rides_Details?.Ride_Status)) &&
                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignItems: "center", alignContent: 'center', padding: 5, marginTop: 10, marginBottom: 15, width: 150 }} onPress={printToFile}>
                                    <View >
                                        <Text style={{ color: esiColor.itemButtenColor }} >Download Invoice</Text>
                                    </View>
                                </TouchableOpacity>
                            }

                            {(orderInfo?.DeliveryPersonDetails?.Phone && !(["COMPLETED", "CANCELLED", "REJECTED"].includes(orderInfo?.Rides_Details?.Ride_Status))) &&
                                <View
                                    style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        shadowOpacity: 0.5,
                                        shadowRadius: 3,
                                        marginHorizontal: 1, padding: 1, margin: 'auto',
                                        backgroundColor: esiColor.BackgroundColor,
                                        marginTop: (Platform.OS === 'web' ? 15 : 5), flexDirection: 'column',
                                        elevation: 10,
                                        width: 350,
                                        borderRadius: 10,
                                        height: 150
                                    }}
                                >
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginLeft: 5, marginTop: 10, alignSelf: 'flex-start' }}>Vehicle Details</Text>
                                    <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {
                                        if (orderInfo?.Rides_Details?.Ride_Status == "ON-THE-WAY") {
                                            setRideTrackingShow(true);
                                        }
                                        setRideTrackingShow(true);
                                    }} >

                                        <View style={{ flex: 1, alignContent: 'center', alignItems: "center", marginLeft: 10 }} >
                                            <Image source={{ cache: "force-cache", uri: orderInfo?.DeliveryPersonDetails?.Photo + `?w=50&h=50` }} style={{ height: 50, width: 50, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}>
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.DeliveryPersonDetails?.Name}</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.DeliveryPersonDetails?.Phone}</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.DeliveryPersonDetails?.Vehicle_Number}</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.DeliveryPersonDetails?.Vehicle_Name}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            }

                            {(rideId && (orderInfo?.Rides_Details?.Ride_Status == "COMPLETED")) &&
                                <View style={{ marginVertical: 5 }} >
                                    <ESIDeliveryRating
                                        orderid={rideId}
                                        ordStatus={orderInfo?.Rides_Details?.Ride_Status}
                                    />
                                </View>
                            }

                            <View style={{ flexDirection: 'column', marginLeft: 10, marginTop: 20 }}  >
                                <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Payment Summary</Text>

                                <View style={{ flexDirection: "row", marginVertical: 3 }}>
                                    <Text style={{ fontSize: 12, color: esiColor.itemColor, alignSelf: "flex-start" }}>Ride Value may get updated in journey. And it is also based on toll fare, inter state fare, waiting changes and also extra distance.</Text>
                                </View>

                                <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ fontSize: 13, color: esiColor.Text, alignSelf: "flex-start" }}>Ride Value</Text>
                                    </View>
                                    <View style={{ flex: 2, }}>
                                        <Text style={{ fontSize: 13, color: esiColor.itemColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Ride_Total_After_Discount}</Text>
                                    </View>
                                </View>

                                <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                    <View style={{ flex: 2 }} >
                                        <Text style={{ fontSize: 13, color: esiColor.Text, alignSelf: "flex-start" }}>Tax Amount</Text>
                                    </View>
                                    <View style={{ flex: 2 }}>
                                        <Text style={{ fontSize: 10, color: esiColor.itemColor, alignSelf: "center" }}>Total Tax : {orderInfo?.Payment_Summary?.Total_Tax_Percentage} %</Text>
                                    </View>
                                    <View style={{ flex: 2, }}>
                                        <Text style={{ fontSize: 13, color: esiColor.itemColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Total_Tax}</Text>
                                    </View>
                                </View>

                                {orderInfo?.Payment_Summary?.Ride_Total_After_Discount &&
                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ fontSize: 13, color: esiColor.Text, alignSelf: "flex-start" }}>Toll Fare</Text>
                                        </View>
                                        <View style={{ flex: 2, }}>
                                            <Text style={{ fontSize: 13, color: esiColor.itemColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Toll_Fare}</Text>
                                        </View>
                                    </View>
                                }

                                {orderInfo?.Payment_Summary?.Inter_State_Fare &&
                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ fontSize: 13, color: esiColor.Text, alignSelf: "flex-start" }}>Inter State Fare</Text>
                                        </View>
                                        <View style={{ flex: 2, }}>
                                            <Text style={{ fontSize: 13, color: esiColor.itemColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Inter_State_Fare}</Text>
                                        </View>
                                    </View>
                                }
                                {orderInfo?.Payment_Summary?.Extra_Charges &&
                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.Text }}>Extra Fare</Text>
                                        </View>
                                        <View style={{ flex: 2, }}>
                                            <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.Payment_Summary?.Extra_Charges}</Text>
                                        </View>
                                    </View>
                                }

                                <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ fontSize: 13, color: esiColor.Text, alignSelf: "flex-start", fontWeight: "bold" }}>Total Gross Price</Text>
                                    </View>
                                    <View style={{ flex: 2, }}>
                                        <Text style={{ fontSize: 13, color: esiColor.itemColor, alignSelf: "center", fontWeight: "bold" }}>{orderInfo?.Payment_Summary?.Final_Gross_Price}</Text>
                                    </View>
                                </View>
                                {orderInfo?.Rides_Details?.Ride_Status == "COMPLETED" &&
                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ fontSize: 13, alignSelf: "flex-start", fontWeight: "bold", color: esiColor.Text }}>Payment Status</Text>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: "column" }}>
                                            <Text style={{ fontSize: 13, alignSelf: "center", fontWeight: "bold", color: orderInfo?.Rides_Details?.Payment_Status == "SUCCESS" ? "green" : "red" }}>{orderInfo?.Rides_Details?.Payment_Status}</Text>
                                            {(orderInfo?.Rides_Details?.Ride_Status == "COMPLETED" && orderInfo?.Rides_Details?.Payment_Status != "SUCCESS") &&
                                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, height: 30, width: 100, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                                                    setShowRidePayment(!showRidePayment);
                                                }}>
                                                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>Pay Now</Text>
                                                </TouchableOpacity>}
                                        </View>
                                    </View>}
                                {orderInfo?.Payment_Summary?.Driver_Tip &&
                                    <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ fontSize: 13, alignSelf: "flex-start", color: esiColor.Text }}>Driver Tip</Text>
                                        </View>
                                        <View style={{ flex: 2, }}>
                                            <Text style={{ fontSize: 13, alignSelf: "center", color: esiColor.itemColor }}>{orderInfo?.Payment_Summary?.Driver_Tip}</Text>
                                        </View>
                                    </View>
                                }
                            </View>
                        </View>

                    </View>
                </ScrollView >


            }
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={showRidePayment}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 400, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                    <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10 }} onPress={() => {
                        setShowRidePayment(!showRidePayment);
                    }}>
                        <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                    </TouchableRipple>
                    <View style={{ marginHorizontal: 20, marginTop: 20 }} >
                        <Text style={{ fontWeight: "700", marginBottom: 10, color: esiColor.DescColor }} > Ride Value : ₹ {orderInfo?.Rides_Details?.Ride_Total_Amount} /-</Text>
                    </View>
                    <View style={{ marginHorizontal: 20 }} >
                        <Text style={{ fontWeight: "bold", color: esiColor.DescColor }} > Ride Tip : </Text>
                        <RadioButton.Group onValueChange={value => setRideTip(value)} value={rideTip}>
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left", color: esiColor.itemColor }} mode="android" label="₹ 0/-  Not interested" value={0} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left", color: esiColor.itemColor }} mode="android" label="₹ 20/- Average" value={20} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left", color: esiColor.itemColor }} mode="android" label="₹ 50/- Good" value={50} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left", color: esiColor.itemColor }} mode="android" label="₹ 100/- Best" value={100} />
                            <RadioButton.Item color={esiColor.itemColor} uncheckedColor={esiColor.SBorderColor} style={{ marginVertical: -8 }} position="leading" labelStyle={{ textAlign: "left", color: esiColor.itemColor }} mode="android" label="₹ 500/- Excellent" value={500} />
                        </RadioButton.Group>
                    </View>
                    {(Number(wallet?.Remaining_Balance) > 0 && (Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip)) > 0 && <>
                        <View style={{ marginHorizontal: 20 }}>
                            <TouchableOpacity
                                onPress={() => {
                                    setUseWallet(!useWallet);
                                }}
                            >
                                <View style={{
                                    flexDirection: "row",
                                    marginTop: -10
                                }}>
                                    <Checkbox
                                        status={useWallet ? 'checked' : 'unchecked'}
                                        uncheckedColor={esiColor.SBorderColor}
                                    />
                                    <Text style={{
                                        marginTop: 10, color: esiColor.DescColor
                                    }}>{"Use Wallet Amount ₹ " + getWalletDiscountPrice((Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip), wallet?.Remaining_Balance)} /-</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        {useWallet &&
                            <View style={{ flexDirection: 'row', marginHorizontal: 20, marginTop: 10 }}>
                                <View style={{ flex: 2, }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: 'bold', marginLeft: 5 }}>Wallet Amount  </Text>
                                </View>
                                <View style={{ flex: 2 }} >
                                    <Text style={{ color: esiColor.itemColor, fontWeight: 'bold' }}>
                                        : -₹ {getWalletDiscountPrice((Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip), wallet?.Remaining_Balance)} /-
                                    </Text>
                                </View>

                            </View>}
                    </>}
                    <View style={{ flexDirection: 'row', marginHorizontal: 20, marginTop: 10 }}>
                        <View style={{ flex: 2 }} >
                            <Text style={{ fontWeight: "bold", marginBottom: 10, color: esiColor.Text }} > Payable Amount </Text>
                        </View>
                        <View style={{ flex: 2 }} >
                            <Text style={{ fontWeight: "bold", marginBottom: 10, color: '#4f8fbf' }} >: ₹ {(Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip) - (useWallet ? getWalletDiscountPrice((Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip), wallet?.Remaining_Balance) : 0)} /-</Text>
                        </View>
                    </View>
                    <View style={{ marginHorizontal: 20, justifyContent: "center", alignItems: "center" }} >
                        {
                            ridePaymentLoading ? <ActivityIndicator color={esiColor.SIconColor}/> :

                                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, maxWidth: 150, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                                    let amount = (Math.round(((Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip) - (useWallet ? getWalletDiscountPrice((Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip), wallet?.Remaining_Balance) : 0)) + Number.EPSILON));

                                    if (amount > 0) {
                                        razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                                            if (status) {
                                                let formData = {
                                                    User_Ride_Id: rideId,
                                                    Payment_Gateway_Amount: amount,
                                                    Driver_Tip: rideTip,
                                                    Payment_Gateway_Transaction_Number: response.razorpay_payment_id,
                                                    Payment_Method: "RAZORPAY",
                                                    Payment_Status: "SUCCESS",
                                                    User_Paid_Total_Fare: Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip,
                                                    Wallet_Amount: (useWallet ? getWalletDiscountPrice((Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip), wallet?.Remaining_Balance) : 0)
                                                }
                                                let formValues = JSON.parse(JSON.stringify(formData));
                                                setRidePaymentLoading(true);
                                                dispatch(rideUpdateAction(formValues, paymentUpdateResponse));
                                            }
                                        });
                                    }
                                    else {
                                        let formData = {
                                            User_Ride_Id: rideId,
                                            Driver_Tip: rideTip,
                                            Payment_Method: "WALLET",
                                            Payment_Status: "SUCCESS",
                                            User_Paid_Total_Fare: Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip,
                                            Wallet_Amount: (useWallet ? getWalletDiscountPrice((Number(orderInfo?.Rides_Details?.Ride_Total_Amount) + rideTip), wallet?.Remaining_Balance) : 0)
                                        }
                                        setRidePaymentLoading(true);
                                        let formValues = JSON.parse(JSON.stringify(formData));
                                        dispatch(rideUpdateAction(formValues, paymentUpdateResponse));
                                    }
                                }}>
                                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>Pay Now</Text>
                                </TouchableOpacity>}
                    </View>
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={rideTrackingShow}>
                <View style={{ flex: 1, flexDirection: "column", maxWidth: 500, minWidth: 300, minHeight: 400, maxHeight: 430, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                    <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10 }} onPress={() => {
                        setRideTrackingShow(!rideTrackingShow);
                    }}>
                        <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                    </TouchableRipple>
                    <DeliveryTracking {...props} rideId={rideId} />
                </View>
            </Modal>
        </Surface >
    )
}