import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl } from "react-native";
import { Badge, Searchbar, Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import ordergif from "../../../assets/images/GIF/ordergif.gif";
import { getBiddingServicesCart, getMyBidServicesAction } from "../../../state/actions/node-actions/bid-services-actions";
import { useFocusEffect, useRoute } from "@react-navigation/native";
import { FontAwesome5 } from "@expo/vector-icons";
import { FlatList } from "react-native";

export default function MyBidServices(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions;
    const esiColor = useSelector(state => state.theme);

    const [ordersFilter, setOrdersFilter] = useState({ Search: "" });

    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "USER_SERVICE_BIDDING_ID", SortOrder: "DESC" });
    const [loading, setIsLoading] = React.useState(false);
    const [myBids, setMyBids] = React.useState([]);

    const dispatch = useDispatch();

    const handleSearch = itemValue => {
        setOrdersFilter({ ...ordersFilter, Search: itemValue })
    };

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setMyBids(data);
        }
    }

    const getMyBids = () => {
        let formData = { ...ordersFilter };
        if (logedInEmail) {
            formData.User_Email_ID = logedInEmail;
        }
        dispatch(getMyBidServicesAction(pagination,
            formData,
            callBackGetData));
        setIsLoading(true);
    }

    const route = useRoute();
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            getMyBids();
            dispatch(getBiddingServicesCart({
                "getCount": true,
                "User_Email_ID": logedInEmail
            }, (status, data) => setCartCount(data?.Counts || 0)));
        } else {
            navigation.navigate("Home");
        }
    }, [logedInEmail, ordersFilter]);

    useFocusEffect(
        React.useCallback(() => {
            if (!isEmpty(logedInEmail)) {
                getMyBids();
                dispatch(getBiddingServicesCart({
                    "getCount": true,
                    "User_Email_ID": logedInEmail
                }, (status, data) => setCartCount(data?.Counts || 0)));
            } else {
                navigation.navigate("Home");
            }
            return () => {

            };
        }, [])
    );

    const styles = StyleSheet.create({

        text: {
            fontSize: 12,
            fontFamily: 'system-ui',
            color: esiColor.itemColor
        }

    });


    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10 }}>
                <View style={{
                    flexDirection: "row", paddingTop: 5
                }}>

                    <View style={{ flex: 4 }}>
                        <Searchbar
                            style={{
                                borderRadius: 5, borderWidth: 0.5, width: 200, borderColor: esiColor.SBorderColor,
                                height: 30, backgroundColor: esiColor.BackgroundColor
                            }}
                            placeholder="Search"
                            iconColor={esiColor.SIconColor}
                            placeholderTextColor={esiColor.SPHTextColor}
                            theme={{ colors: { text: esiColor.SBTextColor } }}
                            onChangeText={(value) => handleSearch(value)}
                            value={ordersFilter.Search}
                        />
                    </View>
                    <View style={{ flex: 3, alignItems: "center", paddingTop: 8 }}>
                        {cartCount > 0 &&
                            <TouchableOpacity onPress={(e) => {
                                navigation.navigate("CartBidServices");
                            }}>
                                <FontAwesome5 name={"shopping-cart"} size={20} color={esiColor.esiMainColor} />
                                <Badge style={{
                                    position: 'absolute',
                                    color: esiColor.SIconColor,
                                    fontWeight: 'bold',
                                    bottom: 12,
                                    left: 18,
                                    backgroundColor: "white"
                                }}>
                                    {cartCount}
                                </Badge>
                            </TouchableOpacity>}
                    </View>
                    <View style={{ flex: 3, alignItems: "flex-end" }}>
                        {/* {Platform.OS !== "web" ? */}
                        <TouchableOpacity style={{
                            backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5,
                            paddingHorizontal: 10, marginHorizontal: 10, maxWidth: 150, height: 30, justifyContent: 'center',
                            alignItems: 'center'
                        }} onPress={(e) => {
                            navigation.navigate("NewBidServices");
                        }}>
                            <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>New Bid</Text>
                        </TouchableOpacity>

                    </View>
                </View>
                <ScrollView style={{ height: height - ((height / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                    showsHorizontalScrollIndicator={false}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={() => {
                                getMyBids();
                            }}
                        />
                    }
                >
                    {myBids.length <= 0 ?
                        <View style={{ alignItems: "center", marginTop: 10 }}>

                            <View>
                                <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image>
                                <Text style={{ textAlign: "center", color: esiColor.NoDataFound }}>Oops! No Bid's found...</Text>
                            </View>

                        </View>
                        :
                        <View style={{ marginTop: 0, flexDirection: 'row', flex: 1, flexWrap: 'wrap', width: width, justifyContent: 'space-evenly' }} >
                            <FlatList
                                // style={{ justifyContent: 'space-evenly'} }
                                numColumns={3}
                                data={myBids || []}
                                renderItem={({ item }) => (
                                    <TouchableOpacity onPress={() => {
                                        if (item?.Finalize_Partner_Bidding_Quote_Id) {
                                            navigation.navigate('BidPartnerServiceDetails', { id: item?.Finalize_Partner_Bidding_Quote_Id });
                                        } else
                                            navigation.navigate('BidServiceDetails', { id: item?.User_Service_Bidding_Id });
                                    }}>
                                        <View style={{
                                            shadowColor: esiColor.brandShadowColor,
                                            // shadowOffset: { width: -2, height: 4 },
                                            shadowOpacity: 5,
                                            shadowRadius: 7,
                                            marginHorizontal: 2, borderRadius: 10, backgroundColor: esiColor.CBColor,
                                            marginTop: 15,
                                            flexDirection: 'row',
                                            maxHeight: height > 250 ? 250 : 90,
                                            minHeight: 125,
                                            borderWidth: 0.5,
                                            width: width / 4,
                                            margin: 15,
                                            flex: 1,
                                            marginBottom: 15, marginLeft: 25, marginRight: 25,
                                            justifyContent: 'space-between'
                                        }}>
                                            <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                                <Image source={{ cache: "force-cache", uri: item?.Image + `?w=80&h=100` }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
                                            </View>
                                            <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{item?.Service_Type}</Text>
                                                <Text style={styles.text}>Bidding ID : {item?.User_Service_Bidding_Id}</Text>
                                                <Text style={styles.text}>Status : {item?.Bidding_Status}</Text>
                                                {item?.Service_Request_Total_Time_In_Days > 0 &&
                                                    <Text style={styles.text}>Required Days: {item?.Service_Request_Total_Time_In_Days}</Text>}
                                                {item?.Service_Request_Total_Time_In_Hours > 0 &&
                                                    <Text style={styles.text}>Required Hours: {item?.Service_Request_Total_Time_In_Hours}</Text>}
                                                {item?.Finalize_Partner_Bidding_Quote_Id &&
                                                    <Text style={styles.text}>Finalize Quote Id: {item?.Finalize_Partner_Bidding_Quote_Id}</Text>}
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                )}
                                keyExtractor={(item, index) => index}
                            />
                            <View style={{ marginBottom: 35 }} ></View>
                        </View>
                    }
                </ScrollView>
            </View>
        </Surface>
    )
}
