import React, { Fragment, useEffect, useState } from "react";
import { Text, View } from "react-native";
import ESIDealsCard from "../../components/custom/deal-card";
export default function DealsF({ dealfilter,toggleDModal,navigation}) {

    useEffect(()=>{
    })


    return (
        dealfilter.length > 0 ?
            (dealfilter?.map((data, index) => (
                <Fragment key={index}>
                    <ESIDealsCard data={data} navigation={navigation} toggleModal={toggleDModal} />
                </Fragment>
            ))
            )
            : (
                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 20 }}>
                    <Text>No Deals found!</Text>
                </View>
            )
    );
}
