import * as React from 'react';
import Navigation from './src/navigation';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './src/state/store';
import AppIntroSlider from 'react-native-app-intro-slider';
import {
  StyleSheet,
  View,
  Text,
  Image, Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { returnTheme } from './src/constants/Colors';
import { ActionType, authTokenGenerate, loginOutSuccess } from './src/state/actions/authActions';
// import * as SecureStore from 'expo-secure-store';
import CryptoJS from 'crypto-js';
import config from './src/state/actions/config';


export default function AuthLayer({ colorScheme }) {
  const isWeb = Platform.OS === 'web';
  const isAndroid = Platform.OS === 'android';
  const isIos = Platform.OS === 'ios';
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated || false);
  const authInterverl = useSelector(state => state.auth?.sessionExpiredTime);
  const sessionExpiredDate = useSelector(state => state.auth?.sessionExpiredDate);

  const [sessionRecal, setSessionRecal] = React.useState(false);
  const dispatch = useDispatch();
  const [sessionLoading, setSessionLoading] = React.useState(true);
  const [sessionExLoading, setSessionExLoading] = React.useState(false);
  const [refreshCall, setRefreshCall] = React.useState(false);
  const [secondsToReload, setSecondsToReload] = React.useState(-1);

  const getToken = () => {
    let token;
    // if (Platform.OS == 'web') {
    const encript = localStorage.getItem('Token')
    const bytesData = CryptoJS.AES.decrypt(encript, config.cryptoKay);
    token = bytesData.toString(CryptoJS.enc.Utf8);
    // } 
    return JSON.parse(token)
  }

  const setToken = (token) => {
    // if (Platform.OS == 'web') {
    const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(token), config.cryptoKay).toString();
    localStorage.setItem('Token', encryptedToken)
    // } 
  }

  const refreshAccessToken = async () => {
    try {
      if (!sessionExLoading) {
        const token = await getToken();
        if (token?.refreshToken) {
          setSessionExLoading(true);
          dispatch(authTokenGenerate({ refreshToken: token.refreshToken }, async (data) => {
            setSessionExLoading(false);
            if (data.Tokens) {
              setSessionLoading(false);
              setToken(data.Tokens);
              dispatch({
                type: ActionType.USER_SESSION_REFRESH_DATE,
                expireDate: new Date().setSeconds(new Date().getSeconds() + (data.Tokens.exp - (5 * 60))),
                expireTime: data.Tokens.exp - (5 * 60)
              })
            }
            else if (data.message) {
              setSessionLoading(false);
              dispatch(loginOutSuccess());
              if (Platform.OS == "web") {
                localStorage.removeItem('signIn');
                localStorage.removeItem('Token');
              } else {
                // await SecureStore.deleteItemAsync('Token');
              }
            }
            else {
              setSessionRecal(true)
            }
          }));
        }
      }
    } catch (error) {
      setSessionRecal(true)
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (secondsToReload == 0) {
        setRefreshCall(true)
      } else if (secondsToReload > 0)
        setSecondsToReload(secondsToReload - 1)
    }, 1000);
    return () => clearTimeout(timer);
  }, [secondsToReload]);


  React.useEffect(() => {

    (async () => {
      if (isAuthenticated) {
        // refreshAccessToken();
        try {
          const current = new Date().valueOf();
          const sED = sessionExpiredDate;
          const difDates = Math.floor((sED - current) / 1000)
          const token = await getToken();
          if (token.refreshToken) {
            if ((difDates <= 0) || sessionRecal) {
              if (sessionRecal) {
                setSessionRecal(false)
              }
              if (difDates <= 0) {
                setSessionLoading(true);
              }
              refreshAccessToken()
            } else {
              setSessionLoading(false);
              setSecondsToReload((difDates < 0) ? authInterverl : difDates)
            }
          }
          else {
            setSessionLoading(false)
            dispatch(loginOutSuccess());
            if (Platform.OS == "web") {
              localStorage.removeItem('signIn');
              localStorage.removeItem('Token');
            } else {
              // SecureStore.deleteItemAsync('Token');
            }
          }
        } catch (error) {
          console.log(error)
          setSessionLoading(false)
        }

        // Cleanup the interval when the component unmounts
      } else {
        setSessionLoading(false)
      }
    })()


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionExpiredDate, sessionRecal, isAuthenticated]);

  React.useEffect(() => {

    if (refreshCall) {
      refreshAccessToken()
    }
    else
      setRefreshCall(false)
  }, [refreshCall])


  React.useEffect(() => {
    dispatch({ type: "THEME_PROVIDER", payload: returnTheme(colorScheme) })
  }, [colorScheme])

  const slides = [
    {
      key: 's1',
      text: 'We at meepaisa know customer pulse pretty much, we put ourselves into your shoes and curated our catalog accordingly, choose from our extensive selection of products and avail them at your door step with ease.',
      title: 'Pick from our extensive selection of products.',
      image: require("./src/assets/images/walkthrough_screens/gift_one.gif"),
      backgroundColor: '#27b6cc',
    },
    {
      key: 's2',
      title: 'Reap the benefits of our partner’s offers/coupons/deals.',
      text: 'With over 200+ online/offline retail partners to choose from, you can straight away steal the deals, coupons and offers provided by them instantly with meepaisa,Our curated deals section appeals anyone, don’t miss checking it out 😉.',
      image: require("./src/assets/images/walkthrough_screens/gift_two.gif"),
      backgroundColor: '#27b6cc',
    },
    {
      key: 's3',
      title: 'Get instant cashback after a successful purchase',
      text: 'Right after a successful purchase meepaisa gives you instant cashback to your wallet which can withdrawn instantly to your bank account and we support all UPI partners at the moment to enable hassle free withdrawal process!',
      image: require("./src/assets/images/walkthrough_screens/gift_four.gif"),
      backgroundColor: '#27b6cc',
    },
    {
      key: 's4',
      title: 'Ride safely to your destination',
      text: 'Using meepaisa, you will be able to book cab or bike on demand to your favourite destination intracity hasslefree, with meepaisa you will get additional benifit of surcharge waiver 😉',
      image: require("./src/assets/images/walkthrough_screens/gift_cabs.gif"),
      backgroundColor: '#27b6cc',
    },
    {
      key: 's5',
      title: 'A Unified app for every need!!',
      text: 'with meepaisa, you will never get the need to use other apps for your day to day needs, be it grocery delivery or event hall booking or booking for a house cleaning service, we have covered it all, Thus the name "meepaisa-India’s first unified app with assured cashback"',
      image: require("./src/assets/images/walkthrough_screens/meepaisa_multiple_services.gif"),
      backgroundColor: '#27b6cc',
    },
    {
      key: 's6',
      title: '24/7 Customer support to address your queries and concerns!!',
      text: 'You made a successful purchase and we missed giving your cashback,Don’t worry our customer support executives work 24/7 to address your concerns,all you have to provide is the order ID and our people will get back to you with a solution with in 24 hours',
      image: require("./src/assets/images/walkthrough_screens//gift_five.gif"),
      backgroundColor: '#27b6cc',
    }
  ];


  const RenderItem = ({ item }) => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: item.backgroundColor,
          alignItems: 'center',
          justifyContent: 'space-around',
          paddingBottom: 100,
        }}>
        <Text style={styles.introTitleStyle}>{item.title}</Text>
        <Image style={styles.introImageStyle} source={item.image} />
        <Text style={styles.introTextStyle}>{item.text}</Text>
      </View>
    );
  };
  const [showRealApp, setShowRealApp] = React.useState(false);

  const onDone = () => {
    setShowRealApp(true);
  };
  const onSkip = () => {
    setShowRealApp(true);
  };

  return (
    <PersistGate loading={null} persistor={persistor}>
      {(!isAuthenticated && (isAndroid || isIos) && !showRealApp) ?
        <AppIntroSlider
          data={slides}
          renderItem={RenderItem}
          onDone={onDone}
          showSkipButton={true}
          onSkip={onSkip}
        /> : sessionLoading ? <Text>Loading</Text> :
          <Navigation colorScheme={colorScheme} />}
      {(isAuthenticated && (isAndroid || isIos)) && !showRealApp && setShowRealApp(true)}
    </PersistGate>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'center',
  },

  introImageStyle: {
    width: 300,
    height: 300,
  },
  introTextStyle: {
    fontSize: 18,
    color: 'white',
    textAlign: 'center',
    paddingVertical: 30,
  },
  introTitleStyle: {
    fontSize: 25,
    color: 'white',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 50,
    fontWeight: 'bold',
  },


});