import React, { Fragment, useEffect, useState } from "react";
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View, Platform, Dimensions } from "react-native";
import { Card, Checkbox, HelperText, Surface, TextInput as TextInputP } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { MaterialIcons, AntDesign, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import EsiSearchDropdown from "../../components/custom/searchable-dropdown";
import { productAddOrRemoveAddress, productAddOrRemoveGiftPack, productAddOrRemoveGiftPackMessage } from "../../state/actions/cartActionsNew";
import { useNavigation } from '@react-navigation/native';
import GiftMessageField from "./GiftMessageField";
import QuentityField from "./QuentityField";

export default function CartProductCard({
  address,
  setOpenAddressPopup,
  index,
  data,
  partner,
  count,
  handleOnChangeCount,
  handleOnClickCountIncrement,
  handleOnClickCountDecrement,
  handleOnClickRemoveCard,
  completeProduct,
  cart,
  productError
}) {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const getProductInventoryError = (completeProduct) => {
    if (completeProduct?.Inventory?.Is_Stock_Available == "0") {
      return { Message: completeProduct?.Inventory?.Stock_Error_Message }
    } else if (Number(completeProduct?.Inventory?.Remaining_Quantity || "0") <= 20) {
      return { Message: completeProduct?.Inventory?.Stock_Error_Message }
    }
    return false;
  };

  const getProductDelivery = (completeProduct) => {
    if (Number(completeProduct?.partnerProduct?.Shipping_Amount)) {
      return { Message: "Delivery charges ₹" + count * Number(completeProduct?.partnerProduct?.Shipping_Amount) + "/-" }
    }
    return false;
  };

  const setdeliveryAddress = (address) => {
    if (address) {
      return {
        label: address.Address_Type + " " + "," + " " + address.Door_Number + " " + "," + " " + address.Landmark + " " + "," + " " + address.Street + " " + "," + " " + address.City + " " + "," + " " + address.State + " " + "," + " " + address.Zip,
        value: JSON.stringify(address)
      }
    }
    else return ""
  };

  const [addressChange, setAddressChange] = useState(false);
  const esiColor = useSelector(state => state.theme);

  const styles = StyleSheet.create({
    placeholderStyle: {
      fontSize: 16,
      color: esiColor.itemColor
    },
    selectedTextStyle: {
      fontSize: 16,
      maxHeight: 40,
      minHeight: 40,
      color: esiColor.SIconColor
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    dropdown: {
      maxHeight: 55,
      minHeight: 50,
      borderWidth: 0.5,
      borderRadius: 5,
      paddingHorizontal: 8,
    },
    centerElement: { justifyContent: 'center', alignItems: 'center', top: 0 },

  });

  return (
    <View style={{
      flex: 1, backgroundColor: esiColor.BackgroundColor, 
      marginLeft: 5, borderRadius: 5,
      shadowColor: esiColor.brandShadowColor,
      marginVertical: 5,
      marginTop: 15, borderBottomColor: esiColor.SBorderColor, borderBottomWidth: 0.2
    }}>

      <View style={{ flexDirection: 'row', backgroundColor: esiColor.BackgroundColor, marginBottom: 2 }}>
        <View style={{ flexDirection: 'row', flexGrow: 1, flexShrink: 1, alignSelf: 'center' }}>
          <TouchableOpacity onPress={() => {/*this.props.navigation.navigate('ProductDetails', {productDetails: item})*/ }} style={{ paddingRight: 10 }}>
            <Image source={{ cache: "force-cache", uri: data?.Product_Image }} style={[styles.centerElement, { height: 60, width: 80, backgroundColor: esiColor.ImgBck, marginLeft: 10 }]} />
          </TouchableOpacity>
          <View style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'center' }}>
            <Text numberOfLines={1} style={{ fontSize: 15, color: esiColor.brandFontColor }}>{data?.Product_Name}</Text>
            <Text numberOfLines={1} style={{ color: esiColor.DescColor }}>{data?.color ? 'Variation: ' + data?.color : ''}</Text>
            <View style={{ flexDirection: 'row' }}>
              <Text numberOfLines={1} style={{ color: esiColor.DescColor, marginBottom: 10, textDecorationColor: esiColor.SBorderColor, textDecorationLine: 'line-through' }}> ₹ {count * data?.Original_Price} </Text>
              <Text numberOfLines={1} style={{ color: esiColor.DescColor, marginBottom: 10, fontWeight: 'bold', paddingLeft: 10 }}> ₹ {Number(count * partner.Partner_Selling_Price).toFixed(2)}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity disabled={Number(count) <= 1} onPress={(e) => handleOnClickCountDecrement(e, count, completeProduct)} style={{ borderWidth: 1, borderColor: Number(count) <= 1 ? "#cccccc" : esiColor.GBFColor }}>
                  <MaterialIcons name="remove" size={22} color={Number(count) <= 1 ? "#cccccc" : esiColor.GBFColor} />
                </TouchableOpacity>
                {
                  <QuentityField count={count} completeProduct={completeProduct} handleOnChangeCount={handleOnChangeCount} />
                }
                <TouchableOpacity onPress={(e) => handleOnClickCountIncrement(count, completeProduct)} style={{ borderWidth: 1, borderColor: esiColor.GBFColor }}>
                  <MaterialIcons name="add" size={22} color={esiColor.GBFColor} />
                </TouchableOpacity>
              </View>

              {partner.Is_Gift_Wrap_Available === "1" &&
                <View style={{ height: 25, alignItems: 'center', flexDirection: 'row' }}>
                  <Checkbox.Android
                    status={completeProduct.isGiftPack ? 'checked' : 'unchecked'}
                    uncheckedColor={esiColor.SBorderColor}
                    onPress={() => {
                      if (completeProduct.isGiftPack)
                        dispatch(productAddOrRemoveGiftPackMessage(completeProduct, ""));
                      dispatch(productAddOrRemoveGiftPack(cart, completeProduct));
                    }
                    }
                  />
                  <Text style={{ color: esiColor.Text }}>Gift Pack</Text>

                </View>
              }

              <TouchableOpacity onPress={(e) => handleOnClickRemoveCard(e, completeProduct)} >
                <Ionicons name="md-trash" style={{ paddingRight: 10 }} size={22} color={esiColor.SIconColor} />
              </TouchableOpacity>
            </View>
            <HelperText type="error" visible={getProductInventoryError(completeProduct)?.Message ? true : false}>
              {getProductInventoryError(completeProduct)?.Message}
            </HelperText>
            <HelperText type="info" style={{ color: esiColor.DescColor }} visible={getProductDelivery(completeProduct)?.Message ? true : false}>
              {getProductDelivery(completeProduct)?.Message}
            </HelperText>
          </View>
        </View>
      </View>

      {completeProduct.isGiftPack &&
        <GiftMessageField
          onChangeText={(value) => {
            dispatch(productAddOrRemoveGiftPackMessage(completeProduct, value))
          }}
          value={completeProduct.isGiftPackMessage}
          error={Boolean((completeProduct.isGiftPack ? true : false) && completeProduct.isGiftPackErrorMessage)}
          errorMessage={completeProduct.isGiftPackErrorMessage}
        />
      }
      {["RETAIL", "FARMER", "MFH"].includes(partner?.Business_Type) &&
        <View style={{ width: "100%", margin: "auto" }}>
          {addressChange &&
            <EsiSearchDropdown
              style={[styles.dropdown, { borderColor: completeProduct.addressError ? '#fff' : '#fff' }]}
              data={address}
              // placeholderStyle={styles.placeholderStyle}
              // selectedTextStyle={styles.selectedTextStyle}
              // inputSearchStyle={styles.inputSearchStyle}
              iconStyle={styles.iconStyle}
              labelField="label"
              valueField="value"
              maxHeight={180}
              search
              placeholder='Shipping Address'
              defaultValue={setdeliveryAddress(completeProduct.address)}
              className="address"
              onUpdateValue={(item: any) => {
                setAddressChange(!addressChange);
                if (item.value === "Add New Address") {
                  dispatch(productAddOrRemoveAddress(completeProduct, {}));
                  navigation.navigate('AddAddress', { page: 'cart' });
                } else {
                  dispatch(productAddOrRemoveAddress(completeProduct, JSON.parse(item.value)));
                }
              }}
              value={completeProduct.address ? JSON.stringify(completeProduct.address) : ''}
            />}
          {!addressChange &&
            <EsiSearchDropdown
              style={[styles.dropdown, { borderColor: completeProduct.addressError ? '#fff' : '#fff' }]}
              data={address}
              // placeholderStyle={styles.placeholderStyle}
              // selectedTextStyle={styles.selectedTextStyle}
              // inputSearchStyle={styles.inputSearchStyle}
              iconStyle={styles.iconStyle}
              labelField="label"
              valueField="value"
              maxHeight={180}
              search
              placeholder='Shipping Address'
              defaultValue={setdeliveryAddress(completeProduct.address)}
              className="address"
              onUpdateValue={(item: any) => {
                setAddressChange(!addressChange);
                if (item.value === "Add New Address") {
                  dispatch(productAddOrRemoveAddress(completeProduct, {}));
                  navigation.navigate('AddAddress', { page: 'cart' });
                } else {
                  dispatch(productAddOrRemoveAddress(completeProduct, JSON.parse(item.value)));
                }
              }}
              value={completeProduct.address ? JSON.stringify(completeProduct.address) : ''}
            />}
          <HelperText type="error" visible={completeProduct.addressError ? true : false}>
            {completeProduct.addressError ? completeProduct.addressError : ""}
          </HelperText>
        </View>}
    </View>
  );
}
