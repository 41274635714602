import React, { useEffect, useState } from "react";
import { Dimensions, Image, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Searchbar, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import { getAllOrdersAction } from "../../state/actions/ordersAction";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import ordergif from "../../assets/images/GIF/noOrdersImg.png";
// import esiColor from "../../constants/Colors";
// import Footer from "../footer";
const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
export default function FoodOrders(props) {
    const { navigation, orderData } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const [dimensions, setDimensions] = useState({ window, screen });
    const esiColor = useSelector(state => state.theme);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions({ window, screen });
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions.window;
    const windowWidth = dimensions.window.width;
    const windowHeight = dimensions.window.height;

    const [showDropDown, setShowDropDown] = React.useState(false);
    const [values, setValues] = React.useState()
    const [page, setPage] = React.useState(0);

    const [ordersFilter, setOrdersFilter] = useState({ select: "", search: "" });

    let allorders = useSelector((state) => state.allOrders.Orders.all);


    const dispatch = useDispatch();
    const filterOptions = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Order_Status',
            label: 'Order Status'
        },
        {
            value: 'Partner_Name',
            label: 'Partner Name'
        },

    ];
    const [oldSearch, setOldSearch] = React.useState(0);
    const checkOrdersForTable = () => {
        if (ordersFilter.search) {
            try {
                if (ordersFilter.select === "All") {
                    return orderData.filter(x => {
                        let colummns = ["Order_Status", "Order_Total", "Partner_Name", "Order_Id"];
                        for (let i = 0; i < colummns.length; i++) {
                            let mainString = typeof x[colummns[i]] == "string" ?
                                (colummns[i] === "Order_Status" ?
                                    x[colummns[i]] === "NEW" ? "ORDERED" : x[colummns[i]] === "COMPLETED" ? "DELIVERED" : x[colummns[i]] === "RETURN" ? "RETURN COMPLETE" : x[colummns[i]] === "REFUND" ? "REFUNDED" : x[colummns[i]]
                                    : x[colummns[i]]).toLowerCase()
                                : x[colummns[i]];


                            const subString = typeof x[colummns[i]] === "string" ? ordersFilter.search.toLowerCase() : ordersFilter.search;
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(ordersFilter.search.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString == ordersFilter.search) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                } else {
                    return orderData.filter(x => {
                        let mainString = typeof x[ordersFilter.select] == "string" ?
                            (ordersFilter.select === "Order_Status" ?
                                x[ordersFilter.select] === "NEW" ? "ORDERED" : x[ordersFilter.select] === "COMPLETED" ? "DELIVERED" : x[ordersFilter.select] === "RETURN" ? "RETURN COMPLETE" : x[ordersFilter.select] === "REFUND" ? "REFUNDED" : x[ordersFilter.select]
                                : x[ordersFilter.select]).toLowerCase()
                            : x[ordersFilter.select];
                        let subString = typeof x[ordersFilter.select] == "string" ? ordersFilter.search.toLowerCase() : ordersFilter.search;

                        if (typeof x[ordersFilter.select] === "string") {
                            return mainString.includes(subString);
                        }
                        else {
                            if (mainString == ordersFilter.search) {
                                return true;
                            }
                        }
                    })
                }
            } finally {
                if (page > 0) {
                    if (oldSearch != ordersFilter.search.length) {
                        setOldSearch(ordersFilter.search.length);
                        setPage(0);
                    }
                }
                else if (oldSearch != ordersFilter.search.length) {
                    setOldSearch(ordersFilter.search.length);
                    setPage(0);
                }
            }
        }
        else {
            return orderData
        }
    }
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Product_Color_Variant_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };
    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
                return -1;
            }
            if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
                return 1;
            }
            return 0;
        } catch (error) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    }
    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleChange = itemValue => {
        setOrdersFilter({ ...ordersFilter, select: itemValue })
    };
    const handleSearch = itemValue => {
        setOrdersFilter({ ...ordersFilter, search: itemValue })
    };
    const data = stableSort(
        checkOrdersForTable(), getComparator(order, orderBy))
    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            let orderformData = {
                Order_Id: "",
                Order_Status: "",
                Records_Filter: "FILTER",
                User_Email_Id: logedInEmail,
            }
            setOrdersFilter({ ...ordersFilter, search: "" });
            setOrdersFilter({ ...ordersFilter, select: 'All' })
            dispatch(getAllOrdersAction(orderformData));
        }
    }, [logedInEmail]);

    const styles = StyleSheet.create({
  
      dropdown: {
        height: 30,
        borderColor: esiColor.SBorderColor,
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 8,
        activeColor: esiColor.itemColor,
        iconColor: esiColor.itemColor,
        backgroundColor: esiColor.BackgroundColor,
        maxWidth: 150
        
      },
      placeholderStyle: {
        fontSize: 16,
        color: esiColor.BackgroundColor
      },
      selectedTextStyle: {
        fontSize: 16,
        color: esiColor.SIconColor
      },
        iconStyle: {
            width: 20,
            height: 20,
        },
        inputSearchStyle: {
            height: 40,
            fontSize: 16,
        },
        containerstyle: {
            maxWidth: 200,
        },
        text: {
            fontSize: 12,
            fontFamily: 'system-ui',
            color: esiColor.DescColor
        }
    });
    

    return (
        <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "row", marginTop: 15, justifyContent: 'space-evenly' }}>
                <View style={{ flex: 1, marginLeft: 15, maxWidth: 150 }}>

                    <EsiSearchDropdown
                        style={[styles.dropdown]}
                        placeholderStyle={styles.placeholderStyle}
                        selectedTextStyle={styles.selectedTextStyle}
                        inputSearchStyle={styles.inputSearchStyle}
                        iconStyle={styles.iconStyle}
                        data={filterOptions}
                        containerStyle={styles.containerstyle}

                        maxHeight={180}
                        labelField="label"
                        valueField="value"
                        placeholder={!showDropDown ? 'All' : 'All'}
                        searchPlaceholder="Search..."
                        value={ordersFilter.select}
                        onFocus={() => setShowDropDown(true)}
                        onBlur={() => setShowDropDown(false)}
                        onUpdateValue={item => {
                            setShowDropDown(false);
                            handleChange(item.value)
                            setValues(item)
                        }}
                    />
                </View>
                {/* <View style={{ paddingLeft: 20, flex: 5 }}> */}
                <Searchbar
                    style={{
                        marginHorizontal: 10, borderColor: esiColor.SBorderColor, borderWidth: 1, maxWidth: 200, marginRight: 10,
                        backgroundColor: esiColor.BackgroundColor, height: 30,
                    }}
                    inputStyle={{ fontSize: 14 }}
                    placeholder="Orders"
                    iconColor= {esiColor.SIconColor}
                    theme={{ colors: { text: esiColor.SBTextColor} }}
                    placeholderTextColor={esiColor.SPHTextColor}
                    onChangeText={(value) => handleSearch(value)}
                    value={ordersFilter.search}
                />
                {/* </View> */}
            </View>
            <View style={{ marginTop: 10, width: "100%" }}>
                {data.length <= 0 ?
                    <View style={{ alignItems: "center", marginTop: 10 }}>

                        <View style={{ maxWidth: 750, minWidth: 600 }}>
                            <Image style={{ resizeMode: 'contain', width: 650, height: 350 }} source={ordergif}></Image>
                            <Text style={{ textAlign: "center", color: esiColor.NoDataFound, marginTop: 10 }}>Oops! No orders found...</Text>
                        </View>

                    </View>
                    :
                    <ScrollView showsVerticalScrollIndicator={false} style={{ height: windowHeight - ((windowHeight / 100) * (Platform.OS === 'web' ? 20 : 30)) }} >
                        <View style={{
                            marginTop: 0, flexDirection: 'row', flex: 1, flexWrap: 'wrap', width: width, justifyContent: 'space-evenly'

                        }}>
                            {data.map((data, index) => (
                                <TouchableOpacity key={index} onPress={() => {
                                    navigation.navigate('OrderDetails', { orderId: data.Order_ID, isRetail: "false" });
                                }}>
                                    <View key={index} style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        shadowOpacity: 7,
                                        shadowRadius: 5,
                                        marginHorizontal: 2, borderRadius: 10, padding: 2,
                                        backgroundColor: esiColor.CBColor,
                                        marginTop: 15, flexDirection: 'row',
                                        marginBottom: 20,
                                        alignItems: 'flex-start',
                                        width: width / 4,
                                        marginLeft: 15, marginRight: 15,
                                        height: 125,
                                        margin: 15,
                                    }}>
                                        <View style={{ flex: 1, alignContent: 'center' }} >

                                            <Image source={{ uri: data.Partner_Logo + `?w=80&h=100` }} style={{ height: 100, marginTop: 10, marginLeft: 10, width: 80, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{data.Partner_Name}</Text>
                                            <Text style={styles.text}>Order ID : {data.Order_ID}</Text>
                                            <Text style={styles.text}>Order Status : <Text style={{ color: data.Order_Status === "NEW" ? esiColor.Text : data.Order_Status === "COMPLETED" ? "green" : data.Order_Status === "CANCELLED" ? "red" : data.Order_Status === "RETURN" ? "orange" : esiColor.GBFColor }}>{data.Order_Status === "NEW" ? "ORDERED" : data.Order_Status === "COMPLETED" ? "DELIVERED" : data.Order_Status === "RETURN" ? "RETURN COMPLETE" : data.Order_Status === "REFUND" ? "REFUNDED" : data.Order_Status}</Text>
                                            </Text>
                                            <Text style={styles.text}>Order Date : {data.Ordered_Date}</Text>
                                            <Text style={styles.text}>Order Value :<Text style={{ color: esiColor.GBFColor }}>{data.Order_Total}</Text></Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>

                            ))}
                        </View>
                        <View style={{ marginBottom: 20 }} ></View>
                    </ScrollView>
                }
            </View>
        </View>

    )
}
