import axios from "axios";
import { boomiApi, boomiDelApi } from "./commonAxios";

//Get Reviews and Ratings
export const getAllDelReviewRatingsAction = (formData: any, setAlldelireviewsandratings: any) => async (dispatch: any) => {
    try {
        let { data } = await boomiDelApi.post(
            `/Delivery_Review_And_Ratings/Get?PageNo=0&PageSize=1000&SortBy=QUESTION&SortOrder=ASC`,
            formData
        );
        if (data) {
            if (data.Is_Data_Exist === "0") {
                setAlldelireviewsandratings({ status: false });
            } else {
                setAlldelireviewsandratings({ status: true, data: data?.Delivery_Reviews_And_Ratings || [] });
            }
        }
    }
    catch (err) {

    }
};

export const getOrderByDelReviewRatingsAction = (formData: any, setAlldelireviewsandratings: any) => async (dispatch: any) => {
    try {
        let { data } = await boomiApi.post(
            `/Order_Review/Order_Review_Get?PageNo=0&PageSize=1000&SortBy=QUESTION&SortOrder=ASC`,
            formData);
        if (data) {
            if (data.Is_Data_Exist === "0") {
                setAlldelireviewsandratings({ status: false });
            } else {
                setAlldelireviewsandratings({ status: true, data: data?.Delivery_Reviews_And_Ratings || [] });
            }
        }
    }
    catch (err) {

    }
};

//Create Reviews and Ratings
export const createDelReviewRatingsAction = (formData: any, toast: any, successCall: any) => async (dispatch: any) => {

    boomiDelApi.post(
        `/Delivery_Review_And_Ratings/Create`,
        formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                toast.show({ message: 'Review added successfully.', type: 'info', duration: 3000, position: 'top' });
                successCall();
            } else {
                toast.show({ message: 'Failed to add Review, please try again.', type: 'info', duration: 3000, position: 'top' });
            }
        })
        .catch(error => {
            toast.show({ message: "Please try sometime later.Currently server is not working", type: 'info', duration: 3000, position: 'top' });
        })
};

export const createOrderDelReviewRatingsAction = (formData: any, toast: any, successCall: any) => async (dispatch: any) => {

    boomiApi.post(
        `/Order_Review/Order_Review_Create`,
        formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                toast.show({ message: 'Review added successfully.', type: 'info', duration: 3000, position: 'top' });
                successCall();
            } else {
                toast.show({ message: 'Failed to add Review, please try again.', type: 'info', duration: 3000, position: 'top' });
            }
        })
        .catch(error => {
            toast.show({ message: "Please try sometime later.Currently server is not working", type: 'info', duration: 3000, position: 'top' });
        })
};