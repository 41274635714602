import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { getAllNewseventsAction } from "../../state/actions/newsandeventsActions";
import { View, StyleSheet, ScrollView, Dimensions, RefreshControl } from 'react-native';
import { Provider, Card, Button, Text, Surface, Modal, ActivityIndicator } from 'react-native-paper';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
// import Footer from '../footer';


export default function NewsAndEvents(props: any) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    // API call
    let newsevent = useSelector((state) => state.allNewsEvents.newsevents.all);
    console.log(newsevent, '............................................')
    //API dispatch
    useEffect(() => {
        refreshControlCall()
    }, []);

    const refreshControlCall = () => {
        // let formData = {
        //     Records_Filter: "ALL",
        // };
        dispatch(getAllNewseventsAction())
    }

    return (
        // <View style={{ flex: 1 }}>
        <Surface style={{ flex: 1 }}>
            <Header navigation={navigation} />

            <Text style={{ fontSize: 24, marginTop: 15, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>News & Events</Text>

            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={refreshControlCall}
                    />
                }
            >

                {!isEmpty(newsevent) ? (
                    newsevent.map((data, index) => (
                        // <View key={data, index}>
                        <View style={{
                            flex: 1,
                            marginHorizontal: 2, borderRadius: 25, padding: 5, margin: 'auto', backgroundColor: esiColor.BackgroundColor, flexDirection: 'column', borderColor: "#CACFD2", borderWidth: 1,
                            shadowOffset: { width: 0, height: 2 },
                            shadowColor: '#6D70E7',
                            marginTop: "1%",
                            marginBottom: "1%",
                            shadowOpacity: 0.2,
                            shadowRadius: 10,
                            elevation: 10,
                            marginLeft: "auto", marginRight: "auto",
                            width: 750,
                        }}>
                            <Text style={{ fontWeight: 'bold', margin: 2, justifyContent: 'center', alignItems: 'center', fontSize: 20, color: esiColor.Text }}>{data.Title} </Text>

                            <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginTop: 1, backgroundColor: esiColor.RTBC, borderRadius: 8 }}>
                                <RenderHTML
                                    source={{ html: data?.Description }} />
                            </View>
                        </View>
                        // </View>
                    ))

                ) : (
                    null
                )}

            </ScrollView>

        </Surface>

    );
}
