import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, StyleSheet, ScrollView, Dimensions, Platform } from 'react-native';
import { Provider, Card, Button, Text, Surface, Modal, ActivityIndicator, Avatar } from 'react-native-paper';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { getdashboard } from '../../state/actions/dashboardActions';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

export default function Dashboard(props) {
    const { navigation } = props;
    const [Mail, setMail] = React.useState("");
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const EmailID = useSelector(state => state.auth.user.Email_Id)
    const Dashboard = useSelector(state => state.dashboard.dashboards.all);
    useEffect(() => {
        if (!isEmpty(EmailID)) {
            if (isEmpty(Dashboard) || EmailID !== Mail) {
                setMail(EmailID)
                let formData = {
                    "Records_Filter": "FILTER",
                    "User_Email_Id": EmailID,
                }
                dispatch(getdashboard(formData));
            }
        } else {
            setTimeout(() => {
                if (isEmpty(EmailID)) {
                    navigation.navigate("Home");
                }
            }, 1000)
        }
    }, [Dashboard, EmailID]);


    return (
        <Surface style={{ flex: 1, alignContent: 'center', backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <ScrollView>
                <Text style={{ fontSize: 22, fontWeight: "bold", textAlign: 'center', color: esiColor.brandFontColor, justifyContent: 'center', marginTop: 15 }}>Dashboard</Text>

                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    // alignSelf: 'flex-start',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    margin: 'auto',
                    marginBottom: 20,
                    marginTop: 20
                }}>
                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20,
                        flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="tag-heart-outline" color="white" style={{ backgroundColor: 'orange' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/gift_purchased.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}>Gift Cards Purchased</Text>

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor }}> {Dashboard[0]?.Gift_Card_count}</Text>
                        </View>

                    </Card>
                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor,
                        margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="basket" color="white" style={{ backgroundColor: 'green' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/orders.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}>Orders</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}> {Dashboard[0]?.Order_Count}</Text>
                        </View>
                    </Card>

                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="cards-outline" color="white" style={{ backgroundColor: 'orange' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/products.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}>Purchased Products</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}> {Dashboard[0]?.Purchased_Products}</Text>
                        </View>
                    </Card>

                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="contacts" color="white" style={{ backgroundColor: 'orange' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/references.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}>References</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}> {Dashboard[0]?.Reference_Count}</Text>
                        </View>
                    </Card>

                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="contacts" color="white" style={{ backgroundColor: 'green' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/cashback.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}>Cashback</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}> {Dashboard[0]?.TOTAL_CASHBACK}</Text>
                        </View>
                    </Card>

                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="currency-usd" color="white" style={{ backgroundColor: 'green' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/gift_amount.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                        </View>
                        <View>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}> Gift Card amount used</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}>{Dashboard[0]?.Total_Gift_Card_amount_used}</Text>
                        </View>
                    </Card>
                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10, maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="currency-usd" color="white" style={{ backgroundColor: 'orange' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/money_spent.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}> Money Spent</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}>{Dashboard[0]?.Total_Money_Spent}</Text>
                        </View>
                    </Card>
                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="ticket" color="white" style={{ backgroundColor: 'green' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/ticket.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}> Tickets</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}>{Dashboard[0]?.Total_Tickets}</Text>
                        </View>
                    </Card>
                    <Card style={{
                        borderRadius: 10, flex: 1, padding: 10, backgroundColor: esiColor.CBColor, margin: 20, flexDirection: 'row', shadowColor: esiColor.brandShadowColor,
                        shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 10,
                        maxWidth: Platform.OS === "ios" ? windowWidth > 250 ? 400 : 400 : 370, minWidth: Platform.OS === "ios" ? 350 : 300,
                        maxHeight: windowHeight > 250 ? 250 : 90, minHeight: 110,
                    }}>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', alignSelf: 'flex-end', marginBottom: -85 }}>
                            {/* <Avatar.Icon size={50} icon="currency-usd" color="white" style={{ backgroundColor: 'orange' }} /> */}
                            <Avatar.Icon size={75} color={esiColor.GBFColor} icon={require('../../assets/icons/wallet.png')} style={{ backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.1 }} />

                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 10, fontWeight: 'bold', color: esiColor.itemColor }}> Wallet</Text>
                        </View>
                        <View style={{ flexDirection: 'column', flexWrap: 'wrap', margin: 10 }}>
                            <Text style={{ padding: 5, fontSize: 20, fontWeight: "bold", marginLeft: "5%", color: esiColor.brandFontColor }}>{Dashboard[0]?.Total_money_added_in_wallet}</Text>
                        </View>
                    </Card>
                </View>

            </ScrollView>

        </Surface>

    );


}
