import { shareAsync } from "expo-sharing";
import React, { useEffect, useRef, useState } from "react";
import { ActivityIndicator, Dimensions, Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Dialog, Button, IconButton, Portal, Surface, Text, Card, Divider, DataTable, List, TextInput, HelperText, Menu } from "react-native-paper";
import StepIndicator from 'react-native-step-indicator';
import { useDispatch, useSelector } from "react-redux";
import { getSingleNonRetailOrdersAction, OrderInvoiceAction } from "../../state/actions/orderDetailsAction";
import isEmpty from "../../state/validations/is-empty";
import * as Print from "expo-print";
import Header from "../header";
import ESIDeliveryRating from "./delireviewrating";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
// import esiColor from "../../constants/Colors";


export default function NonRetailOrderDetails(props) {
    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const { orderId } = props?.route?.params || "";
    const windowHeight = Dimensions.get('window').height;
    const EmailID = useSelector(state => state.auth.user?.Email_Id)
    const [invoiceDetails, setDetails] = useState();
    const [orderInfo, setOrderInfo] = useState({});
    const toast = useToast();

    const handleClick = async (data) => {
        navigation.navigate('ProductDetails', { Product_Id: data?.Product_ID })
    };



    const [oLoading, setOLoading] = React.useState(false);
    const [orderDetailsFilter, setOrderDetailsFilter] = React.useState("");
    useEffect(() => {
        if (isEmpty(orderId)) {
            navigation.navigate('MyOrders');
        }
        else {
            if (EmailID) {
                let formData1 = {
                    Order_Id: orderId
                }
                let formDataInvoice = {
                    "Order_Id": orderId,
                    // "Order_Type": "RETAIL"
                }
                dispatch(OrderInvoiceAction(formDataInvoice, updateStatesInvoice));
                if (!oLoading && orderDetailsFilter != JSON.stringify(formData1)) {
                    setOrderInfo({});
                    setOLoading(true);
                    setOrderDetailsFilter(JSON.stringify(formData1));
                    dispatch(getSingleNonRetailOrdersAction(formData1, orderDetailsResponse));
                }
            }
        }
    }, [orderId]);

    const orderDetailsResponse = (data) => {
        setOrderInfo(data);
        setOLoading(false);
    };

    const updateStatesInvoice = (status, data) => {
        if (status) {
            setDetails(data);

        }
    };


    const getCurrentDeliveryStatus = (deliveryType, ItemMasterStatus) => {
        let orderlabels = [];
        if (deliveryType == "LIVE" && ["NEW", "ACCEPTED", "PACKED", "IN-TRANSIT", "DELIVERED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Packed", "In Transit", "Delivered"];
        } else if (["REJECTED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Rejected", "Settled"];
        } else if (["CANCELLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Cancelled", "Settled"];
        }
        else if (["RETURNED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Delivered", "Returned", "Settled"];
        }
        else if (["SETTLED"].includes(ItemMasterStatus)) {
            orderlabels = ["New", "Settled"];
        } else {
            orderlabels = ["New", "Shipped", "In Transit", "Out For Delivery", "Delivered"];
        }
        return orderlabels
    }

    const getCurrentStatusPoission = (data, ItemMasterStatus, deliveryType) => {
        let poission = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].toUpperCase() == ItemMasterStatus) {
                poission = i;
                break;
            } else if (data[i] == "Out For Delivery" && ItemMasterStatus == "OUT-FOR-DELIVERY") {
                poission = i;
                break;
            }
            else if (data[i] == "In Transit" && ItemMasterStatus == "IN-TRANSIT") {
                poission = i;
                break;
            }
        }
        if (poission < 1) {
            if (deliveryType == "LIVE" && ["NEW", "ACCEPTED", "PACKED", "IN-TRANSIT", "DELIVERED"].includes(ItemMasterStatus)) {
                if (["ACCEPTED", "PACKED"].includes(ItemMasterStatus)) {
                    poission = 2;
                }
            } else if (["REJECTED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "REJECTED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 2;
                }
            } else if (["CANCELLED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "CANCELLED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 2;
                }
            }
            else if (["RETURNED"].includes(ItemMasterStatus)) {
                if (!(["NEW", "DELIVERED", "CANCELLED", "SETTLED"].includes(ItemMasterStatus))) {
                    poission = 3;
                }
            }
            else if (["SETTLED"].includes(ItemMasterStatus)) {
                poission = 2;
            }

            if (["SETTLED", "DELIVERED"].includes(ItemMasterStatus)) {
                poission += 1;
            }
        }
        return poission;
    }
    const customStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: '#27b6cc',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: '#27b6cc',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: '#27b6cc',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'green',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#1a1a1a',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: '#27b6cc'
    }
    const cancelStyles = {
        stepIndicatorSize: 25,
        currentStepIndicatorSize: 30,
        separatorStrokeWidth: 2,
        currentStepStrokeWidth: 3,
        stepStrokeCurrentColor: '#fe7013',
        stepStrokeWidth: 3,
        stepStrokeFinishedColor: 'red',
        stepStrokeUnFinishedColor: '#aaaaaa',
        separatorFinishedColor: 'red',
        separatorUnFinishedColor: '#aaaaaa',
        stepIndicatorFinishedColor: 'red',
        stepIndicatorUnFinishedColor: '#ffffff',
        stepIndicatorCurrentColor: '#ffffff',
        stepIndicatorLabelFontSize: 13,
        currentStepIndicatorLabelFontSize: 13,
        stepIndicatorLabelCurrentColor: '#fe7013',
        stepIndicatorLabelFinishedColor: '#ffffff',
        stepIndicatorLabelUnFinishedColor: '#aaaaaa',
        labelColor: '#999999',
        labelSize: 13,
        currentStepLabelColor: 'red'
    }


    const getInvoice = () => {


        let productRow = "";

        invoiceDetails.product.map((product) => {
            productRow += `<tr> 
                        <td contenteditable="false" style="text-align: center;" >1</td>
                        <td contenteditable="false" style="width:50%;text-align: center;" >`+ product.Name + `</td>
                        <td contenteditable="false" style="text-align: center;" >`+ product.Product_Price + `</td>
                        `+ (product.Discount ? `<td style="text-align: center;" >` + product.Discount + `</td>` : ``) + `
                        <td contenteditable="false" style="text-align: center;" >`+ product?.Product_Selling_Price + `</td>
                        <td contenteditable="false" style="text-align: center;" >`+ product.Qty + `</td>
                        <td contenteditable="false" style="text-align: center;" >`+ product.GrandTotal + `</td>
                        </tr>`
        })



        try {
            return `
        <html>
        <head>
            <style>
            .grid-container {
            display: grid;
            grid-template-columns: auto auto;
            }.hr.solid {
            border-top: 5px solid #27b6cc;
            }
            table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
            }
            td{
            border: 1px solid #27b6cc;
            text-align: right;
            padding: 8px;
            }
            th {
            border: 1px solid #27b6cc;
            text-align: right;
            background-color: #27b6cc;
            padding: 8px;
            }
            </style>
            
                    <meta charset="utf-8">
                    <title contenteditable="false">Invoice</title>
                    <script src="script.js"></script>
                </head>
                <body onclick="window.print()" >
                    <header>
                <center>
                        <h1 contenteditable="false" style="color:#27b6cc;">Invoice</h1>
                </center>
                        <address contenteditable>
                <div class="grid-container">
                <img src="`+ require('../../assets/images/img/logo/logo.png') + `" width="100" height="80" style="background-color: rgb(39, 182, 204);">
                <span style="text-align:right">
                <p contenteditable="false">Order Id: `+ invoiceDetails.OrderId + `</p>
                <p contenteditable="false">Order Date: `+ invoiceDetails.OrderDate + `</p>
                <p contenteditable="false">Invoice No: `+ invoiceDetails.InvoiceNo + `</p>
                <p contenteditable="false">Invoice Date:`+ invoiceDetails.InvoiceDate + `</p>
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4 contenteditable="false">Billing Address:</h4>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.Name + `</p>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.AddressDrToSt + `</p>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.City + `,` + invoiceDetails.BillingAddress.State + `,` + invoiceDetails.BillingAddress.Country + `</p>
                <p contenteditable="false">${invoiceDetails.BillingAddress.PhoneNumber}</p>
                <p contenteditable="false">`+ invoiceDetails.BillingAddress.email + `</p>
                </span>
                <span style="text-align:right">
                <h4 contenteditable="false">Shipping Address:</h4>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.Name + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.AddressDrToSt + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.City + `,` + invoiceDetails.ShippingAddress.State + `,` + invoiceDetails.ShippingAddress.Country + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.PhoneNumber + `</p>
                <p contenteditable="false">`+ invoiceDetails.ShippingAddress.email + `</p>
                </span>
                </div>
                <hr class="solid">
                <div class="grid-container">
                <span>
                <h4 contenteditable="false">Sold By:</h4>
                <p contenteditable="false">`+ invoiceDetails.Partner.Name + `</p>
                <p contenteditable="false">`+ invoiceDetails.Partner.AddressDrToSt + `</p>
                <p contenteditable="false">`+ invoiceDetails.Partner.City + `,` + invoiceDetails.Partner.State + `,` + invoiceDetails.Partner.Country + `</p>
                <p contenteditable="false">`+ invoiceDetails.Partner.Name + `</p>
                </span>
                <span style="text-align:right">
                <p contenteditable="false">PAN NO: `+ invoiceDetails.Partner.Pan + `</p>
                <p contenteditable="false">GST: `+ invoiceDetails.Partner.GST + `</p>
                </span>
                </div>
                <hr class="solid">
                <table>
                    <tr>
                    <th style="text-align: center;" >SI No</th>
                    <th style="width:50%;text-align: center;">Description</th>
                    <th style="text-align: center;" >Rate</th>
                    <th style="text-align: center;" >Discount</th>
                    <th style="text-align: center;" >Amount</th> 
                    <th style="text-align: center;" >QTY</th>
                    <th style="width:10%;text-align: center;" >Total</th>
                    </tr>
                   
                    ` + productRow + (invoiceDetails.Order_Totals.ShippingCharges ? `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td contenteditable="false" style="text-align: center;" colspan="2" > Shipping Charges </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.ShippingCharges + ` </td>
                    </tr>`: ``) + (invoiceDetails.Order_Totals.IGST ? `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td contenteditable="false" style="text-align: center;"> IGST  </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.IGSTPercentage + `% </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.IGST + ` </td>
                    </tr>`: ``)
                + (invoiceDetails.Order_Totals.SGST ? `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td contenteditable="false" style="text-align: center;"> SGST  </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.SGSTPercentage + `% </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.SGST + ` </td>
                    </tr>`: ``) +
                (invoiceDetails.Order_Totals.CGST ? `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td contenteditable="false" style="text-align: center;"> CGST  </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.CGSTPercentage + `% </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.CGST + ` </td>
                    </tr>`: ``) +
                (invoiceDetails.Order_Totals.VAT ? `
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td contenteditable="false" style="text-align: center;"> VAT  </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.VATPercentage + `% </td>
                    <td contenteditable="false" style="text-align: center;"> `+ invoiceDetails.Order_Totals.VAT + ` </td>
                    </tr>`: ``) +
                `<tr>
                <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    </tr>

                    <tr>
                    <td></td>
                    <td>  </td>
                    <td></td>
                    <td contenteditable="false" style="text-align: center; font-weight: bold;" colspan="3">
                    Final Amount
                    </td>
                    <td contenteditable="false" style="text-align: center; font-weight: bold;"> `+ invoiceDetails.Order_Totals.Final_Amount + ` </td>
                    </tr>

                    <tr>
                        <td contenteditable="false" style="text-align: left; font-weight: bold;" colspan="7">
                        Amount in words:${invoiceDetails.Order_Totals.AmountInWords}
                        </td>
                    </tr>
            
                </table>
            </header>
            <aside>
                <div contenteditable>
                    <p contenteditable="false">Note: This is computer generated Invoice and signature is not required.</p>
                </div>
            </aside>
        </body>
        </html>`;
        } catch (error) {
        }

    }

    const printToFile = async () => {
        const html = getInvoice();
        if (Platform.OS == 'web') {
            const pW = window.open('', '', 'height=500, width=500')
            pW.document.write(html);
            pW.document.close();
        }
        else {
            try {
                const { uri } = await Print.printToFileAsync({ html });
                await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
            } catch (error) {
            }
        }
    }
    return (

        <Surface style={{ backgroundColor: esiColor.BackgroundColor, flex: 1 }}>
            <Header {...props} />
            <ScrollView>
                {oLoading ? <ActivityIndicator color='#27b6cc' /> :
                    <Surface style={{ backgroundColor: esiColor.BackgroundColor }}>
                        <View style={{ marginVertical: -10 }}>
                            <IconButton icon="keyboard-backspace" color={"#27b6cc"} onPress={() => navigation.navigate('MyOrders')}>
                            </IconButton>
                        </View>
                        <View style={{ flexDirection: 'row', flex: 1 }}>
                            <View style={{ flexDirection: 'column', flex: 2, marginLeft: 30 }}>
                                {orderInfo.Line_Item_Products?.map((product) => {

                                    return (
                                        <View key={product.Order_Line_Item_ID}
                                            style={{
                                                // shadowColor: '#0E9EAD',
                                                // shadowOffset: { width: -2, height: 4 },
                                                // shadowOpacity: 0.2,
                                                shadowRadius: 7,
                                                marginHorizontal: 1, padding: 1, margin: 10, backgroundColor: esiColor.BackgroundColor,
                                                marginTop: (Platform.OS === 'web' ? 15 : 0),
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                elevation: 10,
                                                maxWidth: 400,
                                                maxHeight: 200,
                                                minHeight: 140,
                                                shadowColor: '#fff',
                                                shadowOpacity: 7,

                                                borderRadius: 10
                                            }}
                                        >
                                            <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                                <TouchableOpacity onPress={() => handleClick(product)}>
                                                    <Image source={{ uri: product?.Product_Image + `?w=80&h=100` }} style={{ height: 100, marginTop: 15, width: 80, resizeMode: 'contain' }}></Image>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.brandFontColor }}>{product?.Product_Name}</Text>
                                                <Text style={{ color: esiColor.DescColor }}>Order Product ID : {product?.Order_Line_Item_ID}</Text>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text style={{ color: esiColor.DescColor }}>Quantity : {product?.Quantity}</Text>
                                                </View>
                                                <Text style={{ color: esiColor.DescColor }}>Price : {product?.Partner_Selling_Price}</Text>
                                                <Text style={{ color: esiColor.DescColor }}>Total Price : {Number(product?.Partner_Selling_Price) * Number(product?.Quantity)}</Text>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>

                            <View style={{ alignSelf: 'flex-end', width: '100%', flex: 4 }}>
                                {getCurrentDeliveryStatus(orderInfo?.FG_Single_OrderDetails?.Delivery_Type, orderInfo?.FG_Single_OrderDetails?.Order_Status).length > 0 &&
                                    <View style={{ marginTop: 5, marginBottom: 30 }}>
                                        <StepIndicator
                                            customStyles={orderInfo?.FG_Single_OrderDetails?.Order_Status === "CANCELLED" ? cancelStyles : customStyles}
                                            labels={getCurrentDeliveryStatus(orderInfo?.FG_Single_OrderDetails?.Delivery_Type, orderInfo?.FG_Single_OrderDetails?.Order_Status)}
                                            currentPosition={getCurrentStatusPoission(getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.FG_Single_OrderDetails?.Order_Status), orderInfo?.FG_Single_OrderDetails?.Order_Status, orderInfo?.ProductDetails?.Delivery_Type)}
                                            stepCount={getCurrentDeliveryStatus(orderInfo?.ProductDetails?.Delivery_Type, orderInfo?.FG_Single_OrderDetails?.Order_Status).length}
                                        />
                                    </View>
                                }
                                <View style={{ marginLeft: 5, marginRight: 5 }}>
                                    <View style={{ flexDirection: 'row', marginBottom: 25 }}>

                                        {orderInfo &&
                                            <Card style={{ flex: 5, flexDirection: 'column', marginLeft: 10, marginRight: 20, backgroundColor: esiColor.BackgroundColor }}  >
                                                <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Delivery Address </Text>
                                                <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.Name},</Text>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor }}>D-No : {orderInfo?.UserDeleveryAddress?.Door_No},</Text>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.Street}, {orderInfo?.UserDeleveryAddress?.Landmark}</Text>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.City},{orderInfo?.UserDeleveryAddress?.State},{orderInfo?.UserDeleveryAddress?.Country},</Text>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserDeleveryAddress?.City}-{orderInfo?.UserDeleveryAddress?.Pin},</Text>
                                                <Text style={{ fontSize: 13, marginBottom: 10, color: esiColor.DescColor }}>Phone number : {orderInfo?.UserDeleveryAddress?.PhoneNumber}.  </Text>
                                                <Text style={{ fontSize: 13, marginBottom: 10, color: esiColor.DescColor }}>Email : {orderInfo?.UserDeleveryAddress?.email}.  </Text>
                                            </Card>
                                        }
                                        {/* <View > */}


                                        <Card style={{ flex: 5, flexDirection: 'column', marginLeft: 10, marginTop: 5, backgroundColor: esiColor.BackgroundColor }}  >
                                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginBottom: 10 }}>Billing Address </Text>
                                            <Text style={{ fontSize: 14, marginBottom: 10, fontWeight: 'bold', color: esiColor.DescColor }}>{orderInfo?.UserBillingAddressDetails?.Name},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>D-No : {orderInfo?.UserBillingAddressDetails?.Door_No},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserBillingAddressDetails?.Street}, {orderInfo?.UserBillingAddressDetails?.Landmark},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserBillingAddressDetails?.City},{orderInfo?.UserBillingAddressDetails?.State},{orderInfo?.UserBillingAddressDetails?.Country},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor }}>{orderInfo?.UserBillingAddressDetails?.City}-{orderInfo?.UserBillingAddressDetails?.Pin},</Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor, marginBottom: 10 }}>Phone number : {orderInfo?.UserBillingAddressDetails?.PhoneNumber}.  </Text>
                                            <Text style={{ fontSize: 13, color: esiColor.DescColor, marginBottom: 10 }}>Email : {orderInfo?.UserBillingAddressDetails?.email}.  </Text>
                                        </Card>
                                        {!(["DELIVERED", "SETTLED"].includes(orderInfo?.FG_Single_OrderDetails?.Order_Status)) &&
                                            <View style={{ marginVertical: 5, flexDirection: "column" }} >
                                                <Text style={{ textAlign: "center" }} >
                                                    {orderInfo?.ProductDetails?.Expected_Delivery_Date_Time}
                                                </Text>
                                                <Text style={{ textAlign: "center" }} >
                                                    {orderInfo?.ProductDetails?.Delivery_Message}
                                                </Text>
                                            </View>
                                        }

                                        {/* </View> */}

                                    </View>
                                    {(orderInfo?.DeliveryPersonDetails?.Phone && !(["RETURN", "DELIVERED", "CANCELLED", "SETTLED"].includes(orderInfo?.FG_Single_OrderDetails?.Order_Status))) &&
                                        <View
                                            style={{
                                                shadowColor: esiColor.brandShadowColor,
                                                // shadowOffset: { width: -2, height: 4 },
                                                shadowOpacity: 5,
                                                shadowRadius: 7,
                                                marginHorizontal: 1, padding: 1, margin: 'auto',
                                                backgroundColor: esiColor.CBColor,
                                                marginTop: (Platform.OS === 'web' ? 15 : 0), flexDirection: 'column',
                                                // elevation: 10,
                                                width: 350,
                                                height: 100,
                                                // borderColor: 'grey',
                                                // borderWidth: 0.5,
                                                borderRadius: 10,
                                                marginBottom: 15
                                            }}
                                        >
                                            <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.DescColor, marginLeft: 3, marginTop: 5, marginBottom: 7 }}>Delivery Person </Text>
                                            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => {
                                                if (orderInfo?.FG_Single_OrderDetails?.Order_Status == "IN-TRANSIT" && orderInfo?.FG_Single_OrderDetails?.Delivery_Type == "LIVE") {
                                                    navigation.navigate("OrderTracking", { ID: orderInfo?.FG_Single_OrderDetails.Order_Id, isRetail: "false" });
                                                }
                                            }} >

                                                <View style={{ flex: 1, alignContent: 'center', alignItems: "center", marginLeft: 10 }} >
                                                    <Image source={{ uri: orderInfo?.DeliveryPersonDetails?.Photo + `?w=50&h=50` }} style={{ height: 50, width: 50, resizeMode: 'contain' }}></Image>
                                                </View>
                                                <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.DeliveryPersonDetails?.Name}</Text>
                                                    <Text style={{ fontSize: 14, fontWeight: 'bold', color: esiColor.itemColor }}>{orderInfo?.DeliveryPersonDetails?.Phone}</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    {(orderId && (orderInfo?.FG_Single_OrderDetails?.Order_Status == "DELIVERED" || orderInfo?.FG_Single_OrderDetails?.Order_Status == "RETURNED")) &&
                                        <View style={{ marginVertical: 5 }} >
                                            <ESIDeliveryRating
                                                orderid={orderId}
                                                // lineitemid={masterId}
                                                ordStatus={orderInfo?.FG_Single_OrderDetails?.Order_Status}
                                                isRetail={false}
                                            /> 
                                        </View>
                                    }
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', color: esiColor.brandFontColor, marginLeft: 15, marginBottom: 25 }}>Payment Summary</Text>

                                    <View style={{ flex: 5, flexDirection: 'column', marginLeft: 15,marginBottom: 15, shadowColor: "#fff", marginRight: 30, shadowOpacity: 7, shadowRadius: 7, justifyContent: 'space-evenly' }}  >

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "flex-start", marginLeft: 15 }}>Order Value</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Order_Total_Before_Discount}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 2 }} >
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "flex-start", marginLeft: 15 }}>Tax Amount</Text>
                                            </View>
                                            <View style={{ flex: 2 }}>
                                                <Text style={{ fontSize: 10, color: esiColor.DescColor, alignSelf: "center" }}>Total Tax : {orderInfo?.Payment_Summary?.Total_Tax_Percentage}%</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Total_Tax}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "flex-start", marginLeft: 15 }}>Coupon Discount</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Partner_Coupon_Amount}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "flex-start", marginLeft: 15 }}>Packaging Charges</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Packaging_Charges}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "flex-start", marginLeft: 15 }}>Handling Charges</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Handling_Fee}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "flex-start", marginLeft: 15 }}>Delivery Charges</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "center" }}>{orderInfo?.Payment_Summary?.Delivery_Fee}</Text>
                                            </View>
                                        </View>

                                        <View style={{ flexDirection: "row", marginVertical: 5 }}>
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "flex-start", fontWeight: "bold", marginLeft: 15 }}>Total Gross Price</Text>
                                            </View>
                                            <View style={{ flex: 2, }}>
                                                <Text style={{ fontSize: 13, color: esiColor.DescColor, alignSelf: "center", fontWeight: "bold" }}>{orderInfo?.Payment_Summary?.Final_Gross_Price}</Text>
                                            </View>
                                        </View>
                                        {!(["CANCELLED", "RETURN"].includes(orderInfo?.FG_Single_OrderDetails?.Order_Status)) &&
                                            <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 5, alignSelf: 'center', padding: 5, marginTop: 5, width: 125, marginBottom: 15 }} onPress={printToFile}>
                                                <View >
                                                    <Text style={{ color: esiColor.itemColor }} >Download Invoice</Text>
                                                </View>
                                            </TouchableOpacity>
                                        }

                                    </View>

                                </View>
                            </View>
                        </View>
                    </Surface>}

            </ScrollView >
        </Surface >
    )
}
