import 'react-native-gesture-handler';
import * as React from 'react';
import Header from '../header';
import { Text, View, StyleSheet, Picker, ScrollView, Platform, Dimensions, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import { Button, Provider, Searchbar, Surface, } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { getbillreceipts, cashbackFilter } from "../../state/actions/missingcashbackActions";
import isEmpty from '../../state/validations/is-empty';
import EsiURLButton from '../../components/custom/linkingfunction/linkingFunction';
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import missingcashbackgif from "../../assets/images/GIF/SprtCashBkImg.png";
import { useToast } from '../../self_modules/react-native-paper-toast/src';

const filterOptions = [
  {
    value: 'All',
    label: 'All'
  },
  {
    value: 'Missing_Cashback_Id',
    label: 'Missing Cashback ID'
  },
  {
    value: 'Total_Amount',
    label: 'Total Amount'
  },
  {
    value: 'Created_Date',
    label: 'Created Date'
  }, {
    value: 'Status',
    label: 'Status'
  },
];

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function MissingCachback(props: any) {
  const esiColor = useSelector(state => state.theme);

  let allcashbacks = useSelector((state) => state.allCashbacks.Cashbacks.all);
  let cashbackMenu = useSelector(state => state.allCashbacks.cashbackFilter.select);
  let cashbackSearch = useSelector(state => state.allCashbacks.cashbackFilter.search);
  const isLoading = useSelector(state => state.allCashbacks.Cashbacks.isLoading);
  const user = useSelector(state => state.auth?.user?.Email_Id);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [cashbacks, setCashbacks] = React.useState([]);
  const { height, width } = Dimensions.get('window');
  const toast = useToast();
  const { navigation } = props;
  const dispatch = useDispatch()
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  React.useEffect(() => {
    if (!isEmpty(user)) {
      const formData = {
        User_Email_Id: user,
        Records_Filter: "FILTER",
      };
      dispatch(getbillreceipts(formData))
      dispatch(cashbackFilter('search', ''));
      dispatch(cashbackFilter('select', 'All'));
    } else {
      setTimeout(() => {
        if (isEmpty(user)) {
          navigation.navigate("Home");
        }
      }, 1000)
    }
  }, [user]);

  const [oldSearch, setOldSearch] = React.useState(0);

  const checkproductmastercolorForTable = () => {
    //search
    if (cashbackSearch) {
      try {
        if (cashbackMenu === "All") {
          return allcashbacks.filter(x => {
            let colummns = ["Missing_Cashback_Id", "Total_Amount", "Created_Date", "Status"];
            for (let i = 0; i < colummns.length; i++) {
              const mainString = typeof x[colummns[i]] === "string" ? x[colummns[i]].toLowerCase() : x[colummns[i]];
              const subString = typeof x[colummns[i]] === "string" ? cashbackSearch.toLowerCase() : cashbackSearch;
              if (typeof x[colummns[i]] === "string") {
                if (mainString.includes(cashbackSearch.toLowerCase())) {
                  return true;
                }
              }
              else {
                if (mainString == cashbackSearch) {
                  return true;
                }
              }
            }
            return false;
          });
        } else {
          return allcashbacks.filter(x => {
            let mainString = typeof x[cashbackMenu] == "string" ? x[cashbackMenu].toLowerCase() : x[cashbackMenu];
            let subString = typeof x[cashbackMenu] == "string" ? cashbackSearch.toLowerCase() : cashbackSearch;
            if (typeof x[cashbackMenu] === "string") {
              return mainString.includes(subString);
            }
            else {
              if (mainString == cashbackSearch) {
                return true;
              }
            }
          })
        }
      } finally {
        if (oldSearch != cashbackSearch.length) {
          setOldSearch(cashbackSearch.length);
        }
      }
    }
    else {
      return allcashbacks
    }
  }

  React.useEffect(() => {
    if (!isEmpty(allcashbacks)) {
      setCashbacks(allcashbacks);
    } else {
      setCashbacks([]);
    }
  }, [allcashbacks]);

  const handleChange = itemValue => {
    dispatch(cashbackFilter('select', itemValue));
  };
  const handleSearch = value => {
    dispatch(cashbackFilter('search', value));
  };

  const styles = StyleSheet.create({
    button: {
      alignSelf: 'center',    // padding: 5,
      backgroundColor: esiColor.globalButtonColor,
      borderRadius: 10,
      marginRight: 15,
      width: 125, height: 35
    },

    mainbox: {
      margin: 15,
      flex: 1,
      justifyContent: 'space-between',
      alignSelf: 'center',
    },

    containerstyle: {
      width: 240,
    },

    dropdown: {
      height: 58,
      borderColor: esiColor.SBorderColor,
      borderWidth: 1,
      borderRadius: 5,
      paddingHorizontal: 8,
      activeColor: esiColor.itemColor,
      iconColor: esiColor.itemColor,
      backgroundColor: esiColor.BackgroundColor,

    },
    placeholderStyle: {
      fontSize: 16,
      // backgroundColor: esiColor.BackgroundColor,
      color: esiColor.BackgroundColor
    },
    selectedTextStyle: {
      fontSize: 16,
      color: esiColor.SIconColor
    },
    iconStyle: {
      width: 20,
      height: 20,
    },
    inputSearchStyle: {
      height: 40,
      fontSize: 16,
    },

  });


  const data = checkproductmastercolorForTable()

  return (

    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={props.navigation} />
      <Provider>

        <View style={{ flexDirection: "row", justifyContent: 'space-between', marginTop: 25 }}>
          <Text style={{ fontSize: 15, color: esiColor.brandFontColor, fontWeight: "bold", marginLeft: 15 }}>Missing Cashback/Rewards</Text>
          <TouchableOpacity onPress={() => navigation.navigate('AddMissingCashback')} style={styles.button}>
            <Text style={{ fontSize: 14, color: esiColor.itemButtenColor, fontWeight: "bold", alignItems: 'center', marginTop: 7 }}>+CREATE TICKET</Text>
          </TouchableOpacity>
        </View>
        <ScrollView>

          <View style={{ flexDirection: "row", marginTop: 20, alignSelf: 'center', margin: 10, justifyContent: 'space-between' }}>
            <View style={{ width: 175, marginRight: 20 }}>
              {/* {Platform.OS !== 'web' ? */}
              <EsiSearchDropdown
                style={[styles.dropdown]}
                placeholderStyle={styles.placeholderStyle}
                selectedTextStyle={styles.selectedTextStyle}
                inputSearchStyle={styles.inputSearchStyle}
                iconStyle={styles.iconStyle}
                data={filterOptions}
                containerStyle={styles.containerstyle}
                // search
                maxHeight={170}
                labelField="label"
                valueField="value"
                placeholder={!showDropDown ? 'All' : ''}
                // searchPlaceholder="Search..."
                value={cashbackMenu}
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
                onUpdateValue={item => {
                  setShowDropDown(false);
                  handleChange(item.value)
                }}
              />
              {/* :
                <Picker
                  selectedValue={cashbackMenu}
                  selectionColor={esiColor.SIconColor}
                  style={{ height: 50, borderRadius: 5, borderWidth: 1, borderColor: '#bdc3c7' }}
                  onValueChange={(itemValue, itemIndex) => handleChange(itemValue)}
                >
                  <Picker.Item label="All" value="" />
                  <Picker.Item label="Missing Cashback ID" value="Missing_Cashback_Id" />
                  <Picker.Item label="Total Amount" value="Total_Amount" />
                  <Picker.Item label="Created Date" value="Created_Date" />
                  <Picker.Item label="Status" value="Status" />
                </Picker> */}
              {/* } */}
            </View>
            {/* <View style={{ flex: dimensions <= 700 ? 0.3 : 0.6 }} /> */}
            <View>
              <Searchbar
                style={{
                  borderColor: esiColor.SBorderColor, borderWidth: 1,
                  backgroundColor: esiColor.BackgroundColor, width: 200
                }}
                placeholder="Search"
                iconColor={esiColor.SIconColor}
                theme={{ colors: { text: esiColor.SBTextColor } }}
                placeholderTextColor={esiColor.SPHTextColor}
                variant="outlined"
                onChangeText={(value) => handleSearch(value)}
                value={cashbackSearch}
              />
            </View>
          </View>
          <ScrollView >
            <View style={styles.mainbox}>
              <View style={{ marginTop: 0, flexDirection: 'row', flex: 1, flexWrap: 'wrap', width: width, alignItems: 'center', justifyContent: 'space-evenly' }} >
                {
                  data.map((cashback, index) => (<View key={index} >
                    {isEmpty(cashback) ? <Text style={{ fontSize: 16, display: 'flex', justifyContent: 'center', color: esiColor.NoDataFound, alignItems: 'center' }}>
                      No Records found.
                    </Text> : <View style={{
                      paddingTop: 15, marginHorizontal: 10, borderRadius: 10, marginTop: 10,
                      backgroundColor: esiColor.BackgroundColor,
                      borderColor: esiColor.SBorderColor,
                      shadowOpacity: 2,
                      shadowColor: esiColor.brandShadowColor,
                      shadowRadius: 2,
                      minHeight: 100,
                      flex: 1,
                      width: width / 4,
                      justifyContent: 'space-between'

                    }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, margin: '1%', padding: 10 }}>
                        <View >
                          <Text style={{ fontSize: 15, color: esiColor.itemColor }}>Ticket ID : {cashback?.Missing_Cashback_Id}</Text>
                          <Text style={{ fontSize: 15, marginTop: 4, marginBottom: 4, color: esiColor.itemColor }}>Total Amount : {cashback?.Total_Amount}</Text>
                          <Text style={{ fontSize: 15, color: esiColor.itemColor }}>Created Date : {cashback?.Created_Date}</Text>
                        </View>
                        <View >
                          <Text style={{ fontSize: 15, color: esiColor.itemColor }}>Status  <Text style={{ color: cashback?.Status === "Hold" ? "red" : cashback?.Status === "Settled" ? 'green' : cashback?.Status === "Active" ? esiColor.GBFColor : cashback?.Status === "Pending" ? 'orange' : esiColor.Text }}>{cashback?.Status}</Text></Text>
                          <View style={{ flexDirection: 'row', marginTop: 18, justifyContent: 'space-between' }}><Text style={{ fontSize: 15, color: esiColor.itemColor }}> Attachment  </Text><EsiURLButton url={cashback?.Invoice_Attachment} /></View>
                        </View>
                      </View>
                    </View>}
                  </View>
                  ))
                }
                {isLoading ? <ActivityIndicator color={esiColor.SIconColor} /> : isEmpty(data) &&
                  <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 250 }} source={missingcashbackgif} />
                    {/* <Image source={require('../../assets/images/not-found-animate.gif')}
                      style={{ width: 300, height: 300 }} /> */}
                    <Text style={{ fontSize: 13, fontWeight: 'bold', color: esiColor.NoDataFound, marginTop: 10 }}>No Records Found!</Text>
                  </View>
                }</View>
            </View>
          </ScrollView>
        </ScrollView>
      </Provider>
    </Surface>

  );
}

export default MissingCachback