import React, { Fragment, useEffect } from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ESICopyCouponModel from "../../components/custom/copycode-modal";
import ESICouponsCard from "../../components/custom/coupon-card";
import isEmpty from "../../state/validations/is-empty";
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import { resetMainData } from "../../state/actions/filterServiceActions";
import { getCouponsAction } from "../../state/actions/node-actions/home-actions";
// import esiColor from "../../constants/Colors";


export default function MeeCoupons(props) {
    const { navigation, partnerId } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const window = Dimensions.get("window");
    //open param is used to open the Copy Code Modal
    const [open, setOpen] = React.useState(false);
    //open param is used to assign the data to Copy Code Modal
    const [couponData, setCouponData] = React.useState('');
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const data = props?.route?.params;
    const [couponLoading, setCouponLoading] = React.useState(false);
    const [coupon, setCoupon] = React.useState([]);

    // Api Calls

    const callBackGetCouponData = async (status, data) => {
        setCouponLoading(false);
        if (status) {
            setCoupon(data);
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        if (partnerId)
            couponsCall();

    }, [partnerId]);

    const couponsCall = () => {
        setCouponLoading(true);
        let formData = { "Records_Filter": "FILTER", "Partner_Details_Id": [partnerId], "Partner_Type": "Physical", "Status": "Active", "condition": true }
        dispatch(getCouponsAction(formData, { PageNo: 1, PageSize: 20, SortBy: "COUPON_ID", SortOrder: "DESC" }, callBackGetCouponData));
    }

    // Condition for coupons expired before 3 days only will display at UI



    const liveData = (inputData: any) => {
        let couponlivedata = [];
        var currentdate = new Date(),
            date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
        if (inputData) {
            couponlivedata = inputData.filter(coupon => {
                let currentDate = new Date(date);
                let couponExpDate = new Date(coupon.Valid_Through_Date.split("/").reverse().join("-"));
                return Math.floor((currentDate - couponExpDate) / (1000 * 60 * 60 * 24)) < 3
            })

        } else {
            couponlivedata = []
        }
        return couponlivedata;
    }


    const signInModalResponse = (status) => {
        setModalVisible(!isModalVisible);
    };
    const openModal = (data) => {
        setCouponData(data)
        setOpen(true);
    };
    const toggleModal = async (data) => {
        if (isAuthenticated) {
            openModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeModal = () => {
        setOpen(!open);
        setCouponData("")
    };
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
    });

    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 5,
            padding: 5,
            shadowColor: esiColor.brandShadowColor,
            // shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 5,
            shadowRadius: 7,
            // elevation: 10
        },

    });

    return (
        <View style={styles.content}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ fontSize: 16, color: "#27b6cc", fontWeight: 'bold' }}> Coupons</Text>
                <TouchableOpacity onPress={(e) => {
                    dispatch(resetMainData());
                    props.navigation.navigate('Main', { screen: 'All', params: { id: partnerId, type: "partner", page: 'coupons', filter: 'all' } });

                }
                } style={{ position: 'absolute', right: 10 }}>
                    <Text style={{ fontSize: 13, color: esiColor.DescColor, fontWeight: 'bold' }}>View All</Text>
                </TouchableOpacity>
            </View>
            <ScrollView
                contentContainerStyle={{ paddingVertical: 10 }}
                horizontal
                showsHorizontalScrollIndicator={false}>
                <View style={{ flexDirection: 'row' }}>

                    {
                        coupon.length === 0 ? (
                            <View style={{ width: dimensions.width - 50, flex: 1, justifyContent: "center" }}>
                                <Text style={{ alignSelf: "center", color: esiColor.NoDataFound }}>No Coupons found!</Text>
                            </View>
                        ) : (
                            liveData(coupon).slice(0, 10).map((data, index) => (
                                <Fragment key={index}>
                                    <ESICouponsCard data={data} toggleModal={toggleModal} />
                                </Fragment>
                            ))
                        )
                    }
                </View>


                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                    <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                        <SignIN navigation={navigation} toggleModal={signInModalResponse} />
                    </View>
                </Modal>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={open}>
                    <View style={{ flex: 1, maxWidth: 600, minWidth: dimensions.width <= 400 ? 200 : dimensions.width <= 400 ? 200 : 350, minHeight: 400, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
                        <ESICopyCouponModel navigation={navigation} openModal={openModal} data={couponData} closeModal={closeModal} />
                    </View >
                </Modal>
            </ScrollView>
        </View>
    )


}

