import { Dimensions, Image, Platform, RefreshControl, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Avatar, Button, Checkbox, Colors, HelperText, IconButton, Surface, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../header";
import { useEffect, useMemo, useState } from "react";
import { getBiddingPartnerServiceDetails } from "../../../../state/actions/node-actions/bid-services-actions";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { placeCommentToBids, updateServicePayment, updateUserBiddingService } from "../../../../state/actions/boomi-actions/bid-services-actions";
import razorpayPay from "../../../../hooks/razorpay";
import useRazorpay from "react-razorpay";
import { getUserSettings, getWalletByUser } from "../../../../state/actions/walletActions";
import Review from "./Review";
import Modal from "react-native-modal";
import { EsiAutocompleteDropdown } from "../../../../components/custom/DropDowns/AutoCompleteDropDown1";
import { getAllCartAddressAction } from "../../../../state/actions/addressActions";


export default function BidPartnerServiceDetails(props) {
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
    const dimensions = Dimensions.get('window');
    const { height, width } = dimensions;
    const { navigation } = props;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const esiColor = useSelector(state => state.theme);

    const [openAddress, setOpenAddress] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [cancelMessage, setCancelMessage] = useState("");

    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isConfirmError, setIsConfirmError] = useState("");


    let RazorPay = null;
    if (Platform.OS === "web") {
        RazorPay = useRazorpay();
    }

    const payNow = () => {
        let amount = (Math.round(finalAdvanceOnlineAmount() + Number.EPSILON));
        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    updatePaymentDetails("RAZORPAY", response.razorpay_payment_id, amount);
                } else {

                }
            });
        } else {
            updatePaymentDetails("WALLET", "", amount);
        }
    }

    const updatePaymentDetails = (type, transactionId, amount) => {
        let formData =
        {
            "Advace_Payment_Status_From_User": 1,
            "Advance_Online_Amount": type == "RAZORPAY" ? amount : 0,
            "Advance_Online_Transaction_Id": transactionId,
            "Advance_Wallet_Amount": (useWallet ? walletPaybleAmount() : 0),
            "Finalize_Partner_Bidding_Quote_Id": Bidding_Id,
            "Modified_By": EmailID,
            "Total_Advance_Amount": getAdvanceAmount,
            "User_Address_Id": deliveryAddress,
            "User_Service_Bidding_Id": product?.User_Service_Bidding_Id
        }
        setLoading(true);
        dispatch(updateServicePayment(formData, paymentCallBack));
    }


    const payNowFinal = () => {
        let amount = (Math.round(finalOnlineAmount() + Number.EPSILON));
        if (amount > 0) {
            razorpayPay(RazorPay, { user: user, amount: amount }, (status, response) => {
                if (status) {
                    updateFinalPaymentDetails("RAZORPAY", response.razorpay_payment_id, amount);
                } else {

                }
            });
        } else {
            updateFinalPaymentDetails("WALLET", "", amount);
        }
    }

    const updateFinalPaymentDetails = (type, transactionId, amount) => {
        let formData = {
            "Final_Payment_Status": 1,
            "Modified_By": EmailID,
            "Online_Amount": type == "RAZORPAY" ? amount : 0,
            "Online_Transaction_Id": transactionId,
            "Wallet_Amount": (useWallet ? walletFinalPaybleAmount() : 0),
            "User_Service_Bidding_Id": product?.User_Service_Bidding_Id
        }
        setLoading(true);
        dispatch(updateServicePayment(formData, paymentCallBack));
    }

    const paymentCallBack = (status, data) => {
        setLoading(false);
        getProductDetails();
    }

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [addressD, setAddressD] = useState();
    const [partnerProduct, setPartnerProduct] = useState({});
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(false);
    const [commentLoading, setCommentLoading] = useState(false);

    const submitComment = () => {
        setCommentError(false);
        if (comment) {
            if (comment.length < 20) {
                setCommentError(true);
            } else {
                setCommentLoading(true);
                let formData = {
                    "Commented_By": "USER",
                    "Comments": comment,
                    "Created_By": EmailID,
                    "Partner_Bidding_Service_Quote_Id": Bidding_Id
                }
                dispatch(placeCommentToBids(formData, placeCallbackResponse));
            }
        } else {
            setCommentError(true);
        }
    }

    const placeCallbackResponse = (status: boolean) => {
        setCommentLoading(false);
        if (status) {
            setComment("");
            getProductDetails();
        }
    }

    const [wallet, setWallet] = useState(null);
    const [maxWalletUsage, setMaxWalletUsage] = useState(50);
    const [useWallet, setUseWallet] = useState(false);


    const updateStatesWallet = (data: any, loading: any) => {
        let add = JSON.parse(JSON.stringify(data));
        setWallet(add?.Wallet[0]);
    };

    const walletPaybleAmount = () => {
        let walletPayable = getAdvanceAmount * (maxWalletUsage * 0.01);
        let payable = walletPayable;
        if (walletPayable > Number(wallet?.Remaining_Balance || 0)) {
            payable = Number(wallet?.Remaining_Balance || 0);
        }
        if (product?.Finalize_Partner_Bidding_Quote_Id) {
            payable = 0;
        }
        return payable
    }

    const walletFinalPaybleAmount = () => {
        let walletPayable = (product?.Remaining_Amount_After_Advance_Amount || 0) * (maxWalletUsage * 0.01);
        let payable = walletPayable;
        if (walletPayable > Number(wallet?.Remaining_Balance || 0)) {
            payable = Number(wallet?.Remaining_Balance || 0);
        }
        if (product?.Payment_Mode != 'COD') {
            payable = 0;
        }
        return payable
    }

    const finalAdvanceOnlineAmount = () => {
        return getAdvanceAmount - (useWallet ? walletPaybleAmount() : 0);
    }

    const finalOnlineAmount = () => {
        return (product?.Remaining_Amount_After_Advance_Amount || 0) - (useWallet ? walletFinalPaybleAmount() : 0);
    }

    const getProductDetails = () => {
        if (Bidding_Id) {
            setLoading(true);
            dispatch(getBiddingPartnerServiceDetails({ Partner_Bidding_Quote_Id: Bidding_Id }, getCallback));
        }
        if (user?.Email_Id) {
            let data = {
                User_Id: user.Email_Id,
                Wallet_Id: "",
            };
            dispatch(getWalletByUser(updateStatesWallet, data));
            dispatch(getUserSettings("ORDER_MAX_WALLET_USAGE_P", setMaxWalletUsage));

        }
    }

    const getAdvanceAmount = useMemo(() => {
        let advance = partnerProduct?.Esi_Commision_Amount || 0;
        return advance + (partnerProduct?.Advance_Token_Amount || 0)
    }, [product, partnerProduct])

    const getCallback = (status, data) => {
        setLoading(false);
        if (status) {
            setProduct(data.User_Bid_Service);
            setPartnerProduct(data.Partnerbids);
            setComments(data.Comments);
            setAddressD(data?.Address);
        }
    }
    const Bidding_Id = props?.route?.params?.id;
    useEffect(() => {
        getProductDetails();
    }, [props?.route?.params, isConfirmLoading])


    const getAddress = () => {
        let data = {
            User_Email_Id: user.Email_Id,
            Records_Filter: "FILTER",
        };
        dispatch(getAllCartAddressAction(updateStates, data));
    }
    const [address, setAddress] = useState([]);
    const [deliveryAddress, setDeliveryAddress] = useState("");

    const updateStates = (data: any, loading: any) => {
        let add = (data ? data : []).map((item => ({ id: item.User_Address_Id, title: item?.Address_Type + " " + "," + " " + item?.Door_Number + " " + "," + " " + item?.Landmark + " " + "," + " " + item?.Street + " " + "," + " " + item?.City + " " + "," + " " + item?.State + " " + "," + " " + item?.Zip, })));
        add.push({ id: "Add New Address", title: "Add New Address" })
        setAddress(add);
    };

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ flexDirection: 'row', position: "relative", marginRight: 15, marginTop: 10 }} >

                <IconButton
                    icon="arrow-left"
                    size={25}
                    color={esiColor.SIconColor}
                    // style={{ marginTop: -2, marginLeft: 1 }}
                    onPress={() => {
                        if (navigation.canGoBack())
                            props.navigation.goBack()
                        else
                            props.navigation.navigate("BidServices")
                    }}
                />
                {product?.Finalize_Partner_Bidding_Quote_Id &&
                    <TouchableOpacity
                        style={{
                            position: "absolute", right: 5, top: 5, backgroundColor: esiColor.globalButtonColor,
                            width: 175, height: 25, alignSelf: 'center', alignItems: 'center', borderRadius: 10, marginTop: 10
                        }}
                        onPress={() => {
                            navigation.navigate('BidServiceDetails', { id: product?.User_Service_Bidding_Id });
                        }}
                    >
                        <Text style={{ fontWeight: '600', color: esiColor.itemButtenColor, alignItems: 'center' }} >Show Remaining Quotes</Text>
                    </TouchableOpacity>}
            </View>
            {/* <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: dimensions.height - 20 }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => getProductDetails}
                    />
                }
            > */}
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <View style={{ flexDirection: 'column', flex: 2.6, marginLeft: 15 }}>

                    {product?.Service_Image &&
                        <View style={{ paddingHorizontal: 10, marginTop: 5 }}>
                            <Image
                                source={{ cache: "force-cache", uri: product?.Service_Image + `?w=600&h=400` }}
                                style={{
                                    height: 400, maxHeight: 400,

                                    width: 600, resizeMode: 'contain', borderRadius: 10, shadowRadius: 2, backgroundColor: esiColor.ImgBck
                                }}>
                            </Image>
                        </View>}
                    {product?.Bidding_Status == "COMPLETED" &&
                        <Review partnerProduct={partnerProduct} product={product} dimensions={dimensions} getProductDetails={getProductDetails} dispatch={dispatch} />
                    }


                </View>
                <View style={{ flexDirection: 'column', flex: 2.5, marginRight: 15 }}>
                    <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: dimensions.height - 20 }}
                        refreshControl={
                            <RefreshControl
                                refreshing={loading}
                                onRefresh={() => getProductDetails}
                            />
                        }
                    >

                        <View style={{ marginVertical: 5, marginBottom: 10, alignSelf: 'flex-start', marginLeft: 15 }} >
                            <Text style={{ textAlign: 'center', color: esiColor.brandFontColor, fontWeight: 'bold' }}>{product?.Service_Type}</Text>
                        </View>
                        <View style={{ paddingHorizontal: 10 }} >
                            {product?.Bidding_Service_Start_Date &&
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Service Date </Text>

                                    </View>
                                    <View style={{ flex: 6 }} >
                                        <Text style={{ color: esiColor.itemColor }}>:   {product?.Bidding_Service_Start_Date}</Text>
                                    </View>
                                </View>
                            }
                            {product?.Bidding_Expiry_Date &&
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Bid last date </Text>

                                    </View>
                                    <View style={{ flex: 6 }} >
                                        <Text style={{ color: esiColor.itemColor }}>:   {product?.Bidding_Expiry_Date}</Text>
                                    </View>
                                </View>
                            }
                            {product?.Service_Request_Total_Time_In_Hours > 0 &&
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Required Hours </Text>

                                    </View>
                                    <View style={{ flex: 6 }} >
                                        <Text style={{ color: esiColor.itemColor }}>:   {product?.Service_Request_Total_Time_In_Hours}</Text>
                                    </View>
                                </View>
                            }
                            {product?.Service_Request_Total_Time_In_Days > 0 &&
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Required days </Text>

                                    </View>
                                    <View style={{ flex: 6 }} >
                                        <Text style={{ color: esiColor.itemColor }}>:   {product?.Service_Request_Total_Time_In_Days} </Text>
                                    </View>
                                </View>
                            }
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Status </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:    {product?.Bidding_Status}</Text>
                                    {(product?.Bidding_Status != "COMPLETED" && product?.Bidding_Status != "CANCELLED") &&
                                        <TouchableOpacity onPress={() => {
                                            setIsConfirmScreen(true);
                                        }} style={{ backgroundColor: 'gray', borderWidth: 1, borderRadius: 8, width: 70 }} >
                                            <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Cancel </Text>
                                        </TouchableOpacity>
                                    }
                                </View>
                            </View>
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Pincode </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   {product?.User_Pincode}</Text>
                                </View>
                            </View>
                            {product?.Advance_Payment_Status_From_User == 1 &&
                                <View style={{ flexDirection: "column", marginTop: 10 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: 'bold' }}> Advance Amount </Text>
                                    {product?.Advance_Wallet_Amount > 0 &&
                                        <View style={{ flexDirection: "row" }} >
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Wallet Payment  </Text>

                                            </View>
                                            <View style={{ flex: 6, flexDirection: "column" }} >
                                                <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Advance_Wallet_Amount} </Text>
                                            </View>
                                        </View>}
                                    {product?.Advance_Online_Amount > 0 &&
                                        <View style={{ flexDirection: "row" }} >
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Online Payment </Text>

                                            </View>
                                            <View style={{ flex: 6, flexDirection: "column" }} >
                                                <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Advance_Online_Amount} </Text>
                                            </View>
                                        </View>}
                                    {product?.Advance_Online_Transaction_Id &&
                                        <View style={{ flexDirection: "row" }} >
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Transaction ID </Text>

                                            </View>
                                            <View style={{ flex: 6, flexDirection: "column" }} >
                                                <Text style={{ color: esiColor.itemColor }}>:   {product?.Advance_Online_Transaction_Id} </Text>
                                            </View>
                                        </View>
                                    }

                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Total Advance </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Total_Advance_Amount} </Text>
                                        </View>
                                    </View>
                                </View>
                            }

                            {product?.Final_Payment_Status == 1 &&
                                <View style={{ flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: 'bold' }}> Payment Summarry </Text>
                                    {product?.Wallet_Amount > 0 &&
                                        <View style={{ flexDirection: "row" }} >
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Wallet Payment  </Text>

                                            </View>
                                            <View style={{ flex: 6, flexDirection: "column" }} >
                                                <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Wallet_Amount} </Text>
                                            </View>
                                        </View>}
                                    {product?.Online_Amount > 0 &&
                                        <View style={{ flexDirection: "row" }} >
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Online Payment </Text>

                                            </View>
                                            <View style={{ flex: 6, flexDirection: "column" }} >
                                                <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Online_Amount} </Text>
                                            </View>
                                        </View>}
                                    {product?.Online_Transaction_Id &&
                                        <View style={{ flexDirection: "row" }} >
                                            <View style={{ flex: 4 }} >
                                                <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Transaction ID </Text>

                                            </View>
                                            <View style={{ flex: 6, flexDirection: "column" }} >
                                                <Text style={{ color: esiColor.itemColor }}>:   {product?.Online_Transaction_Id} </Text>
                                            </View>
                                        </View>
                                    }

                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Total Advance </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Total_Advance_Amount} </Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row" }} >
                                        <View style={{ flex: 4 }} >
                                            <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Total Paid </Text>

                                        </View>
                                        <View style={{ flex: 6, flexDirection: "column" }} >
                                            <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Total_Advance_Amount + product?.Remaining_Amount_After_Advance_Amount} </Text>
                                        </View>
                                    </View>
                                </View>
                            }
                        </View>
                        {product?.Advance_Payment_Status_From_User != 1 ?
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Advance Amount </Text>

                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Total_Advance_Amount || getAdvanceAmount} </Text>

                                    {(walletPaybleAmount() > 0) &&
                                        <View>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    setUseWallet(!useWallet);
                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: "row",
                                                    // marginTop: -10
                                                }}>
                                                    <Checkbox.Android
                                                        status={useWallet ? 'checked' : 'unchecked'}
                                                        uncheckedColor={esiColor.SBorderColor}
                                                    />
                                                    <Text style={{
                                                        marginTop: 8, color: esiColor.DescColor
                                                    }}>{"Use Wallet Amount ₹ " + walletPaybleAmount()}</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    {useWallet &&
                                        <View>
                                            <Text style={{
                                                color: esiColor.DescColor
                                            }}>{"Remaing amount after wallet amount ₹ " + finalAdvanceOnlineAmount()}</Text>
                                        </View>
                                    }
                                    {(!product?.Finalize_Partner_Bidding_Quote_Id && product?.Bidding_Status != "CANCELLED") &&

                                        <View>
                                            <View>
                                                <TouchableOpacity disabled={commentLoading} onPress={() => {
                                                    setDeliveryAddress("");
                                                    setOpenAddress(state => !state);
                                                    getAddress();
                                                }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 50 }} >
                                                    <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > PAY </Text>
                                                </TouchableOpacity>
                                            </View>
                                            <Text style={{ fontWeight: "500", color: esiColor.DescColor }}>Pay advance amount to lock this offer.</Text>
                                        </View>
                                    }
                                </View>
                            </View>
                            : product?.Final_Payment_Status != 1 &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4, marginLeft: 12 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500', }}> Remaining Amount </Text>
                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   ₹ {product?.Remaining_Amount_After_Advance_Amount || 0} </Text>
                                    {(walletFinalPaybleAmount() > 0) &&
                                        <View>

                                            <TouchableOpacity
                                                onPress={() => {
                                                    setUseWallet(!useWallet);
                                                }}
                                            >
                                                <View style={{
                                                    flexDirection: "row",
                                                    // marginTop: -10
                                                }}>
                                                    <Checkbox.Android
                                                        status={useWallet ? 'checked' : 'unchecked'}
                                                        uncheckedColor={esiColor.SBorderColor}
                                                    />
                                                    <Text style={{
                                                        marginTop: 8, color: esiColor.DescColor
                                                    }}>{"Use Wallet Amount ₹ " + walletFinalPaybleAmount()}</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    {useWallet &&
                                        <View>
                                            <Text style={{
                                                color: esiColor.DescColor
                                            }}>{"Remaing amount after wallet amount ₹ " + finalOnlineAmount()}</Text>
                                        </View>
                                    }
                                    {(product?.Payment_Mode != "COD" && product?.Final_Payment_Status == 0 && product?.Bidding_Status != "CANCELLED") &&

                                        <View style={{ marginTop: 10 }}>
                                            {/* <View> */}
                                            <TouchableOpacity disabled={commentLoading} onPress={payNowFinal} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 50 }} >
                                                <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > PAY </Text>
                                            </TouchableOpacity>
                                            {/* </View> */}
                                            <Text style={{ fontWeight: "500", color: esiColor.DescColor, marginTop: 10 }}>Pay remaining amount to get "meepaisa" support.</Text>
                                        </View>
                                    }
                                </View>
                            </View>}

                        {product?.Payment_Mode &&
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Payment Mode </Text>
                                </View>
                                <View style={{ flex: 6, flexDirection: "column" }} >
                                    <Text style={{ color: esiColor.itemColor }}>:    {product?.Payment_Mode} </Text>
                                </View>
                            </View>}

                        <View style={{
                            flexDirection: "column", alignSelf: 'flex-start', borderBottomWidth: 0.1, borderColor: esiColor.SBorderColor,
                            padding: 2, marginTop: 10, maxWidth: (Platform.OS === 'web' ? 650 : 550), backgroundColor: esiColor.BackgroundColor, marginLeft: 15
                        }} >
                            <Text style={{ fontWeight: "bold", marginBottom: 10, color: esiColor.Text }}>Description :</Text>
                            <Text style={{ flexWrap: "wrap", color: esiColor.itemColor, marginBottom: 10 }}>{product?.Description} </Text>
                        </View>


                        {addressD &&
                            <View style={{
                                paddingHorizontal: 10
                            }}>

                                <View style={{
                                    padding: 2,
                                    backgroundColor: esiColor.CBColor, marginTop: (Platform.OS === 'web' ? 10 : 10),
                                    alignItems: 'flex-start',
                                    // elevation: 10,
                                    maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                    maxHeight: height > 250 ? 250 : 90,
                                    minHeight: 100,
                                    // borderColor: 'grey',
                                    borderBottomWidth: 0.1, borderColor: esiColor.SBorderColor,
                                    flexDirection: 'column', marginBottom: 15

                                }}  >
                                    <View style={{
                                        marginHorizontal: 10
                                    }}>
                                        <Text style={{ fontSize: 16, fontWeight: 'bold', color: esiColor.Text, marginBottom: 5 }}>Service Address </Text>
                                        <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{addressD?.Name},</Text>
                                        <Text style={{ fontSize: 14, color: esiColor.itemColor }}>D-No : {addressD?.Door_No},{addressD?.Street}, {addressD?.Land_Mark},</Text>
                                        <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{addressD?.City},{addressD?.State},{addressD?.Country},</Text>
                                        <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{addressD?.City}-{addressD?.Zip},</Text>
                                        <Text style={{ fontSize: 14, marginBottom: 10, color: esiColor.itemColor }}>Phone number : {addressD?.Mobile}.  </Text>
                                    </View>
                                </View>

                            </View>
                        }
                        <View style={{ paddingHorizontal: 10 }} >
                            <View style={{
                                padding: 2, backgroundColor: esiColor.BackgroundColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                alignItems: 'flex-start',
                                // elevation: 10,
                                maxWidth: (Platform.OS === 'web' ? 650 : 550),
                                maxHeight: height > 250 ? 250 : 90,
                                minHeight: 100,
                                borderBottomWidth: 0.1, borderColor: esiColor.SBorderColor,
                            }}>
                                <View style={{ flex: 1, alignContent: 'center', marginLeft: 10, marginVertical: 5 }} >
                                    <Image source={{ cache: "force-cache", uri: partnerProduct?.Logo_Path + `?w=80&h=85` }} style={{ borderRadius: 8, height: 85, width: 80, resizeMode: 'contain', backgroundColor: esiColor.ImgBck }}></Image>
                                </View>
                                <View style={{ flex: 2, flexDirection: 'column' }}  >
                                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.brandFontColor }}>{partnerProduct?.Name}</Text>
                                    <View style={{ flexDirection: 'row' }} >
                                        <View style={{ flex: 4 }}  >
                                            <Text style={{ color: esiColor.Text }}>Amount </Text>

                                        </View>
                                        <View style={{ flex: 6 }}  >
                                            <Text style={{ color: esiColor.itemColor }}>: {partnerProduct?.Quotation_Amount}</Text>

                                        </View>
                                    </View>
                                    {partnerProduct?.Service_Price_Per_Day > 0 &&
                                        <View style={{ flexDirection: 'row' }} >
                                            <View style={{ flex: 4 }}  >
                                                <Text style={{ color: esiColor.Text }} >Day Cost </Text>

                                            </View>
                                            <View style={{ flex: 6 }}  >
                                                <Text style={{ color: esiColor.itemColor }}>: {partnerProduct.Service_Price_Per_Day}</Text>
                                            </View>
                                        </View>
                                    }
                                    {partnerProduct?.Service_Price_Per_Hour > 0 &&
                                        <View style={{ flexDirection: 'row' }} >
                                            <View style={{ flex: 4 }}  >
                                                <Text style={{ color: esiColor.Text }} >Hour Cost </Text>

                                            </View>
                                            <View style={{ flex: 6 }}  >
                                                <Text style={{ color: esiColor.itemColor }} >: {partnerProduct.Service_Price_Per_Hour}</Text>
                                            </View>
                                        </View>
                                    }
                                    <View style={{ flexDirection: 'row' }} >
                                        <View style={{ flex: 4 }}  >
                                            <Text style={{ color: esiColor.Text }}>Location </Text>

                                        </View>
                                        <View style={{ flex: 6 }}  >
                                            <Text style={{ color: esiColor.itemColor }} >: {partnerProduct?.Partner_Location}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                        </View>
                        <View style={{ paddingHorizontal: 10, marginBottom: 15, marginTop: 10 }} >
                            <Text style={{ fontWeight: "bold", color: esiColor.Text, textAlign: "center", paddingVertical: 2 }}>Comments</Text>
                            <View>
                                <Text style={{ flexWrap: "wrap", margin: 10, color: esiColor.itemColor }} > {partnerProduct?.Partner_Comments}</Text>
                            </View>
                            <View style={{}} >
                                <EsiTextAreaInput
                                    style={{ height: 60 }}
                                    mode='outlined'
                                    value={comment}
                                    textareavalueUpdate={value => {
                                        setComment(value)
                                    }}
                                    placeholder={'Please provide comment'}
                                    underlineColorAndroid={'transparent'}
                                    theme={{
                                        colors: {
                                            primary: "#27b6cc"
                                        },
                                        roundness: 20
                                    }}
                                    maxLength={500}
                                />
                                <View style={{ paddingHorizontal: 10, marginTop: 5, marginBottom: 10, flexDirection: "row" }} >
                                    <HelperText style={{ textAlign: "left" }} type="error" visible={commentError} >
                                        {(comment && comment?.length < 20) &&
                                            "Minimum 20 charecters are required."}
                                        {!(comment) &&
                                            "Comment is required."}
                                    </HelperText>
                                    <TouchableOpacity disabled={commentLoading} onPress={submitComment} style={{ position: "absolute", right: 0, backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 100 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Post </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            {comments?.map((item, index) => (
                                <View style={{
                                    flexDirection: "row",
                                    marginTop: 10, borderBottomColor: esiColor.SBorderColor,
                                    borderWidth: 0.5,
                                }} key={"comment" + index} >
                                    <View style={{
                                        flexDirection: "row",
                                    }}>
                                        {(item.Commented_By == "PARTNER" && comments[index - 1]?.Commented_By != "PARTNER") &&
                                            <Image
                                                source={{ cache: "force-cache", uri: item?.Partner_Logo + `?w=40&h=40` }}
                                                style={{
                                                    height: 40, width: 40, marginVertical: 5, alignSelf: "center",
                                                    resizeMode: 'contain', borderRadius: 50, shadowRadius: 2, backgroundColor: esiColor.ImgBck
                                                }}>
                                            </Image>}
                                        {(item.Commented_By == "USER" && comments[index - 1]?.Commented_By != "USER") &&

                                            <Image
                                                source={{ cache: "force-cache", uri: item?.Commenter_Pic + `?w=40&h=40` }}
                                                style={{
                                                    height: 40, width: 40, marginVertical: 5, alignSelf: "center",
                                                    resizeMode: 'contain', borderRadius: 50, shadowRadius: 2, backgroundColor: esiColor.ImgBck
                                                }}>
                                            </Image>}
                                    </View>
                                    <View style={{
                                        flex: 10,
                                        marginHorizontal: 2, borderRadius: 10, padding: 5, backgroundColor: esiColor.BackgroundColor,
                                        alignItems: 'flex-start',
                                        marginVertical: 5, paddingVertical: 10
                                    }} >
                                        <Text style={{ position: "absolute", right: 5, top: 0, color: esiColor.itemColor, fontSize: 12 }}>{new Date(item.Created_Date).toDateString() + " " + new Date(item.Created_Date).toLocaleTimeString()}</Text>
                                        <Text style={{ marginHorizontal: 5, marginTop: 10, color: esiColor.itemColor }}>{item.Comments}</Text>
                                    </View>

                                </View>
                            ))}
                        </View>
                    </ScrollView>
                </View>

                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={openAddress}>
                    <View style={{ flex: 1, maxWidth: 500, minWidth: 400, minHeight: 200, maxHeight: 230, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                        <Surface style={{
                            justifyContent: 'center',
                            borderRadius: 10,
                            top: Platform.OS === 'web' ? 10 : 20,
                            elevation: 0,
                            backgroundColor: esiColor.BackgroundColor,

                        }} >
                            <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                                setOpenAddress((prop) => !prop);
                            }}>
                                <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            <View style={{
                                flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0, zIndex: 2
                            }}>
                                <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>
                                    <EsiAutocompleteDropdown

                                        key={"select-address"}
                                        loading={false}
                                        data={address}
                                        label={"Service Address"}
                                        onSelect={(item) => {
                                            if (item.id === "Add New Address") {
                                                navigation.navigate('AddAddress', { page: 'cart' });
                                                setOpenAddress((prop) => !prop);

                                            } else {
                                                setDeliveryAddress(item.id)
                                            }

                                        }}
                                        onClear={() => {
                                        }}
                                        getSuggestions={(q) => {
                                            // apiCall(q);
                                        }}
                                        // selected={}

                                        maxWidth={260}
                                    />
                                </View>

                            </View>
                            <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", zIndex: 1 }}>
                                <Button disabled={!deliveryAddress} style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {
                                    payNow();
                                    setOpenAddress((prop) => !prop);

                                }}>
                                    <Text style={{ color: esiColor.itemButtenColor }}> Pay Advance</Text>
                                </Button>
                            </View>
                        </Surface>
                    </View>
                </Modal>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }}
                    isVisible={isConfirmScreen}
                >
                    <View style={{
                        maxWidth: 500, minWidth: 300, height: 250, borderRadius: 10,
                        marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.brandShadowColor, borderWidth: 0.5
                    }}>
                        <View style={{ flexDirection: "column" }} >
                            <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, backgroundColor: "" }} onPress={() => {
                                setIsConfirmScreen(!isConfirmScreen);
                            }}>
                                <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "space-around",
                                height: 250
                            }}>
                                <View style={{
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    paddingHorizontal: 10,
                                    marginTop: 20
                                }}>
                                    <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.DescColor }} >Cancel Reason:</Text>
                                    <EsiTextAreaInput
                                        error={Boolean(errorMessage)}
                                        style={{ height: 60 }}
                                        mode='outlined'
                                        value={cancelMessage}
                                        textareavalueUpdate={value => {
                                            if (value) {
                                                if (value.length < 20) {
                                                    setErrorMessage("Cancel reason should be more then 20 charecters.")
                                                } else {
                                                    setErrorMessage("");
                                                }
                                            } else {
                                                setErrorMessage("Please provide cancel reason.")
                                            }
                                            setCancelMessage(value);
                                        }}
                                        placeholder={'Please provide reason'}
                                        underlineColorAndroid={'transparent'}
                                        theme={{
                                            colors: {
                                                primary: "#27b6cc"
                                            },
                                            roundness: 8
                                        }}
                                        maxLength={500}
                                    />
                                    <HelperText type="error" visible={Boolean(errorMessage)}>
                                        {errorMessage}
                                    </HelperText>
                                    <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.itemColor }} >Are you sure you want to cancel?</Text>
                                </View>


                                <View style={{
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    paddingHorizontal: 5,
                                    marginVertical: 5

                                }}>
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmError("");
                                        if (cancelMessage) {
                                            if (cancelMessage.length < 20) {
                                                setErrorMessage("Cancel reason should be more then 20 charecters.")
                                            } else {
                                                setErrorMessage("");

                                                setIsConfirmLoading(true);
                                                dispatch(updateUserBiddingService({
                                                    User_Service_Bidding_Id: product?.User_Service_Bidding_Id,
                                                    Bidding_Status: "CANCELLED",
                                                    Cancelled_Reason: cancelMessage
                                                }, (status) => {
                                                    setIsConfirmLoading(false);
                                                    setCancelMessage("");
                                                    if (status) {
                                                        setIsConfirmScreen(false);
                                                    } else {
                                                        setIsConfirmError("Please try again.");
                                                    }
                                                }))

                                            }
                                        } else {
                                            setErrorMessage("Please provide cancel reason.")
                                        }

                                    }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Yes </Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmScreen(false);
                                    }} style={{ backgroundColor: 'gray', borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Close </Text>
                                    </TouchableOpacity>
                                </View>

                                <HelperText type="error" style={{ textAlign: "left", marginBottom: 10 }} >
                                    Note : Advance amount will not refundable. After cancel our backend team will reach you and help you to get reslove your issue.
                                </HelperText>
                            </View>

                        </View>
                    </View>
                </Modal>

            </View>
            {/* </ScrollView > */}
        </Surface >
    )
}