import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Card, Text, Surface } from "react-native-paper";
import { View, Button, TouchableOpacity, Image, ScrollView, StyleSheet, Platform, Dimensions, RefreshControl } from "react-native";
import Header from "../header";
import { Ionicons } from "@expo/vector-icons";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { getPartnersAction } from "../../state/actions/node-actions/home-actions";
import PHProducts from "./PHProducts";
import PProducts from "./PProducts";
// import esiColor from "../../constants/Colors";
// api
export default function PartnerProducts(props) {
    const { navigation, ...rest } = props;
    const { getState, goBack } = props.navigation;
    const params = getState()?.history || {};
    const toast = useToast();
    const dispatch = useDispatch();
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const partnerId = props?.route?.params?.Partner_Details_Id;
    let Partner = useSelector((state) => state.allPartners.partner.all);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const user = useSelector(state => state.auth?.user?.Email_Id);
    const esiColor = useSelector(state => state.theme);

    const [partnerdata, setPartnerData] = useState();
    const callBackGetPartnerData = (status, Partner) => {
        if (status) {
            setPartnerData(Partner[0]);
        }
    };
    useEffect(() => {
        onRefresh();
    }, [partnerId]);


    const onRefresh = () => {
        dispatch(getPartnersAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Partner_Details_Id": partnerId
        },
            { PageNo: 1, PageSize: 10, SortBy: "PARTNER_DETAILS_ID", SortOrder: "ASC" }
            , callBackGetPartnerData));
    };

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ flexDirection: "column", width: "100%" }} >
                <View style={{ flexDirection: "row", marginHorizontal: '2%' }} >
                    <TouchableOpacity style={{ flex: 3, marginLeft: 8, alignSelf: "flex-start" }} onPress={() => {
                        if (rest?.route?.params?.back) {
                            if (rest?.route?.params?.back.split("-")[0] == "PartnerDetails") {
                                navigation.navigate(rest?.route?.params?.back.split("-")[0], { Partner_Details_Id: partnerId });
                            } else
                                navigation.navigate(rest?.route?.params?.back.split("-")[0]);
                        } else {
                            goBack(null);
                        }
                    }}>
                        <Ionicons name="arrow-back" size={25} color="#27b6cc" style={{ marginTop: 10 }} />
                    </TouchableOpacity>
                    <View style={{ zIndex: 1, flex: 3 }}>
                        {partnerdata?.Is_Open != "1" &&
                            <Text style={{ fontSize: 14, paddingHorizontal: 10, paddingVertical: 5, backgroundColor: '#DC143C', borderRadius: 5, color: 'white', marginTop: 10, marginVertical: 5, fontWeight: "bold", alignSelf: "center" }}> Store Closed</Text>
                        }
                    </View>
                </View>
            </View>
            <ScrollView

                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={onRefresh}
                    />
                }
            >
                <Card style={{ marginHorizontal: '2%', backgroundColor: esiColor.BackgroundColor }} >
                    <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
                        <View style={{ flexDirection: "row", justifyContent: "center" }} >
                            <Image source={{ cache: "force-cache", uri: partnerdata?.Logo_Path }} style={{ maxHeight: 150, minHeight: 100, width: 500, borderRadius: 8 }} resizeMode={'contain'}></Image>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "center", marginVertical: 2 }} >
                            <Text style={{ fontSize: 20, color: esiColor.brandFontColor }}>{partnerdata?.Name}</Text>
                        </View>
                    </View>
                </Card>


                {/* <Provider> */}

                {(partnerdata?.Business_Type == "FOOD" || partnerdata?.Business_Type == "GROCERY") ?
                    <PHProducts navigation={props.navigation} partnerId={partnerdata?.Partner_Details_Id} />
                    :
                    <PProducts navigation={props.navigation} partnerId={partnerdata?.Partner_Details_Id} />
                }
            </ScrollView>
            {/* </Provider> */}

        </Surface>
    );
}
