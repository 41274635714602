import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput, Text, HelperText, Surface, IconButton, ActivityIndicator, } from 'react-native-paper';
import { StyleSheet, View, ScrollView, TouchableOpacity, Image } from 'react-native';
import Header from '../header';
import { GetAllComments, AddTicketcommentAction, getTicketsByIdAction, getallTicketByIdAction } from '../../state/actions/ticketActions';
import isEmpty from '../../state/validations/is-empty';
import EsiURLButton from '../../components/custom/linkingfunction/linkingFunction';
import RenderHTML from "react-native-render-html";
// import esiColor from '../../constants/Colors';
// import Footer from '../footer';

function Comment(props: any) {
  const { navigation, valueUpdate, ticketid, ...rest } = props
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  const [getid, setGetid] = useState("");
  const user = useSelector(state => state.auth?.user);
  const [visible, setVisible] = useState(5);
  const isSubmited = useSelector(state => state.ticket?.CommentCreate?.isLoading || false);

  const [reload, setReload] = useState(false);
  const [Comment, setComment] = useState(false);
  const [id, setID] = useState([]);
  useEffect(() => {
    if (reload)
      setReload(false);
  }, [reload])


  const callBackGetData = (data) => {
    setReload(false);
    if (data) {
      setComment(data);
    }

  };
  React.useEffect(() => {
    setGetid(props.route?.params?.ticketid);
    if (props.route?.params?.ticketid) {
      setReload(true);
      const formData = {
        Records_Filter: "FILTER",
        Ticket_Id: props.route?.params?.ticketid,
      };
      dispatch(getTicketsByIdAction(props.route?.params?.ticketid, callBackGetData));
    }
  }, [props.route?.params?.ticketid]);
  React.useEffect(() => {
    if (user?.Email_Id && getid) {
      let data = {
        Records_Filter: "FILTER",
        Ticket_Id: getid,
      }
      dispatch(GetAllComments(data, callBackGetData))
    }
  }, [user?.Email_Id, getid]);
  let ticket = useSelector((state) => state.ticket?.Comments?.all);
  // const loading = useSelector(state => state.ticket?.Comments?.isLoading);


  //==============================================
  const callBackData = (data) => {
    setReload(false);
    if (data) {
      setID(data);
    }

  };
  React.useEffect(() => {
    if (getid) {
      const formData = {
        Records_Filter: "FILTER",
        User_Ticket_ID: getid
      };
      dispatch(getallTicketByIdAction(formData, callBackData));
    }
  }, [getid]);


  const styles = StyleSheet.create({
    input: {
      backgroundColor: esiColor.BackgroundColor,
      // width: 700,
      height: 60,
      // borderWidth: 1,
      // borderRadius: 10,
      marginHorizontal: "5%",
      borderColor: esiColor.SBorderColor,
      // padding: 5,
      marginBottom: 10,
      // marginLeft:50
    },
    textbox: {
      marginBottom: 80
    },
    containerm: {
      paddingVertical: 12,
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginLeft: 1,
      borderRadius: 2,
      marginTop: 10,
      padding: 2,
    },
    content: {
      marginLeft: 16,
      flex: 1,
    },
    contentHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 2,
      borderBottomWidth: 0.1, borderColor: "#444444",
    },
    image: {
      width: 45,
      height: 45,
      borderRadius: 20,
    },
  
  });


  /*====================================
          Handler
  ====================================*/
  

  return (
    // <View style={{ flex: 1 }}>
    // <View style={{ flex: 1 }}>

    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header {...props} />
      <View style={{ flexDirection: 'row', margin: 3 }}>
        <IconButton
          icon="arrow-left"
          size={27}
          color={"#27B6CC"}
          onPress={() => navigation.navigate('Ticket')} />
        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor, marginTop: 15 }}>
          Comments
        </Text>
      </View>

      <ScrollView>
        {!reload &&
          <Formik initialValues={{

            Comment: "",

          }}
            //field validations
            validationSchema={Yup.object().shape({
              Comment: Yup.string().required('Comment  is required.'),
            })}
            onSubmit={(values: any, { setSubmitting, setErrors, resetForm }: { setSubmitting: any, setErrors: any, resetForm: any }) => {
              let submitTest = true;
              setSubmitting(false);
              const errors = {};
              setErrors(errors);
              if (values) {
                let formValues = JSON.parse(JSON.stringify(values));
                formValues.Created_By = user?.Email_Id;
                formValues.Ticket_Id = getid;
                dispatch(AddTicketcommentAction(formValues, getid, resetForm, setReload, callBackGetData));

              }
            }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              resetForm
            }) => (
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 4, flexDirection: 'column', justifyContent: 'space-between', }}>
                  {id?.length > 0 &&
                    // <View >
                    <View style={{
                      marginHorizontal: "5%", borderRadius: 20, padding: 2, marginTop: 10,
                      backgroundColor: esiColor.BackgroundColor,
                      borderColor: '#27B6CC',
                      // shadowOpacity: 0.2,
                      shadowRadius: 7,
                      // elevation: 10,
                      // minHeight: 80,
                      shadowColor: esiColor.brandShadowColor, shadowOpacity: 7,
                      width: 650,
                      justifyContent: 'space-between'
                    }}>

                      <View style={{ marginBottom: 5, padding: 10 }}>
                        <View style={{ flexDirection: "column", marginLeft: 5 }} >
                          <Text style={{ fontSize: 15, color: esiColor.DescColor }}>Ticket ID :{id[0]?.Ticket_Id}</Text>
                          <Text style={{ fontSize: 15, color: esiColor.DescColor, marginTop: 10 }}>Subject :{id[0]?.Ticket_Subject} </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 10 }} >
                          <Text style={{ fontSize: 15, color: esiColor.DescColor }}> Attachment :  </Text>
                          <EsiURLButton url={id[0]?.Ticket_Attachment} />
                        </View>
                        <View style={{ flexDirection: "column", marginTop: 10 }} >
                          <Text style={{ fontSize: 15, color: esiColor.DescColor }}> Description : {id[0]?.Ticket_Description &&
                            <RenderHTML tagsStyles={{
                              span: { color: esiColor.DescColor }
                            }}
                              source={{ html: id[0]?.Ticket_Description }} />
                            // <Text> {id[0]?.Ticket_Description} </Text>
                          } </Text>

                        </View>
                      </View>
                    </View>
                  }
                </View>
                <View style={{ flex: 4, flexDirection: 'column' }}>

                  {isEmpty(ticket) ? <View style={{ marginTop: 20 }} >
                    <Text style={{ fontSize: 16, textAlign: 'center', color: esiColor.NoDataFound }}>
                      No records found.
                    </Text>
                  </View> : <View style={{
                    width: "90%",
                    marginHorizontal: "5%"
                  }}>
                    {Comment.length > 0 && Comment?.slice(0, visible).map((data: any, index: any) => (
                      // : <ActivityIndicator animating={loading} color='#27b6cc' style={{ marginTop: 20 }} />
                      <View key={index} style={styles.containerm}>
                        <TouchableOpacity>
                          <Image style={styles.image} source={data?.Created_By === user?.Email_Id ? { uri: user.Profile_Pic_Path } : require("../../assets/images/img/logo/meePaisa_logo.png")} />
                        </TouchableOpacity>
                        <View style={styles.content}>
                          <View style={styles.contentHeader}>
                            <Text style={{ fontSize: 14, marginBottom: 50, marginLeft: 16, color: esiColor.DescColor }}>{data?.Comment}</Text>
                          </View>
                        </View>
                      </View>
                    ))}
                  </View>}
                  <View style={{ alignSelf: 'center', marginVertical: 15, marginTop: 2 }}>
                    {Comment?.length > visible &&
                      <Text variant="contained" mode="outlined" style={{ fontWeight: 'bold', color: '#27b6cc' }} onPress={() => setVisible(visible + 5)}>Show More ▼</Text>
                    }
                  </View>
                  <View style={{marginLeft: 65}}>
                    <TextInput
                      theme={{ colors: { text: esiColor.Text, placeholder: esiColor.SPHTextColor} }}
                      multiline
                      numberOfLines={2}
                      placeholder="Add a Comment...."
                      onChangeText={handleChange('Comment')}
                      style={styles.input}
                      activeOutlineColor={esiColor.SBorderColor}
                      mode={"outlined"}
                      name="Comment"
                      value={values.Comment}
                      onChangeText={handleChange('Comment')}
                      disabled={isSubmited}
                      error={Boolean(touched.Comment && errors.Comment)}
                      right={<TextInput.Icon name="send" color="#27B6CC" mode="contained" onPress={handleSubmit} />}

                    />
                    <HelperText type="error" style={{ marginLeft: 10 }} visible={Boolean(touched.Comment && errors.Comment)}>
                      <ErrorMessage name="Comment" />
                    </HelperText>
                  </View>
                </View>

              </View>
            )}
          </Formik>}
      </ScrollView>
    </Surface >

  );
};



export default Comment;