import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersAction, getShopByCitiesAction, getShopByStoresAction } from "../../state/actions/node-actions/home-actions";
import isEmpty from "../../state/validations/is-empty";


export default function MeeCities(props) {
    const { navigation, dataReload, dataErrorReload } = props;

    const dispatch = useDispatch();

    const [AllPartners, setAllPartners] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setAllPartners(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            partnersCall();
        } else {
            await delay(dataErrorReload);
            partnersCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);


    useEffect(() => {
        partnersCall()
    }, []);

    const partnersCall = () => {
        setIsLoading(true);
        dispatch(getShopByCitiesAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : ""
        },
            { PageNo: 1, PageSize: 10, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" }
            , callBackGetData));
    }

    const handleClick = async (data) => {
        navigation('Partners', { city: data.City_Id, cityName: data.Name })
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    return (
        <View style={styles.content}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom:10 }}>
                <Text style={{ fontSize: 16, color: "#27b6cc", fontWeight: 'bold' }}> Mee Cities</Text>
                <TouchableOpacity onPress={() => navigation('allCities')} style={{ position: 'absolute', right: 10 }}>
                    <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold' }}>View All</Text>
                </TouchableOpacity>
            </View>
            <FlatList
                horizontal={true}
                data={AllPartners.slice(0, 10)}
                renderItem={({ item, index, separators }) => (
                    <View key={index} style={{ maxWidth: 90, minWidth: 70 }}>
                        <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                            <Image source={{ cache: "force-cache", uri: item?.Image_Path }} style={{ height: 25, resizeMode: 'contain', maxWidth: 50 }}></Image>
                        </TouchableOpacity>
                        <View style={{ marginTop: 5, marginLeft: 2 }}>
                            <Text style={{ fontSize: 14, color: "#27b6cc", textAlign: 'center' }}>{item?.Name}</Text>
                        </View>
                    </View>
                )}
            />
        </View>


    )
}


const styles = StyleSheet.create({
    content: {
        borderRadius: 10,
        backgroundColor: 'white',
        margin: 5,
        padding: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
        elevation: 10,
    },
    item: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#9ce5f0',
        marginHorizontal: 5,
        padding: 4,
        maxHeight: 35
    }


});