import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl } from "react-native";
import { Searchbar, Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import EsiSearchDropdown from '../../components/custom/searchable-dropdown';
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import ordergif from "../../assets/images/GIF/noRidesImg.png";
import { getMyRiders } from "../../state/actions/ridesAction";

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
export default function MyRides(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    const [dimensions, setDimensions] = useState({ window, screen });
    const esiColor = useSelector(state => state.theme);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions({ window, screen });
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = dimensions.window;
    const windowWidth = dimensions.window.width;
    const windowHeight = dimensions.window.height;

    const [showDropDown, setShowDropDown] = React.useState(false);
    const [values, setValues] = React.useState()
    const [page, setPage] = React.useState(0);

    const [ordersFilter, setOrdersFilter] = useState({ select: "", search: "" });

    let myRides = useSelector((state) => state.rides.all);


    const dispatch = useDispatch();
    const filterOptions = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: 'Ride_Status',
            label: 'Ride Status'
        }

    ];
    const [oldSearch, setOldSearch] = React.useState(0);
    const checkOrdersForTable = () => {
        if (ordersFilter.search) {
            try {
                if (ordersFilter.select === "All") {
                    return myRides.filter(x => {
                        let colummns = ["Ride_Status", "Order_Total", "Pickup_Location", "Drop_Location", "User_Ride_Id"];
                        for (let i = 0; i < colummns.length; i++) {
                            let mainString = typeof x[colummns[i]] == "string" ?
                                (colummns[i] === "Ride_Status" ?
                                    x[colummns[i]]?.split("-").map((value => value)).join(" ")
                                    : x[colummns[i]]).toLowerCase()
                                : x[colummns[i]];


                            const subString = typeof x[colummns[i]] === "string" ? ordersFilter.search.toLowerCase() : ordersFilter.search;
                            if (typeof x[colummns[i]] === "string") {
                                if (mainString.includes(ordersFilter.search.toLowerCase())) {
                                    return true;
                                }
                            }
                            else {
                                if (mainString == ordersFilter.search) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    });
                } else {
                    return myRides.filter(x => {
                        let mainString = typeof x[ordersFilter.select] == "string" ?
                            (ordersFilter.select === "Order_Status" ?
                                x[ordersFilter.select] === "NEW" ? "ORDERED" : x[ordersFilter.select] === "COMPLETED" ? "DELIVERED" : x[ordersFilter.select] === "RETURN" ? "RETURN COMPLETE" : x[ordersFilter.select] === "REFUND" ? "REFUNDED" : x[ordersFilter.select]
                                : x[ordersFilter.select]).toLowerCase()
                            : x[ordersFilter.select];
                        let subString = typeof x[ordersFilter.select] == "string" ? ordersFilter.search.toLowerCase() : ordersFilter.search;

                        if (typeof x[ordersFilter.select] === "string") {
                            return mainString.includes(subString);
                        }
                        else {
                            if (mainString == ordersFilter.search) {
                                return true;
                            }
                        }
                    })
                }
            } finally {
                if (page > 0) {
                    if (oldSearch != ordersFilter.search.length) {
                        setOldSearch(ordersFilter.search.length);
                        setPage(0);
                    }
                }
                else if (oldSearch != ordersFilter.search.length) {
                    setOldSearch(ordersFilter.search.length);
                    setPage(0);
                }
            }
        }
        else {
            return myRides
        }
    }
    const [order, setOrder] = React.useState('ascending');
    const [orderBy, setOrderBy] = React.useState('Product_Color_Variant_Id');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'ascending';
        setOrder(isAsc ? 'descending' : 'ascending');
        setOrderBy(property);
    };
    function descendingComparator(a, b, orderBy) {
        try {
            if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
                return -1;
            }
            if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
                return 1;
            }
            return 0;
        } catch (error) {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }
    }
    function getComparator(order, orderBy) {
        return order === 'descending'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleChange = itemValue => {
        setOrdersFilter({ ...ordersFilter, select: itemValue })
    };
    const handleSearch = itemValue => {
        setOrdersFilter({ ...ordersFilter, search: itemValue })
    };
    const data = stableSort(
        checkOrdersForTable(), getComparator(order, orderBy))
    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            setOrdersFilter({ ...ordersFilter, search: "" });
            setOrdersFilter({ ...ordersFilter, select: 'All' })
            dispatch(getMyRiders({
                "Records_Filter": "FILTER",
                "User_Email_Id": logedInEmail
            }))
        } else {
            navigation.navigate("home");
        }
    }, [logedInEmail]);

    const styles = StyleSheet.create({

        text: {
            fontSize: 12,
            fontFamily: 'system-ui',
            color: esiColor.DescColor
        }
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            {/* <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10, width: 700, alignSelf: 'center' }}> */}
            <View style={{ flexDirection: "row", marginTop: 15, marginBottom: 15, justifyContent: 'space-evenly' }}>

                <View style={{ flex: 5, marginLeft: 15 }}>
                    <Searchbar
                        style={{
                            marginHorizontal: 10, borderColor: esiColor.SBorderColor, borderWidth: 1,
                            maxWidth: 300, marginRight: 10,
                            backgroundColor: esiColor.BackgroundColor, height: 30,
                        }}
                        placeholder="Search"
                        iconColor={esiColor.SIconColor}
                        placeholderTextColor={esiColor.SPHTextColor}
                        theme={{ colors: { text: esiColor.SBTextColor } }}

                        onChangeText={(value) => handleSearch(value)}
                        value={ordersFilter.search}
                    />
                </View>
                <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 10, marginHorizontal: 10, maxWidth: 100, height: 30, justifyContent: 'center', alignItems: 'center' }} onPress={(e) => {
                    navigation.navigate("Rides");
                }}>
                    <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold', marginBottom: 2 }}>New Ride</Text>
                </TouchableOpacity>

            </View>

            <ScrollView style={{ height: windowHeight - ((windowHeight / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                refreshControl={
                    <RefreshControl
                        refreshing={false}
                        onRefresh={() => {
                            dispatch(getMyRiders({
                                "Records_Filter": "FILTER",
                                "User_Email_Id": logedInEmail
                            }))
                        }}
                    />
                }
            >
                <View >

                    {data.length <= 0 ?
                        <View style={{ alignItems: "center", marginTop: 10 }}>

                            <View>
                                <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 350 }} source={ordergif}></Image>
                                <Text style={{ textAlign: "center", color: esiColor.NoDataFound, marginTop: 15 }}>Oops! No Rides found...</Text>
                            </View>

                        </View>
                        : <View style={{ marginTop: 0, flexDirection: 'row', flex: 1, flexWrap: 'wrap', width: width, justifyContent: 'space-evenly' }} >
                            {data.map((data, index) => (
                                <TouchableOpacity key={index} onPress={() => {
                                    navigation.navigate('RideDetails', { rideId: data.User_Ride_Id });
                                }}>
                                    <View key={index} style={{
                                        shadowColor: esiColor.brandShadowColor,
                                        // shadowOffset: { width: -2, height: 4 },
                                        shadowOpacity: 7,
                                        shadowRadius: 4,
                                        marginHorizontal: 2,
                                        borderRadius: 10,
                                        backgroundColor: esiColor.CBColor,
                                        marginTop: 15, flexDirection: 'row',
                                        marginBottom: 20,
                                        alignItems: 'flex-start',
                                        alignContent: 'center',
                                        // flex: 1,
                                        width: width / 4,
                                        marginLeft: 15, marginRight: 15,
                                        height: 125,
                                        margin: 15,

                                    }}>
                                        <View style={{ flex: 1, alignContent: 'center', marginLeft: 10 }} >
                                            <Image source={{ cache: "force-cache", uri: data.Comfort_Type_Image + `?w=80&h=100` }} style={{ height: 100, width: 80, resizeMode: 'contain' }}></Image>
                                        </View>
                                        <View style={{ flex: 2, flexDirection: 'column' }}  >
                                            <Text style={{ fontSize: 14, paddingTop: 10, color: esiColor.brandFontColor, fontWeight: 'bold', fontFamily: 'system-ui' }}>{data?.Drop_Location?.split(",")[0]}</Text>
                                            <Text style={styles.text}>Ride ID:  {data.User_Ride_Id}</Text>
                                            <Text style={styles.text}>Ride Status:  <Text style={{ color: data.Ride_Status === "NEW" ? esiColor.Text : data.Ride_Status === "COMPLETED" ? esiColor.GBFColor : data.Ride_Status === "CANCELLED" ? "red" : data.Ride_Status === "RETURN" ? "orange" : esiColor.DescColor, fontWeight: 'bold' }}>
                                                {data.Ride_Status?.split("-").map((value => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()))}
                                            </Text>
                                            </Text>
                                            <Text style={styles.text}>Ride Date:  {data.Ride_Pickup_Date}</Text>
                                            <Text style={styles.text}>Ride Value:  <Text style={{ color: esiColor.DescColor }}>{data?.Ride_Final_Total}</Text></Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>

                            ))}
                            <View style={{ marginBottom: 35 }} ></View>
                        </View>
                    }
                </View>
            </ScrollView>

        </Surface>
    )
}
