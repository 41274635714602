import { Dimensions, Image, Platform, RefreshControl, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Avatar, Button, Colors, HelperText, IconButton, Surface, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../header";
import { useEffect, useState, useCallback } from "react";
import { getBiddingProductDetails } from "../../../../state/actions/node-actions/bid-products-actions";
import ImageCarousel from "../../../../components/custom/image-carousel";
import ordergif from "../../../../assets/images/GIF/noQuoteImg.png";
import Modal from "react-native-modal";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { updateUserBiddingProduct } from "../../../../state/actions/boomi-actions/bid-products-actions";
import { useFocusEffect } from "@react-navigation/native";


export default function BidProductDetails(props) {
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
    const dimensions = Dimensions.get('window');
    const { height, width } = dimensions;
    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [cancelMessage, setCancelMessage] = useState("");

    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isConfirmError, setIsConfirmError] = useState("");


    const getProductDetails = () => {
        if (Product_Id) {
            setLoading(true);
            dispatch(getBiddingProductDetails({ User_Products_Bidding_Id: Product_Id }, getCallback))
        }
    }

    const getCallback = (status, data) => {
        setLoading(false);
        if (status) {
            setProduct(data);
        }
    }
    const Product_Id = props?.route?.params?.id;
    useEffect(() => {
        getProductDetails();
    }, [props?.route?.params, isConfirmLoading])

    useFocusEffect(
        useCallback(() => {
            setCancelMessage("");
        }, [])
    );

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View>
                {navigation.canGoBack() &&
                    <IconButton
                        icon="arrow-left"
                        size={27}
                        color={esiColor.SIconColor}
                        style={{ marginTop: -2, marginLeft: 1 }}
                        onPress={() => props.navigation.goBack()}
                    />}
            </View>
            <ScrollView style={{ maxHeight: dimensions.height - 20 }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => getProductDetails}
                    />
                }
            >
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <View style={{ flexDirection: 'column', flex: 3 }}>

                        {product?.Images?.length > 0 &&
                            <View style={{ marginTop: 5 }}>
                                <ImageCarousel image={product?.Images.map((image) => { return { imageuri: image } })} dimensions={dimensions} />
                            </View>}
                        <View style={{ marginTop: 15, marginBottom: 15, alignSelf: 'flex-start', marginLeft: 15 }} >
                            <Text style={{ textAlign: 'center', color: esiColor.brandFontColor, fontWeight: 'bold' }}>{product?.User_Bid_Product?.Product_Name}</Text>
                        </View>
                        <View style={{ paddingHorizontal: 10 }} >
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Quantity </Text>

                                </View>
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   {product?.User_Bid_Product?.Quantity} ({product?.User_Bid_Product?.Measurement_Type})</Text>
                                </View>
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Pincode </Text>

                                </View>
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   {product?.User_Bid_Product?.User_Pincode}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row", marginTop: 15 }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.DescColor, fontWeight: '500' }}> Status </Text>

                                </View>
                                <View style={{ flex: 10 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   {product?.User_Bid_Product?.Bidding_Status}</Text>
                                    {product?.User_Bid_Product?.Bidding_Status == "INITIATED" &&
                                        <TouchableOpacity onPress={() => {
                                            setIsConfirmScreen(true);
                                        }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                            <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Cancel </Text>
                                        </TouchableOpacity>
                                    }
                                </View>

                            </View>

                        </View>
                    </View>
                    <View style={{ flexDirection: 'column', flex: 2.5, marginRight: 15 }}>
                        <View style={{ paddingHorizontal: 10, marginTop: 15 }} >

                            <Text style={{ fontWeight: "bold", textAlign: "left", marginBottom: 15, color: esiColor.DescColor }}>Description :</Text>
                            <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }}>{product?.User_Bid_Product?.Description}</Text>
                        </View>

                        <View style={{ paddingHorizontal: 10, marginTop: 15 }} >
                            <View style={{ flexDirection: "column", marginBottom: 10 }} >
                                <Text style={{ fontWeight: "bold", textAlign: "left", color: esiColor.DescColor }}>Quotations :</Text>
                            </View>
                            {(product?.Partnerbids?.length <= 0 || !product?.Partnerbids) ?
                                <View style={{ alignItems: "center", marginTop: 10 }}>

                                    <View>
                                        <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image>
                                        <Text style={{ textAlign: "center", color: esiColor.NoDataFound }}>Oops! No Quotations's found...</Text>
                                    </View>

                                </View>
                                :
                                <View>
                                    {product?.Partnerbids.map((data, index) => (
                                        <TouchableOpacity key={index} onPress={() => {
                                            if (product?.User_Bid_Product?.Finalize_Partner_Bidding_Quote_Id) {
                                                if (product?.User_Bid_Product?.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id) {
                                                    navigation.navigate('BidPartnerProductDetails', { id: data?.Partner_Bidding_Quote_Id });
                                                }
                                            } else {
                                                navigation.navigate('BidPartnerProductDetails', { id: data?.Partner_Bidding_Quote_Id });
                                            }
                                        }}>
                                            <View key={index} style={{
                                                shadowColor: product?.User_Bid_Product?.Finalize_Partner_Bidding_Quote_Id == data?.Partner_Bidding_Quote_Id ? esiColor.brandShadowColor : '#0E9EAD',
                                                shadowOpacity: 5,
                                                shadowRadius: 7,
                                                marginHorizontal: 2, borderRadius: 10, padding: 2,
                                                backgroundColor: esiColor.CBColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                width: 500,
                                                maxHeight: height > 250 ? 250 : 90,
                                                minHeight: 100,
                                                borderColor: esiColor.SBorderColor,
                                                borderWidth: 0.5
                                            }}>
                                                <View style={{ flex: 1, alignItems: 'center', alignSelf: 'center', marginLeft: 10, marginVertical: 5 }} >
                                                    <Image source={{ cache: "force-cache", uri: data?.Logo_Path + `?w=85&h=80` }} style={{ borderRadius: 8, height: 85, width: 80, resizeMode: 'contain', backgroundColor: "#efefef" }}></Image>
                                                </View>
                                                <View style={{ flex: 2, flexDirection: 'column' }}  >
                                                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.DescColor, paddingBottom: 10 }}>{data?.Name}</Text>
                                                    <View style={{ flexDirection: 'row' }} >
                                                        <View style={{ flex: 4 }}  >
                                                            <Text style={{ color: esiColor.DescColor }}>Amount </Text>

                                                        </View>
                                                        <View style={{ flex: 6 }}  >
                                                            <Text style={{ color: esiColor.itemColor }} >:   {data.Quotation_Amount}</Text>

                                                        </View>
                                                    </View>
                                                    <View style={{ flexDirection: 'row' }} >
                                                        <View style={{ flex: 4 }}  >
                                                            <Text style={{ color: esiColor.DescColor }}>Delivery Days </Text>

                                                        </View>
                                                        <View style={{ flex: 6 }}  >
                                                            <Text style={{ color: esiColor.itemColor }}>:   {data.No_Of_Delivery_Days}</Text>

                                                        </View>
                                                    </View>
                                                    <View style={{ flexDirection: 'row' }} >
                                                        <View style={{ flex: 4 }}  >
                                                            <Text style={{ color: esiColor.DescColor }}>Location </Text>

                                                        </View>
                                                        <View style={{ flex: 6 }}  >
                                                            <Text style={{ color: esiColor.itemColor }} >:   {data.Partner_Location}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', marginBottom: 10, marginTop: 5 }}>
                                                        <Text style={{ fontWeight: "bold", color: esiColor.DescColor }} >Info :</Text>
                                                        <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }} > {"  " + data?.Partner_Comments.slice(0, 50)}...</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </TouchableOpacity>

                                    ))}
                                    <View style={{ marginBottom: 30 }} ></View>
                                </View>
                            }
                        </View>
                    </View>
                </View>
            </ScrollView >
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }}
                isVisible={isConfirmScreen}
            >
                <View style={{
                    maxWidth: 500, minWidth: 300, height: 300, borderRadius: 10,
                    marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.brandShadowColor, borderWidth: 0.5
                }}>
                    <View style={{ flexDirection: "column" }} >
                        <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, marginBottom: 7 }} onPress={() => {
                            setIsConfirmScreen(!isConfirmScreen);
                        }}>
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                        </TouchableRipple>
                        <View style={{
                            flexDirection: "column",
                            justifyContent: "space-around",
                            height: 170
                        }}>
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "space-around",
                                paddingHorizontal: 10,
                                marginTop: 75
                            }}>
                                <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.DescColor }} >Cancel Reason:</Text>
                                <EsiTextAreaInput
                                    error={Boolean(errorMessage)}
                                    style={{ height: 85 }}

                                    mode='outlined'
                                    value={cancelMessage}
                                    textareavalueUpdate={value => {
                                        if (value) {
                                            if (value.length < 20) {
                                                setErrorMessage("Cancel reason should be more then 20 charecters.")
                                            } else {
                                                setErrorMessage("");
                                            }
                                        } else {
                                            setErrorMessage("Please provide cancel reason.")
                                        }
                                        setCancelMessage(value);
                                    }}
                                    placeholder={'Please provide reason'}
                                    underlineColorAndroid={'transparent'}
                                    theme={{
                                        colors: {
                                            primary: "#27b6cc"
                                        },
                                        roundness: 8
                                    }}
                                    maxLength={500}
                                />
                                <HelperText type="error" visible={Boolean(errorMessage)}>
                                    {errorMessage}
                                </HelperText>
                                <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.itemColor }} >Are you sure you want to cancel?</Text>
                                <HelperText type="error" style={{ textAlign: "center" }} visible={Boolean(isConfirmError)}>
                                    {isConfirmError}
                                </HelperText>
                            </View>


                            <View style={{
                                flexDirection: "row",
                                justifyContent: "space-around",
                                paddingHorizontal: 5,
                                marginVertical: 5

                            }}>
                                <TouchableOpacity onPress={() => {
                                    setIsConfirmError("");
                                    if (cancelMessage) {
                                        if (cancelMessage.length < 20) {
                                            setErrorMessage("Cancel reason should be more then 20 charecters.")
                                        } else {
                                            setErrorMessage("");

                                            setIsConfirmLoading(true);
                                            dispatch(updateUserBiddingProduct({
                                                User_Products_Bidding_Id: Product_Id,
                                                Bidding_Status: "CANCELLED",
                                                Cancelled_Reason: cancelMessage
                                            }, (status) => {
                                                setIsConfirmLoading(false);
                                                setCancelMessage("");
                                                if (status) {
                                                    setIsConfirmScreen(false);
                                                } else {
                                                    setIsConfirmError("Please try again.");
                                                }
                                            }))

                                        }
                                    } else {
                                        setErrorMessage("Please provide cancel reason.")
                                    }

                                }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                    <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Yes </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {
                                    setIsConfirmScreen((prop) => !prop);
                                }} style={{ backgroundColor: esiColor.SBorderColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                    <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Close </Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View>
                </View>
            </Modal>
        </Surface >
    )
}