import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, ImageBackground, StyleSheet, TouchableOpacity, View } from "react-native";
// import { ScrollView } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getBrandsAction } from "../../state/actions/node-actions/home-actions";
// import isEmpty from "../../state/validations/is-empty";
// import esiColor from "../../constants/Colors";


export default function MeeBrands({ navigation, dataReload, dataErrorReload }) {

    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);
    const [brands, setBrands] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setBrands(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            brandsCall();
        } else {
            await delay(dataErrorReload);
            brandsCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);

    useEffect(() => {
        brandsCall();
    }, []);

    const brandsCall = () => {
        setIsLoading(true);
        dispatch(getBrandsAction({
            "Records_Filter": "FILTER"
        },
            { PageNo: 1, PageSize: 10, SortBy: "BRAND_ID", SortOrder: "DESC" },
            callBackGetData
        ));
    }

    const handleClick = async (data) => {
        navigation('BrandDetails', { Brand_Id: data.Brand_Id })
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const styles = StyleSheet.create({
        content: {
            shadowColor: esiColor.brandShadowColor,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            // elevation: 10,
            borderRadius: 10,
            height: 200, width: 500,
            alignSelf: 'center',
            alignItems: 'center',
            marginBottom: 30,
            marginLeft: 15,
            marginRight: 15
        },

        item: {
            borderRadius: 5,
            marginRight: 10,
            padding: 5,
            // flex: 1,
            maxHeight: 100,
            minHeight: 100,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
        }

    });

    return (brands.length > 0 &&
        <View>
            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', padding: 10 }}> Mee Brands</Text>

            <View style={styles.content}>

                <FlatList
                    numColumns={3}
                    showsHorizontalScrollIndicator={false}
                    data={brands.slice(0, 3)}
                    renderItem={({ item, index, separators }) => (
                        <View key={index} style={{ marginTop: 20 }}>

                            <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                                <ImageBackground style={{ backgroundColor: esiColor.ImgBck, height: 80, width: 100 }}>
                                    <Image source={{ cache: "force-cache", uri: item?.Brand_Image + `?w=100&h=75` }} style={{ height: 75, resizeMode: 'contain', flex: 1, alignSelf: "center", width: 100 }}></Image>
                                </ImageBackground>
                            </TouchableOpacity>
                            {/* <View style={{ marginTop: 5, }}> */}
                            <Text style={{ fontSize: 14, color: esiColor.itemColor, textAlign: 'center', marginTop: 10 }}>{item?.Brand_Name}</Text>
                            {/* </View> */}
                        </View>
                    )}
                />
                <TouchableOpacity onPress={() => navigation('Brands')} style={{ position: 'absolute', bottom: 0, left: 225, marginBottom: 25 }}>
                    <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold', textDecorationLine: 'underline' }}>View all</Text>
                </TouchableOpacity>

            </View>
        </View>

    )
}


