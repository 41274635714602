import { useFocusEffect } from "@react-navigation/native";
import React, { useState } from "react";
import { Dimensions, RefreshControl } from "react-native";
import { FlatList, Image, ScrollView, StyleSheet, Text, View } from "react-native";
import { ActivityIndicator, Surface } from "react-native-paper";
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from "react-redux";
import giftgif from "../../../assets/images/GIF/giftCardImg.png"

import { getAllOrderedGiftAction } from "../../../state/actions/giftActions";
export default function SendGift(props) {
    const { height, width } = Dimensions.get('window');
    const user = useSelector(state => state.auth?.user?.Email_Id);
    const esiColor = useSelector(state => state.theme);
    const [gift_list, setGiftList] = useState([]);
    const [loading, setLoading] = useState(true);
    const orderedgifts = useSelector(state => state.BuyGift?.orderedGifts?.all);
    const dispatch = useDispatch();
    const onRefresh = () => {
        setGiftList([]);
        setLoading(true);
        let filter = {
            "Email_Id": user,
            "Is_approved": "1"
        }
        dispatch(getAllOrderedGiftAction(filter))
            .then(async res => {
                setLoading(false)
            })
            .catch(err => {
                setLoading(false);
            })
    };
    useFocusEffect(
        React.useCallback(() => {
            onRefresh();
        }, [])
    );


    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            marginHorizontal: 15,
            marginVertical: 5,
            height: 150,
            // padding: 10,
            shadowColor: esiColor.brandShadowColor,
            // shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 7,
            shadowRadius: 10,
            // elevation: 10,
            flexDirection: 'row',
            flexWrap: 'wrap',
            // alignSelf: 'center',
            justifyContent: 'space-evenly',
            position: 'relative',
            marginTop: 10,
            width: 475, maxWidth: 500
        },
        image: {
            width: 80,
            height: 80,

        }
    });


    const RenderItem = ({ item }) => {
        return (
            <View style={styles.content}>
                <View style={{ flexDirection: 'row', margin: 'auto', alignContent: 'flex-start' }}>
                    <View style={{ flex: 1, justifyContent: 'flex-start', right: 30 }}>
                        <Image source={{ uri: item?.Gift_Image + `?w=80&h=80` }} style={styles.image}></Image>
                    </View>
                    <View style={{ flex: 2, margin: 'auto', left: 30 }}>
                        <Text style={{ color: esiColor.DescColor }}>{item?.Order_To_Email_Id}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Message: {item?.Gift_Message}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Type: {item?.Type}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Voucher: {item?.Voucher_Name}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Amount/Gift Card: ₹ {item?.Total_Value}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Date: {item?.Created_Date}</Text>
                    </View>
                </View>
            </View>
        )
    }
    return (
        <View style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={onRefresh}
                    />
                }
            >
                {loading ? <ActivityIndicator color={esiColor.SIconColor} style={{ padding: 10 }} />
                    :
                    orderedgifts?.length > 0 ?
                        <FlatList
                            data={orderedgifts}
                            numColumns={3}
                            renderItem={RenderItem}
                            keyExtractor={(item, index) => item?.Gift_Voucher_Id + index}
                            showsVerticalScrollIndicator={false}
                        />
                        :
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={giftgif} />
                            {/* <Ionicons name="gift-outline" size={100} color="gray"></Ionicons> */}
                            <Text style={{ color: esiColor.NoDataFound, marginTop: 15 }}>No Gift Cards Sent.</Text>
                        </View>
                }
            </ScrollView>
        </View>
    )
}

