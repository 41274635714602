import React, { useRef, useState } from "react";
import { Dimensions, FlatList, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { IconButton, Surface } from "react-native-paper";
import Header from "../header";

import Footer from "../footer";
// import HomeFoldminusone from "./HomeFoldminusone";
import MeeStores from "./MeeStores";
import MeeProducts from "./MeeProducts";
import MeeDeals from "./MeeDeals";
import MeeCoupons from "./MeeCoupons";
import MeeBrands from "./MeeBrands";
import TrendingOffers from "./TrendingOffers";
import RecentlyVisites from "./RecentlyVisites";
import HomeFoldOne from "./HomeFoldOne";
import MeeStoresTypes from "./MeeStoresTypes";
import MeeCities from "./MeeCities";
import { useSelector } from "react-redux";
import MeeProductsType from "./MeeProductsType";
import MeeSCProducts from "./sub-category-products/MeeSCProducts";
import MeeFarmers from "./MeeFarmers";
import MfhZones from "../MFH/MFHZones";
// import { transparent } from "react-native-paper/lib/typescript/styles/colors";

export default function Homee(props) {
    const { navigation } = props;
    const esiColor = useSelector(state => state.theme);

    const dataReload = (5 * 60) * 1000;
    const dataErrorReload = 30 * 1000;
    const shippingAddress = JSON.parse(useSelector((state) => state.cart.defaultAddress)?.value || '{}');
    const [cityDataExist, setCityDataExist] = useState(true);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));

    const scrollViewRef = useRef(null);

    const scrollHorizontally = (direction) => {
        if (scrollViewRef.current) {
            const currentPosition = scrollViewRef.current.contentOffset?.x || 0;
            const newPosition = direction === 'right' ? currentPosition + dimensions.width : currentPosition - dimensions.width;
            scrollViewRef.current.scrollTo({ x: newPosition, y: 0, animated: true });
        }
    };

    return (

        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>

            <Header {...props} />

            <ScrollView showsVerticalScrollIndicator={false} >

                    <MeeStoresTypes navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} title={"Mee Store Types"} />

                    <MfhZones navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                    {/* <HomeFoldOne navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} /> */}

                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ marginVertical: -10 }}>
                            <IconButton icon="keyboard-backspace" color={esiColor.SIconColor} size={20} onPress={() => scrollHorizontally('left')}></IconButton>
                        </View>

                        <ScrollView horizontal={true} ref={scrollViewRef} showsHorizontalScrollIndicator={false} style={{}}>

                            {shippingAddress?.City_Id &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Physical"} storeType={"Mee Food"} address={shippingAddress} title={"Food Stores - " + shippingAddress.City} />
                            }
                            {shippingAddress?.City_Id &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Physical"} storeType={"Mee Grocery"} address={shippingAddress} title={"Grocery Stores - " + shippingAddress.City} />
                            }
                            {shippingAddress?.City_Id &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} setCityDataExist={setCityDataExist} type={"Physical"} title={"Mee Stores - " + shippingAddress.City} address={shippingAddress} />
                            }
                            {(!cityDataExist && shippingAddress?.City_Id || !shippingAddress?.City_Id) &&
                                <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Physical"} title={"Mee Physical Stores"} />
                            }
                            <MeeBrands navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                            <MeeStores navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} type={"Online"} title={"Mee Online Stores"} />

                        </ScrollView>

                        <IconButton style={{ transform: [{ scaleX: -1 }], backgroundColor: "transparent" }} icon="keyboard-backspace" size={20} color={esiColor.SIconColor} onPress={() => scrollHorizontally('right')}></IconButton>
                    </View>

                    {shippingAddress?.City_Id &&
                        <MeeProductsType navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} Business_Type={"FOOD"} title={"Mee Food"} City_Id={shippingAddress?.City_Id} />
                    }
                    {shippingAddress?.City_Id &&
                        <MeeProductsType navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} Business_Type={"GROCERY"} title={"Mee Grocery"} City_Id={shippingAddress?.City_Id} />
                    }
                    {shippingAddress?.City_Id &&
                        <MeeProductsType navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} Business_Type={"RETAIL"} title={"Mee Retail"} City_Id={shippingAddress?.City_Id} />
                    }
                    <MeeProducts navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />

                    <MeeDeals navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                    <MeeCoupons navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                    <RecentlyVisites navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                    {/* <MeeSCProducts navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} City_Id={shippingAddress?.City_Id} />; */}
                    <MeeFarmers navigation={navigation.navigate} dataReload={dataReload} dataErrorReload={dataErrorReload} />
                    <Footer navigation={navigation} />

            </ScrollView>
        </Surface >

    )

}
