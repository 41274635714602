import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { material } from 'react-native-typography';
import { RootStackScreenProps } from '../../../types';
import EsiCheckboxTree from '../../components/custom/checkbox-tree';
import { getAllfilterBrandsAction } from '../../state/actions/brandsActions';
import isEmpty from '../../state/validations/is-empty';
import { changeBrandsData, changeMainData } from "../../state/actions/filterServiceActions";
// import esiColor from "../../constants/Colors";


export default function SortByBrands(props) {
    const dispatch = useDispatch();
    const brandId = props.state?.routes[0]?.params?.id;
    const idType = props.state?.routes[0]?.params?.type;
    const esiColor = useSelector(state => state.theme);

    const callback = (data: any) => {
        let topBrands = [];
        let otherBrands = [];
        if (data) {

            data.filter(item => item.Is_Top_Brand === "1").slice(0, 10).map((item: any, index: Number) => {
                topBrands.push({
                    label: item.Brand_Name,
                    // value: item.Brand_Name,
                    value: item.Brand_Id,
                    // checked: "unchecked",
                    checked: item.Brand_Id === brandId ? "checked" : "unchecked",
                    key: "1-" + String(index),
                });
            })
            data.filter(item => item.Is_Top_Brand !== "1").slice(0, 10).map((item: any, index: Number) => {
                otherBrands.push({
                    label: item.Brand_Name,
                    // value: item.Brand_Name,
                    value: item.Brand_Id,
                    // checked: "unchecked",
                    checked: item.Brand_Id === brandId ? "checked" : "unchecked",
                    key: "2-" + String(index),
                });
            })
        }
        dispatch(changeBrandsData(
            [{
                label: "Top Brands",
                value: "Top_Brands",
                type: "Top",
                children: topBrands,
                key: '1'
            },
            {
                label: "Other Brands",
                value: "Other_Brands",
                type: "Other",
                children: otherBrands,
                key: '2'
            }]
        ));
    };

    let sortByOptionsInit = useSelector((state) => state.filters.brands);
    let filters = useSelector((state) => state.filters);
    const checkChildChecked = (data: any) => {
        let count = 0;
        data.map((item: any) => {
            if (item.checked === "checked") {
                count++
            }
        })
        return (count === data.length && data.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
    }


    useEffect(() => {
        if (isEmpty(sortByOptionsInit)) {
            dispatch(getAllfilterBrandsAction(callback));
        } else {
            if (brandId && idType === "brand") {
                sortByOptionsInit.filter((item => (checkChildChecked(item.children) === 'checked' || checkChildChecked(item.children) === 'indeterminate'))).map(item => {
                    item.children.filter(child => child.checked === 'checked').map(children => {
                        children.checked = children.value === brandId ? "checked" : "unchecked";
                    })
                })
            }

            dispatch(changeBrandsData(sortByOptionsInit));
        }
    }, [sortByOptionsInit, brandId]);


    const { navigation } = props;


    const checkChildChange = (data: any, status: string) => {
        if (props.state?.routes[0]?.params?.type === "brand") {
            navigation.setParams({ ...props.state?.routes[0]?.params, id: "", type: "" });
        }
        let key = data.key;
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
            if (status === 'unchecked' || status === 'indeterminate') {
                item.checked = "checked";
            } else {
                item.checked = "unchecked";
            }
        })
        response.filter((item: any) => { return item.key != key }).map(children => {
            children.children.map((item: any) => {
                item.checked = "unchecked";
            })
        })
        dispatch(changeBrandsData(response));
    }

    const changeChecked = (data: any, status: string) => {
        if (props.state?.routes[0]?.params?.type === "brand") {
            navigation.setParams({ ...props.state?.routes[0]?.params, id: "", type: "" });
        }
        let key = data.key;
        let keys = key.split('-');
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        if (keys.length === 1) {
            response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        } else if (keys.length === 2) {
            response.filter((item: any) => { return item.key === keys[0] })[0].children.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
            response.filter((item: any) => { return item.key != keys[0] }).map(children => {
                children.children.map((item: any) => { item.checked = "unchecked" });
            })
        }
        dispatch(changeBrandsData(response));
    }

    const updateOptions = ((data: any) => {
        dispatch(changeBrandsData(data));
    })

    const styles = StyleSheet.create({
        container: {
            backgroundColor: esiColor.BackgroundColor
        }
    });

    return (
        <View style={styles.container}>
            <View style={{ marginLeft: 10, flexDirection: 'row' }}>
                {/* <View>
                    <Image style={{ width: 25, height: 25, marginRight: 10 }}
                        source={require('../../assets/images/favicon.png')}
                    />
                </View> */}
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 14, color: esiColor.itemColor, fontWeight: 'bold', marginTop: 5 }}>Brands</Text>

                </View>
                <TouchableOpacity style={{ position: 'absolute', marginTop: 7, right: 10 }} onPress={() => { navigation.navigate('Brands') }} >
                    <Text style={{ textDecorationLine: 'underline', color: esiColor.itemColor }} >See All</Text>
                </TouchableOpacity>
            </View>
            <EsiCheckboxTree
                sortByOptionsInit={sortByOptionsInit}
                updateOptions={updateOptions}
                checkChildChange={checkChildChange}
                changeChecked={changeChecked}
                {...props} />
        </View>
    );
}


