import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Image,
  ScrollView
} from 'react-native';
// import { useFocusEffect } from "@react-navigation/native";
import { useDispatch, useSelector } from 'react-redux';
import { Surface } from 'react-native-paper';
import Modal from "react-native-modal";
import moment from 'moment';
import { updateNotificationAction } from '../../state/actions/notififcationAction';
import Header from '../header';
import ESIDealModel from '../../components/custom/deal-modal';
import ESICopyCouponModel from '../../components/custom/copycode-modal';
import { Feather } from '@expo/vector-icons';
import isEmpty from '../../state/validations/is-empty';
import notificationgif from "../../assets/images/GIF/notificationImg.png";
import { getAllNotification, getCouponsAction, getDealsAction, getProductsAction } from '../../state/actions/node-actions/home-actions';
// import esiColor from '../../constants/Colors';


function Notification({ navigation }: { navigation: any }) {
  const { height, width } = Dimensions.get('window');
  const EmailID = useSelector(state => state.auth.user.Email_Id);
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);
  const [notifications, setNotifications] = useState([]);
  const [couponData, setCouponData] = useState({});
  const [dealData, setDealData] = useState({});
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  const [dopen, setDopen] = React.useState(false);
  const [copen, setCopen] = React.useState(false);

  const dispatch = useDispatch()
  const esiColor = useSelector(state => state.theme);

  const callBackData = (status: any, data: any) => {
    if (status) {
      setNotifications(data)
    }
  }
  const closeDealModal = () => {
    setDopen(!dopen);
    setDealData([])
  };
  const closeCouponModal = () => {
    setCopen(!copen);
    setDealData([])
  };

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
    return () => clearTimeout(timer)
  }, [seconds]);


  React.useEffect(() => {
    if (!isEmpty(EmailID) && seconds == -1) {
      setSeconds(20);
      let filter = {
        "Records_Filter": "FILTER",
        "Email_Id": EmailID
      }
      dispatch(getAllNotification(filter, { PageNo: 1, PageSize: 1000, SortBy: "NOTIFICATION_ID", SortOrder: "DESC" }, callBackData))
    }
  }, [EmailID, seconds]);

  const callBackCouponData = (status: any, data: any) => {
    if (status) {
      setCouponData(data[0])
      setCopen(true)
    }
  }
  const callBackDealData = (status: any, data: any) => {
    if (status) {
      setDealData(data[0])
      setDopen(true)
    }
  }
  const callBackProductData = (status: any, formData: any) => {
    if (status) {
      let Product_Details = formData[0];
      navigation.navigate('ProductDetails', { masterId: Product_Details.Product_Master_Id, Product_Id: Product_Details.Product_Id, Partner_Details_Id: Product_Details.Partner_Details_Id })
    }
  }
  const callBackOrderData = (notification) => {
    if (notification) {
      navigation.navigate('OrderDetails', { order: notification })
      // setOrderData(data)
    }
  }
  const notificationData = (notification: any) => {
    if (notification.Is_User_Read == 0) {
      let filter = {
        "Module": "USER",
        "Notification_Id": notification.Notification_Id
      }
      dispatch(updateNotificationAction(filter, () => {
        let filter = {
          "Records_Filter": "FILTER",
          "Email_Id": EmailID
        }
        dispatch(getAllNotification(filter, { PageNo: 1, PageSize: 1000, SortBy: "NOTIFICATION_ID", SortOrder: "DESC" }, callBackData))
      }, EmailID))
    }

    if (notification.Type === "Coupon") {
      let formdata = {
        Records_Filter: "FILTER",
        Coupon_Id: [notification.Coupon_Id]
      }
      dispatch(getCouponsAction(formdata, { PageNo: 1, PageSize: 50, SortBy: "COUPON_ID", SortOrder: "DESC" }, callBackCouponData));

    }

    else if (notification.Type === "Product") {
      let formdata = {
        Records_Filter: "FILTER",
        Product_Id: [notification.Product_Id]
      }
      dispatch(getProductsAction(formdata, { PageNo: 1, PageSize: 50, SortBy: "PRODUCT_NAME", SortOrder: "DESC" }, callBackProductData));
    }

    else if (notification.Type === "Deal") {
      let formdata = {
        Records_Filter: "FILTER",
        Deal_Id: [notification.Deal_Id]
      }
      dispatch(getDealsAction(formdata, { PageNo: 1, PageSize: 50, SortBy: "DEAL_ID", SortOrder: "DESC" }, callBackDealData));

    }

    else if (notification.Type === "Order") {
      callBackOrderData(notification)
    }
  }
  const ListEmptyView = () => {
    return (
      <View style={styles.MainContainer}>
        <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={notificationgif} />
        <Text style={{ textAlign: 'center', color: esiColor.NoDataFound }}>Currently don't have notifications to display.</Text>
      </View>

    );
  }

  const styles = StyleSheet.create({
    root: {
      backgroundColor: esiColor.BackgroundColor,
      // width: 200,
      // height: '100%'
      // justifyContent:'space-evenly'
    },

    avatar: {
      width: 50,
      height: 50,
      borderRadius: 25,
    },

    MainContainer: {
      alignSelf: 'center',
      flex: 1,
      margin: 10

    },
    text: {
      marginBottom: 15,
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginLeft: 15,
      marginTop: 15,
      margin: 15
    },

    img: {
      height: 50,
      width: 50,
      margin: 0
    },
    attachment: {
      position: 'absolute',
      right: 0,
      height: 50,
      width: 50
    },
    separator: {
      height: 1,
      // backgroundColor: "#5D5D5D"
    },
    timeAgo: {
      fontSize: 12,
      textAlign: "right",
      color: esiColor.globalButtonColor,
      paddingBottom: 8,
      fontWeight: "bold"
    },
    name: {
      fontSize: 16,
      color: esiColor.DescColor,
      // fontWeight: "bold",
      marginBottom: 10,

    },
    description: {
      fontSize: 13,
      color: esiColor.itemColor,
      marginTop: 5
    }
  });

  return (
    <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
      <Header navigation={navigation}></Header>
      {!isAuthenticated && navigation.navigate('Home')}
      <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor, marginLeft: 15, marginTop: 15, }}>Notifications</Text>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View >

          <FlatList
            // numColumns={3}
            style={styles.root}
            data={notifications}
            extraData={notifications}
            ItemSeparatorComponent={() => {
              return (
                <View style={styles.separator} />
              )
            }}
            keyExtractor={(item) => {
              return item.Notification_Id;
            }}
            ListEmptyComponent={ListEmptyView}
            renderItem={(item) => {
              const Notification = item.item;
              // let mainContentStyle;
              return (
                <TouchableOpacity onPress={(e) => { notificationData(Notification) }}
                  style={[{ marginHorizontal: 10, marginVertical: 5, borderRadius: 8, paddingVertical: 5 },
                  !Notification.Is_User_Read ? { borderColor: esiColor.itemColor, borderWidth: 2 } : { borderColor: esiColor.SBorderColor, borderWidth: 2 }]}>

                  <View style={styles.text}>
                    <Text style={styles.name}>{Notification.Subject}</Text>
                    <Text style={styles.description}>{Notification.Description}</Text>
                  </View>
                  <View style={{ position: "absolute", right: 0 }}>

                    <Text style={styles.timeAgo}>{moment(Notification.Created_Date).fromNow() + " "} </Text>

                  </View>
                </TouchableOpacity>
              );
            }} />
        </View>
      </ScrollView>

      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={dopen}>
        <View style={{ flex: 1, minWidth: dimensions.width <= 400 ? 350 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 430, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
          <ESIDealModel navigation={navigation} openModal={dopen} data={dealData} closeModal={closeDealModal} />
        </View >
      </Modal>
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={copen}>
        <View style={{ flex: 1, minWidth: dimensions.width <= 400 ? 350 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 430, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>
          <ESICopyCouponModel navigation={navigation} openModal={copen} data={couponData} closeModal={closeCouponModal} />
        </View >
      </Modal>
    </Surface>
  );
}



export default Notification;