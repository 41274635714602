import { meeapiBoomi, meeapiCUD } from "./meeapi";

export const availabileBiddingServices = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.post(urlGenarator('/Partner_Bidding_Available/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;

        if (data?.Success_Response?.Is_Data_Exist == "0" || data?.Is_Data_Exist) {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.Partner_Bidding_Service_Available_Services, data.Cart_Count, data.Pagination);
            } catch (error) {
                responseFunction(true, data.Partner_Bidding_Service_Available_Services, data.Cart_Count);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const createServiceBids = (
    formData = [],
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.post("/Bidding_User_Service/Create", formData)
        .then(response => {
            let data = response.data;
            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const createServiceCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapiCUD.post("/User_Service_Cart/Create", formData)
        .then(response => {
            let data = response.data;

            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const getBiddingservicesCart = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiBoomi.post(urlGenarator('/User_Service_Cart/GET', {}),
        formData,
    ).then(response => {
        let data = response.data;

        try {
            responseFunction(true, data);
        } catch (error) {
            responseFunction(true, []);
        }

    }).catch(error => {
        responseFunction(false, error);
    });
};

export const updateServiceCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapiCUD.put("/User_Service_Cart/Update", formData)
        .then(response => {
            let data = response.data;
            if (data.Response_Status == "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const deleteServiceCartBids = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("/User_Service_Cart/Delete", formData)
        .then(response => {
            let data = response.data;

            if (data.Response_Status === "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {
            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
};

export const placeServiceCartBids = (
    formData = [],
    responseFunction: any
) => async (dispatch: any) => {

    meeapiCUD.post("Bidding_User_Service/Create", formData)
        .then(response => {
            let data = response.data;

            if (data.Response_Status === "Success") {
                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {

            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
}
export const deleteallFromCart = (formData = {},
    responseFunction: any) => async (dispatch: any) => {
        meeapiBoomi.post("User_Service_Bidding/Cart_Delete_and_Create",
            formData,
            responseFunction
        ).then(response => {
            let data = response.data;

            if (data.Response_Status === "Success") {

                responseFunction(true);
            } else {
                responseFunction(false);
            }
        }).catch(error => {

            responseFunction(error.message == "Network Error" ? "network" : false, error);
        });
    }
const urlGenarator = (url: any, pagination: any) => {
    let queryString = "?";
    Object.keys(pagination).map((key) => {
        queryString += key + "=" + pagination[key] + "&"
    })
    return (url + queryString).slice(0, -1);
}
