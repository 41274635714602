import { Dimensions, FlatList, Image, Platform, RefreshControl, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Avatar, Colors, HelperText, IconButton, Surface, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../header";
import { useEffect, useState } from "react";
import ordergif from "../../../../assets/images/GIF/noQuoteImg.png";
import { getBiddingRideDetails } from "../../../../state/actions/node-actions/bid-rides-actions";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Modal from "react-native-modal";
import EsiTextAreaInput from "../../../../components/custom/TextAreaInput/textareainput";
import { updateUserBiddingRide } from "../../../../state/actions/boomi-actions/bid-ride-actions";


export default function BidRideDetails(props) {
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
    const dimensions = Dimensions.get('window');
    const { height, width } = dimensions;
    const { navigation } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const [loading, setLoading] = useState(false);
    const [rideDetails, setRideDetails] = useState({});

    const [errorMessage, setErrorMessage] = useState("");
    const [cancelMessage, setCancelMessage] = useState("");

    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);
    const [isConfirmError, setIsConfirmError] = useState("");

    const getRideDetails = () => {
        if (rideId) {
            setLoading(true);
            dispatch(getBiddingRideDetails({ User_Ride_Bidding_Id: rideId }, getCallback))
        }
    }

    const getCallback = (status, data) => {
        setLoading(false);
        if (status) {
            setRideDetails(data);
        }
    }
    const rideId = props?.route?.params?.id;
    useEffect(() => {
        getRideDetails();
    }, [props?.route?.params])

    const RenderItemVehicals = ({ item }) => {

        return (
            <View style={{
                marginHorizontal: 2, padding: 2,
                backgroundColor: esiColor.CBColor, marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                alignItems: 'flex-start',
                // elevation: 10,
                width: 500,
                maxHeight: height > 250 ? 250 : 90,
                minHeight: 100,
                borderBottomWidth: 0.1,
                borderBottomColor: esiColor.SBorderColor,
                marginBottom: 10
            }}>
                <View style={{ flex: 2, alignContent: 'center', alignSelf: 'center', marginLeft: 15 }} >
                    {item?.Images?.length > 0 &&
                        <Image source={{ cache: "force-cache", uri: item?.Images[0] }} style={{ borderRadius: 8, height: 85, width: 80, resizeMode: 'contain', backgroundColor: esiColor.ImgBck }}></Image>
                    }
                </View>
                <View style={{ flex: 4, marginLeft: 15 }}>
                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.itemColor }}>{item?.Vehicle_Type},  {item?.Vehicle_Comfort_Type + "  ,  "}
                        <MaterialCommunityIcons name="seat-passenger" size={18} color={esiColor.itemColor} /> {item?.Seating_Capacity}</Text>

                    <View style={{ flexDirection: 'row' }} >
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.Text }}>Quantity </Text>
                        </View>
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.itemColor }}>:   {item.Quantity}</Text>
                        </View>
                    </View>
                    {item?.Brand_Name ?
                        <View style={{ flexDirection: 'row' }} >
                            <View style={{ flex: 4 }}  >
                                <Text style={{ color: esiColor.Text }}>Brand</Text>
                            </View>
                            <View style={{ flex: 6 }}  >
                                <Text style={{ color: esiColor.itemColor }}>: {item.Brand_Name}</Text>
                            </View>
                        </View> : <View></View>}
                </View>
            </View >
        )
    }

    const RenderItemDeliveryPersons = ({ item }) => {
        let data = item;
        return (<TouchableOpacity onPress={() => {
            if (rideDetails?.User_Bid_Ride?.Finalize_Delivery_Bidding_Quote_Id) {
                if (rideDetails?.User_Bid_Ride?.Finalize_Delivery_Bidding_Quote_Id == data?.Delivery_Bidding_Quote_Id) {
                    navigation.navigate('BidPartnerRideDetails', { id: data?.Delivery_Bidding_Quote_Id });
                }
            } else {
                navigation.navigate('BidPartnerRideDetails', { id: data?.Delivery_Bidding_Quote_Id });
            }
        }}>
            <View style={{
                shadowColor: rideDetails?.User_Bid_Ride?.Finalize_Delivery_Bidding_Quote_Id == data?.Delivery_Bidding_Quote_Id ? esiColor.brandShadowColor : '#0E9EAD',
                shadowOpacity: 5,
                shadowRadius: 7,
                marginHorizontal: 2, borderRadius: 10, padding: 2,
                backgroundColor: esiColor.CBColor, marginTop: (Platform.OS === 'web' ? 10 : 10),
                flexDirection: 'row',
                alignItems: 'flex-start',
                elevation: 10,
                width: 500,
                maxHeight: height > 250 ? 250 : 90,
                minHeight: 100,
                // borderColor: 'grey',
                // borderWidth: 0.5,
                marginBottom: 15
            }}>
                <View style={{ flex: 1, alignItems: 'center', alignSelf: 'center', marginLeft: 10, marginVertical: 5 }} >
                    <Image source={{ cache: "force-cache", uri: data?.Profile_Pic_Path }} style={{ borderRadius: 8, height: 100, width: 80, resizeMode: 'contain', backgroundColor: esiColor.ImgBck }}></Image>
                </View>
                <View style={{ flex: 2, flexDirection: 'column' }}  >
                    <Text style={{ fontSize: 14, paddingTop: 10, fontWeight: 'bold', fontFamily: 'system-ui', marginBottom: 15, color: esiColor.DescColor }}>{data?.Full_Name}</Text>
                    <View style={{ flexDirection: 'row' }} >
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.Text }}>Amount </Text>
                        </View>
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.itemColor }}>:   {data.Quotation_Amount}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row' }} >
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.Text }}>Extra Fare Per Day </Text>
                        </View>
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.itemColor }}>:   {data.Fare_Per_Day}</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }} >
                        <View style={{ flex: 6 }}  >
                            <Text style={{ color: esiColor.Text }}>Extra Fare Per KM </Text>
                        </View>
                        <View style={{ flex: 4 }}  >
                            <Text style={{ color: esiColor.itemColor }}>:   {data.Price_Per_Km}</Text>
                        </View>
                    </View>
                    <Text style={{ fontWeight: "bold", color: esiColor.Text }} >Info </Text>
                    <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.Text }} >Are you sure you want to cancel?</Text>
                </View>
            </View>
        </TouchableOpacity>)
    }

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View>
                {navigation.canGoBack() &&
                    <IconButton
                        icon="arrow-left"
                        size={25}
                        color={esiColor.SIconColor}
                        style={{ marginTop: -2, marginLeft: 1 }}
                        onPress={() => props.navigation.goBack()}
                    />}
            </View>
            <ScrollView style={{ maxHeight: dimensions.height - 20 }}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={() => getRideDetails}
                    />
                }
            >
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                    <View style={{ flexDirection: 'column', flex: 3, marginLeft: 15 }}>

                        <View style={{ marginVertical: 5, alignSelf: 'flex-start', marginBottom: 10, marginLeft: 15 }} >
                            <Text style={{ textAlign: 'center', color: esiColor.brandFontColor, fontWeight: 'bold' }}>{rideDetails?.User_Bid_Ride?.Pickup_Zip_Code}</Text>
                        </View>
                        <View style={{ paddingHorizontal: 10 }} >
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Ride Date </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   {rideDetails?.User_Bid_Ride?.Ride_Start_Date}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Bid Expire Date </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   {rideDetails?.User_Bid_Ride?.Bidding_Expiry_Date}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "row" }} >
                                <View style={{ flex: 4 }} >
                                    <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Status </Text>

                                </View>
                                <View style={{ flex: 6 }} >
                                    <Text style={{ color: esiColor.itemColor }}>:   {rideDetails?.User_Bid_Ride?.Bidding_Status}</Text>
                                    {rideDetails?.User_Bid_Ride?.Bidding_Status == "INITIATED" &&
                                        <TouchableOpacity onPress={() => {
                                            setIsConfirmScreen(true);
                                        }} style={{ backgroundColor: 'gray', borderWidth: 1, borderRadius: 8, width: 70 }} >
                                            <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Cancel </Text>
                                        </TouchableOpacity>
                                    }
                                </View>
                            </View>
                            {rideDetails?.User_Bid_Ride?.Finalize_Delivery_Bidding_Quote_Id &&
                                <View style={{ flexDirection: "row" }} >
                                    <View style={{ flex: 4 }} >
                                        <Text style={{ color: esiColor.Text, fontWeight: '500' }}> Finalize Delivery Bidding Quote ID </Text>

                                    </View>
                                    <View style={{ flex: 6 }} >
                                        <Text style={{ color: esiColor.itemColor }}>:   {rideDetails?.User_Bid_Ride?.Finalize_Delivery_Bidding_Quote_Id}</Text>
                                    </View>
                                </View>
                            }
                        </View>

                        <View style={{ flexDirection: "column", justifyContent: 'center', marginTop: 15, marginLeft: 15 }} >
                            <Text style={{ fontWeight: "bold", color: esiColor.itemColor }}>Vehicles :</Text>
                            <View>
                                <FlatList
                                    data={rideDetails?.Vehicals || []}
                                    renderItem={RenderItemVehicals}
                                    keyExtractor={(item, index) => index}
                                />
                            </View>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'column', flex: 3, marginRight: 15 }}>

                        <View style={{ paddingHorizontal: 10 }} >
                            <View style={{ flexDirection: "column", alignContent: 'center', marginBottom: 15 }} >
                                <Text style={{ fontWeight: "bold", color: esiColor.Text, marginBottom: 10 }}>Description :</Text>
                                <Text style={{ flexWrap: "wrap", color: esiColor.itemColor }}>{rideDetails?.User_Bid_Ride?.Description}</Text>
                            </View>
                            <View style={{ flexDirection: "column", marginBottom: 10 }} >
                                <Text style={{ fontWeight: "bold", textAlign: "left", color: esiColor.Text, fontSize: 16 }}>Quotations :</Text>
                            </View>
                            {(rideDetails?.Deliverybids?.length <= 0 || !rideDetails?.Deliverybids) ?
                                <View style={{ alignItems: "center", marginTop: 10 }}>

                                    <View>
                                        <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 300 }} source={ordergif}></Image>
                                        <Text style={{ textAlign: "center", color: esiColor.NoDataFound }}>Oops! No Quotations's found...</Text>
                                    </View>

                                </View>
                                :
                                <View>

                                    <FlatList
                                        data={rideDetails?.Deliverybids || []}
                                        renderItem={RenderItemDeliveryPersons}
                                        keyExtractor={(item, index) => index}
                                    />

                                    <View style={{ marginBottom: 35 }} ></View>
                                </View>
                            }
                        </View>
                    </View>
                </View>

                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }}
                    isVisible={isConfirmScreen}
                >
                    <View style={{
                        maxWidth: 500, minWidth: 300, height: 250, borderRadius: 10,
                        marginLeft: 'auto', marginRight: 'auto', backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5
                    }}>
                        <View style={{ flexDirection: "column" }} >
                            <TouchableRipple style={{ width: 36, position: "absolute", right: 2, top: 0, zIndex: 10, backgroundColor: "" }} onPress={() => {
                                setIsConfirmScreen(!isConfirmScreen);
                            }}>
                                <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            <View style={{
                                flexDirection: "column",
                                justifyContent: "space-around",
                                height: 250
                            }}>
                                <View style={{
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    paddingHorizontal: 10,
                                    marginTop: 20
                                }}>
                                    <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.DescColor }} >Cancel Reason:</Text>
                                    <EsiTextAreaInput
                                        error={Boolean(errorMessage)}
                                        style={{ height: 60 }}
                                        mode='outlined'
                                        value={cancelMessage}
                                        textareavalueUpdate={value => {
                                            if (value) {
                                                if (value.length < 20) {
                                                    setErrorMessage("Cancel reason should be more then 20 charecters.")
                                                } else {
                                                    setErrorMessage("");
                                                }
                                            } else {
                                                setErrorMessage("Please provide cancel reason.")
                                            }
                                            setCancelMessage(value);
                                        }}
                                        placeholder={'Please provide reason'}
                                        underlineColorAndroid={'transparent'}
                                        theme={{
                                            colors: {
                                                primary: "#27b6cc"
                                            },
                                            roundness: 8
                                        }}
                                        maxLength={500}
                                    />
                                    <HelperText type="error" visible={Boolean(errorMessage)}>
                                        {errorMessage}
                                    </HelperText>
                                    <Text style={{ marginVertical: 5, fontWeight: "500", fontSize: 14, color: esiColor.itemColor }} >Are you sure you want to cancel?</Text>
                                </View>


                                <View style={{
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    paddingHorizontal: 5,
                                    marginVertical: 5

                                }}>
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmError("");
                                        if (cancelMessage) {
                                            if (cancelMessage.length < 20) {
                                                setErrorMessage("Cancel reason should be more then 20 charecters.")
                                            } else {
                                                setErrorMessage("");

                                                setIsConfirmLoading(true);
                                                dispatch(updateUserBiddingRide({
                                                    User_Ride_Bidding_Id: rideDetails?.User_Bid_Ride?.User_Ride_Bidding_Id,
                                                    Bidding_Status: "CANCELLED",
                                                    Cancelled_Reason: cancelMessage
                                                }, (status) => {
                                                    setIsConfirmLoading(false);
                                                    setCancelMessage("");
                                                    if (status) {
                                                        getRideDetails()
                                                        setIsConfirmScreen(false);
                                                    } else {
                                                        setIsConfirmError("Please try again.");
                                                    }
                                                }))

                                            }
                                        } else {
                                            setErrorMessage("Please provide cancel reason.")
                                        }

                                    }} style={{ backgroundColor: esiColor.globalButtonColor, borderWidth: 1, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Yes </Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        setIsConfirmScreen((prop) => !prop);
                                    }} style={{ backgroundColor: 'gray', borderWidth: 0.5, borderRadius: 8, width: 70 }} >
                                        <Text style={{ color: esiColor.itemButtenColor, marginTop: Platform.OS == "web" ? 5 : 2, fontWeight: "bold", marginBottom: 5, textAlign: "center" }} > Close </Text>
                                    </TouchableOpacity>
                                </View>
                            </View>

                        </View>
                    </View>
                </Modal>

            </ScrollView >
        </Surface >
    )
}