import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView } from 'react-native';
import { Text, Surface, ActivityIndicator } from 'react-native-paper';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { getAllContentManagementStaticAction } from '../../state/actions/contentActions';
import Footer from '../footer';


export default function Returns_Cancellations({ navigation }) {
    const dispatch = useDispatch();
    const [contentsOne, setContentsOne] = useState({});
    const responseFunction = (data) => {
        setContentsOne(data)
    }
    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Name": "Returns & Cancellation",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);
    const [pos, setPos] = React.useState(0);

    return (
        <View style={{ flex: 1 }}>
            <Surface style={{ flex: 1 }}>
                <Header navigation={navigation} />

                <Text style={{ fontSize: 24, marginTop: 15, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>Returns & Cancellation</Text>

                <ScrollView onScroll={(e) => setPos(e.nativeEvent.contentOffset.y)}>

                    <View style={{
                        flex: 1,
                        marginHorizontal: 2, padding: 5, margin: 'auto',
                        backgroundColor: 'white', flexDirection: 'column',
                        marginTop: 10,
                        marginBottom: 20,
                        paddingTop: 10,
                        elevation: 10,
                        minWidth: 800,
                        maxWidth: 800,
                        alignSelf: 'center'

                    }}>
                        <View>
                            {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                                <RenderHTML
                                    source={{ html: contentsOne?.Description }} /> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                    <Text>No Data found!</Text></View>) :
                                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                    <ActivityIndicator animating={true} color='#27B6CC' style={{ marginTop: 25 }} />
                                </View>}
                        </View>

                    </View>
                    {pos >= 0 && <View style={!contentsOne ? { display: "none" } : { display: "flex" }} ><Footer navigation={navigation} /></View>}

                </ScrollView>
            </Surface>

        </View>
    );


}
