import React, { Fragment, useEffect } from "react";
import { Dimensions, FlatList, ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ESICouponsCard from "../../components/custom/coupon-card";
import isEmpty from "../../state/validations/is-empty";
import Modal from "react-native-modal";
import SignIN from "../auth/signin";
import ESICopyCouponModel from "../../components/custom/copycode-modal";
import ESIDealModel from "../../components/custom/deal-modal";
import ESIDealsCard from "../../components/custom/deal-card";
import ProductCard from "../../components/custom/product-card";
import { TabBar, TabView } from "react-native-tab-view";
import { resetMainData } from "../../state/actions/filterServiceActions";
import { getDealsAction, getCouponsAction, getProductsAction } from "../../state/actions/node-actions/home-actions";


export default function TrendingOffers({ navigation, dataReload, dataErrorReload }) {
    // const { navigation } = props;

    const dispatch = useDispatch();
    // const data = { data };

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const window = Dimensions.get("window");
    //open param is used to open the Copy Code Modal
    const [open, setOpen] = React.useState(false);
    //open param is used to assign the data to Copy Code Modal
    const [couponData, setCouponData] = React.useState('');
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [Dopen, setDOpen] = React.useState(false);
    const [dealData, setDealData] = React.useState('');
    const [wishdata, setWishData] = React.useState(false);

    const [coupon, setCoupon] = React.useState([]);
    const [loadingC, setLoadingC] = React.useState(false);
    const callBackGetDataC = async (status, data) => {
        setLoadingC(false);
        if (status) {
            setCoupon(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            couponsCall();
        } else {
            await delay(dataErrorReload);
            couponsCall();
        }
    }

    const [deal, setDeal] = React.useState([]);
    const [loadingD, setLoadingD] = React.useState(false);
    const callBackGetDataD = async (status, data) => {
        setLoadingD(false);
        if (status) {
            setDeal(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            dealsCall();
        } else {
            await delay(dataErrorReload);
            dealsCall();
        }
    }

    const [allproducts, setAllproducts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            productsCall();
        } else {
            await delay(dataErrorReload);
            productsCall();
        }
    }

    // Condition for coupons expired before 3 days only will display at UI
    const liveData = (inputData: any) => {
        let couponlivedata = [];
        var currentdate = new Date(),
            date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
        if (inputData) {

            couponlivedata = inputData.filter(coupon => {
                let currentDate = new Date(date);
                let couponExpDate = new Date(coupon.Valid_Through_Date.split("/").reverse().join("-"));
                return Math.floor((currentDate - couponExpDate) / (1000 * 60 * 60 * 24)) < 3
            })

        } else {
            couponlivedata = []
        }
        return couponlivedata;
    }

    const signInModalResponse = (status) => {
        setModalVisible(!isModalVisible);
    };
    const openModal = (data) => {
        setCouponData(data)
        setOpen(true);
    };
    const toggleModal = async (data) => {
        if (isAuthenticated) {
            openModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeModal = () => {
        setOpen(!open);
        setCouponData("")
    };
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
    });


    // Condition for deals expired before  3 days only will display at UI
    const dealLiveData = (deals: any) => {
        let data = [];
        var currentdate = new Date(),
            date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
        if (deals) {
            if (deals[0]) {
                data = deals.filter((deal: any) => {
                    let currentDate=new Date(date);
                    let dealExpDate=new Date(deal.End_Date.split("/").reverse().join("-"));
                    return Math.floor((currentDate - dealExpDate) / (1000 * 60 * 60 * 24)) < 3})
            }
        } else {
            data = []
        }
        return data;
    }

    const openDModal = (data) => {
        setDealData(data)
        setDOpen(true);
    };
    const toggleDModal = async (data) => {
        if (isAuthenticated) {
            openDModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeDModal = () => {
        setDOpen(!Dopen);
        setDealData("")
    };

    const couponsCall = () => {
        setLoadingC(true);
        let formData = {
            Records_Filter: "FILTER",
            Status: "Active",
            Trending_Today: 1,
        }
        dispatch(getCouponsAction(formData, { PageNo: 1, PageSize: 20, SortBy: "COUPON_ID", SortOrder: "DESC" }, callBackGetDataC));
    }

    const dealsCall = () => {
        setLoadingD(true);
        let formData = {
            Records_Filter: "FILTER",
            Status: "Active",
            Trending_Today: 1,
        }
        dispatch(getDealsAction(formData, { PageNo: 1, PageSize: 20, SortBy: "DEAL_ID", SortOrder: "DESC" }, callBackGetDataD));
    }

    const productsCall = () => {
        setLoading(true);
        dispatch(getProductsAction({
            Records_Filter: "FILTER",
            Status: "Active",
            Trending_Today: 1,

        }, { PageNo: 1, PageSize: 20, SortBy: "PRODUCT_NAME", SortOrder: "DESC" }, callBackGetData));
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    // Api Calls
    useEffect(() => {
        if (!(coupon?.length > 0 && loadingC)) {
            couponsCall();
        }

        if (!(deal?.length > 0 || loadingD)) {
            dealsCall();
        }
        if (!(allproducts?.length > 0 || loading)) {
            productsCall();
        }
    }, []);

    useEffect(() => {

        if (coupon.length > 0) {
            let route = JSON.parse(JSON.stringify(routes));
            route.push({ key: 'first', title: 'Coupons' })
            if (routes.filter((item) => item.key == "first").length == 0)
                setRoutes(route);
        }

        if (deal.length > 0) {
            let route = JSON.parse(JSON.stringify(routes));
            route.push({ key: 'second', title: 'Deals' })
            if (routes.filter((item) => item.key == "second").length == 0)
                setRoutes(route);
        }

        if (allproducts.length > 0) {
            let route = JSON.parse(JSON.stringify(routes));
            route = [{ key: 'third', title: 'Products' }].concat(route);
            route.push()
            if (routes.filter((item) => item.key == "third").length == 0)
                setRoutes(route);
        }
    }, [coupon, deal, allproducts]);


    const handleClick = async (item) => {
        navigation('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id })
    };
    const [index, setIndex] = React.useState(0);
    const [routes, setRoutes] = React.useState([]);
    const renderScene = ({ route, jumpTo }) => {
        switch (route.key) {
            case 'first':
                return <View style={{marginBottom:15}} >
                    <FlatList
                        horizontal={true}
                        data={liveData(coupon).slice(0, 10)}
                        renderItem={({ item, index, separators }) => (
                            <ESICouponsCard data={item} toggleModal={toggleModal} />
                        )}
                    />
                    {coupon?.length > 8 &&
                        <TouchableOpacity onPress={(e) => {
                            dispatch(resetMainData());
                            navigation('Main', { screen: 'All', params: { page: 'coupons', filter: 'all' } })
                        }} style={{ position: 'absolute', right: 10,bottom:-15 }}>
                            <Text style={{ fontSize: 15, marginTop: 10, color: '#026473', fontWeight: 'bold' }}>View All</Text>
                        </TouchableOpacity>
                    }
                    <Modal animationIn={"slideInDown"}
                        deviceWidth={dimensions.width}
                        deviceHeight={dimensions.height}
                        style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                        <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: 'white' }}>
                            <SignIN navigation={navigation} toggleModal={signInModalResponse} />
                        </View>
                    </Modal>
                    <Modal animationIn={"slideInDown"}
                        deviceWidth={dimensions.width}
                        deviceHeight={dimensions.height}
                        style={{ alignItems: 'center' }} isVisible={open}>
                        <View style={{ flex: 1, maxWidth: 600, minWidth: dimensions.width <= 400 ? 200 : dimensions.width <= 400 ? 200 : 350, minHeight: 400, maxHeight: 500, borderRadius: 10, backgroundColor: 'white' }}>
                            <ESICopyCouponModel navigation={navigation} openModal={openModal} data={couponData} closeModal={closeModal} />
                        </View >
                    </Modal>
                </View>
            case 'second':
                return <View >

                    <FlatList
                        horizontal={true}
                        data={dealLiveData(deal).slice(0, 10)}
                        renderItem={({ item, index, separators }) => (
                            <ESIDealsCard data={item} toggleModal={toggleModal} />
                        )}
                    />
                    {dealLiveData(deal).length > 8 &&
                        <TouchableOpacity onPress={(e) => {
                            dispatch(resetMainData());
                            navigation('Main', { screen: 'All', params: { page: 'deals', filter: 'all' } })
                        }} style={{ position: 'absolute', right: 10 }}>
                            <Text style={{ fontSize: 15, marginTop: 10, color: '#026473', fontWeight: 'bold' }}>View All</Text>
                        </TouchableOpacity>
                    }
                    <Modal animationIn={"slideInDown"}
                        deviceWidth={dimensions.width}
                        deviceHeight={dimensions.height}
                        style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                        <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: 'white' }}>
                            <SignIN navigation={navigation} toggleModal={signInModalResponse} />
                        </View>
                    </Modal>
                    <Modal animationIn={"slideInDown"}
                        deviceWidth={dimensions.width}
                        deviceHeight={dimensions.height}
                        style={{ alignItems: 'center' }} isVisible={Dopen}>
                        <View style={{ flex: 1, maxWidth: 600, minWidth: dimensions.width <= 400 ? 200 : dimensions.width <= 400 ? 200 : 400, maxHeight: dimensions.height <= 600 ? 400 : dimensions.height <= 500 ? 200 : 400, borderRadius: 10, backgroundColor: 'white' }}>
                            <ESIDealModel navigation={navigation} openModal={openDModal} data={dealData} closeModal={closeDModal} />
                        </View >
                    </Modal>
                </View>
            case 'third':
                return <View >
                    <FlatList
                        horizontal={true}
                        data={allproducts.slice(0, 10)}
                        renderItem={({ item, index, separators }) => (
                            <ProductCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />
                        )}
                    />

                    {allproducts?.length > 8 &&
                        <TouchableOpacity onPress={(e) => {
                            dispatch(resetMainData());
                            navigation('Main', { screen: 'All', params: { page: 'products', filter: 'all' } })
                        }} style={{ position: 'absolute', right: 10, bottom: -15 }}>
                            <Text style={{ fontSize: 15, marginTop: 8, color: '#026473', fontWeight: 'bold' }}>View All</Text>
                        </TouchableOpacity>}

                </View>
        }
    };
    return (routes.length > 0 &&
        <View style={styles.content}>
            <Text style={{ textAlign: 'center', fontSize: 16, color: "#27b6cc", marginTop: 2, fontWeight: 'bold' }}>Trending Offers </Text>
            <TabView
                renderTabBar={props => <TabBar {...props}
                    style={{ backgroundColor: '#fff' }}
                    activeColor='#27b6cc'
                    inactiveColor='#111'
                    labelStyle={{ fontWeight: 'bold' }}
                    indicatorStyle={{ backgroundColor: '#27b6cc' }} />}
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
                pagerStyle={{ height: 300 }}
            />
        </View>
    )


}


const styles = StyleSheet.create({

    content: {
        borderRadius: 10,
        backgroundColor: 'white',
        margin: 5,
        padding: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
        elevation: 10,
        // minHeight: 360
    },

});     