/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: ['https://meepaisa.com', 'http://localhost:19006', '*.meepaisa.com'],
  enabled: true,
  config: {
    screens: {
      HomeMain: {
        screens: {
          Index: 'index',
          Home: 'home',
          Modal: 'modal',
          Main: {
            screens: {
              All: 'all'
            }
          }, MainCity: {
            screens: {
              AllCity: 'all-city'
            }
          },
          AllPages: 'all-pages',
          NewProducts: "products",
          ProductDetails: 'product-details',
          Coupons: "coupons",
          Category: "categories",
          SubCategory: "sub-category",
          Notification: "notification",
          Partners: 'partners',
          PartnerDetails: 'partner-details',
          deals: 'deals',
          Brands: 'brands',
          BrandDetails: 'brand-details',
          Products: "all-products",
          refer: 'new-refer',
          earn: 'earns',
          MissingCashback: 'missing-cashback',
          AddMissingCashback: 'add-missing-cashback',
          UserAddresses: 'user-addresses',
          AddAddress: 'add-address',
          EditAddress: 'edit-address',
          faq: 'faq',
          NewsAndEvents: 'news-and-events',
          PrivacyPolicy: 'privacy-policy',
          TermsAndConditions: 'terms-and-conditions',
          MarketingTerms: 'marketing-terms',
          ContactUs: 'contact-us',
          Homee: "homee",
          ProfileDetails: 'profile-details',
          UserWallet: 'user-wallet',
          Withdrawals: 'withdrawals',
          // Transactions: 'transactions',
          WalletHistory: 'wallet-history',
          allCities: 'all-cities',
          BuyGift: 'buy-gift',
          GiftCard: 'gift-cards',
          HowCashbackWorks: 'how-cashback-works',
          WishList: 'wish-list',
          ReviewsAndRatings: 'reviews-and-ratings',
          Cart: 'cart',
          CartSummary: 'cart-summary',
          Dashboard: 'dashboard',
          searchproduct: "search-product",
          SearchResults: "search-results",
          MyOrders: "my-orders",
          OrderDetails: "order-details",
          OrderTracking: "order-tracking",
          Ticket: 'support-ticket',
          AddTicket: 'add-support-ticket',
          Comments: 'support-comment',
          SetPassword: 'set-password',
          ActivateAccount: 'activate-account',
          // FinancialTransactions: 'financial-transactions',
          ShopbyStoreType: 'store-types',
          PartnerPrivacyPolicy: "partner-privacy-policy",
          PartnerTermsAndConditions: "partner-terms-and-conditions",
          Rides: "rides",
          MyRides: "my-rides",
          RideDetails: "ride-details",
          Services: "services",
          MyServices: "my-services",
          ServiceDetails: "service-details",
          FunctionHalls: "function-halls",
          AllEvents: "all-events",
          EventDetails: "event-details",
          AddEventDetails: "add-event-details",
          AllHalls: "all-halls",
          EditEventDetails: "eidt-event-details",
          PartnerProducts: 'PP',
          BidProducts: "bid-products",
          CartBidProducts: "cart-bid-products",
          NewBidProducts: "new-bid-products",
          BidProductDetails: "bid-product-details",
          BidPartnerProductDetails: "bid-partner-details",
          BidServices: "bid-services",
          BidRides: "bid-rides",
          CartBidRides: 'cart-bid-rides',
          NewBidRides: 'new-bid-ride',
          BidRideDetails: 'bid-ride-details',
          BidPartnerRideDetails: 'bid-partner-ride-details',
          CartBidServices: 'services-bit-cart',
          NewBidServices: 'new-bid-service',
          BidServiceDetails: 'bid-service-details',
          BidPartnerServiceDetails: 'bid-partner-service-details',
          MFH: 'mfh',
          MFHDetails: 'mfh-details',
          Farmers: 'farmers',
          FarmerDetails: 'farmer-details',
          NotFound: '*',
        }
      },

    },
  }
};

export default linking;
