import * as React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { material } from 'react-native-typography';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps } from '../../../types';
import EsiCheckboxTree from '../../components/custom/checkbox-tree';
// import esiColor from '../../constants/Colors';
import { changeCashbackData } from '../../state/actions/filterServiceActions';
import isEmpty from '../../state/validations/is-empty';


export default function SortByExtraCashback(props) {
    const dispatch = useDispatch();
    let sortByOptionsInit = useSelector((state) => state.filters.cashback);
    const esiColor = useSelector(state => state.theme);

    const [oldParams, setOldParams] = React.useState("");
    React.useEffect(() => {
        let response = JSON.parse(JSON.stringify(sortByOptionsInit));
        if (isEmpty(oldParams)) {
            setOldParams(JSON.stringify(props.state.routes[0].params));
            let isChecked = false;
            response.map((item: any) => {
                if (item.checked == "checked") {
                    isChecked = true;
                }
                item.checked = "unchecked";
            })
            if (isChecked) {
                dispatch(changeCashbackData(response));
            }
        }
        if ((JSON.stringify(props.state.routes[0].params) != oldParams) && !isEmpty(oldParams)) {
            setOldParams(JSON.stringify(props.state.routes[0].params));
            let isChecked = false;
            response.map((item: any) => {
                if (item.checked == "checked") {
                    isChecked = true;
                }
                item.checked = "unchecked";
            })
            if (isChecked) {
                dispatch(changeCashbackData(response));
            }
        }

    }, [props.state.routes[0].params])

    React.useEffect(() => {
        let response = JSON.parse(JSON.stringify(sortByOptionsInit));
        if (isEmpty(oldParams)) {
            setOldParams(JSON.stringify(props.state.routes[0].params));
            let isChecked = false;
            response.map((item: any) => {
                if (item.checked == "checked") {
                    isChecked = true;
                }
                item.checked = "unchecked";
            })
            if (isChecked) {
                dispatch(changeCashbackData(response));
            }
        }
        if ((JSON.stringify(props.state.routes[0].params) != oldParams) && !isEmpty(oldParams)) {
            setOldParams(JSON.stringify(props.state.routes[0].params));
            let isChecked = false;
            response.map((item: any) => {
                if (item.checked == "checked") {
                    isChecked = true;
                }
                item.checked = "unchecked";
            })
            if (isChecked) {
                dispatch(changeCashbackData(response));
            }
        }

    }, [props.state.routes[0].params])

    const checkChildChange = (data: any, status: string) => {
        let key = data.key;
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response.filter((item: any) => { return item.key === key })[0].children.map((item: any) => {
            if (status === 'unchecked' || status === 'indeterminate') {
                item.checked = "checked";
            } else {
                item.checked = "unchecked";
            }
        })
        dispatch(changeCashbackData(response));
    }

    const changeChecked = (data: any, status: string) => {
        let key = data.key;
        let keys = key.split('-');
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        if (keys.length === 1) {
            response.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        } else if (keys.length === 2) {
            response.filter((item: any) => { return item.key === keys[0] })[0].children.filter((item: any) => { return item.key === key })[0].checked = status === 'checked' ? 'unchecked' : 'checked';
        }
        dispatch(changeCashbackData(response));
    }

    const updateOptions = ((data: any) => {
        dispatch(changeCashbackData(data));
    })

    const styles = StyleSheet.create({
        container: {
            backgroundColor: esiColor.BackgroundColor
        }
    });
    
    return (
        <View style={styles.container}>
            <View style={{ marginLeft: 10, flexDirection: 'row' }}>
                {/* <View>
                    <Image style={{ width: 25, height: 25, marginRight: 10 }}
                        source={require('../../assets/images/favicon.png')}
                    />
                </View> */}
                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <Text style={{ fontSize: 14, color: esiColor.itemColor,fontWeight:'bold', marginTop: 5 }}>Extra Cashback</Text>
                </View>
            </View>
            <EsiCheckboxTree
                sortByOptionsInit={sortByOptionsInit}
                updateOptions={updateOptions}
                checkChildChange={checkChildChange}
                changeChecked={changeChecked}
                noSearch={true}
                {...props} />
        </View>
    );
}


