import * as React from 'react';
import { Text, StyleSheet, View, TouchableOpacity, FlatList } from "react-native";
import { Checkbox } from 'react-native-paper';
import { useSelector } from 'react-redux';

function EsiCheckbox(props: any) {
  const { data, onchange } = props;
  const esiColor = useSelector(state => state.theme);

  
const styles = StyleSheet.create({

  checkboxContainer: {
    flexDirection: "row",
    marginTop: -5,
    paddingLeft: 20,
  },
  label: {
    marginTop: 10,
    fontWeight: 'bold',
    fontSize: 10,
    color: esiColor.DescColor
  }
});


  const buildCheckBox = (input: any) => {
    return (
      <View  >
        <TouchableOpacity
          onPress={() => {
            onchange(input.item);
          }}
        >
          <View style={styles.checkboxContainer}>
            <Checkbox.Android
              status={input.item.chacked ? "checked" : "unchecked"}
              uncheckedColor={esiColor.SBorderColor}

            />
            <Text style={styles.label}>{input.item.label}</Text>
          </View>
        </TouchableOpacity>

      </View>
    );
  }

  return (
    <View>
      <FlatList
        data={data}
        renderItem={buildCheckBox}
        keyExtractor={(item, index) => "item" + index}
      />
    </View>

  );
}

export default EsiCheckbox;
