import 'react-native-gesture-handler';
import * as React from 'react';
import { deleteAddressAction, GetAllAddressAction, } from "../../state/actions/addressActions";
import { View, StyleSheet, ScrollView, Dimensions, TouchableOpacity, Image, Platform, ActivityIndicator, RefreshControl } from 'react-native';
import { Provider, Card, Text, Surface, IconButton, Button } from 'react-native-paper';
import { useSelector, useDispatch } from 'react-redux'
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import addressgif from "../../assets/images/GIF/addressgif.gif";
import { useToast } from '../../self_modules/react-native-paper-toast/src';
// import esiColor from '../../constants/Colors';

function Useraddresses(props: any) {
    const { navigation, ...rest } = props
    const { height, width } = Dimensions.get('window');
    const toast = useToast();
    const esiColor = useSelector(state => state.theme);

    const addresses = useSelector(state => state.address.addresses.all);
    const isLoading = useSelector(state => state.address.addresses.isLoading);
    const EmailID = useSelector(state => state.auth.user.Email_Id);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    // const windowHeight = Dimensions.get('window').height;
    const halfdimensions = dimensions.width / 2;

    //API dispatch
    const dispatch = useDispatch();
    const onRefresh = () => {
        let formData = {
            Records_Filter: "FILTER",
            User_Email_Id: EmailID,
        };
        dispatch(GetAllAddressAction(formData))
    };
    React.useEffect(() => {
        if (!isEmpty(EmailID)) {
            if (!isLoading) {
                onRefresh()
                onRefresh()
            }
        } else {
            setSeconds(2);
        }
    }, [EmailID]);

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (isEmpty(EmailID) || seconds === 1) {
                navigation.navigate("Home");
            }
            seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);

        }, 1000)
        return () => clearTimeout(timer)
    }, [seconds]);
    const [bopen, setBopen] = React.useState(false);
    const bhandleClose = (address: any) => {
        setBopen(false);
        if (address) {
            dispatch(deleteAddressAction(address, EmailID, toast));

        }
    };

    const style = StyleSheet.create({

        button: {
            alignItems: "center",
            paddingTop: 6,
            backgroundColor: esiColor.globalButtonColor,
            borderRadius: 5,
            width: 145,
            marginTop: 15,
            height: 30

        },
        title: {
            fontWeight: "bold",
            fontSize: 12,
            justifyContent: "center",
            textAlign: "center",
            width: 80
        },
        titletext: {
            fontWeight: "bold",
            fontSize: 14
        },
        tablerow: {
            justifyContent: "center",
            textAlign: "center",
            width: 140
        },
        mainbox: {
            textAlign: 'center',
            marginHorizontal: 15,
            // flex: 1,
            // justifyContent: 'space-between',
            width: 500,
            height: 500,
            // margin:10,
            marginBottom: 20,
            flexDirection: 'row'
            // backgroundColor: "#fff"
        },
        databeBox: {
            margin: 10,
            textAlign: 'center',
        },
        databeHeader: {
            margin: 10,
            textAlign: 'center',
        },
    });

    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />

            <View style={{ flexDirection: 'row-reverse', marginTop: "2%", marginBottom: "2%", marginLeft: 10, }}>
                <View style={{ marginRight: 10 }}>
                    {isEmpty(addresses) ? null : <TouchableOpacity onPress={() => navigation.navigate('AddAddress')} style={style.button}>
                        <Text style={{ fontSize: 15, color: esiColor.itemButtenColor, fontWeight: "bold", paddingBottom: 10 }}>+ ADD ADDRESS</Text>
                    </TouchableOpacity>
                    }
                </View>
            </View>

            <ScrollView showsVerticalScrollIndicator={false} refreshControl={
                <RefreshControl
                    refreshing={isLoading}
                    onRefresh={onRefresh}

                />
            }>
                {isLoading ? <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                    <Text style={{ fontSize: 13, marginTop: 10, fontWeight: 'bold', color: esiColor.NoDataFound }}>No Address Found!</Text>

                    <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={addressgif} />
                </View> : <View style={{ marginTop: 0, flexDirection: 'row', flex: 1, flexWrap: 'wrap', width: width, justifyContent: 'space-evenly' }} >
                    {
                        addresses.map((address, index) => (
                            <View style={{
                                shadowOpacity: 7,
                                shadowRadius: 7,
                                shadowColor: esiColor.brandShadowColor,
                                borderRadius: 8,
                                borderWidth: 1,
                                height: 200,
                                // borderColor: "#27B6CC",
                                marginVertical: 2,
                                width: width / 4,
                                margin: 15,
                                // justifyContent: 'space-between',
                                flexDirection: 'row',
                                marginBottom: 20

                            }} key={"address" + index}  >
                                <View style={{ flexDirection: "column", marginLeft: 25 }}>
                                    <Text style={{ color: esiColor.brandFontColor, fontSize: 16, marginTop: 10, marginBottom: 10 }}>{address?.Name}</Text>
                                    <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{address?.Mobile}</Text>
                                    <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{address?.Door_Number},{address?.Street.substring(0, 30) + "..."} ,</Text>
                                    <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{address?.City},{address?.State},{address?.Country},</Text>
                                    <Text style={{ fontSize: 14, color: esiColor.itemColor }}>{address?.Zip}. </Text>

                                    {/* <View style={{ flexDirection: 'column', justifyContent: 'space-between', alignSelf: 'flex-end' }}> */}
                                    <View style={{ flexDirection: 'row', alignItems: 'flex-end', marginTop: 35, marginLeft: 110 }}>
                                        <View style={{ height: 30, width: 38, backgroundColor: esiColor.globalButtonColor, borderRadius: 8, marginRight: 10 }} >
                                            <Text style={{ textAlign: 'center', color: esiColor.itemButtenColor, fontSize: 10, marginVertical: 8 }} >{address?.Address_Type} </Text>
                                        </View>
                                        <View style={{ height: 30, width: 45, backgroundColor: address?.Is_Default === "1" ? esiColor.globalButtonColor : esiColor.ImgBck, borderRadius: 8 }} >
                                            {/* <Text style={{ color: address?.Is_Default === "1" ? esiColor.itemButtenColor : esiColor.itemButtenColor, fontSize: 10, textAlign: 'center', marginVertical: 8 }}> Default</Text> */}
                                            <Text style={{ color: address?.Is_Default === "1" ? esiColor.itemButtenColor : esiColor.itemButtenColor, fontSize: 10, textAlign: 'center', marginVertical: 8 }}> Default</Text>
                                        </View>

                                        <View style={{ flexDirection: "row" }}  >
                                            <IconButton color={esiColor.globalButtonColor} icon="square-edit-outline" size={25} style={{ height: 20, marginHorizontal: 'auto' }} onPress={() => {
                                                navigation.navigate('EditAddress', { data: address.User_Address_Id });
                                            }}>
                                            </IconButton>
                                            <IconButton color={esiColor.globalButtonColor} size={25} style={{ height: 20, marginHorizontal: 'auto' }} icon="delete" onPress={() => { bhandleClose(address) }}  >
                                            </IconButton>
                                        </View>
                                    </View>

                                    {/* </View> */}
                                </View>
                            </View>
                        ))
                    }
                    {isEmpty(addresses) &&
                        <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                            <Text style={{ fontSize: 13, marginTop: 10, fontWeight: 'bold', color: esiColor.itemColor, marginBottom: 15 }}>No Address Found!</Text>

                            <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 200 }} source={addressgif} />
                            <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                                <TouchableOpacity onPress={() => navigation.navigate('AddAddress')} style={style.button}>
                                    <Text style={{ fontSize: 15, color: esiColor.itemButtenColor, fontWeight: "bold" }}>+ ADD ADDRESS</Text>
                                </TouchableOpacity>
                                <View style={{ margin: 20 }} />
                                <TouchableOpacity onPress={() => {
                                    let formData = {
                                        Records_Filter: "FILTER",
                                        User_Email_Id: EmailID,
                                    };
                                    dispatch(GetAllAddressAction(formData))
                                }} style={style.button}>
                                    <Text style={{ fontSize: 15, color: esiColor.itemButtenColor, fontWeight: "bold", textAlign: 'center' }}>RELOAD</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    }</View>}
            </ScrollView>

        </Surface>
    );
}


export default Useraddresses