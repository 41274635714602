import * as React from 'react';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import { LogBox } from 'react-native';
import { Provider } from "react-redux";
import store from './src/state/store';
import { DefaultTheme, DarkTheme, Provider as PaperProvider } from 'react-native-paper';
import AuthLayer from './AuthLayer';

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { getToken_firebase, onMessageListener } from './src/firebase-web';

// enableLatestRenderer();
import * as Sentry from 'sentry-expo';
import { ToastProvider } from './src/self_modules/react-native-paper-toast/src';

Sentry.init({
  dsn: 'https://57b022fcf4d244d18172ad1c6f03524c@sentry.meepaisa.com/3',
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  tracesSampleRate: 1.0,
});


const App = () => {
  const colorScheme = useColorScheme();


  const [show, setShow] = React.useState(false);
  const [notification, setNotification] = React.useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = React.useState(false);
  getToken_firebase(setTokenFound);

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    // console.log(payload);
  }).catch((err: any) => console.log('failed: ', err));
  const isLoadingComplete = useCachedResources();
  LogBox.ignoreAllLogs(true);
  LogBox.ignoreLogs(['Remote debugger']);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <PaperProvider theme={colorScheme == "dark" ? DarkTheme : DefaultTheme}>
          <Provider store={store}>
            <ToastProvider>
              <AuthLayer colorScheme={colorScheme} />
              <StatusBar />
            </ToastProvider>
          </Provider>
        </PaperProvider>
      </SafeAreaProvider>
    );
  }
}





export default App;
serviceWorkerRegistration.register();
