import React, { useState, useEffect } from "react";
import { Avatar, Button, Card, Colors, IconButton, Surface, Text, TouchableRipple } from "react-native-paper";
import { Image, TouchableOpacity, View, Dimensions, ActivityIndicator, Linking, Platform, StyleSheet, FlatList, ImageBackground } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { useDispatch, useSelector } from "react-redux";
import { apiActions } from "../../state/actions";
import isEmpty from "../../state/validations/is-empty";
import SignIN from "../../screens/auth/signin";
import Modal from "react-native-modal";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
import { adduservisitAction, getProductSizeActionWishList, productsizereset } from "../../state/actions/productsActions";
import * as Network from 'expo-network';
import { addCart, addItemMultipleCart } from "../../state/actions/cartActionsNew";
import { getSingleCartItemCheck } from "../../state/actions/node-actions/home-actions";
import QuentityField from "./QuentityField";
import { Rating } from "react-native-ratings";
// import esiColor from "../../constants/Colors";


export default function ProductHCard(props: any) {
    // handle click event is used to go to next page with IDs
    // data is a Product object as we use this object to display the info front-end
    const { data, handleClick, navigation, ProductId, PartnerId } = props;
    const dispatch = useDispatch()
    const esiColor = useSelector(state => state.theme);

    const [selected, setSelected] = useState(false);
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const [checkWish, setCheckWish] = useState({});
    const [loading, setLoading] = useState(false);
    const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);
    const [openredirect, setRedirectionOpen] = React.useState(false);
    const onOpenModal = (data) => {
        let Url = data.Web_Redirection_Url;
        setTimeout(function () {
            Linking.openURL(Url);
            setRedirectionOpen(false);
        }, 2000);
    };
    const [imageURL, setImageURL] = useState(false);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [cartLoading, setCartLoading] = useState(false);
    const [productData, setProductData] = useState();

    const handleClickCart = async (data) => {
        if (EmailID) {
            if (data.Partner_Type === "Online") {
                setRedirectionOpen(true)
                onOpenModal(data);
                setImageURL(data.Logo_Path)
            }
            else {
                dispatch(getProductSizeActionWishList(data.Product_Id, data.Partner_Details_Id, data, sizeAddtoCart));
                setModalIsOpen(true);
                setCartLoading(true);
                setProductData(data);
            }
            let iPAddress = await Network.getIpAddressAsync();
            let formData = {
                User_Email_Id: EmailID,
                Ip_Address: iPAddress,
                Offer_Type: "Product",
                Offer_Id: data.Product_Id,
                Store_Type: data.Partner_Type,
                Store_Id: data.Partner_Details_Id,
                Status: "Active"
            }
            dispatch(adduservisitAction(formData));
        } else {
            setModalVisible(!isModalVisible);
        }
    };

    const [cartSize, setCartSize] = useState([]);
    const [cartErrorMessage, setCartErrorMessage] = useState("");

    const sizeAddtoCart = (status, response, partnerData, message) => {
        setCartLoading(false);
        if (status && !isEmpty(response)) {
            if (response.length > 1) {
                setCartSize(response);
            } else {
                let payload = {
                    "Partner_Details_Id": partnerData.Partner_Details_Id,
                    "Partner_Product_Id": partnerData.Partner_Product_Id,
                    "Quantity": "1",
                    "Size": response[0]?.Product_Size,
                    "User_Email_Id": EmailID
                }

                dispatch(addCart(payload, EmailID, checkCartUpdateResponse));
                dispatch(productsizereset());
            }
        } else {
            setCartErrorMessage(message);
        }
    }
    const [size, setSize] = useState({ size: "", quantity: "" });

    const onCloseModal = () => {
        setSize({ size: "", quantity: "" })
        setModalIsOpen(false);
        setSelected(false);
    };

    useEffect(() => {
        getWishList();
        checkCart();
    }, [EmailID, ProductId])
    const sampleData = {
        Brand_Id: "B-0000194",
        Brand_Image_Path: "http://api.easystepin.com:8092/uploads/ESICB/Brand/20210526/Brand_20210526051658_Sample.jpg",
        Brand_Name: "Adidas",
        CashBack_Product_Price: 2.4402,
        Category_Id: "C-0000319",
        Country_Of_Origin: "IN",
        Created_By_Email: "devi.neralla@easystepin.com",
        Created_Date: "20/07/2021",
        Created_User_Type: null,
        Discount: 2,
        Discount_From_Date: "06/08/2021",
        Discount_To_Date: "31/01/2022",
        Discount_Type: "P",
        Esi_CashBack_Price: 2.4402,
        Esi_Cashback: 2,
        Esi_Cashback_Type: "P",
        Esi_Rating: 4,
        Esi_Reviews: "<p>Awsome to buy this product. It's nyce</p>\n",
        Extra_Cashback: 1,
        Features: "<p>24/7 freshness with a joy.</p>\n",
        Gift_Wrap_Amount: 30,
        Is_Gift_Wrap_Available: 1,
        Is_Popular: 1,
        Is_Top_Brand: 1,
        Is_Top_Product: 0,
        Is_Upcoming: 0,
        Is_Verified: 0,
        Logo_Path: "http://api.easystepin.com:8092/uploads/ESICB/PartnerProfile/20210624/PartnerProfile_Banner_20210624101653_JK.jpg",
        Mobile_Redirection_Url: "https://www.amazon.in/Adidas-Pure-Spray-developed-Athletes/dp/B00BVO9N2A/ref=sr_1_2?crid=3H70RRH5FHF6C&dchild=1&keywords=adidas+perfume&qid=1626753666&sprefix=addidas+p%2Caps%2C399&sr=8-2",
        Modified_By: null,
        Modified_Date: null,
        Original_Price: 249,
        Partner_Details_Id: "P-D-0000215",
        Partner_Name: "Amazon",
        Partner_Price: 249,
        Partner_Product_Id: "P-P-0001555",
        Partner_Selling_Price: 244.02,
        Partner_Type: "Online",
        Product_Color_Variant_Id: "C-V-0000038",
        Product_Description: "<p>ADIDAS PURE GAME by Adidas for MEN EDT SPRAY 3.4 OZ (DEVELOPED WITH ATHLETES) Launched by the design house of Adidas in 2010, ADIDAS PURE GAME by Adidas possesses a blend of Grapefruit, Incense, And Patchouli.</p>\n",
        Product_Id: "P-0000614",
        Product_Image: "http://api.easystepin.com:8092/uploads/ESICB/ProductImage/20210720/ProductImage_20210720041241_BTS.jpg",
        Product_Master_Id: "P-M-0000113",
        Product_Name: "Adidas Pure Game By Adidas Edt Spray 3.4 Oz (developed With Athletes) (men)",
        Product_Short_Description: "Fragrance Notes: Grapefruit, Incense, And Patchouli.",
        Specifications: "<p>Long time flavour.<br>Spay like a boom.</p>\n",
        Status: "Active",
        Sub_Category_Id: "C-S-0000229",
        Web_Redirection_Url: "https://www.amazon.in/Adidas-Pure-Spray-developed-Athletes/dp/B00BVO9N2A/ref=sr_1_2?crid=3H70RRH5FHF6C&dchild=1&keywords=adidas+perfume&qid=1626753666&sprefix=addidas+p%2Caps%2C399&sr=8-2",
        Whats_Hot: 0,
    }

    const [cart, setCart] = useState({ User_Cart_Id: 0, Quantity: 0 });

    const checkCartResponse = (status, data) => {
        if (status) {
            setCart(data);
        }
    }

    const checkCartUpdateResponse = (data) => {
        checkCart();
    }

    const checkCart = () => {
        if (EmailID && data?.Partner_Product_Id) {
            let filter = {
                "Partner_Product_ID": data?.Partner_Product_Id,
                "User_Email_Id": EmailID,
            }
            dispatch(getSingleCartItemCheck(filter, {}, checkCartResponse))
        }
    }
    const getWishList = () => {
        setCheckWish({});
        if (EmailID && ProductId && PartnerId && !loading) {
            setLoading(true);
            let filter = {
                "Records_Filter": "FILTER",
                "User_Email_Id": EmailID,
                "Product_Id": ProductId,
                "Partner_Details_Id": PartnerId,
                "Route_Filter_Key": '2'
            }
            apiActions.get_wish_list(filter)
                .then(async res => {
                    setLoading(false);
                    let wish_data = res.Wish[0]?.Wish_List;
                    if (!isEmpty(wish_data)) {
                        if (wish_data.length > 0) {
                            setCheckWish(wish_data[0])
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }
    const onLikeClicked = (is_like) => {
        setLoading(true);
        if (!is_like) {
            if (isAuthenticated) {
                var data = {
                    "User_Email_Id": EmailID,
                    "Product_Id": ProductId,
                    "Partner_Details_Id": PartnerId
                }
                apiActions.create_wish_list(data)

                    .then(async res => {
                        setLoading(false);
                        setCheckWish({});
                        getWishList();
                    })
                    .catch(err => {
                        setLoading(false);
                    })
            } else {
                setModalVisible(!isModalVisible);
                setLoading(false);
            }

        } else {

            var data = {
                Wish_Id: checkWish?.Wish_Id
            }

            apiActions.delete_wish_list(data)
                .then(async res => {

                    setLoading(false);
                    getWishList();
                })
                .catch(err => {
                    setLoading(false);
                })

        }
    }
    const toast = useToast();
    const [isModalVisible, setModalVisible] = React.useState(false);

    const signInModalResponse = (status: any) => {
        setModalVisible(!isModalVisible);
        if (status) {
            onLikeClicked(false);
        }
    };

    const handleSelect = (id) => {
        if (id === selected) {
            setSize("")
        }
        setSelected(id !== selected ? id : "")
    };

    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    // const halfdimensions = dimensions.width >= 800 ? (dimensions.width / 2) : dimensions.width;
    const [isNotProductAvailable, setIsNotProductAvailable] = React.useState(false);


    const cartData = () => {
        setModalIsOpen(!modalIsOpen);
        setSelected(false);
        let payload = {
            "Partner_Details_Id": productData.Partner_Details_Id,
            "Partner_Product_Id": productData.Partner_Product_Id,
            "Quantity": "1",
            "Size": size.size,
            "User_Email_Id": EmailID
        }

        dispatch(addCart(payload, EmailID, checkCartUpdateResponse));

        dispatch(productsizereset());
        setSize({ size: "", quantity: "" });
        setProductData();
    }
    return (
        <Card style={{ maxWidth: 470, width: 470, borderWidth: 0.5, margin: 10, height: 175, backgroundColor: esiColor.CBColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 10, marginBottom: 15, marginLeft: 15, marginRight: 15 }}>
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 4, padding: 5, flexDirection: "column" }}>
                    <View style={{ flexDirection: "row" }} >

                    </View>
                    <Text style={{ fontWeight: "bold", marginTop: 7, color: esiColor.brandFontColor }}>{data?.Product_Name.slice(0, 50)}</Text>
                    <View style={{ flexDirection: "row", marginTop: 7 }}>
                        <Text style={{ paddingVertical: 3, fontSize: 13, color: esiColor.DescColor, textDecorationLine: 'line-through' }}>₹{data?.Original_Price.toFixed(2)}</Text>
                        <Text style={{ paddingVertical: 3, marginLeft: 5, color: esiColor.SIconColor, fontSize: 14, fontWeight: 'bold' }}>₹{parseFloat(data?.Partner_Selling_Price).toFixed(2)}
                        </Text>
                    </View>
                    <Rating
                        readonly={true}
                        defaultRating={data?.Esi_Rating ? parseInt(data?.Esi_Rating) : 0}
                        type='heart'
                        tintColor={esiColor.TintColor}
                        // ratingBackgroundColor='#c8c7c8'
                        startingValue={data?.Esi_Rating}
                        ratingCount={5}
                        imageSize={15}
                        style={{ alignSelf: "flex-start", marginTop: 7 }}
                        minValue={1}
                        // onFinishRating={(rating) => ratingCompleted(rating)}
                        name="rating"
                    />
                    <Text style={{ marginTop: 10, color: esiColor.DescColor, fontSize: 12.5 }}>
                        {data?.Product_Short_Description.slice(0, 150)}  <TouchableOpacity onPress={() => { handleClick(data) }} ><Text style={{ color: esiColor.readMoreColor, fontWeight : 'bold' }} >... read more</Text></TouchableOpacity>
                    </Text>
                </View>

                <View style={{ flex: 2, flexDirection: 'column', justifyContent: "center", width: '100%', padding: 5 }}>
                    {/* <View style={{
                        borderRadius: 8,
                        shadowOffset: { width: 0, height: 0 },
                        shadowOpacity: 0.2,
                        shadowColor: "#171717",
                        shadowRadius: 8,
                        // marginTop: 10
                    }}> */}

                    <ImageBackground style={{ backgroundColor: esiColor.ImgBck, height: 100, width: "100%", borderRadius: 8, marginBottom: 10, marginTop: 15, }}>
                        <Image source={{ cache: "force-cache", uri: data?.Product_Image }} style={{
                            height: 100, width: "100%", resizeMode: 'cover', 
                            borderRadius: 8
                        }}>
                        </Image>
                    </ImageBackground>
                    {/* </View> */}
                    {/* <View style={{ flexDirection: 'row', marginBottom:10 }}> */}
                    <View style={{ position: "absolute", right: 10, bottom: 10 }}>
                        <TouchableWithoutFeedback onPress={() => {
                            if (!loading)
                                onLikeClicked(Boolean(checkWish?.Wish_Id))
                        }}>
                            {loading ? <ActivityIndicator color='#27b6cc' size="small" /> :
                                <FontAwesome name={Boolean(checkWish?.Wish_Id) ? 'heart' : 'heart-o'} color='red' size={20} style={{ marginBottom: 10 }}></FontAwesome>}
                        </TouchableWithoutFeedback>
                        {/* </View> */}
                    </View>
                    {cart.User_Cart_Id ?
                        <View style={{ flexDirection: 'row', justifyContent: "center", alignSelf: "center", maxWidth: "50%", backgroundColor: esiColor.BackgroundColor, borderRadius: 8, borderColor: esiColor.SBorderColor, borderWidth: 0.5, marginBottom: 10, marginTop: 15, height: 25 }}>
                            <TouchableOpacity disabled={cart.Quantity <= 1 ? true : false} onPress={(e) => dispatch(addItemMultipleCart({
                                User_Cart_Id: cart.User_Cart_Id,
                                partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                            }, Number(cart.Quantity) - 1, checkCartUpdateResponse))} style={{ marginBottom: 15, height: 20 }}>
                                <MaterialIcons name="remove" size={22} color={esiColor.itemColor} />
                            </TouchableOpacity>
                            {
                                <QuentityField count={cart.Quantity} handleOnChangeCount={(count) => {
                                    dispatch(addItemMultipleCart({
                                        User_Cart_Id: cart.User_Cart_Id,
                                        partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                                    }, count, checkCartUpdateResponse))
                                }} />
                            }
                            <TouchableOpacity onPress={(e) => dispatch(addItemMultipleCart({
                                User_Cart_Id: cart.User_Cart_Id,
                                partnerProduct: { Partner_Product_Id: data?.Partner_Product_Id }
                            }, Number(cart.Quantity) + 1, checkCartUpdateResponse))} style={{ height: 20, minWidth: "30%", }}>
                                <MaterialIcons name="add" size={22} color={esiColor.itemColor} />
                            </TouchableOpacity>
                        </View> :
                        <TouchableOpacity onPress={() => {
                            if (data.Is_Open !== 1 || data.Is_Product_Available !== 1) {
                                setIsNotProductAvailable(!isNotProductAvailable);
                            } else {
                                handleClickCart(data);
                            }
                        }} style={{ alignSelf: "center", maxWidth: "40%", minWidth: "30%", marginTop: 2, marginBottom: 10 }} >
                            <View style={{ flexDirection: "row", justifyContent: "center", backgroundColor: esiColor.globalButtonColor, borderRadius: 20, paddingVertical: 5, paddingHorizontal: 20 }}>
                                <FontAwesome name={'cart-plus'} color={esiColor.itemButtenColor} size={20}></FontAwesome>
                            </View>
                        </TouchableOpacity>
                    }

                </View>
            </View>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isNotProductAvailable}>
                <View style={{ flex: 1, maxWidth: 300, minWidth: 200, minHeight: 250, maxHeight: 300, borderRadius: 10, backgroundColor: esiColor.BackgroundColor }}>

                    <View style={{ marginHorizontal: 10, justifyContent: "center", flexDirection: "column", height: "100%" }} >
                        <Text style={{ textAlign: "center", color: esiColor.DescColor, fontSize: 24 }} >
                            Oops..
                        </Text>
                        <Text style={{ marginVertical: 10, color: esiColor.DescColor }} >
                            Currently the partner is not accepting orders, Please click okay in order to check this product's availability in other stores.
                        </Text>
                        <View style={{ flexDirection: "row", justifyContent: "space-around" }} >
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    color="gray"
                                    mode="outlined"
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flex: 2, paddingHorizontal: 10 }} onPress={() => {
                                handleClick(data);
                                setIsNotProductAvailable(!isNotProductAvailable);
                            }}>
                                <Button
                                    color={esiColor.globalButtonColor}
                                    mode="contained"
                                    labelStyle={{ color: "#fff" }}
                                    onPress={() => {
                                        setIsNotProductAvailable(!isNotProductAvailable);
                                        handleClick(data);
                                    }}
                                >
                                    Okay
                                </Button>
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7 }}>

                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

                </View>
            </Modal>

            {/* Partner Redirection Modal */}
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={openredirect}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, maxHeight: 150, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7 }}>
                    <View style={{ alignItems: 'flex-end', marginTop: '-6%' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { setRedirectionOpen(false) }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor, marginTop: 30 }} size={36} icon="close" />
                        </TouchableRipple>
                    </View>
                    <View>
                        <Text style={{ color: "#27b6cc", textAlign: 'center', marginBottom: 10 }}>Prices may vary for this product. Redirecting to partner site. Please wait....</Text>
                    </View>
                    <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                            <Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} style={{ height: 50, width: 50, resizeMode: 'contain' }} />
                            <Button color="#27b6cc" icon="arrow-right-bold" style={{ size: 15, marginTop: 10 }} />
                            <Image source={{ cache: "force-cache", uri: imageURL }} style={{ height: 60, width: 60, resizeMode: 'contain' }} />
                        </View>
                    </View>
                </View>
            </Modal>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={modalIsOpen}>
                <View style={{
                    flex: 1, maxWidth: 500, minWidth: 300, minHeight: 200, maxHeight: 250, borderRadius: 10,
                    backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowOpacity: 7, shadowRadius: 7
                }}>

                    <View style={{ alignItems: 'flex-end' }}>
                        <TouchableRipple style={{ width: 36 }} onPress={() => { onCloseModal(); setCartErrorMessage(""); }} >
                            <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={30} icon="close" />
                        </TouchableRipple>
                    </View>
                    {
                        !isEmpty(cartSize) ?
                            <View>
                                <Text style={{ textAlign: "center", color: esiColor.DescColor }}>
                                    Select Size
                                </Text>

                                <View style={{ textAlign: "center", justifyContent: "space-evenly", flexDirection: 'row' }}>
                                    <FlatList
                                        // horizontal={true}
                                        showsHorizontalScrollIndicator={true}
                                        numColumns={cartSize.length}
                                        data={cartSize}
                                        contentContainerStyle={{ justifyContent: "space-around" }}
                                        renderItem={({ item, index, separators }) => (
                                            <View key={index}>
                                                <TouchableOpacity
                                                    style={selected === item.Product_Size ? styles.productDetailsSize : styles.productDetailsSizeSelect}
                                                    key={index}
                                                    onPress={() => { setSize({ size: item.Product_Size, quantity: item.Product_Available_Stock }); handleSelect(item.Product_Size) }}
                                                >
                                                    <View ><Text style={{ textAlign: "center", justifyContent: "center", marginTop: 16, color: "black" }} >{item.Product_Size}</Text>
                                                    </View>

                                                </TouchableOpacity>
                                                {
                                                    item.Product_Available_Stock ?
                                                        <Text >
                                                            {parseInt(item.Product_Available_Stock) < 11 ?
                                                                <Text style={styles.ribbon}>{item.Product_Available_Stock} Left</Text>
                                                                : null}
                                                        </Text> : null
                                                }
                                            </View>
                                        )}
                                    />
                                </View>

                                <View>
                                    <Button
                                        color={size.size && size.quantity ? esiColor.actionColor : "gray"}
                                        type="submit"
                                        mode="contained"
                                        disabled={size.size && size.quantity ? false : true}
                                        style={{ color: size.size && size.quantity ? "#000000" : "#fff", width: "50%" }}
                                        onPress={cartData}
                                    >
                                        Proceed
                                    </Button>
                                </View>
                            </View>
                            :
                            cartLoading ? <ActivityIndicator color='#27b6cc' /> :
                                <View style={{ flexDirection: 'row', justifyContent: 'center', backgroundColor: esiColor.BackgroundColor }}>
                                    {cartErrorMessage ? <Text style={{ color: "red" }} >{cartErrorMessage}</Text> :
                                        <View style={{ flexDirection: "column", alignItems: "center" }} >
                                            <Text style={{ fontSize: 16, fontWeight: "bold", color: esiColor.DescColor }} >Item added to the cart.</Text>
                                            <IconButton icon={"cart-check"} color={esiColor.itemButtenColor} size={60} />
                                            <TouchableOpacity onPress={() => {
                                                onCloseModal(); setCartErrorMessage("");
                                            }}>
                                                <Button
                                                    mode="contained"
                                                    color={esiColor.globalButtonColor}
                                                    labelStyle={{ color: esiColor.itemButtenColor }}
                                                    type="submit"
                                                    // style={{ : "#fff" }}
                                                    onPress={() => {
                                                        onCloseModal(); setCartErrorMessage("");
                                                    }}
                                                >
                                                    Done
                                                </Button>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View>
                    }
                </View>
            </Modal >
        </Card>

    );
}


const styles = StyleSheet.create({

    productDetailsSize: {
        width: 50,
        height: 50,
        backgroundColor: 'rgb(42, 175, 82)',
        borderRadius: 50,
        borderColor: '#c4c4da',
        borderWidth: 1,
        color: 'white',
        flexDirection: "column",
        marginHorizontal: 4

    },

    productDetailsSizeSelect: {
        width: 50,
        height: 50,
        backgroundColor: 'white',
        borderRadius: 50,
        borderColor: '#c4c4da',
        borderWidth: 1,
        flexDirection: "column",
        marginHorizontal: 4

    },

    ribbon: {
        backgroundColor: 'rgb(207, 152, 49)',
        color: '#dddeda',
        fontSize: 15,
        textAlign: "center"
    }


});