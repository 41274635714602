import React from 'react';
import { Rating } from 'react-native-ratings';
import { StyleSheet, Text, View, Platform, Dimensions } from 'react-native';
import { Avatar, Card, IconButton, Paragraph, } from 'react-native-paper';
import RenderHTML from "react-native-render-html";
import { Ionicons } from '@expo/vector-icons';
const { height, width } = Dimensions.get('window');

const EsiTestimonial = (props) => {
    // const window = Dimensions.get("window");
    // const screen = Dimensions.get("screen");
    // const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);

    //    item is Testimonial object as we use this object to display the info front-end
    const { data, ...rest } = props

    //    const sampleData:{
    //     Description: "Good app iam giving rating five",
    //     Profile_Pic_Path: "http://api.easystepin.com:8092/uploads/ESICB/User/20210811/User_20210811132753_test.jpg",
    //     Rating: "5",
    //     Testmonial_Id: "T-0000024",
    //     Title: "Hello app",
    //     User_Name: "rajesh rajie",
    //     }

    const regex = /(<([^>]+)>)/ig;
    // const result = data.Description.replace(regex, '');
    return (

        <Card style={{
            flex: 1,
            alignContent: 'center',
            marginHorizontal: 2, borderRadius: 25, padding: 5,
            margin: 'auto', backgroundColor: 'white',
            flexDirection: 'column', shadowColor: '#000',
            borderColor: "#CACFD2", borderWidth: 0.5,
            shadowOffset: { width: 0, height: 2 },
            marginTop: 50,
            shadowOpacity: 0.2,
            shadowRadius: 10,
            // paddingTop: 10,
            elevation: 10,
            marginLeft: "auto", marginRight: "auto",
            width: 750,
            // flexWrap: 'wrap'
            // maxHeight: 300,
            // position: 'relative',
        }}>
            <View style={styles.img} >
                <Avatar.Image size={100} source={{ uri: data?.Profile_Pic_Path }} />
            </View>
            <Card.Title style={styles.heading} title={data.Title} />

            <Card.Content>
            <Text> {data?.Description} </Text>

                <View style={styles.welcome}>
                    <Text style={{ fontWeight: "bold" }}>  {data.User_Name} </Text>
                </View>
                <View style={styles.rating}>
                    <Rating
                        readonly={true}
                        type='custom'
                        ratingBackgroundColor='#c8c7c8'
                        startingValue={data.Rating}
                        ratingCount={5}
                        imageSize={20}
                        onFinishRating={(rating) => ratingCompleted(rating)}
                    />
                    {props?.user && props?.user === data?.User_Email_Id ? (
                        <IconButton icon="pencil" size={30} onPress={props.editFunction} />

                    ) : (null)}
                </View>
                {/* <View style={{ top: -70, width: "70%" }}>
                    <RenderHTML
                        source={{ html: data?.Description }} />
                </View> */}
            </Card.Content>
        </Card>

    );
}

const styles = StyleSheet.create({

    img: {
        alignItems: 'center',
        marginTop: -50,
        resizeMode: 'contain'
    },
    welcome: {
        alignItems: 'center',
        paddingTop: 5,
        alignSelf: "auto",
        margin: "auto",

    },
    rating: {
        alignItems: 'center',
        paddingTop: 3,
    },
    heading: {
        justifyContent: 'center',
        fontWeight: 'bold',
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        margin: "auto",
        fontSize: 30
    },
    description: {
        width: 450,
        alignContent: "center",
        alignSelf: 'center',
        alignItems:'center',
        justifyContent:'center'
    }
})


export default EsiTestimonial;