import React, { Fragment, useEffect, useState } from "react";
import { Text, View } from "react-native";
import ProductCard from "../../components/custom/product-card";
import noProdImg from "../../assets/images/GIF/noProdFoundImg.png";

export default function ProductsF({ productfilter, handleProductClick, navigate }) {

    useEffect(() => {
    })
    const [wishdata, setWishData] = React.useState(false);

    return (
        productfilter.length > 0 ?
            productfilter.map((data, index) => (
                <View key={data, index}>
                    <ProductCard data={data} navigate={navigate} handleClick={handleProductClick} setData={setWishData} ProductId={data?.Product_Id} PartnerId={data?.Partner_Details_Id} />
                </View>
            )) : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 30 }}>
                <Image style={{ resizeMode: 'contain', width: 400, height: 250, alignSelf: 'center' }} source={noProdImg} />

                <Text>No Products found!</Text>
            </View>

    );
}
