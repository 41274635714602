import React, { useEffect } from "react";
import { Dimensions, FlatList, StyleSheet, View } from "react-native";
import { Searchbar, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/custom/pagination/pagination";
import ProductHCard from "../../components/custom/product-h-card";
import noProdImg from "../../assets/images/GIF/noProdFoundImg.png";
import { getProductsAction } from "../../state/actions/node-actions/home-actions";

export default function PHProducts(props) {
    const { navigation, partnerId } = props;
    const dispatch = useDispatch();
    const [wishdata, setWishData] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const esiColor = useSelector(state => state.theme);

    const [loading, setLoading] = React.useState(false);
    const [allproducts, setAllproducts] = React.useState([]);

    const [pageOld, setPageOld] = React.useState(false);
    const [searchBarOpen, setSearchBarOpen] = React.useState(false);

    const callBackGetData = async (status, data, pagination) => {
        setLoading(false);
        if (status) {
            setAllproducts(data);
            setPageOld(true);
            if (pagination) {
                setPagination(pagination);
            } else {
                setPagination({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });
            }
        }
    }

    const [search, setSearch] = React.useState("");
    const [pagination, setPagination] = React.useState({ PageNo: 1, PageSize: 50, SortBy: "PRODUCT_ID", SortOrder: "DESC" });

    useEffect(() => {
        if (pageOld) {
            setPageOld(!pageOld)
        } else {
            productsCall();
        }
    }, [partnerId, pagination, search]);

    const productsCall = () => {
        if (!loading && partnerId) {
            setLoading(true);
            dispatch(getProductsAction({ "Records_Filter": "FILTER", "Status": "Active", "condition": true, "Partner_Details_Id": [partnerId], "Search": search }, pagination, callBackGetData));
        }
    }

    const handleClick = async (item) => {
        navigation.navigate('ProductDetails', { masterId: item.Product_Master_Id, Product_Id: item.Product_Id, Partner_Details_Id: item.Partner_Details_Id, wishdata: wishdata, back: "PartnerProducts" })
    };

    return (
        <View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', width: "100%", alignItems: 'flex-end' }}>

                <Searchbar iconColor={esiColor.SIconColor} style={{
                    marginHorizontal: 10,
                    borderColor: esiColor.SBorderColor, borderWidth: 1, flex: 1, backgroundColor: esiColor.BackgroundColor,
                    minWidth: "25%", maxWidth: "25%",
                    maxHeight: 30, marginBottom: 15, alignSelf: 'flex-end',
                    right: 0
                }}
                    inputStyle={{ fontSize: 12 }}
                    value={search}
                    onChangeText={(value) => {
                        setSearch(value);
                    }}
                    theme={{ colors: { text: esiColor.DescColor } }}
                    placeholderTextColor={esiColor.SPHTextColor}
                    multiline={false}
                    placeholder="Search products"
                />

            </View>

            <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'center', width: '100%', backgroundColor: esiColor.BackgroundColor }}>
                {allproducts.length <= 0 ? (
                    <View style={{ width: 700, flex: 1, justifyContent: "center" }}>
                        <Image style={{ resizeMode: 'contain', width: 400, height: 250, alignSelf: 'center' }} source={noProdImg} />
                        <Text style={{ alignSelf: "center", color: esiColor.NoDataFound }} >No Products found!</Text>
                    </View>
                ) : (<FlatList
                    contentContainerStyle={{ width: '100%', justifyContent: 'space-evenly', flexWrap: 'wrap' }}
                    data={allproducts}
                    numColumns={3}
                    renderItem={({ item, index, separators }) => (
                        <ProductHCard data={item} navigation={navigation.navigate} handleClick={handleClick} setData={setWishData} ProductId={item?.Product_Id} PartnerId={item?.Partner_Details_Id} />
                    )}
                />
                )}

            </View>
            <View style={{ marginBottom: 5 }}>
                <Pagination pagination={pagination} setPagination={setPagination} />
            </View>
        </View>
    )
}
