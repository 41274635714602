import * as React from 'react';
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import SortByBrands from './brands';
import SortByCategories from './categories';
import SortByDiscount from './discount';
import SortByExtraCashback from './extra-cashback';
import SortByPage from './main';
import SortByPartners from './partners';
import SortByPrice from './price';
import isEmpty from '../../state/validations/is-empty';
import { useDispatch, useSelector } from 'react-redux';
import { changeMainData } from '../../state/actions/filterServiceActions';
// import esiColor from '../../constants/Colors';

export default function EsiDrawer(props) {
  const dispatch = useDispatch();
  const esiColor = useSelector(state => state.theme);

  let sortByOptionsInit = useSelector((state) => state.filters.main);
  const checkChildChecked = (data: any) => {
    let count = 0;
    data.map((item: any) => {
      if (item.checked === "checked") {
        count++
      }
    })
    return (count === data.length && data.length > 0) ? 'checked' : count === 0 ? "unchecked" : "indeterminate";
  }

  React.useEffect(() => {
    const parmas = props.state.routes[0].params;

    if (parmas) {
      if (parmas.page) {
        let response = JSON.parse(JSON.stringify(sortByOptionsInit));
        if (parmas.page === "coupons") {

          if (parmas.filter === "Verified") {
            response[1].children.map((item: any) => {
              if (item.value === 'Verified') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Exclusive") {
            response[1].children.map((item: any) => {
              if (item.value === 'Exclusive') {
                item.checked = "checked";
              }
            })
          } else if (parmas.filter === "Is_Top_Coupon") {
            response[1].children.map((item: any) => {
              if (item.value === 'Is_Top_Coupon') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Whats_Hot") {
            response[1].children.map((item: any) => {
              if (item.value === 'Whats_Hot') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Is_Upcoming") {
            response[1].children.map((item: any) => {
              if (item.value === 'Is_Upcoming') {
                item.checked = "checked";
              }
            })
          } else {
            response[1].children.map((item: any) => {
              item.checked = "checked";
            })
          }
          response[0].children.map((item: any) => {
            item.checked = "unchecked";
          })
          response[2].children.map((item: any) => {
            item.checked = "unchecked";
          })
          dispatch(changeMainData(response));
        } else if (parmas.page === "deals") {
          response[0].children.map((item: any) => {
            item.checked = "unchecked";
          })
          response[1].children.map((item: any) => {
            item.checked = "unchecked";
          })
          if (parmas.filter === "Verified") {
            response[2].children.map((item: any) => {
              if (item.value === 'Verified') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Exclusive") {
            response[2].children.map((item: any) => {
              if (item.value === 'Exclusive') {
                item.checked = "checked";
              }
            })
          } else if (parmas.filter === "Is_Top_Deal") {
            response[2].children.map((item: any) => {
              if (item.value === 'Is_Top_Deal') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Whats_Hot") {
            response[2].children.map((item: any) => {
              if (item.value === 'Whats_Hot') {
                item.checked = "checked";
              }
            })
          } else {
            response[2].children.map((item: any) => {
              item.checked = "checked";
            })
          }
          dispatch(changeMainData(response));
        } else if (parmas.page === "products") {

          if (parmas.filter === "all") {
            response[0].children.map((item: any) => {
              item.checked = "checked";

            })
          }
          else if (parmas.filter === "Is_Popular") {
            response[0].children.map((item: any) => {
              if (item.value === 'Verified') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Is_Top_Product") {
            response[0].children.map((item: any) => {
              if (item.value === 'Exclusive') {
                item.checked = "checked";
              }
            })
          } else if (parmas.filter === "Whats_Hot") {
            response[0].children.map((item: any) => {
              if (item.value === 'Is_Top_Deal') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Is_Upcoming") {
            response[0].children.map((item: any) => {
              if (item.value === 'Whats_Hot') {
                item.checked = "checked";
              }
            })
          }
          else if (parmas.filter === "Is_Verified") {
            response[0].children.map((item: any) => {
              if (item.value === 'Whats_Hot') {
                item.checked = "checked";
              }
            })
          }

          response[2].children.map((item: any) => {
            item.checked = "unchecked";
          })
          response[1].children.map((item: any) => {
            item.checked = "unchecked";
          })
          dispatch(changeMainData(response));
        }
      }
    }
    else {
      let isChecked = false;
      if (checkChildChecked(sortByOptionsInit[0].children) === 'checked' || checkChildChecked(sortByOptionsInit[0].children) === 'indeterminate') {
        isChecked = true;
      } else if (checkChildChecked(sortByOptionsInit[1].children) === 'checked' || checkChildChecked(sortByOptionsInit[1].children) === 'indeterminate') {
        isChecked = true;
      } else if (checkChildChecked(sortByOptionsInit[2].children) === 'checked' || checkChildChecked(sortByOptionsInit[2].children) === 'indeterminate') {
        isChecked = true;
      }
      if (!isChecked) {
        let response = JSON.parse(JSON.stringify(sortByOptionsInit))
        response[0].children.map((item: any) => {
          if (item.value === 'Is_Top_Product') {
            item.checked = "checked";
          }
        })
        dispatch(changeMainData(response));
      }
    }
  }, [props.state.routes[0].params]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: esiColor.BackgroundColor
    }
  });
  

  return (
    <View style={styles.container}>
      <ScrollView style={{ marginTop: Platform.OS === 'web' ? 0 : 40 }} >
        <SortByPage {...props} />
        <SortByPartners {...props} />
        <SortByCategories {...props} />
        <SortByBrands {...props} />
        <SortByExtraCashback {...props} />
        <SortByDiscount {...props} />
        {(checkChildChecked(sortByOptionsInit[0].children) === 'checked' || checkChildChecked(sortByOptionsInit[0].children) === 'indeterminate') &&
          <SortByPrice {...props} />}
      </ScrollView>
    </View>
  );
}

