import React from "react";
import { View, TouchableOpacity, Dimensions, ImageBackground } from "react-native";
import { Avatar, Colors, Text, TouchableRipple } from "react-native-paper";
import { useSelector } from "react-redux";

export default function ESIStoreTypeCard(props: any) {
  // data is a Coupon object as we use this object to display the info front-end
  // toggleModal param is used to call the Coupon details popup screen
  const { setOpen, navigation, data, closeModal, toggleModal, ...rest } = props
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });
  const closeModal1 = (data) => {
    setOpen(false)
    navigation.navigate('Category', { Store_Type_Name: data?.Store_Type_Name, type: "store" })
  };
  const closeModal2 = (data) => {
    setOpen(false)
    navigation.navigate('Partners', { Store_Type: data?.Store_Type_Name })
  };
  return (
    <View style={{ flex: 1, maxHeight: 150, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7, minWidth: 300, justifyContent: 'center', alignItems: 'center', borderRadius: 7 }}>
      <TouchableRipple style={{ position: "absolute", right: 5, top: 5 }} onPress={closeModal}>
        <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={25} icon="close" />
      </TouchableRipple>
      <TouchableOpacity onPress={() => { closeModal2(data) }} style={{ marginBottom: 10, backgroundColor: esiColor.globalButtonColor, borderRadius: 10, paddingVertical: 5, paddingHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Stores</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => { closeModal1(data) }} style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 10, paddingVertical: 5, paddingHorizontal: 10, height: 30, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: esiColor.itemButtenColor, fontWeight: 'bold' }}>Categories</Text>
      </TouchableOpacity>
    </View>
  );
}
