import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { View } from 'react-native';
import { TextInput } from 'react-native-paper';
import esiColor from '../../constants/Colors';
import { useSelector } from 'react-redux';

const ESITextInput = (props: any) => {
    // props variable consist all react text field properties along with valueUpdate function.
    // valueUpdate function will return onChange value of date field.

    // valueUpdate function is a custom property as it dont have in react native textfield properties.
    const { valueUpdate, ...rest } = props
    const esiColor = useSelector(state => state.theme);

    return (
        <TextInput
        {...props}
        value={(props?.value || "") + ""}
        selectionColor={esiColor.TextSelection}
        theme={{
            colors: {
                primary: esiColor.TextHighlight,
                text:esiColor.Text,
                placeholder: esiColor.SPHTextColor
            }
        }}
        outlineColor={esiColor.SPHTextColor}
        underlineColor={esiColor.SPHTextColor}
        style={[props?.style, { backgroundColor: esiColor.TextBC }]}
    />

    );
};

export default ESITextInput;