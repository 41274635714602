import React, { Fragment, useEffect } from "react";
import { Dimensions, FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
// import { ScrollView } from "react-native-gesture-handler";
import { Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ESICopyCouponModel from "../../components/custom/copycode-modal";
import ESICouponsCard from "../../components/custom/coupon-card";
// import isEmpty from "../../state/validations/is-empty";
import SignIN from "../auth/signin";
import Modal from "react-native-modal";
import { resetMainData } from "../../state/actions/filterServiceActions";
import { getCouponsAction } from "../../state/actions/node-actions/home-actions";
import { useToast } from "../../self_modules/react-native-paper-toast/src";
// import esiColor from "../../constants/Colors";

export default function MeeCoupons(props) {
    const { navigation, dataReload, dataErrorReload } = props;
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const window = Dimensions.get("window");
    //open param is used to open the Copy Code Modal
    const [open, setOpen] = React.useState(false);
    //open param is used to assign the data to Copy Code Modal
    const [couponData, setCouponData] = React.useState('');
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const data = props?.route?.params;
    const [couponLoading, setCouponLoading] = React.useState(false);
    const [coupon, setCoupon] = React.useState([]);

    // Api Calls

    const callBackGetCouponData = async (status, data) => {
        setCouponLoading(false);
        if (status) {
            setCoupon(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            couponsCall();
        } else {
            await delay(dataErrorReload);
            couponsCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        couponsCall();

    }, []);

    const couponsCall = () => {
        setCouponLoading(true);
        let formData = {
            Records_Filter: "FILTER"
        }
        dispatch(getCouponsAction(formData, { PageNo: 1, PageSize: 20, SortBy: "COUPON_ID", SortOrder: "DESC" }, callBackGetCouponData));
    }

    // Tost message code.
    const toast = useToast();

    // Tost message code.
    const dropDownAlertFun = (type, title, sub_title) => {
        toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
    }

    // Condition for coupons expired before 3 days only will display at UI
    const liveData = (inputData: any) => {
        let couponlivedata = [];
        var currentdate = new Date(),
            date = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
        if (inputData) {
            couponlivedata = inputData.filter(coupon => {
                let currentDate = new Date(date);
                let couponExpDate = new Date(coupon.Valid_Through_Date.split("/").reverse().join("-"));
                return Math.floor((currentDate - couponExpDate) / (1000 * 60 * 60 * 24)) < 3
            })
        } else {
            couponlivedata = []
        }
        return couponlivedata;
    }


    const signInModalResponse = (status) => {
        setModalVisible(!isModalVisible);
    };
    const openModal = (data) => {
        setCouponData(data)
        setOpen(true);
    };
    const toggleModal = async (data) => {
        if (isAuthenticated) {
            openModal(data)
        }
        else {
            setModalVisible(!isModalVisible);
        }
    }
    const closeModal = () => {
        setOpen(!open);
        setCouponData("")
    };
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
    });

    const styles = StyleSheet.create({

        content: {
            // borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            margin: 5,
            height: 375,
            shadowColor: esiColor.brandShadowColor,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            // elevation: 10,
            marginTop: 15,
            marginBottom: 15,
            marginLeft: 15, marginRight: 15
        },
    
    });
    

    return (liveData(coupon).length > 0 &&
        <Surface style={{ backgroundColor: esiColor.CBColor }}>

            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', marginBottom: 10, marginTop: 10, marginLeft: 15 }}> Mee Coupons</Text>

            <View style={styles.content}>

                <FlatList
                    style={{ marginLeft: 10, marginTop: 25 }}

                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    data={liveData(coupon).slice(0, 10)}
                    renderItem={({ item, index, separators }) => (
                        <ESICouponsCard data={item} toggleModal={toggleModal} />
                    )}
                />
                <TouchableOpacity onPress={(e) => {
                    dispatch(resetMainData());
                    navigation('Main', { screen: 'All', params: { page: 'coupons', } })
                }
                } style={{ position: 'absolute', right: 10, marginTop: 15, marginBottom: 15 }}>
                    <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold' }}>View All</Text>
                </TouchableOpacity>
            </View>

            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={isModalVisible}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 480, maxHeight: 500, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />
                </View>
            </Modal>
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={open}>
                <View style={{ flex: 1, maxWidth: 500, minWidth: 300, minHeight: 350, maxHeight: 400, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor, shadowRadius: 7 }}>
                    <ESICopyCouponModel navigation={navigation} openModal={openModal} data={couponData} closeModal={closeModal} />
                </View >
            </Modal>
        </Surface>
    )

}

