import React, { useEffect } from 'react';
import {
    createProfileAction,
    getUserProfileAction,
} from "../../state/actions/profileActions";
import { getUserUPIAction } from "../../state/actions/userupiActions";
import Header from '../header';
import { getLogedInUser } from "../../state/actions/authActions";
import { Text, Button, Surface } from 'react-native-paper';
import { StyleSheet, View, ScrollView, Dimensions, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from './ChangePassword';
import Profile from './profile';
import Account from './Account';
import isEmpty from '../../state/validations/is-empty';

const ProfileDetails = (props) => {
    const { navigation, profiletemp, callgetuser, ...rest } = props
    const [userProfile, setUserProfile] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [profileExist, setProfileExist] = React.useState(false);
    const [profileResponse, setProfileResponse] = React.useState(false);
    const [tempUserEmail, setTempUserEmail] = React.useState("");
    const dispatch = useDispatch();
    const esiColor = useSelector(state => state.theme);

    //User contains the data from API's using state call
    const user = useSelector((state) => state.auth.user);

    //dispatch is used to perform the API action
    useEffect(() => {
        if (tempUserEmail != user?.Email_Id) {
            setTempUserEmail(user?.Email_Id);
            onRefresh();
        } else {
            setSeconds(2);
        }

    }, [user, tempUserEmail]);

    const onRefresh = () => {
        setUserProfile(null);
        let data = {
            Email_Id: user?.Email_Id,
            Records_Filter: "FILTER",
        };
        dispatch(getUserProfileAction(updateStates, data));
        let formData = {
            Records_Filter: "FILTER",
            USER_EMAIL_ID: user?.Email_Id,
        };
        dispatch(getUserUPIAction(formData));
    };

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (isEmpty(user) && seconds === 1) {
                navigation.navigate("Home");
            }
            seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);

        }, 1000)
        return () => clearTimeout(timer)
    }, [seconds]);


    const [secondsT, setSecondsT] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (secondsT === 1) {
                callGetUser();
            }
            secondsT > 0 ? setSecondsT(secondsT - 1) : setSecondsT(-1);

        }, 1000)
        return () => clearTimeout(timer)
    }, [secondsT]);

    const updateStates = (data, loading, isError) => {
        if (isError) {
            setSecondsT(15);
        } else {
            if (Number(data.Success_Response ? data.Success_Response.Is_Data_Exist : 0) > 0 || Number(data.Is_Data_Exist ? data.Is_Data_Exist : 0) > 0) {
                setProfileExist(true);
                setUserProfile(data.User_Details[0]);
                setLoading(false);
                //window.scrollTo(0, 0);
            } else {
                createNewProfile();
            }

        }
    };


    const createNewProfile = () => {
        setLoading(true);
        let formData = {
            "Created_By": user?.Email_Id,
            "Dob": "",
            "Email_Id": user?.Email_Id,
            "Facebook_Login_Id": "",
            "First_Name": "",
            "Gender": "",
            "Google_Login_Id": "",
            "Id_Proof_Number": "",
            "Id_Proof_Type": "",
            "Last_Name": "",
            "Mobile": "",
            "Profile_Pic_Path": "",
            "Status": "Active"
        }
        dispatch(createProfileAction(updateStatesCreate, formData));
    };

    const callGetUser = () => {
        setLoading(true);
        let data = {
            Email_Id: user?.Email_Id,
            Records_Filter: "FILTER",
        };
        dispatch(getUserProfileAction(updateStates, data));
        dispatch(getLogedInUser(user?.Email_Id, false, () => { }, user?.User_Session_Id));
    };

    const updateStatesCreate = (data, loading, isError) => {
        if (isError) {
            createNewProfile();
        } else {
            if (data.Response_Status == "Failure") {
                // setProfileResponse("Please contact administrator,Unable featch profile.");
            } else {
                callGetUser();
                setLoading(false);
            }
        }
    };

    //dimensions
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const [screenType, setScreenType] = React.useState("profile");

    return (
        <Surface style={{
            flex: 1, backgroundColor: esiColor.BackgroundColor, position: Platform.OS === 'web' ? 'absolute' : null,
            top: Platform.OS === 'web' ? 0 : null, left: Platform.OS === 'web' ? 0 : null,
            right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null,
        }}>
            <Header {...props} />
            {user &&
                <ScrollView>
                    {screenType === "password" &&
                        <ChangePassword
                            loading={loading}
                            setLoading={setLoading}
                            setScreenType={setScreenType}
                        />
                    }
                    {screenType === "profile" && <>
                        <Profile
                            profiletemp={userProfile} callgetuser={callGetUser}
                            loading={loading}
                            setLoading={setLoading}
                        />
                        {profileResponse ?
                            <Text> {profileResponse} </Text> : <Account profiletemp={userProfile} callgetuser={callGetUser}
                                loading={loading}
                                setLoading={setLoading}
                                setScreenType={setScreenType}
                            />}</>}
                </ScrollView>
            }
        </Surface>
    );
};

export default ProfileDetails;