
import React from 'react';
import { Image, Text, View, StyleSheet, ImageBackground } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { Surface } from 'react-native-paper';
import { Linking } from 'react-native';
import { useSelector } from 'react-redux';

const Footer = (props) => {
    const { navigation } = props;
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const esiColor = useSelector(state => state.theme);

    return (
        <View>
            <Surface style={{
                flex: 1, backgroundColor: esiColor.CBColor,
                shadowColor: esiColor.brandShadowColor,
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.2,
                shadowRadius: 10,
            }}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: 10 }}>
                    <View style={{ flexDirection: 'column' }} >

                        <Image source={{ uri: require('../assets/images/img/logo/logo.png') }} style={{ height: 50, width: 100, resizeMode: 'contain' }}></Image>

                        <Text style={{ fontSize: 15, color: esiColor.itemColor, fontWeight: 'bold' }}>
                            Download Mobile App from
                        </Text>
                        <View style={{ flexDirection: 'row', width: 200, justifyContent: 'flex-start' }}>
                            <TouchableOpacity onPress={() => Linking.openURL("https://apps.apple.com/in/app/meepaisa/id1629144663").catch((err) =>
                                console.error('An error occurred', err),
                            )}>
                                <Image source={require('../assets/images/app_store_download.png')} style={{ height: 50, width: 80, resizeMode: 'contain', margin: 2 }} />
                            </TouchableOpacity>


                            <TouchableOpacity onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.user.meepaisa").catch((err) =>
                                console.error('An error occurred', err),
                            )}>
                                <Image source={require('../assets/images/play_store_download.png')} style={{ height: 50, width: 80, resizeMode: 'contain', margin: 2 }} />
                            </TouchableOpacity>

                        </View>
                        <Text style={{ fontSize: 15, marginBottom: 5, color: esiColor.itemColor, fontWeight: 'bold' }}>
                            Follow Us
                        </Text>
                        <View style={{ flexDirection: 'row', margin: 5 }}>
                            <TouchableOpacity onPress={() => Linking.openURL("https://www.facebook.com/easystepin")} >
                                <Image source={{ uri: require('../assets/images/icons/facebook.jpg') }} style={{ height: 25, width: 25, shadowRadius: 10, marginRight: 5 }}></Image>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => Linking.openURL("https://www.youtube.com/watch?v=LEF4YDYoDM8")}>
                                <Image source={{ uri: require('../assets/images/icons/youtube.png') }} style={{ height: 27, width: 28, shadowRadius: 10, marginTop: -2, marginRight: 5 }}></Image>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => Linking.openURL("https://www.instagram.com/easystepin_/")}>
                                <Image source={{ uri: require('../assets/images/icons/insta.png') }} style={{ height: 25, width: 25, shadowRadius: 10, marginRight : 5 }}></Image>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => Linking.openURL("https://twitter.com/EasyStepIn/")}>
                                <Image source={{ uri: require('../assets/images/icons/twitter.png') }} style={{ height: 25, width: 25, shadowRadius: 10, marginRight : 5 }}></Image>
                            </TouchableOpacity>

                            <TouchableOpacity onPress={() => Linking.openURL("https://ca.linkedin.com/company/easystepin")}>
                                <Image source={{ uri: require('../assets/images/icons/linkedin.png') }} style={{ height: 25, width: 25, shadowRadius: 10}}></Image>
                            </TouchableOpacity>

                        </View>

                    </View>
                    <View style={{ flexDirection: 'column' }} >

                        <Text style={{ fontSize: 20, marginBottom: 15, marginTop: 10, color: esiColor.FHColor, fontWeight: 'bold' }}>
                            ABOUT
                        </Text>
                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('AboutUs')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 2 }} >
                                About Us
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('ContactUs')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 2 }} >
                                Contact Us
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('faq')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 2 }} >
                                FAQ's
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('ReviewsAndRatings')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 2 }} >
                                Testimonials
                            </Text>
                        </TouchableOpacity>

                    </View>

                    <View style={{ flexDirection: 'column' }} >

                        <Text style={{ fontSize: 20, marginBottom: 15, marginTop: 10, color: esiColor.FHColor, fontWeight: 'bold' }}>
                            RESOURCES
                        </Text>
                        {
                            (isAuthenticated) &&

                            <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('earn')}>

                                <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                    Refer & Earn
                                </Text>
                            </TouchableOpacity>
                        }

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('BecomeourPartner')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                Become Our Partner
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Advertise')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                Advertise With Us
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('HowCashbackWorks')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                How meepaisa works?
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('NewsAndEvents')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                News & Events
                            </Text>
                        </TouchableOpacity>

                    </View>

                    <View style={{ flexDirection: 'column' }} >

                        <Text style={{ fontSize: 20, marginBottom: 15, marginTop: 10, color: esiColor.FHColor, fontWeight: 'bold' }}>
                            POLICY
                        </Text>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('TermsAndConditions')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                Terms & Conditions
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('PrivacyPolicy')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                Privacy Policy
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('AntiSpamPolicy')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                Anti-Spam Policy
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('ReturnsCancellations')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3, marginBottom: 10 }} >
                                Returns & Cancellations
                            </Text>
                        </TouchableOpacity>

                    </View>

                    <View style={{ flexDirection: 'column' }} >

                        <Text style={{ fontSize: 20, marginBottom: 15, marginTop: 10, color: esiColor.FHColor, fontWeight: 'bold' }}>
                            MEEPAISA
                        </Text>

                        <TouchableOpacity onPress={() => navigation.navigate('Category')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                All Categories
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Brands')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                All Brands
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.item_styles} onPress={() => navigation.navigate('Partners')}>
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                All Partners
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => navigation.navigate('Main', { screen: 'All', params: { page: 'coupons', filter: 'all' } })}  >
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                All Coupons
                            </Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => navigation.navigate('Main', { screen: 'All', params: { page: 'deals', filter: 'all' } })}  >
                            <Text style={{ fontSize: 15, color: esiColor.itemColor, margin: 3 }} >
                                All Deals
                            </Text>
                        </TouchableOpacity>

                    </View>
                </View>

            </Surface>

            <View style={{
                backgroundColor: esiColor.CBColor, borderTopColor: esiColor.SBorderColor,
                shadowColor: esiColor.brandShadowColor,
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.2,
                shadowRadius: 10,
            }}>
                <Text style={{ textAlign: 'center', color: esiColor.itemColor, marginTop: 15, marginBottom: 15 }}>
                    Copyright © 2016 - 2023 EasyStepIn. All Rights Reserved.
                </Text>
            </View>
        </View>

    )

};


export default Footer;

const styles = StyleSheet.create({
    item_styles: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    // separatorm: {
    //     height: 0.5,
    //     backgroundColor: "#1a1a1a",
    // },
});
