import { getAllContentManagementStaticAction } from "../../state/actions/contentActions";
import React, { useState, useEffect, useMemo } from 'react';
import RenderHTML from "react-native-render-html";
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView, Dimensions, Image, TouchableOpacity, Linking, Platform, ImageBackground, } from 'react-native';
import { Text, Surface, ActivityIndicator, Card } from 'react-native-paper';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import Footer from "../footer";
// import Map from "../../components/custom/MapView/MapView";


export default function ContactUs(props) {
    const { navigation } = props;
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    const dispatch = useDispatch();
    const [contentsOne, setContentsOne] = useState({});
    const MY_URL = 'https://www.google.com/maps/place/EasyStepIn+IT+Services+Private+Limited/@12.9560352,77.6945393,19z/data=!4m13!1m7!3m6!1s0x3bae13cc14d607cb:0x5eaf25d9f64c2215!2sEasyStepIn+IT+Services+Private+Limited!3b1!8m2!3d12.9560347!4d77.6950702!3m4!1s0x3bae13cc14d607cb:0x5eaf25d9f64c2215!8m2!3d12.9560347!4d77.6950702'
    const responseFunction = (data) => {
        setContentsOne(data)
    }
    useEffect(() => {
        if (isEmpty(contentsOne)) {
            let formdata = {
                "Name": "ContactUs",
                "Records_Filter": "FILTER"
            }
            dispatch(getAllContentManagementStaticAction(formdata, responseFunction));
        }
    }, [contentsOne]);

    const [pos, setPos] = React.useState(0);

    return (
        <Surface style={{ flex: 1 }}>
            <Header {...props} />
            <ScrollView>
                <View style={{ alignItems: 'center' }} >
                    <Text style={{ fontSize: 24, marginBottom: 10, fontWeight: "bold", textAlign: 'center', color: '#27b6cc', justifyContent: 'center' }}>Contact Us</Text>
                    <Image source={require('../../assets/images/cms.png')} style={{ padding: 3, minHeight: 110, maxHeight: 120, width: 350, borderRadius: 10 }} />
                    <View style={{
                        // flex: 1,
                        marginHorizontal: 2, borderRadius: 25, padding: 5, margin: 'auto', backgroundColor: 'white', flexDirection: 'column', shadowColor: '#000', borderColor: "#CACFD2", borderWidth: 0.5,
                        shadowOffset: { width: 0, height: 2 },
                        marginTop: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                        shadowOpacity: 0.2,
                        shadowRadius: 10,
                        paddingTop: 5,
                        width: 350,
                        maxHeight: 350,
                        alignSelf: 'center',

                    }}>
                        {/* <View style={{ flexDirection: 'column', flexWrap: "wrap" }}>
                        <View style={styles.containerStyle}>
                         
                            <FormBuilder
                                control={control}
                                formConfigArray={[
                                    {
                                        type: 'text',
                                        name: 'name',

                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'Name is required',
                                            },
                                        },
                                        textInputProps: {
                                            label: 'Name',
                                        },
                                    },
                                    {
                                        type: 'email',
                                        name: 'email',

                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'Email is required',
                                            },
                                        },
                                        textInputProps: {
                                            label: 'Email',
                                        },
                                    },
                                    {
                                        type: 'text',
                                        name: 'message',
                                        rules: {
                                            required: {
                                                value: true,
                                                message: 'Message is required',
                                            },
                                        },
                                        textInputProps: {
                                            label: 'Message',
                                        },
                                    },

                                ]}
                            />
                            <Button
                                style={{ height: 50, backgroundColor: '#27b6cc',justifyContent:'space-around' }}
                                mode={'contained'}
                                onPress={handleSubmit((data: any) => {
                                    // console.log('form data', data);
                                })}>
                                Submit
                            </Button>

                        </View>
                    </View> */}
                        <View style={{ alignSelf: 'center', flexDirection: 'column', marginBottom: 50 }} >

                            <TouchableOpacity onPress={() => Linking.openURL(MY_URL)}>
                                <View style={{ flexDirection: 'row', marginLeft: -10 }}>
                                    <Image source={require('../../assets/images/location-pin.gif')} style={{ width: 30, height: 30 }} />
                                    <Text style={{ marginTop: 7, fontWeight: 'bold', fontSize: 15 }}>EasyStepIn IT Services Private Limited</Text>
                                </View>
                            </TouchableOpacity>
                            {!isEmpty(contentsOne) ? (contentsOne?.Description ?
                                <RenderHTML
                                    source={{ html: contentsOne?.Description }} /> : <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 5 }}>
                                    <Text>No Data found!</Text></View>) :
                                <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, margin: 20 }}>
                                    <ActivityIndicator animating={true} color='#27B6CC' style={{ marginTop: 25 }} />
                                </View>}
                        </View>

                    </View>

                </View>
                <Footer navigation={navigation} />

            </ScrollView>
        </Surface>
    );
}
