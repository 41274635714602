import React, { useEffect } from "react";
import { ActivityIndicator, Card, Surface, Text, IconButton, Colors } from 'react-native-paper';
import { useDispatch, useSelector } from "react-redux";
import { Image, StyleSheet, View, ScrollView, Platform, Dimensions, RefreshControl, ImageBackground } from "react-native";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";
import Modal from "react-native-modal";
import ESIStoreTypeCard from "../../components/custom/store-type-modal";
import { getShopByStoresAction } from "../../state/actions/node-actions/home-actions";

export default function ShopbyStoreType(props) {
  // api using redux

  const { navigation } = props;
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
  const esiColor = useSelector(state => state.theme);

  const halfdimentions = dimensions / 5;

  React.useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window.width);
      }
    );
  });

  useEffect(() => {
    onRefresh();
  }, []);

  const [loading, setIsLoading] = React.useState(false);
  const [storeTypes, setStoreTypes] = React.useState([]);

  const callBackGetData = async (status, data) => {
    setIsLoading(false);
    if (status) {
      setStoreTypes(data);
    }
  }

  const onRefresh = () => {
    setIsLoading(true);
    dispatch(getShopByStoresAction({
      "Records_Filter": "FILTER",
      "Status": "Active",
      "Type": props.type ? props.type : ""
    },
      { PageNo: 1, PageSize: 100, SortBy: "STORE_TYPE_NAME", SortOrder: "DESC" }, callBackGetData));
  };

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState();

  const closeModal = () => {
    setOpen(!open)
  }

  const styles = StyleSheet.create({
    Card: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      position: 'relative',
      backgroundColor: esiColor.BackgroundColor
    },
  });


  return (
    <Surface style={{
      marginBottom: Platform.OS === 'web' ? null : 120,
      position: Platform.OS === 'web' ? 'absolute' : null, top: Platform.OS === 'web' ? 0 : null,
      left: Platform.OS === 'web' ? 0 : null, right: Platform.OS === 'web' ? 0 : null, bottom: Platform.OS === 'web' ? 0 : null,
      backgroundColor: esiColor.BackgroundColor, alignSelf: 'center'
    }}>
      <Header {...props} />
      <View style={{ flexDirection: "column", marginVertical: 5, justifyContent: 'flex-start' }}>
        <Text style={{ fontSize: 20, fontWeight: "bold", color: esiColor.brandFontColor, margin: 2, marginLeft: 50 }}>Shop by Store Type</Text>
      </View>
      <IconButton
        icon="arrow-left"
        size={25}
        color={esiColor.SIconColor}
        style={{ marginTop: (Platform.OS === 'web' ? -38 : -40), marginLeft: 5 }}
        onPress={() => navigation.navigate('Home')}
      />

      {storeTypes?.Is_Data_Exist === "0" &&
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <Text style={{ color: esiColor.NoDataFound }}>No Store types are available to display</Text>
        </View>
      }
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={onRefresh}
          />
        }
      >
        <View style={styles.Card}>
          {!isEmpty(storeTypes) ? (
            storeTypes.Is_Data_Exist === "0" ? (
              <></>
            ) : typeof storeTypes === "object" ? (
              storeTypes.map((item, index) => {
                return (
                  <View key={index} style={{ padding: 4, maxWidth: (halfdimentions), width: (halfdimentions) }}>
                    <Card style={{
                      backgroundColor: esiColor.CBColor,
                      height: 140, maxHeight: 140, margin: 5,
                      borderRadius: 5, shadowRadius: 7, marginLeft: 15, marginRight: 15
                    }} onPress={(e) => { setOpen(true), setData(item) }}>
                      <ImageBackground style={{ height: 125, maxHeight: 125, backgroundColor: esiColor.ImgBck }}>

                        <Image source={{ cache: "force-cache", uri: item?.Image_Path }}
                          style={{
                            height: 124, maxHeight: 124,
                            borderRadius: 7, shadowRadius: 10,
                            marginTop: 10, marginBottom: 15, marginLeft: 15, marginRight: 15
                          }}
                        />
                      </ImageBackground>
                    </Card>
                    <Text style={{ margin: 2, flex: 1, color: esiColor.itemColor, fontSize: 14, fontWeight: 'bold', textAlign: 'center', paddingVertical: 4, marginBottom: 25 }}>{item?.Store_Type_Name}</Text>
                  </View>
                )
              })
            ) : (""))
            : (null
            )}
        </View>
        <ActivityIndicator color={esiColor.SIconColor} animating={storeTypes.length || storeTypes?.Is_Data_Exist === "0" ? false : true} style={{ marginTop: 20 }} />
      </ScrollView>
      <Modal animationIn={"slideInDown"}
        deviceWidth={dimensions.width}
        deviceHeight={dimensions.height}
        style={{ alignItems: 'center' }} isVisible={open}>
        <ESIStoreTypeCard navigation={navigation} setOpen={setOpen} data={data} closeModal={closeModal} />
      </Modal>
    </Surface>
  );
}
