import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, ImageBackground, StyleSheet, TouchableOpacity, View } from "react-native";
// import { ScrollView } from "react-native-gesture-handler";
import { IconButton, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersAction } from "../../state/actions/node-actions/home-actions";
// import isEmpty from "../../state/validations/is-empty";
// import esiColor from "../../constants/Colors";

export default function MeeStores(props) {
    const { navigation, dataReload, dataErrorReload, setCityDataExist = (() => { }), address = {}, storeType = null } = props;

    const dispatch = useDispatch();

    const [AllPartners, setAllPartners] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);
    const esiColor = useSelector(state => state.theme);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setAllPartners(data);
            if (address?.City_Id && storeType == null) {
                setCityDataExist(data.length > 0 ? true : false);
            }
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            // partnersCall();
            setReloadPage(!reloadPage);
        } else {
            if (address?.City_Id && storeType == null) {
                setCityDataExist(false);
            }
            await delay(dataErrorReload);
            // partnersCall();
            setReloadPage(!reloadPage);
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);
    const [cityId, setCityId] = React.useState("");


    useEffect(() => {
        if (address?.City_Id) {
            if (cityId != address?.City_Id) {
                setCityId(address?.City_Id);
                partnersCall()
            }
        } else {
            partnersCall()
        }
    }, [address?.City_Id]);

    useEffect(() => {
        if (reloadPage) {
            partnersCall();
            setReloadPage(false);
        }
    }, [reloadPage]);

    const partnersCall = () => {
        setIsLoading(true);
        dispatch(getPartnersAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : "",
            "City_ID": address?.City_Id || "",
            "Store_Type": storeType
        },
            { PageNo: 1, PageSize: 10, SortBy: "PARTNER_DETAILS_ID", SortOrder: "DESC" }
            , callBackGetData));
    }

    const handleClick = async (data) => {
        if (!(data?.Product_Count == '0')) {
            navigation('PartnerProducts', { Partner_Details_Id: data.Partner_Details_Id, back: "Home" });
        } else
            navigation('PartnerDetails', { Partner_Details_Id: data.Partner_Details_Id })
    }


    const styles = StyleSheet.create({
        content: {
            shadowColor: esiColor.brandShadowColor,
            backgroundColor: esiColor.CBColor,
            margin: 5,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            // elevation: 10,
            borderRadius: 10,
            height: 200, width: 500,
            alignSelf: 'center',
            alignItems: 'center',
            marginBottom: 30,
            marginLeft: 15,
            marginRight: 15
        },
        item: {
            borderRadius: 5,
            marginRight: 10,
            padding: 5,
            // flex: 1,
            maxHeight: 100,
            minHeight: 100,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
        }

    });

    return (AllPartners.length > 0 &&
        <View>

            <Text style={{ color: esiColor.LCFontColor, fontWeight: 'bold', padding: 10 }}> {props.title}</Text>

            <View style={styles.content}>
                <FlatList
                    numColumns={3}
                    showsHorizontalScrollIndicator={false}
                    data={AllPartners.slice(0, 3)}
                    renderItem={({ item, index, separators }) => (
                        <View key={index} style={{ marginTop: 20, margin: 10 }}>
                            <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                                <ImageBackground style={{ backgroundColor: esiColor.ImgBck, height: 80, width: 100, alignSelf:'center' }}>
                                    <Image source={{ cache: "force-cache", uri: item?.Logo_Path + `?w=100&h=80` }}
                                        style={{ height: 80, flex: 1, alignSelf: "center", resizeMode: 'contain', width: 100 }}></Image>
                                </ImageBackground>
                            </TouchableOpacity>
                            <Text style={{ fontSize: 14, color: esiColor.itemColor, textAlign: 'center', flexWrap: 'wrap', marginTop: 10 }}>{item?.Name?.substring(0, 20)}</Text>
                        </View>
                    )}
                />
                <TouchableOpacity style={{ position: 'absolute', bottom: 0, left: 225, marginBottom: 25 }} onPress={() => {
                    if (address) {
                        if (address?.City_Id) {
                            navigation('Partners', { city: address.City_Id, cityName: address.City });
                        } else {
                            navigation('Partners', { type: props.type });
                        }
                    } else
                        navigation('Partners', { type: props.type });
                }} >
                    <Text style={{ fontSize: 13, color: esiColor.actionColor, fontWeight: 'bold', textDecorationLine: 'underline' }}>View all</Text>

                </TouchableOpacity>
            </View>
        </View>

    )
}

