import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
const ENV = {
    dev: {
        BOOMI_HOST: "https://apiqa.meepaisa.com:9093/ws/rest/ESICB",
        BOOMI_HOST_DEL: "https://apiqa.meepaisa.com:9093/ws/rest/Meepaisa_Delivery",
        USERNAME_BOOMI: 'meepaisa_qa@easystepin-6WV1UR',
        PASSWORD_BOOMI: 'c44bafa4-3f94-457f-9a95-32df8110ef9c',
        xApiKey: "909615ef-93d1-4241-b9af-1a094dc1d1d5",
        NODE_USER_HOST: "https://node-user-uat.meepaisa.com",
        USERNAME_USER_NODE: 'ESICashback@easystepin-6WV1UR',
        PASSWORD_USER_NODE: 'b0b963cf-41ee-4240-8767-7c4921b460f8',
        NODE_CUD_HOST: "https://cud-uat-user.meepaisa.com",
        USERNAME_CUD_NODE: 'ESICashback@easystepin-6WV1UR',
        PASSWORD_CUD_NODE: 'b0b963cf-41ee-4240-8767-7c4921b460f8',
        BASEURL: "https://mobile-uat.meepaisa.com",
        API_KEY: "rzp_test_r0cSwenhbbzJdF",
        API_SECRET: "xQkvgc1WkuqxIPLui68q6Lbk",
        GOOGLE_MAP_API_KEY: "AIzaSyDiDtecys1_22J4dkbTSsh_cRqo1t_GbLA",
        GOOGLE_MAP_WEB_API_KEY: "AIzaSyDiDtecys1_22J4dkbTSsh_cRqo1t_GbLA",
        CRYPTO_KEY:'Meepaisa@2023'
    },
    prod: {
        BOOMI_HOST: "https://apiprod.meepaisa.com:9093/ws/rest/ESICB",
        BOOMI_HOST_DEL: "https://apiprod.meepaisa.com:9093/ws/rest/Meepaisa_Delivery",
        USERNAME_BOOMI: "meepaisa_prod@easystepin-6WV1UR",
        PASSWORD_BOOMI: "4c32aef7-de87-44b9-b44c-ca2ca5d92092",
        NODE_USER_HOST: "https://node.meepaisa.com",
        USERNAME_USER_NODE: 'meepaisa@easystepin-6WV1UR',
        PASSWORD_USER_NODE: '2be5ae9b-45ac-4450-ae39-97be12308d90',
        NODE_CUD_HOST: "https://cud-user.meepaisa.com",
        USERNAME_CUD_NODE: 'meepaisa@easystepin-6WV1UR',
        PASSWORD_CUD_NODE: '2be5ae9b-45ac-4450-ae39-97be12308d90',
        BASEURL: "https://m.meepaisa.com",
        API_KEY: "rzp_live_SR43tNThczUuzd",
        API_SECRET: "",
        GOOGLE_MAP_API_KEY: "AIzaSyDiDtecys1_22J4dkbTSsh_cRqo1t_GbLA",
        GOOGLE_MAP_WEB_API_KEY: "AIzaSyDiDtecys1_22J4dkbTSsh_cRqo1t_GbLA",
        CRYPTO_KEY:'Meepa!sa@2023'
    },
};

// const getEnvVars = (prop = Constants.manifest) => {
//     const env = prop.releaseChannel || prop.extra.env_mode || prop.extra.NODE_ENV || Updates.releaseChannel;
//     if (__DEV__) {
//         return ENV.dev;
//     } else if (env.trim() == 'prod') {
//         return ENV.prod;
//     } else if (env.trim() == 'dev') {
//         return ENV.dev;
//     }
//     else if (env.trim() == 'preview') {
//         return ENV.dev;
//     }
//     else if (env.trim() == 'production') {
//         return ENV.prod;
//     } else if (!(__DEV__)) {
//         return ENV.dev;
//     }
// };

const getEnvVars = (prop = Constants.expoConfig) => {
    const env = prop.releaseChannel || prop.extra.env_mode || prop.extra.NODE_ENV || Updates.releaseChannel || null;
    if (env.trim() == 'prod') {
        return ENV.prod;
    } else if (env.trim() == 'dev') {
        return ENV.dev;
    }
    else if (env.trim() == 'preview') {
        return ENV.dev;
    }
    else if (env.trim() == 'development') {
        return ENV.dev;
    }
    else if (env.trim() == 'production') {
        return ENV.prod;
    } else if (__DEV__) {
        return ENV.dev;
    } else if (!(__DEV__)) {
        return ENV.prod;
    }
};


export default getEnvVars;