import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { useDispatch } from "react-redux";
import { getUserSettings } from "../../../state/actions/walletActions";
import MeeProductsType from "./MeeProductsType";


export default function MeeSCProducts({ navigation, dataReload, dataErrorReload, City_Id }) {

    const dispatch = useDispatch();

    const [subCategory, setSubCategory] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        try {
            let arraySub = JSON.parse(status);
            setSubCategory(arraySub);
            await delay(arraySub.length > 0 ? dataReload : dataErrorReload);
            getUserSettingsSC();
        } catch (error) {
            await delay(dataErrorReload);
            getUserSettingsSC();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);

    useEffect(() => {
        getUserSettingsSC();
    }, []);

    const getUserSettingsSC = () => {
        setIsLoading(true);
        dispatch(getUserSettings("HOME_PAGE_SUBCATEGORY", callBackGetData, false));
    }
    return (subCategory.length > 0 &&
        <FlatList
            // horizontal={true}
            data={subCategory}
            renderItem={({ item, index, separators }) => (
                <MeeProductsType navigation={navigation} dataReload={dataReload} dataErrorReload={dataErrorReload} subId={item.id} title={item.title} City_Id={City_Id} />
            )}
        />
    )
}


const styles = StyleSheet.create({
    content: {
        borderRadius: 10,
        backgroundColor: 'white',
        margin: 5,
        padding: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
        // elevation: 10
    },

    item: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#9ce5f0',
        marginHorizontal: 5,
        padding: 4,
        maxHeight: 35
    }


});