import React from "react";
import { StyleSheet, TextInput, View, Keyboard, Button, Platform } from "react-native";
import { Feather, Entypo } from "@expo/vector-icons";
import { useSelector } from "react-redux";

const SearchBar = ({ clicked, searchPhrase, setSearchPhrase, setClicked }) => {
    const esiColor = useSelector(state => state.theme);

    return (
        <View style={styles.container} onBlur={() => {
            Keyboard.dismiss();
            setClicked(false);
        }}>
            <View
                style={styles.searchBar__unclicked
                }
            >
                {/* search Icon */}
                <Feather
                    name="search"
                    size={10}
                    color={esiColor.SIconColor}
                    style={{ marginLeft: 5, marginRight: 5 }}
                />
                {/* Input field */}
                <TextInput
                    selectionColor={esiColor.TextSelection}
                    theme={{
                        colors: {
                            primary: esiColor.TextHighlight,
                            text: esiColor.Text,
                            placeholder: esiColor.TextPlaceholder
                        }
                    }}
                    outlineColor={esiColor.TextOuterLine}
                    underlineColor={esiColor.TextUnderline}
                    style={Platform.OS === 'web' ? {
                        flex: 1, borderWidth: 1, backgroundColor: esiColor.TextBC, fontSize: 12, height: 25,
                        color: esiColor.Text,
                    } : styles.input}
                    placeholder="Search"
                    value={searchPhrase}

                    onChangeText={setSearchPhrase}
                    onFocus={() => {
                        setClicked(true);
                    }}
                />

                {/* cross Icon, depending on whether the search bar is clicked or not */}
                {searchPhrase.length > 0 && (
                    <Entypo name="cross" size={12} color={esiColor.itemColor} style={{ padding: 1 }} onPress={() => {
                        setSearchPhrase("");
                    }} />
                )}
            </View>
            {/* cancel button, depending on whether the search bar is clicked or not */}
            {/* {clicked && (
                <View>
                    <Button
                        title="Cancel"
                        onPress={() => {
                            Keyboard.dismiss();
                            setClicked(false);
                        }}
                    ></Button>
                </View>
            )} */}
        </View>
    );
};
export default SearchBar;

// styles
const styles = StyleSheet.create({
    container: {
        // margin: 15,
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        width: "90%",

    },
    searchBar__unclicked: {
        padding: 10,
        flexDirection: "row",
        width: "95%",
        // backgroundColor: "#d9dbda",
        // borderRadius: 15,
        alignItems: "center",
    },
    // searchBar__clicked: {
    //     padding: 10,
    //     flexDirection: "row",
    //     width: "80%",
    //     backgroundColor: "#d9dbda",
    //     borderRadius: 15,
    //     alignItems: "center",
    //     justifyContent: "space-evenly",
    // },
    input: {
        fontSize: 12,
        marginLeft: 4,
        width: "90%",
        elevation: 0
    }
});