import { useFocusEffect } from "@react-navigation/native";
import React, { useState } from "react";
import { Dimensions } from "react-native";
import { FlatList, Image, StyleSheet, Text, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from "react-redux";
import giftgif from "../../../assets/images/GIF/giftCardImg.png"

import { getAllReceivedGiftsAction } from "../../../state/actions/giftActions";
export default function ReceiveGift(props) {
    const user = useSelector(state => state.auth?.user?.Email_Id);
    const esiColor = useSelector(state => state.theme);

    const [gift_list, setGiftList] = useState([]);
    const [loading, setLoading] = useState(true);
    let receivedGifts = useSelector((state) => state.BuyGift.receivedGifts.all);
    const dispatch = useDispatch();
    useFocusEffect(
        React.useCallback(() => {
            setGiftList([]);
            setLoading(true);
            let filter = {
                "Email_Id": user,
                "Is_approved": "1"
            }
            dispatch(getAllReceivedGiftsAction(filter))
                .then(async res => {
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false);
                    // Toast.show("Network connection issue.");
                })
        }, [])
    );

    const styles = StyleSheet.create({

        content: {
            borderRadius: 10,
            backgroundColor: esiColor.BackgroundColor,
            marginHorizontal: 15,
            marginVertical: 10,
            minHeight: 120,
            padding: 10,
            alignSelf: 'center',
            shadowColor: esiColor.brandShadowColor,
            shadowOpacity: 7,
            shadowRadius: 10,
            width: 450
        },
        image: {
            width: 80,
            height: 80
        }
    });

    const { height, width } = Dimensions.get('window');
    const RenderItem = ({ item }) => {
        return (
            <View style={styles.content}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex: 1 }}>
                        <Image source={{ uri: item?.Gift_Image + `?w=80&h=80` }} style={styles.image}></Image>
                    </View>
                    <View style={{ flex: 2, justifyContent: 'flex-start', alignSelf: 'flex-start' }}>
                        <Text style={{ color: esiColor.DescColor }}>{item?.Received_By_Email}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Message: {item?.Gift_Message}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Type: {item?.Type}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Voucher: {item?.Voucher_Name}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Amount/Gift Card: ₹ {item?.Total_Value}</Text>
                        <Text style={{ color: esiColor.DescColor }}>Date: {item?.Created_Date}</Text>
                    </View>
                </View>
            </View>
        )
    }
    return (
        <View style={{ flex: 1 }}>
            {loading ? <ActivityIndicator color={esiColor.SIconColor} />
                :
                receivedGifts.length > 0 ?
                    <FlatList
                        numColumns={3}
                        data={receivedGifts}
                        renderItem={RenderItem}
                        keyExtractor={(item, index) => item?.Gift_Voucher_Id + index}
                    />
                    :
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Image style={{ resizeMode: 'contain', width: (width > height) ? height : width, height: 250 }} source={giftgif} />
                        {/* <Ionicons name="gift-outline" size={100} color="gray"></Ionicons> */}
                        <Text style={{ color: esiColor.NoDataFound, marginTop: 15 }}>No Gift Cards Received.</Text>
                    </View>
            }
        </View>
    )
}
