import React, { useContext, useEffect, useState } from 'react';
import { BackHandler, Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View, TextInput, Alert, Linking } from 'react-native';
import { Appbar, Avatar, Badge, Button, Colors, Menu, Surface, TouchableRipple } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { loginOut, loginOutSuccess } from '../state/actions/authActions';
import Modal from "react-native-modal";
import SignIN from './auth/signin';
import { AntDesign, Entypo, EvilIcons, Feather, FontAwesome } from '@expo/vector-icons';
import isEmpty from '../state/validations/is-empty';
import { scrollTo, useAnimatedRef, useSharedValue } from 'react-native-reanimated';
import * as Notifications from 'expo-notifications';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { pushNotificationAction } from '../state/actions/pushnotificationAction';
import { useToast } from '../self_modules/react-native-paper-toast/src';
import { getCart, setDefaultAddress, setDefaultAddressOnTest } from "../state/actions/cartActionsNew";
import { getAllCartAddressAction } from '../state/actions/addressActions';
import Dropdown from '../components/custom/Dropdown';
import { useRoute } from '@react-navigation/native';
import { addStatistics } from '../state/actions/dashboardActions';
import * as Network from 'expo-network';


const window = Dimensions.get("window");
const { height, width } = Dimensions.get('window');
const Header = (props) => {
  const { navigation } = props;
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
  const esiColor = useSelector(state => state.theme);
  const signInModalResponse = (status: any) => {
    setModalVisible(!isModalVisible);
    const { refferal } = props?.route?.params || false;
    if (refferal) {
      navigation.navigate("Home");
    }
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };


  const checkUpdateNeeded = async () => {
    try {
      let updateNeeded = await checkVersion();
      if (updateNeeded.needsUpdate) {
        Alert.alert("Update Required", 'You will have to update your app to the latest version to continue using.',
          [
            {
              text: 'Update',
              onPress: () => {
                BackHandler.exitApp();
                Linking.openURL(updateNeeded.url);
              }
            },
            {
              text: "Cancel",
              onPress: () => { },
              style: "cancel"
            }
          ]
        );
      }

    } catch (error) {
      Alert.alert("Error", "Version control location");
    }

    // Alert.alert("getPackageName", VersionCheck.getPackageName());
    // Alert.alert("getCurrentBuildNumber", VersionCheck.getCurrentBuildNumber());
    // Alert.alert("getCurrentVersion", VersionCheck.getCurrentVersion());

    // VersionCheck.getLatestVersion()
    //   .then(latestVersion => {
    //     Alert.alert("latestVersion", latestVersion);
    //   });
  }

  React.useEffect(() => {
    if (Platform.OS != "web") {
      checkUpdateNeeded();
    }

    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions(window);
      }
    );
  });

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const userName = useSelector(state => state.auth.user?.User_Name ? state.auth.user?.User_Name : "");
  const userPic = useSelector(state => state.auth.user?.Profile_Pic_Path ? { uri: state.auth.user?.Profile_Pic_Path } : "");
  const cart = useSelector((state) => state.cart.cart);
  const shippingAddress = useSelector((state) => state.cart.defaultAddress);
  const _handleSignOut = () => {
    // dispatch(loginOut(navigation));
  };

  const [testUserSession, setTestUserSession] = React.useState("");


  React.useEffect(() => {
    if (!isEmpty(user)) {
      setModalVisible(false);
      if (user?.User_Session_Id) {
        if (testUserSession != user?.User_Session_Id) {
          setTestUserSession(user?.User_Session_Id);
          dispatch(getCart(
            {
              "Records_Filter": "FILTER",
              "User_Email_Id": user.Email_Id
            }
          ));
          dispatch(setDefaultAddressOnTest())
        }

      } else {
        dispatch(loginOutSuccess());
      }
    } else {
      setSeconds(2);
    }
  }, [user])

  const route = useRoute();

  React.useEffect(() => {
    if (!isEmpty(user)) {
      (async () => {
        let iPAddress = await Network.getIpAddressAsync();
        let formdata = {
          "Component": route.name,
          "Email_Id": user?.Email_Id,
          "Event_Context": JSON.stringify(route.params),
          "Event_Name": "Navigation",
          "Ip_Address": iPAddress,
          "Mobile_Number": user?.Mobile,
          "User_Full_Name": user?.User_Name,
          "Origin": Platform.OS
        };
        dispatch(addStatistics(formdata));
      })();
    }
  }, [route])

  const [seconds, setSeconds] = React.useState(-1);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1);
      if (seconds === 1) {
        if (isEmpty(user)) {
          const { referral } = props?.route?.params || false;
          if (referral) {
            setModalVisible(true);
          }
        }
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds]);

  const _handleProfile = () => {
    navigation.navigate('ProfileDetails')
  }

  const _esiLogo = () => {
    navigation.navigate('Home')
  }
  const toast = useToast();

  // Tost message code.
  const dropDownAlertFun = (type, title, sub_title) => {
    toast.show({ message: sub_title, type: 'info', duration: 3000, position: 'top' });
  }


  const goToTop = () => {
    try {
      scrollTo({ x: 0, y: 0, animated: true });
    } catch (error) {

    }
  }


  // push notifications
  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  React.useEffect(() => {
    if (
      lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.url &&
      lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      navigation.navigate(lastNotificationResponse.notification.request.content.data.url);
    }
  }, [lastNotificationResponse]);

  useEffect(() => {
    const requestPermissions = async () => {
      const ExpoPushToken = await AsyncStorage.getItem('ExpoPushToken');

      let message = {
        to: ExpoPushToken,
        sound: 'default',
        title: 'Original Title',
        body: 'And here is the body!',
        data: { url: 'Notification' },
      };
      dispatch(pushNotificationAction(message))
    }
    if (Platform.OS != "web") {
      // requestPermissions()
    }
  }, [seconds])

  const [addressT, setAddress] = React.useState([]);
  const [addressV, setAddressV] = React.useState(false);

  const updateStates = (data: any, loading: any) => {
    let add = JSON.parse(JSON.stringify(data ? data : []));
    setAddress(addresslist(add));
  };

  const addresslist = (address: any) => {
    let addresslist = []
    if (address) {
      for (let i = 0; i < address.length; i++) {
        addresslist.push({
          label: address[i].City + "," + address[i].Zip + "," + address[i].Address_Type + "," + address[i].Name,
          value: JSON.stringify(
            {
              "User_Address_Id": address[i].User_Address_Id,
              "Name": address[i].Name,
              "Shipping_Email_Id": address[i].Shipping_Email_Id,
              "User_Email_Id": address[i].User_Email_Id,
              "Mobile": address[i].Mobile,
              "Address_Type": address[i].Address_Type,
              "Door_Number": address[i].Door_Number,
              "Street": address[i].Street,
              "City": address[i].City,
              "State": address[i].State,
              "Country": address[i].Country,
              "Zip": address[i].Zip,
              "Landmark": address[i].Landmark,
              "Location": address[i].Location,
              "Alternate_Mobile": address[i].Alternate_Mobile,
              "City_Id": address[i]?.City_Id
            }
          )
        })
      }
    }
    if (isAuthenticated == true) {
      addresslist.push({
        label: "Add New Address",
        value: ""
      })
    }
    return addresslist
  }

  const getAddress = () => {
    let data = {
      User_Email_Id: user.Email_Id,
      Records_Filter: "FILTER",
    };
    dispatch(getAllCartAddressAction(updateStates, data));
  }

  const [openDropDown, setOpenDropDown] = React.useState(false);

  const styles = StyleSheet.create({

    cart_icon: {
      borderRadius: 50,
      position: 'relative',
      left: 150,
      label: 'CART'
    },
    search: {
      backgroundColor: esiColor.BackgroundColor,
      borderRadius: 10,
      // borderEndColor: '#27b6cc',
      height: 30,
      margin: 25,
      width: (width > height) ? height : width,
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: esiColor.SBorderColor,
      color: esiColor.DescColor,
      alignItems: 'center',
    },
    search2: {
      backgroundColor: esiColor.BackgroundColor,
      borderRadius: 10,
      // borderEndColor: '#27b6cc',
      height: 30,
      margin: 25,
      width: 300,
      flexDirection: 'row',
      borderWidth: 1,
      borderColor: esiColor.SBorderColor,
      color: esiColor.DescColor,
      alignItems: 'center',
    },
    cart_count: {
      position: 'absolute',
      zIndex: 10,
      // right: 45,
      // marginTop: -30,
      backgroundColor: 'blue',
      borderRadius: 50,
      paddingHorizontal: 5,
      justifyContent: 'center',
    },
    cart_text: {
      color: 'white',
      fontSize: 10,
      textAlign: 'center',
      fontWeight: 'bold'
    },
    item_styles: {
      flexDirection: 'row',
      alignItems: 'center',
      // left: 180
    },

  });

  return (
    <View>
      <Surface>

        <View style={{ flexDirection: 'row', backgroundColor: esiColor.BackgroundColor, height: 55, justifyContent: "space-between" }}>
          <View style={{ flexDirection: 'row', alignItems: "center" }}>
            <TouchableOpacity style={{ paddingLeft: 20 }} onPress={() => {
              if (props.isFilter) {
                navigation.getParent().openDrawer();
              } else { navigation.openDrawer(); goToTop() }
            }} >
              <EvilIcons name="navicon" size={35} color={esiColor.SIconColor} style={{ paddingTop: 4 }} />
            </TouchableOpacity>

            <TouchableRipple>
              <Appbar.Action size={125} color={esiColor.SIconColor} icon={require('../assets/images/img/logo/logo.png')} onPress={_esiLogo} />
            </TouchableRipple>

            {!isAuthenticated ?

              <View style={styles.search}>
                <EvilIcons name="search"
                  size={25}
                  color={esiColor.SIconColor}
                  style={{ margin: 10 }} />

                <TextInput
                  onTouchStart={() => navigation.navigate('searchproduct')}
                  style={{ flex: 1, color: esiColor.amountStrikeColor, fontSize: 12 }}
                  placeholderTextColor={esiColor.SPHTextColor}
                  multiline={false}
                  editable={false}
                  onPressIn={() => navigation.navigate('searchproduct')}
                  onKeyPress={() => navigation.navigate('searchproduct')}
                  placeholder="Search for Products, Brands and More"
                  onFocus={() => {
                    navigation.navigate('searchproduct')

                  }}
                />
              </View>
              :

              <View style={{ flexDirection: "row", marginVertical: 10, justifyContent: "space-between", flex: 4 }} >
                <View style={{ overflow: Platform.OS != "web" ? "scroll" : "hidden", marginTop: 25 }}  >
                  <TouchableOpacity style={{ flexDirection: "row" }} onPress={() => {
                    getAddress();
                    setOpenDropDown(!openDropDown);
                  }}>
                    <EvilIcons
                      name="location"
                      size={25}
                      color={esiColor.SIconColor}
                      style={{
                        flex: 1,
                        marginTop: 2,
                      }}
                    />
                    <Text style={{
                      marginTop: 4,
                      fontWeight: 'bold',
                      color: esiColor.GBFColor,
                      // flex: 4
                      // width: 700
                    }}> {"Deliver to "}</Text>
                    <Dropdown label="select address" onClick={() => {
                      getAddress();
                    }} data={addressT} onSelect={(item) => {
                      if (item.value) {
                        dispatch(setDefaultAddress(item));
                      } else {
                        navigation.navigate("AddAddress");
                      }
                    }}
                      maxWidth={500}
                      selected={shippingAddress}
                    />
                  </TouchableOpacity>
                </View>
                <View style={styles.search2}>
                  <EvilIcons name="search"
                    size={25}
                    color={esiColor.SIconColor}
                    style={{ margin: 10 }} />

                  <TextInput
                    onTouchStart={() => navigation.navigate('searchproduct')}
                    style={{ flex: 1, color: esiColor.amountStrikeColor, fontSize: 12 }}
                    placeholderTextColor={esiColor.SPHTextColor}
                    // style={{ flex: 1, color: esiColor.amountStrikeColor }}
                    multiline={false}
                    editable={true}
                    onPressIn={() => navigation.navigate('searchproduct')}
                    onKeyPress={() => navigation.navigate('searchproduct')}
                    placeholder="Search for Products, Brands and More"
                    onFocus={() => {
                      navigation.navigate('searchproduct')

                    }}
                  />
                </View>
              </View>

            }

            <View style={{ flexDirection: "row", alignContent: 'space-evenly' }}>
              <TouchableOpacity onPress={() => props.navigation.navigate('HowCashbackWorks')}>
                <Text style={{ fontSize: 18, fontWeight: 'normal', color: esiColor.SIconColor }}>How meepaisa works?</Text>
              </TouchableOpacity>

              {
                !isAuthenticated ?
                  <TouchableOpacity style={{ marginHorizontal: 5, marginTop: -5, left: 125 }} onPress={toggleModal}>
                    <EvilIcons name='user' size={35} color={esiColor.esiMainColor} />
                  </TouchableOpacity>

                  :
                  userPic ?
                    <TouchableRipple onPress={_handleProfile}>
                      <Avatar.Image size={30} source={userPic} style={{ backgroundColor: esiColor.esiMainColor, left: 125 }} />
                    </TouchableRipple> : <TouchableOpacity style={{ marginHorizontal: 5, marginTop: -5 , left: 125}} onPress={_handleProfile}>
                      <EvilIcons name='user' size={35} color={esiColor.esiMainColor} />
                    </TouchableOpacity>
              }
              {/* <TouchableOpacity style={{
                width:25,height:25,marginTop:-5
                // ,borderRadius:40,
                // backgroundColor:esiColor.esiMainColor
              }} // onPress={() => gotoCart()}
                onPress={() => { }}>
                <EvilIcons name='user' size={35} color={esiColor.esiMainColor} />
                </TouchableOpacity> */}
              <TouchableOpacity style={styles.cart_icon} // onPress={() => gotoCart()}
                onPress={() => { isAuthenticated ? navigation.navigate('Cart') : toggleModal() }}>
                {isAuthenticated ? cart.length > 0 &&
                  <Badge style={{
                    position: 'absolute',
                    color: "#27b6cc",
                    fontWeight: 'bold',
                    bottom: 12,
                    left: 15,
                    backgroundColor: "white"
                  }}>
                    {cart.length}
                  </Badge> : null}
                <Entypo name="shopping-cart" size={23} color="#27b6cc" />
              </TouchableOpacity>

            </View>
          </View>

          <Modal animationIn={"slideInDown"}
            // backdropColor={esiColor.BackgroundColor}
            deviceWidth={dimensions.width}
            deviceHeight={dimensions.height}
            style={{ alignItems: 'center', left: 30, }} isVisible={isModalVisible}>
            <View style={{
              flex: 1, left: 30, maxWidth: 500, minWidth: 300, minHeight: 500,
              maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, shadowColor: esiColor.brandShadowColor,
              shadowRadius: 3
            }}>

              <SignIN navigation={navigation} dropDownAlertFunHead={dropDownAlertFun} toggleModal={signInModalResponse} />

            </View>
          </Modal>
        </View>
      </Surface >
    </View >
  );
};

export default Header;

