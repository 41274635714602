import * as React from 'react';
import { Avatar, Button, Colors, HelperText, ProgressBar, Surface, Text, TextInput } from 'react-native-paper';
import { material } from 'react-native-typography';
import { Linking, View, Dimensions, ScrollView, Platform, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Modal from "react-native-modal";
import { setPasswordAction, setPasswordSignUpAction, signUpCheck, tokenVarivicationAction, tokenVarivicationSignUpAction } from '../../state/actions/authActions';
import getEnvVars from '../../../environment';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';
import { navigationRef } from '../../navigation';
import ESITextInput from '../../components/custom-fields/textInput';
const { BASEURL } = getEnvVars();

// import { TouchableOpacity } from 'react-native-gesture-handler';
// import {logo} from '../../assets/images/img/logo/meePaisa_logo.png'

function Copyright() {
    return (

        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Text onPress={() => {
                Linking.openURL("https://www.easystepin.com/").catch((err) =>
                    console.error('An error occurred', err),
                );
            }} style={[material.caption, {}]}>{'Copyright © '}
                EasyStepIn
                {' 2016 - '}
                {new Date().getFullYear()}
                {'.'}
            </Text>
        </View>

    );
}


const SignUPPassword = (props: any) => {
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const dispatch = useDispatch();
    const [signupInit, setSignupInit] = React.useState({});

    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [secondsO, setSecondsO] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => secondsO > 0 ? setSecondsO(secondsO - 1) : setSecondsO(-1), 1000)
        return () => clearTimeout(timer)
    }, [secondsO]);

    const [errorMessage, setErrorMessage] = React.useState('');
    const [modelMessage, setModelMessage] = React.useState('');
    const setPasswordCallResponse = (status: any, errorMessage: any, response: any) => {
        setErrorMessage(errorMessage);
        setIsSubmited(false);
        if (!status) {
            setSeconds(3);
        } else {
            setModelMessage(errorMessage);
            setTokenRequestStatusError(true);
        }
    }

    // password token realted one
    const [tokenRequestStatus, setTokenRequestStatus] = React.useState(false);
    const [tokenRequestStatusError, setTokenRequestStatusError] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    const [passwordType, setPasswordType] = React.useState("");
    const [tokenInfo, setTokenInfo] = React.useState("");
    const [otpMobile, setMtpMobile] = React.useState("");
    React.useEffect(() => {
        if (props?.route?.params?.token) {
            setPasswordType("token")
            setTokenInfo(props?.route?.params?.token);
            setSignupInit({
                password: '',
                cpassword: ''
            });
        } else if (props?.route?.params?.auth) {
            setPasswordType("auth")
            setTokenInfo(props?.route?.params?.auth);
            setSignupInit({
                password: '',
                cpassword: '',
                Mobile_Otp: ''
            });
        } else {
            setPasswordType("")
            setTokenInfo("");
            setModelMessage(`Unauthorised url access, please try with valid url.`);
            setTokenRequestStatusError(true);
        }

    }, [props?.route?.params]);

    React.useEffect(() => {
        if (tokenInfo) {
            setTokenRequestStatus(true);
            if (passwordType == "token") {
                dispatch(tokenVarivicationAction({ Token: tokenInfo }, setTokenCallResponse));
            }
            else if (passwordType == "auth") {
                dispatch(tokenVarivicationSignUpAction({ Token: tokenInfo }, setTokenCallResponse));
                setSecondsO(30);
            } else {
                setModelMessage(`Unauthorised url access1, please try with valid url.`);
                setTokenRequestStatusError(true);
            }
        }

    }, [tokenInfo]);

    const setTokenCallResponse = (status: any, errorMessage: any, response: any) => {
        setTokenRequestStatus(false);
        if (status) {
            if (passwordType == "auth") {
                setMtpMobile(response?.Mobile)
            }
        } else {
            setModelMessage(errorMessage);
            setTokenRequestStatusError(true);
        }
    }


    return (
        <Surface style={{
            flex: 1
        }} >
            <Header navigation={props.navigation} />
            {tokenRequestStatus ? <ActivityIndicator color='#27b6cc' /> :
                <ScrollView keyboardShouldPersistTaps='always' style={{

                }} showsVerticalScrollIndicator={false} >

                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20
                    }}>
                        <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10 }} />
                        <Text style={material.headline}>Set Password</Text>
                    </View>
                    <View>
                        <Formik
                            initialValues={
                                signupInit
                            }

                            validationSchema={
                                passwordType == "auth" ? Yup.object().shape({
                                    password: Yup.string()
                                        .max(255)
                                        .matches(/[a-z]+/, "At least one lowercase letter is required.")
                                        .matches(/[A-Z]+/, "At least one uppercase letter is required.")
                                        .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
                                        .matches(/.*\d+/, "At least one number is required.")
                                        .min(8, "At least minimum 8 characters are required.")
                                        .required('Password is required.'),
                                    cpassword: Yup.string()
                                        .max(255).oneOf([Yup.ref('password'), null], 'Passwords must match.')
                                        .required('Confirm Password is required.'),
                                    Mobile_Otp: Yup.string()
                                        .min(6, 'Please provide valid OTP.')
                                        .required('Mobile OTP is required.'),
                                }) :
                                    Yup.object().shape({
                                        password: Yup.string()
                                            .max(255)
                                            .matches(/[a-z]+/, "At least one lowercase letter is required.")
                                            .matches(/[A-Z]+/, "At least one uppercase letter is required.")
                                            .matches(/[^\da-zA-Z]+/, "At least one special character is required.")
                                            .matches(/.*\d+/, "At least one number is required.")
                                            .min(8, "At least minimum 8 characters are required.")
                                            .required('Password is required.'),
                                        cpassword: Yup.string()
                                            .max(255).oneOf([Yup.ref('password'), null], 'Passwords must match.')
                                            .required('Confirm Password is required.'),

                                    })

                            }

                            onSubmit={(values: any, { setSubmitting, setErrors }: { setSubmitting: any, setErrors: any }) => {
                                setSubmitting(false);
                                setSignupInit(values);
                                setErrorMessage("");
                                if (passwordType == "token") {
                                    dispatch(setPasswordAction({
                                        New_Password: values.password,
                                        Password_Token: tokenInfo
                                    }, setPasswordCallResponse));
                                }
                                else if (passwordType == "auth") {
                                    dispatch(setPasswordSignUpAction({
                                        New_Password: values.password,
                                        Password_Token: tokenInfo,
                                        Mobile_Otp: values.Mobile_Otp
                                    }, setPasswordCallResponse));
                                } else {

                                }
                                setIsSubmited(true);

                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue }: { handleChange: any, handleBlur: any, handleSubmit: any, values: any, errors: any, touched: any, setFieldValue: any }) => (
                                <View style={{
                                    flexDirection: "row", flexWrap: "wrap", alignItems: 'center',
                                    justifyContent: 'center', marginBottom: 10
                                }}>
                                    <View style={{ width: "80%", margin: "auto" }}>
                                        <ESITextInput 
                                            style={{ width: "100%" }}
                                            label="Password"
                                            mode='outlined'
                                            onChangeText={handleChange('password')}
                                            onBlur={handleBlur('password')}
                                            value={values.password}
                                            secureTextEntry={!show}
                                            right={<TextInput.Icon onPress={() => setShow(!show)} name={show ? "eye" : "eye-off"} />}
                                            error={Boolean(touched.password && errors.password)}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.password && errors.password)}>
                                            {touched.password && errors.password}
                                        </HelperText>
                                    </View>
                                    <View style={{ width: "80%", margin: "auto" }}>
                                        <ESITextInput 
                                            style={{ width: "100%" }}
                                            label="Confirm Password"
                                            mode='outlined'
                                            onChangeText={handleChange('cpassword')}
                                            onBlur={handleBlur('cpassword')}
                                            value={values.cpassword}
                                            secureTextEntry={true}
                                            error={Boolean(touched.cpassword && errors.cpassword)}
                                        />
                                        <HelperText type="error" visible={Boolean(touched.cpassword && errors.cpassword)}>
                                            {touched.cpassword && errors.cpassword}
                                        </HelperText>
                                    </View>
                                    {passwordType == "auth" &&
                                        <View style={{ width: "80%", margin: "auto" }}>
                                            <ESITextInput 
                                                style={{ width: "100%" }}
                                                label="Mobile OTP"
                                                mode='outlined'
                                                onChangeText={handleChange('Mobile_Otp')}
                                                onBlur={handleBlur('Mobile_Otp')}
                                                value={values.Mobile_Otp}
                                                error={Boolean(touched.Mobile_Otp && errors.Mobile_Otp)}
                                            />
                                            <HelperText type="error" visible={Boolean(touched.Mobile_Otp && errors.Mobile_Otp)}>
                                                {touched.Mobile_Otp && errors.Mobile_Otp}
                                            </HelperText>
                                        </View>
                                    }

                                    {passwordType == "auth" &&
                                        <View style={{ width: "80%", margin: "auto" }}>
                                            <HelperText type="info">
                                                OTP has been sent to mobile number( ****{otpMobile?.substring(otpMobile?.length - 4)})
                                            </HelperText>
                                        </View>
                                    }

                                    <View style={{
                                        width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        {seconds > 0 && <><Text  >{errorMessage}</Text><ProgressBar style={{
                                            height: 5,
                                            width: 250,
                                        }} progress={.30 * seconds} color={Colors.red900} /></>}
                                    </View>
                                    <View style={{
                                        width: "80%", margin: "auto", marginTop: -5, alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: "row"
                                    }}>
                                        {isSubmited ? <ActivityIndicator color='#27b6cc' /> :
                                            <Button style={{ marginTop: 15, backgroundColor: '#27b6cc' }} disabled={isSubmited} mode="contained" onPress={handleSubmit}>
                                                Submit
                                            </Button>}
                                        {(!isSubmited && passwordType == "auth") &&
                                            <Button style={{ marginTop: 15, marginLeft: 15, backgroundColor: '#27b6cc' }} mode="contained" disabled={secondsO >= 0} onPress={() => {
                                                if (!(secondsO >= 0)) {
                                                    dispatch(tokenVarivicationSignUpAction({ Token: tokenInfo }, setTokenCallResponse));
                                                    setSecondsO(30);
                                                }
                                            }}>
                                                Resend OTP {secondsO > 0 ? secondsO : ""}
                                            </Button>}
                                    </View>
                                </View>
                            )}
                        </Formik>
                    </View>
                </ScrollView>}
            <Copyright />
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={tokenRequestStatusError}>
                <View style={{ flex: 1, flexDirection: "column", padding: 15, maxWidth: dimensions.width - 100, minWidth: 300, minHeight: 100, maxHeight: 250, borderRadius: 10, backgroundColor: Colors.white }}>
                    <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, alignSelf: "center", borderColor: '#ffffff' }} />

                    <Text style={{ alignSelf: "center" }} >{modelMessage}</Text>

                    <Button style={{ marginTop: 15, maxWidth: 150, backgroundColor: '#27b6cc', alignSelf: "center" }} mode="contained" onPress={() => {
                        setTokenRequestStatusError(false);
                        props.navigation.navigate("Home");
                    }}>
                        Okay
                    </Button>
                </View>
            </Modal>
        </Surface>
    );
};

export default SignUPPassword;
