import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersAction, getShopByStoresAction } from "../../state/actions/node-actions/home-actions";
import isEmpty from "../../state/validations/is-empty";
// import esiColor from "../../constants/Colors";
import { ImageBackground } from "react-native";


export default function MeeStoresTypes(props) {
    const { navigation, dataReload, dataErrorReload } = props;
    const esiColor = useSelector(state => state.theme);

    const dispatch = useDispatch();

    const [AllPartners, setAllPartners] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setAllPartners(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            partnersCall();
        } else {
            await delay(dataErrorReload);
            partnersCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);


    useEffect(() => {
        partnersCall()
    }, []);

    const partnersCall = () => {
        setIsLoading(true);
        dispatch(getShopByStoresAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : ""
        },
            { PageNo: 1, PageSize: 10, SortBy: "STORE_TYPE_NAME", SortOrder: "DESC" }
            , callBackGetData));
    }

    const handleClick = async (data) => {
        navigation('Partners', { Store_Type: data?.Store_Type_Name })
    }

    const styles = StyleSheet.create({

        content: {
            backgroundColor: esiColor.CBColor,
            margin: 10,
            padding: 5,
            alignItems: 'center',
            shadowOffset: { width: 0, height: 0.5 },
            shadowOpacity: 0.2,
            elevation: 5,
            height: 125,
        },

        item: {
            borderRadius: 5,
            marginHorizontal: 5,
            padding: 4,
            maxHeight: 75,
            shadowColor: esiColor.brandShadowColor,

        },

    });

    return (AllPartners.length > 0 &&

        <View style={styles.content}>

            <FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={AllPartners.slice(0, 10)}
                style={{ shadowColor: esiColor.brandShadowColor }}
                renderItem={({ item, index, separators }) => (

                    <View key={index} style={{ maxWidth: 125, minWidth: 100 }}>
                        <TouchableOpacity onPress={() => handleClick(item)} style={styles.item}>
                            <ImageBackground style={{
                                height: 80, shadowColor: esiColor.brandShadowColor,
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.2,
                                shadowRadius: 5,
                                borderRadius: "100%", maxWidth: 80
                            }}>
                                <Image source={{ cache: "force-cache", uri: item?.Image_Path + `?w=80&h=80` }}
                                    style={{ height: 80, resizeMode: 'contain', width: 80, borderRadius: '100%' }}>
                                </Image>
                            </ImageBackground>
                        </TouchableOpacity>

                        <View style={{ marginTop: 20, }}>
                            <Text style={{ fontSize: 14, color: esiColor.brandFontColor, textAlign: 'center' }}>{item?.Store_Type_Name.split(" ")[item?.Store_Type_Name.split(" ").length - 1]}</Text>
                        </View>

                    </View>
                )
                }
            />
        </View >

    )
}
