import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import RenderHTML from "react-native-render-html";
import { useSelector } from 'react-redux';
// import esiColor from '../../constants/Colors';


const Readmore = (props) => {
    const [more, setMore] = useState(false);
    const esiColor = useSelector(state => state.theme);

    const removeTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }
    return (
        <View style={{ marginLeft: 10, borderBottomWidth: 0.1, borderColor: esiColor.SBorderColor, marginTop: 15, backgroundColor: esiColor.BackgroundColor }} >
            <Text style={{ fontWeight: "bold", fontSize: 16, color: esiColor.DescColor, marginBottom: 15, marginLeft: 10 }} >{props.label} </Text>
            {more ?
                <RenderHTML                
                    tagsStyles={{
                        span: { color: esiColor.DescColor  }                        
                    }}
                    source={{ html: props?.value }}
                    containerStyle={{marginLeft: 15}}

                /> : <Text style={{ marginTop: 0, color: esiColor.itemColor, marginLeft: 15 }} >{removeTags(props?.value).slice(0, 50)}</Text>}

            {props?.value.length > 50 && <TouchableOpacity onPress={() => {
                setMore(!more);
            }} style={{ alignSelf: "flex-end", marginBottom: 5, marginTop: more ? -20 : -5, marginRight: 10 }}>
                <Text style={{ color: "#27b6cc" }} >{more ? "show less" : "show more"}</Text></TouchableOpacity>}
        </View>
    )
}

export default Readmore