import React from 'react';
import { Image, View, StyleSheet } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { Button, Card, Avatar } from 'react-native-paper';
import esiColor from '../../../constants/Colors';

const ProfileUpload = (props: any) => {

  //props consists of style props along with uploadPic function and custom props like image.
  // uploadPic function will return onchange value of perticular upload image responce. 
  // pickImage is a function for selecting images from mobile or web using ImagePicker plugin and will get uploaded image URL.

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,

      allowsEditing: true,
      //   aspect: [4, 3],
      quality: 1,
    });
    // if image is not cancelled after upload then  responce will passing to the uploadPic
    if (!result.canceled) {
      props.uploadPic(result);
    }
  };
  const { item, ...rest } = props

  return (

    <View {...props}>
      <View style={styles.container}>
        <View style={styles.CircleShape} >
          {props.image ? (
            <Avatar.Image size={120}
             source={{ uri: props.image }}
             style={{backgroundColor:"#27B6CC", shadowColor: "#fff", shadowRadius: 7}}
              alt="Upload"
            ></Avatar.Image>
          ) : (
            <>
              <Avatar.Image
                source={props.image}
                style={{backgroundColor:"#27B6CC"}}
                alt="Upload"           
              ></Avatar.Image>
            </>
          )}

        </View>
      </View>
      <Button style={{ fontSize:10, color: ' #425A70 ', marginTop:20, }} color={esiColor.brandFontColor} uppercase={false}  onPress={pickImage} >UPLOAD PICTURE</Button>
    </View>
    
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop:"5%",
  },

  
  CircleShape: {
    width: 60,
    height: 50,
    marginBottom: 20,
    borderRadius: 150 / 2,
    backgroundColor: '#D3D3D3',
    justifyContent: "center",
    alignItems: "center",
  },
});
export default ProfileUpload;