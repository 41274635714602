import React, { useEffect, useMemo, useState } from "react";
import { Dimensions, ScrollView, Image, Platform, StyleSheet, TouchableOpacity, View, RefreshControl, ActivityIndicator } from "react-native";
import { Avatar, Button, HelperText, IconButton, Surface, Text, TouchableRipple } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../state/validations/is-empty";
import Header from "../../header";
import { getBiddingProductsCart, getMyBidProductsAction } from "../../../state/actions/node-actions/bid-products-actions";
import { useRoute } from "@react-navigation/native";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import ESITextInput from "../../../components/custom-fields/textInput";
import EsiTextAreaInput from "../../../components/custom/TextAreaInput/textareainput";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown1";
import { Formik } from "formik";
import * as Yup from 'yup';
import Modal from "react-native-modal";
import { deleteCartBids, placeCartBids, updateCartBids } from "../../../state/actions/boomi-actions/bid-products-actions";
import EsiDatePicker from "../../../components/custom/date/date";


export default function CartOfBids(props) {
    const { navigation } = props;
    const logedInEmail = useSelector((state) => state.auth.user.Email_Id);
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window);
            }
        );
        return () => subscription?.remove();
    });

    const { height, width } = Dimensions.get('window');
    const [dimensions, setDimensions] = useState(Dimensions.get("window"));

    const esiColor = useSelector(state => state.theme);

    const [loading, setIsLoading] = React.useState(false);
    const [myBids, setMyBids] = React.useState([]);

    const dispatch = useDispatch();

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setMyBids(data);
        }
    }

    const getMyCartBids = () => {
        dispatch(getBiddingProductsCart({ User_Email_ID: logedInEmail },
            callBackGetData));
        setIsLoading(true);
    }

    const route = useRoute();

    useEffect(() => {
        if (!isEmpty(logedInEmail)) {
            getMyCartBids();
        } else {
            navigation.navigate("Home");
        }
    }, [logedInEmail, route]);

    const deleteCartAction = (item) => {
        setIsLoading(true);
        dispatch(deleteCartBids({ Bidding_Product_Cart_Id: item?.Bidding_Product_Cart_Id }, deleteCallbackResponse));
    }

    const placeCartAction = () => {
        setIsLoading(true);
        dispatch(placeCartBids({ User_Email_Id: logedInEmail }, placeCallbackResponse));
    }

    const placeCallbackResponse = (status: boolean) => {
        setIsLoading(false);
        if (status) {
            getMyCartBids();
            navigation.navigate("BidProducts");
        }
    }

    const deleteCallbackResponse = (status: boolean) => {
        setIsLoading(false);
        if (status) {
            getMyCartBids();
        }
    }

    const editCartAction = (item) => {
        setAddCartItem(item);
        setAddCartPop(true);
    }

    const [addCartPop, setAddCartPop] = React.useState(false);
    const [addCartItem, setAddCartItem] = React.useState();
    const [loadingForm, setloadingForm] = React.useState(false);
    const [formResults, setFormResults] = React.useState(false);
    const formCallbackResponse = (status: boolean) => {
        setloadingForm(false);
        if (status) {
            setFormResults(true);
            getMyCartBids();
        }
    }
    let nDate = new Date();
    nDate.setDate(nDate.getDate() + 2);
    let month = String(nDate.getMonth() + 1).length > 1 ? String(nDate.getMonth() + 1) : "0" + String(nDate.getMonth() + 1);
    let date = String(nDate.getDate()).length > 1 ? String(nDate.getDate()) : "0" + String(nDate.getDate());
    const [dos, setDos] = useState(nDate.getFullYear() + "-" + month +
        "-" + date);
    const datediff = (first, second) => {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    const styles = StyleSheet.create({

        containerstyle: {
            maxWidth: 200,
        },
        text: {
            fontSize: 14,
            fontFamily: 'system-ui',
            color: esiColor.itemColor
        }
    });


    return (
        <Surface style={{ flex: 1, backgroundColor: esiColor.BackgroundColor }}>
            <Header {...props} />
            <View style={{ marginBottom: (Platform.OS === 'web' ? 10 : 100), marginRight: 10, marginLeft: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                    <IconButton
                        icon="arrow-left"
                        // size={30}
                        color={esiColor.SIconColor}
                        // style={{ marginVertical: -10, flex: 1, marginLeft: 0 }}
                        onPress={() => {
                            if (navigation.canGoBack())
                                navigation.goBack();
                            else navigation.navigate("BidProducts")

                        }} />
                    <Text style={{ fontSize: 22, fontWeight: 'bold', alignSelf: "center", color: esiColor.brandFontColor }}>
                        Products Cart
                    </Text>
                </View>
                <ScrollView style={{ height: height - ((height / 100) * (Platform.OS === 'web' ? 20 : 30)) }}
                    showsHorizontalScrollIndicator={false}
                    refreshControl={
                        <RefreshControl
                            refreshing={loading}
                            onRefresh={() => {
                                getMyCartBids();
                            }}
                        />
                    }
                >
                    {myBids.length <= 0 ?
                        <View style={{ alignItems: "center", marginTop: 10 }}>

                            <View >
                                <View style={{ justifyContent: 'center', alignItems: 'center', }}>
                                    <Image style={{ width: 130, height: 130 }} source={require('../../../assets/images/Cart_empty.png')} />
                                    <Text style={{ fontWeight: 'bold', fontSize: 30, margin: 5, color: esiColor.NoDataFound }}> Your Cart is Empty.</Text>
                                    <Text style={{ fontSize: 15, fontWeight: 'bold', margin: 5, color: esiColor.Text }}>Add Products to create a bidding product service.</Text>
                                    <TouchableOpacity style={{ backgroundColor: esiColor.globalButtonColor, borderRadius: 10, padding: 10, margin: 5 }} onPress={() => navigation.navigate('NewBidProducts')}><Text style={{ fontWeight: 'bold', color: esiColor.itemButtenColor }}>Bid Now</Text></TouchableOpacity>
                                </View>
                            </View>
                        </View>
                        :
                        <View>
                            {(Array.isArray(myBids) ? myBids : []).map((data, index) => (
                                <View key={index} style={{
                                    shadowColor: esiColor.brandShadowColor,
                                    shadowOpacity: 5,
                                    shadowRadius: 7,
                                    marginHorizontal: 2, borderRadius: 10,
                                    padding: 2, backgroundColor: esiColor.CBColor,
                                    marginTop: (Platform.OS === 'web' ? 10 : 10), flexDirection: 'row',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    marginBottom: 15,
                                    maxHeight: height > 250 ? 250 : 90,
                                    width: 650,
                                    minHeight: 100,

                                }}>
                                    <View style={{ flex: 3, alignContent: 'center', marginLeft: 10 }} >
                                        <Image source={{ cache: "force-cache", uri: data?.Image + `?w=80&h=85` }} style={{ height: 85, width: 80, resizeMode: 'contain' }}></Image>
                                    </View>
                                    <View style={{ flex: 6, flexDirection: 'column' }}  >
                                        <Text style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'system-ui', color: esiColor.DescColor }}>{data?.Product_Name}</Text>
                                        <Text style={styles.text}>Quantity: {data?.Quantity} {data?.Measurement_Type}</Text>
                                        <Text style={styles.text}>Description : {data?.Description}</Text>
                                    </View>
                                    <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-around", alignSelf: "center" }}>
                                        <TouchableOpacity style={{ marginVertical: 6 }} onPress={(e) => { editCartAction(data); }} >
                                            <FontAwesome5 name="edit" size={22} color={esiColor.SIconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ marginVertical: 6 }} onPress={(e) => { deleteCartAction(data) }} >
                                            <Ionicons name="md-trash" size={22} color="#ee4d2d" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            ))}
                            <View style={{ minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                                <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={placeCartAction}>
                                    <Text style={{ color: esiColor.itemButtenColor }}>Ask Quotation</Text>
                                </Button>

                            </View>
                        </View>
                    }
                </ScrollView>
                <Modal animationIn={"slideInDown"}
                    deviceWidth={dimensions.width}
                    deviceHeight={dimensions.height}
                    style={{ alignItems: 'center' }} isVisible={addCartPop}>
                    <ScrollView style={{ flex: 1, maxWidth: 500, minWidth: 400, minHeight: 500, maxHeight: 530, borderRadius: 10, backgroundColor: esiColor.BackgroundColor, borderColor: esiColor.SBorderColor, borderWidth: 0.5 }}>
                        <Surface style={{
                            justifyContent: 'center',
                            borderRadius: 10,
                            top: Platform.OS === 'web' ? 10 : 20,
                            elevation: 0, backgroundColor: esiColor.BackgroundColor,
                        }} >
                            <TouchableRipple style={{ width: 36, alignSelf: "flex-end" }} onPress={() => {
                                setAddCartPop((prop) => !prop);
                                setloadingForm(false);
                                setFormResults(false);
                            }}>
                                <Avatar.Icon color={esiColor.itemColor} style={{ backgroundColor: esiColor.BackgroundColor }} size={36} icon="close" />
                            </TouchableRipple>
                            <Formik
                                key={addCartItem?.Bidding_Product_Cart_Id}
                                initialValues={
                                    {
                                        Bidding_Product_Cart_Id: addCartItem?.Bidding_Product_Cart_Id,
                                        Quantity: addCartItem?.Quantity || 0,
                                        Measurement_Type: addCartItem?.Measurement_Type || "kg",
                                        Modified_By: logedInEmail,
                                        Description: addCartItem?.Description || "",
                                        Pincode: addCartItem?.Pincode || 0,
                                        Bidding_Expiry_Date: addCartItem?.Bidding_Expiry_Date || ''
                                    }
                                }
                                validationSchema={Yup.object().shape({
                                    Quantity: Yup.number().min(1, "Quantity should be more then Zere.").required("Quantity is required."),
                                    Measurement_Type: Yup.string().required("Size is required."),
                                    Pincode: Yup.string().min(6, "Pincode should be 6 numbers.").max(6, "Pincode should be 6 numbers.").required("Pincode is required."),
                                    Bidding_Expiry_Date: Yup.string().required('Date of service is required.').test("date-of-bidding", "Service Date should be more then " + dos + ".", (value: any) => {
                                        if (value) {
                                            let date1 = new Date(value).getTime();
                                            let date2 = new Date(dos).getTime();
                                            if (datediff(date2, date1) > -1) {
                                                return true;
                                            }
                                        }
                                        return false;

                                    })
                                })}
                                onSubmit={values => {
                                    setloadingForm(true);
                                    dispatch(updateCartBids(values, formCallbackResponse));
                                }}
                            >
                                {({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
                                    <View style={{
                                        flexDirection: "row", justifyContent: "center", marginRight: Platform.OS != "web" ? -30 : 0
                                    }}>
                                        {loadingForm ?
                                            <ActivityIndicator color={esiColor.esiMainColor} />
                                            :
                                            formResults ?
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>
                                                    <Image source={require('../../../assets/images/GIF/cartItemUpdateSuccess.png')}
                                                        style={{ height: 300, width: '100%', resizeMode: 'contain' }}></Image>
                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <Text style={{ textAlign: "center", fontWeight: "bold", color: esiColor.itemColor }} >Successfully updated cart item.
                                                        </Text>
                                                    </View>
                                                </View>
                                                :
                                                <View style={{ flex: 1, flexDirection: "column", marginHorizontal: Platform.OS != "web" ? 20 : 10, justifyContent: "center" }}>

                                                    <View style={{ width: "90%", margin: "auto" }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Quantity*"
                                                            mode='outlined'
                                                            onChangeText={handleChange("Quantity")}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Quantity}
                                                            error={Boolean(touched.Quantity && errors.Quantity)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Quantity && errors.Quantity)}>
                                                            {touched.Quantity && errors.Quantity}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto", zIndex: 1 }}>
                                                        <EsiAutocompleteDropdown

                                                            key={"size" + values.Measurement_Type}
                                                            loading={false}
                                                            data={addCartItem?.Measurement_Type?.split(",").map((type) => ({ id: type, title: type }))}
                                                            label={"Select Size"}
                                                            onSelect={(item) => {
                                                                if (item?.id)
                                                                    setFieldValue("Measurement_Type", item.id)
                                                            }}
                                                            onClear={() => {
                                                            }}
                                                            getSuggestions={(q) => {
                                                                // apiCall(q);
                                                            }}
                                                            selected={{ id: values.Measurement_Type, title: values.Measurement_Type }}

                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Measurement_Type && errors.Measurement_Type)}>
                                                            {touched.Measurement_Type && errors.Measurement_Type}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto" }}>

                                                        <Text style={{ fontSize: 16, marginLeft: 5, color: esiColor.itemColor }}>Description:</Text>
                                                        <EsiTextAreaInput
                                                            error={Boolean(touched.Description && errors.Description)}
                                                            containerStyle={{ height: 170 }}
                                                            style={{ height: 170 }}
                                                            mode='outlined'
                                                            value={values.Description}
                                                            textareavalueUpdate={value => {
                                                                setFieldValue("Description", value)
                                                            }}
                                                            placeholder={'Please provide additional information'}
                                                            underlineColorAndroid={'transparent'}
                                                            theme={{
                                                                colors: {
                                                                    primary: "#27b6cc"
                                                                },
                                                            }}
                                                        />
                                                    </View>
                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <ESITextInput
                                                            style={{ width: "100%" }}
                                                            label="Pincode*"
                                                            mode='outlined'
                                                            onChangeText={(text: any) => { setFieldValue("Pincode", text.replace(/\D/gm, '')) }}
                                                            onBlur={
                                                                handleBlur
                                                            }
                                                            value={values.Pincode}
                                                            error={Boolean(touched.Pincode && errors.Pincode)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Pincode && errors.Pincode)}>
                                                            {touched.Pincode && errors.Pincode}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", margin: "auto", marginTop: 5 }}>
                                                        <EsiDatePicker
                                                            style={{ margin: "auto" }}
                                                            name={'Bidding_Expiry_Date'}
                                                            label='Bidding Expiry Date'
                                                            value={values.Bidding_Expiry_Date}
                                                            valueUpdate={item => {
                                                                setFieldValue("Bidding_Expiry_Date", item);
                                                            }}
                                                            error={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}
                                                        />
                                                        <HelperText type="error" visible={Boolean(touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date)}>
                                                            {touched.Bidding_Expiry_Date && errors.Bidding_Expiry_Date}
                                                        </HelperText>
                                                    </View>
                                                    <View style={{ width: "90%", minHeight: 70, margin: "auto", flexDirection: "row", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap" }}>
                                                        <Button style={{ marginTop: 15, backgroundColor: esiColor.globalButtonColor }} uppercase={false} mode="contained" onPress={() => {

                                                            handleSubmit()
                                                        }}>
                                                            <Text style={{ color: esiColor.itemButtenColor }}>Save</Text>
                                                        </Button>

                                                    </View>
                                                </View>}
                                    </View>)}
                            </Formik>
                        </Surface>

                    </ScrollView>
                </Modal>
            </View>
        </Surface>
    )
}
