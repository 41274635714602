import React, { useEffect, useState } from "react";
import { Dimensions, FlatList, Image, ImageBackground, StyleSheet, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { getMfhZones } from "../../state/actions/node-actions/mfh-actions";

export default function MfhZones(props) {
    const { navigation, dataReload, dataErrorReload } = props;
    const esiColor = useSelector(state => state.theme);
    const dispatch = useDispatch();

    const [AllPartners, setAllPartners] = useState([]);

    const callBackGetData = async (status, data) => {
        setIsLoading(false);
        if (status) {
            setAllPartners(data);
            await delay(data.length > 0 ? dataReload : dataErrorReload);
            partnersCall();
        } else {
            await delay(dataErrorReload);
            partnersCall();
        }
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const [loading, setIsLoading] = React.useState(false);

    useEffect(() => {
        partnersCall()
    }, []);

    const partnersCall = () => {
        setIsLoading(true);
        dispatch(getMfhZones({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Type": props.type ? props.type : ""
        },
            { PageNo: 1, PageSize: 10, SortBy: "MEEPAISA_ZONE_ID", SortOrder: "DESC" }
            , callBackGetData));
    }

    const handleClick = async (data) => {
        navigation('MFH', { Zone_Id: data?.Zone_Id })
    }

    const styles = StyleSheet.create({
        content: {

            backgroundColor: esiColor.CBColor,
            margin: 10,
            padding: 5,
            alignItems: 'center',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            elevation: 10,
            height: 125,
        },

        item: {
            borderRadius: 5,
            marginHorizontal: 5,
            padding: 4,
            height: 80, width: 150,
            shadowColor: esiColor.brandShadowColor,
        },
    });

    return (AllPartners.length > 0 &&
        <View style={styles.content}>
            <FlatList
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={AllPartners.slice(0, 10)}
                renderItem={({ item, index, separators }) => (
                    <View key={index} style={{ maxWidth: 125, minWidth: 110 }}>
                        <TouchableOpacity onPress={() => handleClick(item)} style={[styles.item]}>
                            <ImageBackground style={{ backgroundColor: esiColor.ImgBck, shadowColor: esiColor.brandShadowColor, shadowRadius: 4, height: 80, width: 100 }}>

                                <Image source={{ cache: "force-cache", uri: item?.Zone_Image + `?w=100&h=80` }}
                                    style={{ resizeMode: 'contain', height: 80, width: 100, borderRadius: 10 }}
                                ></Image>
                            </ImageBackground>
                        </TouchableOpacity>
                    </View>
                )}
            />
        </View>

    )
}


